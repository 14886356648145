import React, { Fragment } from 'react';
import { fileType } from '../../../../services/resources';
import KeyValueItem from '../../../KeyValueItem';

const NameCell = ({ value: name, row }) => {
  const { original: { type, url } } = row;
  const { name: typeName } = fileType(type); // icon: typeIcon

  return (
    <Fragment>
      {typeName === 'image' ? (
        <img
          src={url}
          alt={name}
          className="p-0"
          height="30"
          style={{
            height: '30px'
          }}
        />
      ) : (
        <span className="d-inline-block text-center" style={{ width: '40px' }}>
          <i className="far fa-file-alt" style={{ fontSize: '1.25rem' }} />
        </span>
      )}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        title="View in new tab"
        className="ml-2"
      >
        {name} <small className="ml-1"><i className="fas fa-external-link-alt" /></small>
      </a>
      <KeyValueItem
        title='URL'
        value={url}
        className="d-block d-sm-none"
      />
    </Fragment>
  );
};

export default NameCell;
