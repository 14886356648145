import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import {
  resetCurrentQuiz,
  getQuizOutcomes,
  setCurrentClassAnswers,
  setCurrentClassChallenges,
  setCurrentClassQuizzes,
  setCurrentTopicAnswers
} from '../../../actions';
import { canManageContent } from '../../../services/currentUser';
import Alert from '../../Alert';
import ManageButton from '../../btns/ManageButton';
import Outcomes from './Outcomes';
import Form from './Form';
import './style.css';

class Quiz extends Component {
  componentDidMount() {
    const {
      currentUser, course, currentTopic, currentQuiz
    } = this.props;

    if (!course || !currentTopic || !currentQuiz) {
      return null;
    }

    if (!currentUser?.id && !course.courseReadOnly) {
      return null;
    }

    const userId = pathOr(null, ['id'], currentUser);
    const topicId = pathOr(null, ['id'], currentTopic);
    const classId = pathOr(null, ['id'], course);

    if (currentUser?.id) {
      this.props.setCurrentTopicAnswers({ topicId, userId });
      this.props.setCurrentClassAnswers({ classId, userId });
      this.props.setCurrentClassChallenges({ classId });
      this.props.setCurrentClassQuizzes({ classId });

      this.props.getQuizOutcomes({
        quizId: currentQuiz.id,
        userId
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentUser, currentQuiz, course
    } = this.props;
    const quizForm = document.getElementById('class-quiz');

    if (!currentQuiz) {
      return null;
    }

    if (!currentUser?.id && !course.courseReadOnly) {
      return null;
    }

    const userId = pathOr(null, ['id'], currentUser);

    const { currentQuiz: prevCurrentQuiz } = prevProps;

    if (currentQuiz.id !== prevCurrentQuiz.id) {
      if (quizForm) {
        quizForm.reset();
      }

      this.props.getQuizOutcomes({
        quizId: currentQuiz.id,
        userId
      });
    }
  }

  onComponentUnmount() {
    this.props.resetCurrentQuiz();
  }

  render() {
    const {
      currentUser,
      currentTopic,
      currentQuiz,
      course
    } = this.props;

    if (!currentQuiz || !course) {
      return null;
    }

    if (!currentUser?.id && !course.courseReadOnly) {
      return null;
    }

    const topicId = pathOr(null, ['id'], currentTopic);
    const classId = pathOr(null, ['id'], course);
    const quizId = pathOr(null, ['id'], currentQuiz);
    const activeQuiz = pathOr(null, ['activeQuiz'], currentQuiz);

    return (
      <div className="row">
        <div className="col-12">

          <Outcomes />

          {!activeQuiz && (
            <Alert
              type="light"
              icon="fas fa-users-slash"
              className="mx-3 mt-3 border-bottom"
            >
              <h6>
                <small><i className="fas fa-circle text-danger" /></small> Inactive
              </h6>
              <div>
                This Quiz is not currently accepting user submissions.
              </div>
            </Alert>
          )}

          {!activeQuiz && canManageContent({ currentUser, course }) && (
            <Alert type="light" icon="fas fa-info-circle" className="mx-3">
              <div style={{ fontSize: '1rem' }}>
                After reviewing your Quiz,
                <ManageButton
                  className="btn-link text-lowercase px-1"
                  classId={classId}
                  topicId={topicId}
                  entryId={quizId}
                  contentType="quiz"
                  scrollToId="quiz-activeQuiz"
                  title="Quiz to make it active"
                  manageType="edit"
                >
                  make it active
                </ManageButton>
                to begin accepting user submissions.
              </div>
              <hr className="my-2 mx-0" />
              <div className="small font-weight-bold">
                <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
              </div>
            </Alert>
          )}

          <Form />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  currentQuiz,
  currentUser,
  currentClass,
  currentTopic
}) => ({
  currentQuiz,
  currentUser,
  course: currentClass,
  currentTopic
});

export default withRouter(connect(mapStateToProps, {
  resetCurrentQuiz,
  getQuizOutcomes,
  setCurrentTopicAnswers,
  setCurrentClassQuizzes,
  setCurrentClassAnswers,
  setCurrentClassChallenges
})(Quiz));
