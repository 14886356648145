import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { constructWidgetUrl } from '../../../../services/widgets';

const WidgetLink = ({
  className = '',
  image = '',
  title = 'Widget',
  body = '',
  height = '40',
  width = '40',
  linkTarget,
  url,
  includeEmail,
  includeExternalUserId
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const widgetUrl = useMemo(() => {
    return constructWidgetUrl({
      email: currentUser?.email || null,
      externalUserId: currentUser?.externalUserId || null,
      includeEmail,
      includeExternalUserId,
      url
    });
  }, [
    currentUser?.email,
    currentUser?.externalUserId,
    includeEmail,
    includeExternalUserId,
    url
  ]);

  return (
    <div className="col-sm-12 col-md-6 mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <img
            className="p-2 border shadow bg-white rounded d-block mr-2"
            src={image}
            alt={title}
            style={{
              height: `${height}px`,
              width: `${width}px`
            }}
          />
          <div
            className="w-100 pb-2 d-flex align-items-center justify-content-between flex-auto"
            style={{ borderBottom: '1px solid rgba(0, 0, 0, .5)' }}
          >
            <div>
              <div className="font-weight-bold widget-listView-title">
                {title}
              </div>
              <div className="widget-listView-body">{body}</div>
            </div>
            <a
              className={`${className} mx-1 btn btn-sm btn-white py-1`}
              target={linkTarget}
              rel="noopener noreferrer"
              href={widgetUrl}
              title={title}
              style={{
                minWidth: '70px',
                borderRadius: '1rem',
                fontSize: '.75rem'
              }}
            >
              GO
            </a>
          </div>
        </div>

        {/* <a
          className={`${className} mx-1 btn btn-sm btn-white py-1`}
          target={linkTarget}
          rel="noopener noreferrer"
          href={url}
          title={title}
          style={{
            minWidth: '70px',
            borderRadius: '1rem',
            fontSize: '.75rem'
          }}
        >
          GO
        </a> */}
      </div>
    </div>
  );
};

export default WidgetLink;
