import React from 'react';
import { useSelector } from 'react-redux';
import * as ROUTES from '../../../constants/routes';
import {
  courseCompleteCertificate,
  showCourseCompleteCertificateNotice
} from '../../../services/user';
import { mdyDate } from '../../../utils/date';
import Alert from '../../Alert';
import './style.css';

const Banner = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  const organization = useSelector((state) => state.organization);
  if (!currentUser || !course || !organization) return null;

  if (!showCourseCompleteCertificateNotice({ course, currentUser })) {
    return null;
  }

  const { slug: orgSlug } = organization;
  const { title } = course;
  // const uniqueCertificateIdSpec = pathOr(false, ['integration', 'uniqueCertificateIdSpec'], course);
  // const uniqueCertificateData = pathOr(false, ['integration', 'uniqueCertificateData'], currentUser);
  const completionCertificateData = courseCompleteCertificate({ course, currentUser });

  return (
    <div
      className="card border mb-3"
    >
      <div className="card-header p-3 h5 m-0">
        <i className="fas fa-check-circle text-keppel mr-1" /> Completion Certificate
      </div>
      <div className="card-body">
        <p>
          You have completed {title}!
        </p>
        {completionCertificateData && (
          <div>
            <Alert
              type=""
              icon="fas fa-award"
              className="alertWithIconSlim border"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="h6 mb-0">
                  {completionCertificateData.id && (
                    <small className="text-muted cert-data">
                      ID: {completionCertificateData.id}
                    </small>
                  )}
                  {completionCertificateData.issueDate && (
                    <small className="text-muted cert-data font-weight-bold">
                      { mdyDate(completionCertificateData.issueDate) }
                    </small>
                  )}
                </div>
                <a
                  title="View Certificate"
                  // className={`btn btn-sm text-nowrap ${uniqueCertificateIdSpec.oneTimeUse ? 'disabled btn-outline-secondary' : 'btn-outline-primary'}`}
                  className="btn btn-sm btn-primary text-nowrap"
                  // href={uniqueCertificateIdSpec.oneTimeUse ? '#' : `${ROUTES.COMPLETION_CERTIFICATE}/${orgSlug}/${completionCertificateData.id}`}
                  href={`${ROUTES.COMPLETION_CERTIFICATE}/${orgSlug}/${completionCertificateData.id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {/* {uniqueCertificateIdSpec.oneTimeUse ? 'Unavailable' : 'View Certificate'} */}
                  View <span className="d-none d-sm-inline">Certificate</span>
                </a>
              </div>
            </Alert>
          </div>
        )}

        {/* {uniqueCertificateIdSpec.oneTimeUse && (
          <div>
            <p>
              Completion certificates are available to save or print immediately upon course completion. To generate a new certificate, re-enroll and complete this course again.
            </p>
            <div className="d-flex justify-content-end">
              <button
                title="Re-enroll"
                className="btn btn-sm btn-outline-primary"
                onClick={this.reEnroll}
                style={{ minWidth: '100px'}}
              >
                Re-Enroll
              </button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Banner;
