import React from 'react';
import { TURBINE_LOGO } from '@apprentage/constants';
import './style.css';

const Logo = ({ orgName, orgLogo }) => (
  <div
    className="authpage-logo mb-3 d-flex justify-content-center"
  >
    <img
      src={orgLogo || TURBINE_LOGO}
      alt={orgName || 'Turbine Workforce'}
    />
  </div>
);

export default Logo;
