import { getAuth, getIdToken } from '@firebase/auth';
import { TW_API } from '../constants/urls';
import { app } from '../firebaseConfig';

async function request({
  method, endpoint, params, body, bypass = false
}) {
  if (typeof params === 'object') {
    const searchParams = new URLSearchParams(params);

    endpoint += `?${searchParams.toString()}`;
  }

  let idToken = null;
  const auth = getAuth(app);

  // https://firebase.google.com/docs/reference/js/auth.md#getidtoken
  if (!bypass && auth.currentUser) {
    idToken = await getIdToken(auth.currentUser, true);
  }

  const config = {
    method: method || 'GET',
    body
  };

  if (!bypass) {
    config.headers = {
      Authorization: `FirebaseBearer ${idToken}`
    };
  }

  const response = await fetch(TW_API + endpoint, config);

  return await response.json();
}

export async function get({
  endpoint,
  params,
  bypass
}) {
  return await request({
    method: 'GET',
    endpoint,
    params,
    bypass
  });
}

export async function put(endpoint, params, body) {
  return await request({
    method: 'PUT',
    endpoint,
    params,
    body
  });
}

export async function post(endpoint, params, body) {
  return await request({
    method: 'POST',
    endpoint,
    params,
    body
  });
}

// "delete" is a JS keyword
export async function requestDelete(endpoint, params, body, bypass = false) {
  return await request({
    method: 'DELETE',
    endpoint,
    params,
    body,
    bypass
  });
}
