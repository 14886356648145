import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isProgramManager, isProgramTrainer } from '@apprentage/utils';
import { setCurrentModal } from '../../actions';
import { withAuthorization } from '../Session';
import StudentDashboard from './StudentDashboard';
import ProgramDashboard from './ProgramDashboard';
import { MODAL_KEY_WELCOME } from '../../constants/modal';

const Dashboard = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const role = currentUser?.role || [];
  const onboarded = currentUser?.onboarded || null;

  useEffect(() => {
    if (!onboarded) {
      dispatch(setCurrentModal({ key: MODAL_KEY_WELCOME }));
    }
  }, [dispatch, onboarded]);

  if (
    orgType === 'community'
    && (isProgramManager(role) || isProgramTrainer(role))
  ) {
    return <ProgramDashboard />;
  }

  return <StudentDashboard />;
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Dashboard);
