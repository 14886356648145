import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../actions/Courses';
import { getGroups } from '../../actions/Groups';
import Loading from '../Loading';
import List from './List';

const CoursesList = ({
  courseIds,
  list, // Bypass fetching courses if you already have the list and just need to display it
  showCheckBoxes = false,
  selectedCourseIds,
  handleSelectCourse
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const coursesFetched = useSelector((state) => state.coursesFetched);
  const orgId = pathOr(null, ['id'], organization);
  const hiddenColumns = [];

  if (showCheckBoxes === false) {
    hiddenColumns.push('createdAt'); // Have to use random accessor for this Cell
  }

  useEffect(() => {
    if (!list || (list && Array.isArray(list) && !list.length)) {
      dispatch(getCourses({ orgId, courseIds }));
    }

    dispatch(getGroups({ orgId, type: 'course' }));
  }, []);

  if (!list && !coursesFetched) {
    return (
      <Loading text="Loading Courses..." className='position-relative' />
    );
  }

  return (
    <div
      data-cy="coursesList"
    >
      <List
        data={list || courses}
        courseIds={courseIds}
        selectedCourseIds={selectedCourseIds}
        handleSelectCourse={handleSelectCourse}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
};

export default CoursesList;
