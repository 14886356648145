import React from 'react';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';

const NameBlock = ({
  data,
  pictureHeight = '80',
  pictureWidth = '80',
  className = 'd-flex align-items-center justify-content-center mb-3'
}) => {
  const name = data?.name || '';
  const title = data?.title || '';
  const profilePicture = data?.profilePicture || null;

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <img
          data-demo="disabled"
          className="bd-placeholder-img rounded-circle mr-3"
          src={profilePicture || DEFAULT_PROFILE_PIC}
          alt={name || 'Profile Picture'}
          width={pictureWidth}
          height={pictureHeight}
          style={{
            ...(pictureHeight ? { height: `${pictureHeight}px` } : {}),
            ...(pictureWidth ? { width: `${pictureWidth}px` } : {})
          }}
        />
        <div>
          <div className="h4 m-0" data-demo="disabled">
            {name}
          </div>
          <div className="text-muted" data-demo="disabled">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameBlock;
