import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC, ICON_SETTINGS } from '@apprentage/constants';
import { setCurrentModal } from '../../../actions/Modals';
import { isActiveMembership } from '../../../services/currentUser';
import { MODAL_KEY_IMAGE_CROP } from '../../../constants/modal';
import {
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import {
  ProfilePictureIconContainer,
  ProfilePictureImgAndButtonContainer,
  ProfilePictureImgContainer
} from './styles';
import { fetchFlags } from '../../../services/flagsmith';

const ProfilePicture = ({
  children,
  className = '',
  height = '150',
  width = '150'
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);

  const openImageCropModal = () => {
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.users_readonly?.enabled && fetchedFlags?.users_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        }));
        return;
      }

      dispatch(setCurrentModal({
        key: MODAL_KEY_IMAGE_CROP
      }));
    });
  };

  if (!currentUser || !organization) return null;

  const { profilePicture, name, membership } = currentUser;

  return (
    <div
      className={`profile-picture-container ${className}`}
    >
      <ProfilePictureImgAndButtonContainer
        height={height}
        width={width}
      >
        <ProfilePictureImgContainer
          height={height}
          width={width}
        >
          <img
            src={profilePicture || DEFAULT_PROFILE_PIC}
            className="w-100"
            alt={name}
          />
        </ProfilePictureImgContainer>

        {isActiveMembership(membership) && (
          <ProfilePictureIconContainer
            className="btn btn-sm btn-white profilePicture-stop-propagation"
            onClick={openImageCropModal}
            role="button"
          >
            <i className={ICON_SETTINGS} />
          </ProfilePictureIconContainer>
        )}
      </ProfilePictureImgAndButtonContainer>

      {children || null}
    </div>
  );
};

export default ProfilePicture;
