import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import * as ROUTES from '../../../../constants/routes';
import { redirectToAdmin } from '../../../../services/admin';
import { canManageContent } from '../../../../services/currentUser';
import RenderMarkdown from '../../../ManageContent/RenderMarkdown';

const InstructionNotes = ({ className = '' }) => {
  // Redux
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  const currentUser = useSelector((state) => state.currentUser);

  if (!course || !currentTopic || !currentUser) return null;

  // Misc
  const classId = pathOr(null, ['id'], course);
  const topicId = pathOr(null, ['id'], currentTopic);
  const topicTitle = pathOr(null, ['title'], currentTopic);
  const instructionNotes = pathOr(null, ['instructionNotes'], currentTopic);
  const enableNewEditor = pathOr(false, ['enableNewEditor'], currentTopic);
  const canAddEditInstructionNotes = canManageContent({ currentUser, course });

  if (!instructionNotes && !canAddEditInstructionNotes) return null;

  return (
    <div
      id="instructors-only"
      className={className}
    >
      <div
        className="collapse"
        id="collapse-lesson-plan"
        data-parent="#instructors-only"
      >
        <div className="card">
          <div className="card-header p-3">
            <h5 className="m-0">
              Lesson Plan: <strong>{topicTitle}</strong>
            </h5>
          </div>
          <div className="card-content p-3">
            {instructionNotes ? (
              <RenderMarkdown
                source={instructionNotes}
                enableNewEditor={enableNewEditor}
              />
            ) : (
              <Fragment>
                {canAddEditInstructionNotes ? (
                  <Fragment>
                    <p>
                      Save time and provide invaluable guidance by adding teaching scripts, talking points and important takeaways for this topic.
                    </p>
                    <a
                      className="btn btn-primary btn-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={redirectToAdmin({
                        pathname: ROUTES.MANAGE_CURRENT_ENTRY,
                        data: {
                          scrollToId: 'topic-instructionNotes',
                          classId,
                          topicId,
                          contentType: 'topic',
                          editContent: true,
                          manageType: 'edit'
                        }
                      })}
                      title="Add Notes"
                    >
                      Add Notes
                    </a>
                  </Fragment>
                ) : (
                  <p>
                    No lesson plan.
                  </p>
                )}
              </Fragment>
            )}
          </div>

          {instructionNotes && canAddEditInstructionNotes && (
            <div className="card-footer p-3">
              <a
                className="btn btn-primary btn-md"
                target="_blank"
                rel="noopener noreferrer"
                href={redirectToAdmin({
                  pathname: ROUTES.MANAGE_CURRENT_ENTRY,
                  data: {
                    scrollToId: 'topic-instructionNotes',
                    classId,
                    topicId,
                    contentType: 'topic',
                    editContent: true,
                    manageType: 'edit'
                  }
                })}
                title="Edit Instruction Notes"
              >
                <i className="fas fa-pencil-alt mr-1" /> Edit
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstructionNotes;
