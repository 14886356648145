import {
  SET_MANAGE_MEMBERSHIP,
  RESET_MANAGE_MEMBERSHIP
} from './types';

export const setManageMembership = ({ membership, userId, orgId }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_MANAGE_MEMBERSHIP,
        manageMembership: {
          membership,
          userId,
          orgId
        }
      });
      resolve();
    });
  };
};

export const resetManageMembership = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_MANAGE_MEMBERSHIP
    });
  };
};
