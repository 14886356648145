import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import { withAuthorization } from '../Session';
import { resetClassCertificate } from '../../actions';
import { ATTENDANCE } from '../../constants/routes';
import { mdySimple, ySimple } from '../../utils/date';
import { totalSessionCredits } from '../../services/attendance';
import CourseInfo from './CourseInfo';
import CategoryCredits from './CategoryCredits';
import Loading from '../Loading';
import SignatureBlock from './SignatureBlock';
import './style.css';

const Certificate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);
  const classCertificate = useSelector((state) => state.classCertificate);

  // Misc
  const { name } = currentUser;
  const { managerIds } = userLocation;
  const completionCertificateDescription = pathOr(null, ['completionCertificateDescription'], course);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetClassCertificate());
    };
  }, []);

  if (!organization) {
    return (
      <Loading />
    );
  }

  const { name: orgName } = organization;
  const attendance = pathOr([], ['attendance'], classCertificate);

  if (!attendance || (attendance && Array.isArray(attendance) && attendance.length === 0)) {
    history.push(ATTENDANCE);
  }

  const eUnitTracking = pathOr(null, ['eUnitTracking'], classCertificate);
  const courseCodes = [];
  const categories = {};
  let attendanceDate = null;

  attendance.forEach((item) => {
    if (item.date && attendanceDate === null) {
      attendanceDate = item.date;
    }

    if (item.sessionTopic) {
      const { sessionTopic, sessionCredits } = item;
      const { category } = sessionTopic;

      if (category) {
        if (categories[category] === undefined) {
          categories[category] = 0;
        }

        categories[category] += totalSessionCredits({ sessionCredits });
      }
    }

    if (item.eUnitCodes) {
      item.eUnitCodes.forEach((eUnitCode) => {
        if (!courseCodes.includes(eUnitCode)) {
          courseCodes.push(eUnitCode);
        }
      });
    }
  });

  return (
    <main role="main" className="col-sm-12 py-3">
      {/* TODO BREADCRUMB */}
      <div
        className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3"
      >
        <h4 className="mt-2">
          {orgName}
        </h4>
        <button
          className="btn btn-md btn-outline-primary print-cert"
          title="Print"
          onClick={() => {
            window.print();
          }}
          type="button"
        >
          <i className="fas fa-print" /> Print
        </button>
      </div>

      {classCertificate && (
        <div className="">
          <p>
            {ySimple(attendanceDate)} SEMESTER RECORD OF ATTENDANCE
          </p>
          <div className="my-3">
            <CourseInfo
              title={classCertificate.title}
              eUnitTracking={eUnitTracking || null}
              courseCodes={courseCodes || null}
            />
          </div>
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th
                  scope="col"
                  colSpan={2}
                >
                  Sessions
                </th>
                <th scope="col">
                  Category
                </th>
                <th scope="col">
                  Level
                </th>
                <th
                  scope="col"
                  className="text-center"
                >
                  Credits
                </th>
                <th
                  scope="col"
                  className="text-center"
                >
                  Attendance Date
                </th>
                {/* <th scope="col">Last</th>
                <th scope="col">Handle</th> */}
              </tr>
            </thead>
            <tbody>
              {attendance && attendance.map((attendanceRow, i) => {
                const {
                  sessionCredits,
                  sessionTopic,
                  date
                } = attendanceRow;

                return (
                  <tr key={i}>
                    <td
                      colSpan={2}
                    >
                      {sessionTopic && sessionTopic.title ? sessionTopic.title : '-'}
                      <br />
                    </td>

                    <td>
                      {sessionTopic && sessionTopic.category ? sessionTopic.category : '-'}
                    </td>

                    <td>
                      {sessionTopic && sessionTopic.level ? sessionTopic.level : '-'}
                    </td>

                    <td className="text-center">
                      {sessionCredits && sessionCredits.map(({ number, type }, scIndex) => (
                        <div key={scIndex}>
                          <span>{number}</span>
                          {type && (
                            <span className="ml-2">
                              ({type})
                            </span>
                          )}
                        </div>
                      ))}
                    </td>

                    <td className="text-center">
                      {mdySimple(date)}
                    </td>
                    {/* <td>Otto</td>
                    <td>@mdo</td> */}
                  </tr>
                );
              })}

            </tbody>
          </table>

          <div className="my-3">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div className="mb-2">
                      <b data-demo="disabled">{ name }</b> successfully completed the following:
                    </div>
                    <CategoryCredits categories={categories} />
                  </td>
                  <td>
                    <SignatureBlock
                      userIds={managerIds}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {completionCertificateDescription && (
            <div className="my-3">
              {completionCertificateDescription}
            </div>
          )}
        </div>
      )}
    </main>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Certificate);
