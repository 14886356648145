import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { canViewRoleInTopNav, roleReadable } from '../../../services/currentUser';
import ProfilePicture from '../../ManageContent/ProfilePicture';

const TopNavEmail = styled.small`
  display: block;
  max-width: 276px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TopNavUserRole = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  display: flex;
  flex-flow: column;
`;

const TopNavUserDetails = ({ children }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';

  if (!currentUser?.id || !organization?.id) {
    return null;
  }

  const {
    email,
    role,
    testUser,
    name
  } = currentUser;

  return (
    <div
      className="py-3 px-4 border-bottom text-center position-relative"
      style={{
        minWidth: '250px'
      }}
    >
      <TopNavUserRole>
        {canViewRoleInTopNav(role) && (<span className="badge badge-secondary mb-1">{roleReadable({ role, orgType })}</span>)}
        {testUser && (<div className="badge badge-warning">Test User</div>)}
      </TopNavUserRole>

      <ProfilePicture
        className="d-flex align-items-center justify-content-center"
        height="80"
        width="80"
      />

      <div className="text-nowrap">
        {name}
      </div>
      <TopNavEmail className="text-nowrap mb-1">
        {email}
      </TopNavEmail>
      {children && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

export default TopNavUserDetails;
