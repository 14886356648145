import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import { NOTIFICATIONS } from '../../../constants/routes';
import PinnedAndUnpinnedNotifications from '../../NotificationList/PinnedAndUnpinnedNotifications';

const NotificationsMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const notificationsOrgList = useSelector((state) => state.notificationsOrgList);
  // Organization
  const orgId = organization?.id || '';
  // Pagination
  const initialListConfig = {
    orgId,
    classId: null,
    limit: 3
  };
  // Local State
  const [listConfig] = useState(initialListConfig);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const goToNotifications = () => {
    dispatch(resetCurrentModal());
    // course && currentEntry?.id ? `${CLASSES}/${course?.id}` : NOTIFICATIONS
    history.push(NOTIFICATIONS);
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal?.visible}
        close={handleClose}
        theme="dark"
        pos="top"
      >
        <div className='card'>
          <div className="card-header bg-dark d-flex align-items-center">
            <div className="m-0 text-white h5">
              Notifications
              {/* {course && currentEntry ? 'Course' : ''} Notifications */}
            </div>
          </div>
          <div className="card-body pb-0">
            <PinnedAndUnpinnedNotifications listConfig={listConfig} orgId={orgId} />
          </div>

          {Array.isArray(notificationsOrgList) && notificationsOrgList.length > 0 && (
            <div className="card-footer text-center">
              <button
                className='btn btn-link p-0'
                type='button'
                onClick={goToNotifications}
              >
                View all notifications
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default NotificationsMenu;
