import React from 'react';
import { useSelector } from 'react-redux';
import ApprenticeshipsList from '../ApprenticeshipsList';

const MyTrainingPrograms = ({ title = 'My Training Programs' }) => {
  const currentUser = useSelector((state) => state.currentUser);
  // Apprenticeship
  const apprenticeshipIds = currentUser?.apprenticeshipIds || [];

  return (
    <div className="card h-100">
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          <i className="fas fa-laptop-code mr-1" /> {title}
        </h5>
      </div>
      <div className="card-content overflow-hidden h-100">
        <ApprenticeshipsList ids={apprenticeshipIds} />
      </div>
    </div>
  );
};

export default MyTrainingPrograms;
