import React from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import * as ROUTES from '../../constants/routes';
import { canManageContent } from '../../services/currentUser';
import { redirectToAdmin } from '../../services/admin';
import './style.css';

const AdminMenu = ({
  scrollToId,
  clickButtonId,
  referenceIndex,
  id,
  containerClassName,
  buttonClassName,
  className,
  classId,
  topicId,
  entryId,
  contentType,
  addContent,
  editContent,
  deleteContent,
  children
}) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);

  const handleAdminMenuClick = () => {
    $(`topic-${topicId}`).collapse('hide');
  };

  if (!canManageContent({ currentUser, course })) {
    return null;
  }

  // TODO is this needed
  if (!addContent && !editContent && !deleteContent) {
    return null;
  }

  return (
    <div
      className={`dropdown ${containerClassName || ''}`}
    >
      <button
        className={`${buttonClassName || 'btn btn-md btn-link admin-menu-link h-100'}`}
        type="button"
        id={id || 'adminMenu'}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={handleAdminMenuClick}
        data-tip={`Edit ${contentType}`}
        data-place="bottom"
      >
        {children || (<i className="fas fa-ellipsis-v" />)}
      </button>
      <ReactTooltip />

      <div
        className={`dropdown-menu ${className || ''}`}
        aria-labelledby={id || 'adminMenu'}
      >
        {editContent === true && (
          <a
            className="dropdown-item"
            title="Edit"
            target="_blank"
            rel="noopener noreferrer"
            href={redirectToAdmin({
              pathname: ROUTES.MANAGE_CURRENT_ENTRY,
              data: {
                scrollToId,
                clickButtonId,
                manageType: 'edit',
                referenceIndex,
                classId,
                topicId,
                entryId,
                contentType
              }
            })}
          >
            <i className="fas fa-pencil-alt mr-1" /> Edit
          </a>
        )}
      </div>
    </div>
  );
};

export default AdminMenu;
