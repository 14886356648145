import React from 'react';
import { useSelector } from 'react-redux';
import { withAuthorization } from '../../../Session';
import { roleReadable } from '../../../../services/currentUser';
import InstructorsList from './InstructorsList';
import './style.css';

const Instructors = ({ className }) => {
  const organization = useSelector((state) => state.organization);
  const courseExperts = useSelector((state) => state.courseExperts);
  const { type: orgType } = organization;

  if (!courseExperts) return null;

  return (
    <div className={`card ${className}`}>
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          {roleReadable({ role: ['teacher'], orgType })}s
        </h5>
      </div>
      <div className="card-body py-0 h-100">
        <InstructorsList />
      </div>
    </div>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Instructors);
