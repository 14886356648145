import { referenceType, referenceTypes } from '../contentful';

function formatTopic({
  locked,
  title,
  description,
  sections,
  challenges,
  prerequisiteId,
  instructionNotes
}) {
  const data = {};

  if (locked !== undefined) {
    data.locked = {
      'en-US': locked
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (instructionNotes !== undefined) {
    data.instructionNotes = {
      'en-US': instructionNotes
    };
  }

  if (sections !== undefined) {
    data.sections = {
      'en-US': referenceTypes(sections)
    };
  }

  if (challenges !== undefined) {
    data.challenges = {
      'en-US': referenceTypes(challenges)
    };
  }

  if (prerequisiteId !== undefined) {
    data.prerequisite = {
      'en-US': referenceType(prerequisiteId)
    };
  }

  return { fields: { ...data } };
}

export default formatTopic;
