/**
 * Return active cohorts
 * @param {arr} courseAttendance
 * @param {string} cohortId
 * @returns {arr}
 */
export const courseAttendanceByCohort = ({ courseAttendance, cohortId }) => {
  const attendanceEntries = courseAttendance.filter((item) => {
    if (item.cohort && item.cohort.id && item.cohort.id === cohortId) {
      return item;
    }

    return false;
  });

  return attendanceEntries;
};

export const generateCourseRecord = ({ course, userCohorts, attendance }) => {
  let courseRecord = {};

  if (course) {
    const {
      id: classId,
      title: courseTitle
    } = course;

    const courseCohorts = userCohorts && userCohorts.filter((cc) => cc.classId === classId);

    if (attendance && attendance[classId] !== undefined) {
      const data = {
        title: courseTitle,
        id: classId,
        attendance: attendance[classId]
      };

      if (courseCohorts.length) {
        data.cohortAttendance = [];

        courseCohorts.forEach((cohort) => {
          const {
            id: cohortId, title, eUnitTracking, eUnits
          } = cohort;
          const cohortData = {
            title,
            id: cohortId
          };

          cohortData.attendance = courseAttendanceByCohort({
            courseAttendance: attendance[classId],
            cohortId
          });

          data.cohortAttendance.push(cohortData);

          if (eUnitTracking) {
            cohortData.eUnitTracking = eUnitTracking;
          }

          if (eUnits) {
            cohortData.eUnits = eUnits;
          }
        });
      }

      courseRecord = data;
    }
  }

  return courseRecord;
};

export const generateCourseRecords = ({
  userCourses, userCohorts, attendance
}) => {
  const courseRecords = [];

  if (userCourses && attendance) {
    userCourses.forEach((course) => {
      if (attendance[course.id] !== undefined) {
        const data = generateCourseRecord({ course, userCohorts, attendance });

        courseRecords.push(data);
      }
    });
  }

  return courseRecords;
};

export const totalSessionCredits = ({ sessionCredits }) => {
  if (!sessionCredits) return null;

  let totalCredits = 0;

  sessionCredits.forEach(({ number }) => {
    totalCredits += number;
  });

  return totalCredits;
};
