function statusFeedback(status) {
  // Answer approved, changes requested
  if (status === 'requestChanges') {
    return {
      title: 'Your solution was accepted, but you still have changes to make.',
      alertType: 'warning',
      iconClass: 'fas fa-highlighter'
    };
  }

  if (status === 'accepted') {
    return {
      title: 'Well done! Your submission was accepted.',
      alertType: 'success',
      iconClass: 'fas fa-check'
    };
  }

  if (status === 'rejected') {
    return {
      title: 'Please review the feedback, make changes and resubmit.',
      alertType: 'danger',
      iconClass: 'fas fa-flag'
    };
  }

  // status === 'inReview'
  // Answer submitted, but not yet reviewed
  return {
    title: 'Your submission is being reviewed.',
    alertType: 'info',
    iconClass: 'far fa-clock'
  };
}

export default statusFeedback;
