import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sortArrByArr } from '@apprentage/utils';
import { fetchUsers } from '../../../services/users';
import { canAccessDirectory } from '../../../services/currentUser';
import { TURBINE_ADMIN } from '../../../constants/urls';

const Supervisors = ({ userIds }) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // local state
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (userIds && Array.isArray(userIds) && userIds.length !== 0) {
      fetchUsers({
        userIds,
        orgId,
        select: ['id', 'name', 'title', 'profilePicture']
      }).then((response) => {
        if (mounted) {
          setSupervisors(response?.items);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [userIds, orgId]);

  if (!supervisors || !supervisors.length) {
    return null;
  }

  const whichSupervisors = supervisors ? sortArrByArr(supervisors, userIds, 'id') : null;

  return (
    <div className="supervisors d-flex flex-column">
      {whichSupervisors && whichSupervisors.map(({ name, id: userId }, i) => {
        if (canAccessDirectory(role, orgType)) {
          return (
            <a
              key={`supervisor-user-${userId}-${i}`}
              data-demo="disabled"
              className="btn-link text-left p-0"
              href={`${TURBINE_ADMIN}/org/users/${userId}`}
              role="button"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          );
        }

        return (
          <span
            key={`supervisor-user-${userId}-${i}`}
            data-demo="disabled"
            className='text-left'
          >
            {name}
          </span>
        );
      })}
    </div>
  );
};

export default Supervisors;
