import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FaBell } from 'react-icons/fa';
import { NOTIFICATIONS_PAGINATION_LIMIT } from '../../../constants/api';
import PinnedAndUnpinnedNotifications from '../../NotificationList/PinnedAndUnpinnedNotifications';

const Notifications = ({
  title = 'Notifications',
  limit = 10,
  link,
  className = ''
}) => {
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);
  // Organization
  const orgId = organization?.id || '';
  // Course
  const classId = course?.id || '';
  // Pagination
  const listConfig = useMemo(() => ({
    orgId,
    classId,
    limit: limit || NOTIFICATIONS_PAGINATION_LIMIT
  }), [orgId, classId]);

  return (
    <div className={`card card-dashboard--module ${className}`}>
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          <span className='d-flex align-items-center'>
            <FaBell />
            <span className='ml-1'>
              {title}
            </span>
          </span>
        </h5>
      </div>
      {orgId && classId && (
        <div className="card-body overflow-hidden">
          <PinnedAndUnpinnedNotifications listConfig={listConfig} orgId={orgId} entity="course" />
        </div>
      )}

      {link && (
        <div className="card-footer p-3 d-flex align-items-center justify-content-center bg-light">
          {typeof link === 'function' && link()}
        </div>
      )}
    </div>
  );
};

export default Notifications;
