import { pathOr } from 'ramda';
import { CLASSES } from '../constants/routes';
import isPublished from '../utils/isPublished';
import { client, flattenItems } from './contentful';

/**
 * Return active cohorts
 * @param {arr} cohorts
 * @returns {arr}
 */
export const activeCohorts = (courseCohorts) => {
  if (!courseCohorts) return false;

  const results = courseCohorts.filter((cohort) => {
    if (cohort.openCohortEnrollment) {
      return cohort.id;
    }

    return false;
  });

  return results.length ? results : null;
};

/**
 * Check if user is enrolled in cohort
 * @param {arr} userCohorts
 * @param {arr} courseCohorts
 * @returns {bool}
 */
export const courseCohortUserHasEnrolled = ({ userCohorts, courseCohorts }) => {
  let enrolledCohort = null;

  if (userCohorts && userCohorts.length) {
    userCohorts.forEach((userCohort) => {
      const { id: cohortId } = userCohort;
      // User has active ("openCohortEnrollment") Cohorts
      const cohort = courseCohorts ? courseCohorts.find((courseCohort) => courseCohort.id === cohortId) : null;

      if (cohort) {
        enrolledCohort = cohort;
      }
    });
  }

  return enrolledCohort;
};

/**
 * Checks if cohorts exists and have eUnitTracking
 * @param {obj} currentClass
 * @param {obj} currentUser
 */
export const activeCohortHasEUnitTracking = ({ courseCohorts = [], cohortIds = [] }) => {
  if (!cohortIds.length || !courseCohorts.length) {
    return false;
  }

  const userCourseCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));
  const userCohorts = activeCohorts(userCourseCohorts);
  const activeCohort = courseCohortUserHasEnrolled({ userCohorts, courseCohorts });

  if (activeCohort) {
    const { eUnitTracking } = activeCohort;

    if (eUnitTracking) return true;
  }

  return false;
};

const getTopic = ({
  currentUser, topics, courseCohorts
}) => {
  if (!topics) return null;

  if (!currentUser && topics[0] !== undefined) {
    return topics[0];
  }

  const { cohortIds, completedCourseTopicIds } = currentUser;
  const userCourseCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));
  // let hasEUnitTracking = false;

  // User or Course Cohorts missing, return first topic.
  if (!userCourseCohorts || !courseCohorts) return topics[0];

  // User & Course Cohorts
  // The logic below determines if sessionsTopicSync is activated
  // If it is, the topic returned will match topic index to the currentSession
  const userCohorts = activeCohorts(userCourseCohorts);
  const activeCohort = courseCohortUserHasEnrolled({ userCohorts, courseCohorts });

  if (activeCohort) {
    const { eUnitTracking } = activeCohort;
    const currentSession = pathOr(null, ['fields', 'currentSession'], eUnitTracking); // TODO cohort flatten

    if (currentSession) {
      const {
        fields: {
          sessionsTopicSync
        }
      } = eUnitTracking;

      if (!sessionsTopicSync) {
        // Topics aren't synced to the session, return the first topic.
        return topics[0];
      }

      const sessionIndex = currentSession - 1; // offset to match topic index

      if (isPublished(topics[sessionIndex])) {
        // Check if topic has sections
        if (topics[sessionIndex].sections) {
          const {
            prerequisite, // TODO topic flatten (preReqTopicId)
            locked
          } = topics[sessionIndex];

          // Topic Locked
          if (locked) {
            // Check if the topic's prerequisite has been completed
            if (prerequisite && completedCourseTopicIds && completedCourseTopicIds.includes(prerequisite.sys.id)) { // TODO course flatten
              // Prerequisite completed, deep-link into locked topic
              return topics[sessionIndex];
            }
          }

          return topics[sessionIndex];
        }
      }
    }
  }

  // TODO show warning alert when eUnitTracking "currentSession"
  // is greater than number of topics

  // No active Course Cohort return first topic.
  return topics[0];
};

const getTopicSection = (topic, i = 0) => {
  if (topic.title && topic.sections) {
    return topic.sections[i];
  }

  return null;
};

const getTopicEntry = (section) => {
  if (isPublished(section)) {
    return section;
  }

  return null;
};

export const canUseLeftNav = ({ course, currentUser }) => {
  const { id: classId, guidedCourse } = course;
  const completedCourseIds = pathOr([], ['completedCourseIds'], currentUser);
  let flag = true;

  if (guidedCourse) {
    // Prevent users from interacting with Left Nav
    flag = false;

    // If the course is complete, allow user to interact with Left Nav
    if (completedCourseIds.length > 0 && completedCourseIds.includes(classId)) {
      flag = true;
    }
  }

  return flag;
};

export const getCurrentClassRoute = ({
  currentUser,
  topics,
  classId,
  courseCohorts
}) => {
  let topicId = null;
  let entryId = null;

  if (topics === undefined) return null;

  const topic = topics ? getTopic({ currentUser, topics, courseCohorts }) : null;
  const firstTopicSection = topic ? getTopicSection(topic) : null;
  // TODO course flatten
  const firstTopicEntry = isPublished(firstTopicSection) ? getTopicEntry(firstTopicSection) : null;

  if (!topic || !firstTopicSection || !firstTopicEntry) return null;

  topicId = topic.id;
  entryId = firstTopicEntry.sys.id; // TODO course flatten

  return `${CLASSES}/${classId}/${topicId}/${entryId}`;
};

export const fetchCourses = ({
  orgId, courseIds, select, include = 1
}) => {
  const config = {
    content_type: 'class',
    include,
    'fields.orgId': orgId
  };

  if (select) {
    config.select = select.join(',');
  }

  if (courseIds) {
    config['sys.id[in]'] = courseIds.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const courses = items.length ? flattenItems(items) : null;

      resolve(courses);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchSyndicatedCourses = ({ orgIds, select }) => {
  const config = {
    content_type: 'class',
    include: 1,
    'fields.orgIds[in]': orgIds.join(',')
  };

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const syndicatedCourses = items.length ? flattenItems(items) : null;

      // setTimeout(() => {}, 250);
      resolve(syndicatedCourses);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const classPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    if (course.prerequisite) {
      hash[course.id] = course.prerequisite.sys.id; // TODO course flatten
    }
  });

  return hash;
};

export const topicPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    const { subjects } = course; // Course "Sections"

    if (subjects) {
      subjects.forEach((subject) => {
        if (isPublished(subject) && subject.fields.prerequisite) { // TODO course flatten
          hash[subject.sys.id] = subject.fields.prerequisite.sys.id; // TODO course flatten
        }
      });
    }
  });

  return hash;
};

export const classChallengesQuizzes = ({ courses }) => {
  const hash = {
    classes: { // TODO change to courses
      quizes: {},
      challenges: {}
    },
    topics: {
      quizes: {},
      challenges: {}
    }
  };

  if (!courses) return hash;

  courses.forEach((course) => {
    let numChallenges = 0;
    let numQuizzes = 0;

    const { id: classId, subjects } = course;

    if (subjects) {
      subjects.forEach((topic) => {
        if (isPublished(topic)) {
          const topicId = pathOr(undefined, ['sys', 'id'], topic); // TODO topic flatten
          const quizzes = pathOr(undefined, ['fields', 'quizes'], topic); // TODO topic flatten
          const challenges = pathOr(undefined, ['fields', 'challenges'], topic); // TODO topic flatten

          if (quizzes) {
            numQuizzes += quizzes.length;
            hash.topics.quizes[topicId] = quizzes.length; // TODO change to quizzes
          }

          if (challenges) {
            numChallenges += challenges.length;
            hash.topics.challenges[topicId] = challenges.length;
          }
        }
      });
    }

    hash.classes.challenges[classId] = numChallenges; // TODO change to courses
    hash.classes.quizes[classId] = numQuizzes; // TODO change to courses & quizzes
  });

  return hash;
};
