import { pathOr } from 'ramda';
import React from 'react';
import { mdySimple } from '../../../utils/date';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';

const Feedback = ({
  feedback, enableNewEditor = true
}) => {
  const feedbackList = pathOr(null, ['list'], feedback);

  if (!feedbackList) return null;

  return (
    <div>
      {feedbackList.map(({
        userName, userId, body, date
      }, i) => {
        return (
          <div
            key={`feedback-${i}`}
            className={`border px-2 py-1 ${feedbackList.length === 1 ? '' : 'mb-1'}`}
          >
            <RenderMarkdown
              source={body}
              enableNewEditor={enableNewEditor}
            />
            <div>
              <small
                className="text-muted"
                data-demo="disabled"
                data-name={userName}
                data-id={userId}
              >
                {userName ? `${userName} • ` : ''}{mdySimple(date)}
              </small>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Feedback;
