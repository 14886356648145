import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { redirectToAdmin } from '../../../services/admin';
import * as ROUTES from '../../../constants/routes';

const ManageButton = ({
  tooltip = false,
  tooltipText = 'Not visible to users',
  tooltipPlace = 'top',
  manageType = 'edit',
  classId,
  topicId,
  entryId,
  contentType,
  children,
  className = '',
  title,
  scrollToId,
  buttonText = ''
}) => {
  const data = {
    classId,
    topicId,
    entryId,
    title,
    contentType,
    editContent: true,
    manageType
  };

  if (scrollToId) {
    data.scrollToId = scrollToId;
  }

  return (
    <Fragment>
      <a
        className={`${className} text-capitalize text-nowrap`}
        target="_blank"
        rel="noopener noreferrer"
        href={redirectToAdmin({
          pathname: ROUTES.MANAGE_CURRENT_ENTRY,
          data
        })}
        title={tooltip ? '' : `${manageType} ${title}`}
        data-tip={tooltipText}
        data-place={tooltipPlace}
      >
        {children || (
          <Fragment>
            <i className="fas fa-pencil-alt mr-1" /> {buttonText || manageType}
          </Fragment>
        )}
      </a>
      {tooltip && (
        <ReactTooltip />
      )}
    </Fragment>
  );
};

export default ManageButton;
