import React from 'react';
import Table from './Table';
import EmployersCell from './EmployersCell';
import DateCell from '../../ManageContent/Tables/DateCell';
import TitleCell from './TitleCell';

const List = ({ data, hiddenColumns = [] }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: TitleCell
      },
      {
        Header: 'Employers',
        accessor: 'employerIds',
        Cell: EmployersCell
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: DateCell
      }
    ],
    []
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Apprenticeships</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
    />
  );
};

export default List;
