import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaEyeSlash,
  FaUser,
  FaCheckCircle,
  FaArrowRight
} from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { APPRENTICESHIP_PUZZLE } from '../../../constants/assets';
import Modal from '../../Modal';
import { handleEditUserDetails } from '../../../actions';
import goToElem from '../../../utils/goToElem';

const ApprenticeInfo = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const currentUser = useSelector((state) => state.currentUser);
  const userProfile = useSelector((state) => state.userProfile);
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  // Current User
  const userId = currentUser?.id || '';
  const phone = currentUser?.phone || '';
  const mobilePhone = currentUser?.mobilePhone || '';
  // User Profile
  const userProfileId = userProfile?.id || '';
  const hasPhone = phone || mobilePhone;

  const handlePrimaryAction = () => {
    if (!hasPhone || !userProfileId) {
      dispatch(handleEditUserDetails({
        orgId,
        userId,
        activeTab: !hasPhone ? 'profile' : 'private'
      }));
    }

    dispatch(resetCurrentModal());
    goToElem('root');
  };

  return (
    <Modal
      visible={currentModal.visible}
      close={false}
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      pos="top"
    >
      <div className="card">
        <div className="card-header h3 mb-0 text-center">
          <h5 className="m-0">
            Apprentice Program
          </h5>
        </div>
        <div className="card-content">
          <img
            src={APPRENTICESHIP_PUZZLE}
            className="w-100"
            alt='Apprenticeship'
          />
          <div className='p-3'>
            <p>
              You are part of an apprenticeship hosted by <strong>{orgName}</strong>.
            </p>
            <p>
              Additional information is needed before your participation.
            </p>
            <h6>
              Next Steps
            </h6>
            <div className='ml-2 mb-2'>
              <span className='d-flex align-items-center'>
                {hasPhone ? (
                  <FaCheckCircle className="text-success" />
                ) : (
                  <FaUser className='font-weight-bold' />
                )}
                <span className='ml-2'>
                  {hasPhone ? 'Phone Number' : 'Enter your phone number & click "Save"'}
                </span>
              </span>
            </div>
            <div className='ml-2'>
              <span className='d-flex align-items-center'>
                {userProfileId ? (
                  <FaCheckCircle className="text-success" />
                ) : (
                  <FaEyeSlash className='font-weight-bold' />
                )}
                <span className='ml-2'>
                  {userProfileId ? 'Private Information' : 'Enter sensitive information & click "Save"'}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-center align-items-center">
          <button
            className="btn btn-primary btn-md"
            onClick={handlePrimaryAction}
            type="button"
          >
            <span className="d-flex align-items-center">
              {hasPhone && userProfileId ? (
                <span>
                  I'm Done
                </span>
              ) : (
                <span>
                  {hasPhone ? 'Enter Private Information' : 'Enter Phone Number'}
                </span>
              )}
              <FaArrowRight className="ml-2" />
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ApprenticeInfo;
