import React from 'react';
import { VELA_LOGO_BLACK } from '@apprentage/constants';
import { useDispatch } from 'react-redux';
import { redirectToVela } from '../../services/auth';
import Alert from '../Alert';
import { setCurrentModal } from '../../actions';
import { MODAL_KEY_VELA_HANDOFF } from '../../constants/modal';

const VelaBanner = () => {
  const dispatch = useDispatch();

  return (
    <div className="row mt-4">
      <div className="col">
        <Alert
          type="light"
          icon="fas fa-info-circle"
          className="m-0"
        >
          <div className="d-flex align-items-center justify-content-between">
            <img
              src={VELA_LOGO_BLACK}
              alt="VELA"
              height="60"
              style={{
                height: '60px'
              }}
            />
            <div className="d-none d-sm-block">
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={() => {
                  dispatch(
                    setCurrentModal({
                      key: MODAL_KEY_VELA_HANDOFF
                    })
                  );
                }}
              >
                Open VELA
              </button>
            </div>
          </div>
          <hr className="my-2 mx-0" />
          <p className="font-weight-bold mb-1">
            We use VELA for our apprenticeship logbooks.
          </p>
          <div>
            Tell VELA what task you completed and she logs the date, hours spent
            and relevant competencies
          </div>
          <div className="d-block d-sm-none mt-3">
            <a
              className="btn btn-primary btn-md"
              href={redirectToVela()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open VELA
            </a>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default VelaBanner;
