import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { SET_ORG } from './types';
import { getLocations } from './Locations';
import { fetchOrg } from '../services/organizations';

export const getOrg = ({ orgId, slug, include = 3 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId && !slug) {
        const orgFailedError = 'An id or slug are required to get an Organization';

        toast.error(orgFailedError);
        reject(new Error(orgFailedError));
      }

      fetchOrg({ orgId, slug, include }).then((organization) => {
        const courseGroupIds = pathOr(null, ['courseGroupIds'], organization);

        if (!organization?.id) {
          reject(new Error('Something went wrong, try again.'));
          console.error('fetchOrg: No Organization found.', slug);
          return;
        }

        // SET LOCATIONS by ORG ID
        dispatch(getLocations({ orgId: organization.id })).then(() => {
          const data = {
            organization,
            courseGroupIds
          };

          dispatch({ type: SET_ORG, ...data });
          resolve(data);
        });
      }).catch((error) => {
        toast.error(error.message);
        reject(error);
      });
    });
  };
};
