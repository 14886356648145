import React from 'react';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal } from '../../../actions';
import { CLASSES } from '../../../constants/routes';
import contentTypeIcon from '../../../services/contentTypeIcon';
import isPublished from '../../../utils/isPublished';
import AdminMenu from '../../AdminMenu';
import { MODAL_KEY_COURSE_MENU } from '../../../constants/modal';

const RenderSections = ({ topic }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const course = useSelector((state) => state.currentClass);
  const currentEntry = useSelector((state) => state.currentEntry);
  const currentModal = useSelector((state) => state.currentModal);
  // Misc
  const { id: classId } = course;
  const sections = pathOr(null, ['sections'], topic); // TODO course flatten
  const topicTitle = pathOr(null, ['title'], topic); // TODO course flatten
  const id = pathOr(null, ['id'], topic);

  if (!sections) {
    return null;
  }

  const isActiveEntry = (section) => {
    if (currentEntry.id === section.sys.id) { // TODO course flatten
      return true;
    }

    return false;
  };

  const buttonClassNames = (section) => { // TODO use classNames npm pkg
    const btnClasses = ['btn', 'btn-link', 'text-truncate'];
    const contentType = pathOr(null, ['sys', 'contentType', 'sys', 'id'], section); // TODO course flatten

    if (isActiveEntry(section)) {
      btnClasses.push('nav-current');
    }

    btnClasses.push(`type-${contentType}`);

    return btnClasses.join(' ');
  };

  const handleLinkClick = ({ topicId, entryId }) => {
    if (currentModal && currentModal.key === MODAL_KEY_COURSE_MENU) {
      dispatch(resetCurrentModal());
    }

    history.push(`${CLASSES}/${classId}/${topicId}/${entryId}`);
  };

  return (
    <ul className="nav nav-sidenav-sub_list bg-light">
      {sections.map((section, i) => {
        if (!isPublished(section)) {
          return null;
        }

        const contentType = pathOr(null, ['sys', 'contentType', 'sys', 'id'], section); // TODO course flatten
        const entryId = pathOr(null, ['sys', 'id'], section); // TODO course flatten
        const title = pathOr(null, ['fields', 'title'], section); // TODO course flatten

        return (
          <li key={i}>
            <button
              className={buttonClassNames(section)}
              onClick={() => {
                handleLinkClick({ topicId: id, entryId });
              }}
              type="button"
              title={title}
            >
              {contentTypeIcon({ contentType, active: isActiveEntry(section) })} {title}
            </button>
            <AdminMenu
              id={`admin-menu-${entryId}-${i}`}
              classId={classId}
              topicId={id}
              entryId={entryId}
              title={topicTitle}
              contentType={contentType}
              editContent
              className="admin-menu--left-nav dropdown-menu-right"
            // addContent
            // addTypes={['topic']}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default RenderSections;
