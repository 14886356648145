import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withRouter } from 'react-router';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import LeftNav from '../../Class/LeftNav';
import { CLASSES } from '../../../constants/routes';
import { canUseLeftNav } from '../../../services/courses';

const initialState = {
  loading: false
};

class CourseTopics extends Component {
  state = {
    ...initialState,
    ...this.props.currentModal.data
  }

  handleFieldChange = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value
    });
  }

  handleClose = () => {
    this.props.resetCurrentModal();
    // this.clearForm();
  }

  goToCourseDashboard = () => {
    const { history, course } = this.props;

    history.push(`${CLASSES}/${course.id}`);
    this.props.resetCurrentModal();
  }

  render() {
    const { currentModal, course, currentUser } = this.props;

    const useLeftNav = canUseLeftNav({ course, currentUser });

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        close={this.handleClose}
        theme="dark"
        pos="top"
      >
        <div className="card border-0">
          <div className="card-header bg-dark text-white">
            <h1 className="h5 m-0">
              <i className="fas fa-cubes mr-1" /> Sections
            </h1>
          </div>
          <div className="card-body p-0">
            <LeftNav
              header={false}
              className={`col-12 border-top p-0 ${useLeftNav ? '' : 'pointer-events-none'}`}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  currentUser,
  currentClass
}) => ({
  currentModal,
  currentUser,
  course: currentClass
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    resetCurrentModal
  })
)(CourseTopics);
