import { pathOr } from 'ramda';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { EXTERNAL_LINK } from '../../constants/icons';
import { MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import { redirectToAdmin } from '../../services/admin';

const EditClassBtn = () => {
  const course = useSelector((state) => state.currentClass);

  if (!course) return null;

  const classId = pathOr(null, ['id'], course);
  const classTitle = pathOr(null, ['title'], course);

  return (
    <Fragment>
      <a
        className="btn btn-sm btn-outline-secondary d-flex align-items-center"
        target="_blank"
        rel="noopener noreferrer"
        href={redirectToAdmin({
          pathname: MANAGE_CURRENT_ENTRY,
          data: {
            id: `admin-menu-${classId}`,
            classId,
            title: classTitle,
            contentType: 'class',
            editContent: true,
            manageType: 'edit'
          }
        })}
        data-tip="Not visible to Users"
        data-place="bottom"
      >
        Course Console <small><i className={`${EXTERNAL_LINK} ml-2`} /></small>
      </a>
      <ReactTooltip />
    </Fragment>
  );
};

export default EditClassBtn;
