import { pathOr } from 'ramda';
import { client, flattenItem } from '../services/contentful';
import isPublished from '../utils/isPublished';
import { setCurrentAnswerByChallenge } from './Answers';
import { setCurrentQuiz } from './Quizes'; // TODO rename file "Quizzes"
import { SET_CURRENT_ENTRY, RESET_CURRENT_ENTRY } from './types';

export const setCurrentEntry = (contentEntryId) => {
  return (dispatch, getState) => {
    return client.getEntry(contentEntryId).then((response) => {
      const currentEntry = isPublished(response) ? flattenItem(response) : null;

      // Challenge
      const { contentType, id: entryId } = currentEntry;

      // Challenge
      if (contentType === 'challenge') {
        const { currentUser } = getState();
        const userId = pathOr(null, ['id'], currentUser);

        dispatch(setCurrentAnswerByChallenge(entryId, userId));
      }

      // Quiz
      if (contentType === 'quiz') {
        dispatch(setCurrentQuiz(currentEntry));
      }

      dispatch({
        type: SET_CURRENT_ENTRY,
        currentEntry
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentEntry = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ENTRY });
  };
};
