import React from 'react';
import { useSelector } from 'react-redux';
import { percentComplete } from '@apprentage/utils';
import ProgressBar from '../../ProgressBar';
import './style.css';

const Progress = ({
  classId,
  className = ''
}) => {
  // Redux
  const currentAnswers = useSelector((state) => state.currentAnswers);
  const classChallenges = useSelector((state) => state.classChallenges);

  if (!classId || !classChallenges) return null;

  const challengeAnswers = { [classId]: 0 };
  const acceptedAnswers = { [classId]: 0 };
  const changesAnswers = { [classId]: 0 };
  const rejectedAnswers = { [classId]: 0 };
  const inReviewAnswers = { [classId]: 0 };

  if (currentAnswers && currentAnswers.length) {
    currentAnswers.forEach((answer) => {
      if (challengeAnswers[answer?.classId] !== undefined) {
        challengeAnswers[answer?.classId] += 1;
      }

      if (answer?.status) {
        if (answer?.status === 'accepted') {
          if (acceptedAnswers[answer?.classId] !== undefined) {
            acceptedAnswers[answer?.classId] += 1;
          }
        } else if (answer?.status === 'requestChanges') {
          if (changesAnswers[answer?.classId] !== undefined) {
            changesAnswers[answer?.classId] += 1;
          }
        } else if (answer?.status === 'rejected') {
          if (rejectedAnswers[answer?.classId] !== undefined) {
            rejectedAnswers[answer?.classId] += 1;
          }
        } else if (answer?.status === 'inReview') {
          if (inReviewAnswers[answer?.classId] !== undefined) {
            inReviewAnswers[answer?.classId] += 1;
          }
        }
      }
    });
  }

  const acceptedPercent = percentComplete({
    current: acceptedAnswers[classId],
    total: classChallenges[classId]
  });
  const changesPercent = percentComplete({
    current: changesAnswers[classId],
    total: classChallenges[classId]
  });
  const rejectedPercent = percentComplete({
    current: rejectedAnswers[classId],
    total: classChallenges[classId]
  });
  const inReviewPercent = percentComplete({
    current: inReviewAnswers[classId],
    total: classChallenges[classId]
  });

  // TODO include quizzes in progress
  // If no challenges, don't show progress bar
  if (classChallenges[classId] === undefined || classChallenges[classId] === 0) {
    return null;
  }

  return (
    <div className={className}>
      <div className="class-progress">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            {challengeAnswers[classId]} / {classChallenges[classId]} Challenges
          </div>
          <div>
            <span className="badge badge-secondary">{acceptedPercent}</span> Accepted
          </div>
        </div>
        <div className="progress">
          <ProgressBar
            title={`${inReviewAnswers[classId]} In Review`}
            className="progress-bar-striped bg-info"
            valueNow={inReviewPercent}
            style={{ width: inReviewPercent }}
            text={inReviewAnswers[classId] === 0 ? '' : inReviewAnswers[classId]}
          />

          <ProgressBar
            title={`${rejectedAnswers[classId]} Rejected`}
            className="progress-bar-striped bg-danger"
            valueNow={rejectedPercent}
            style={{ width: rejectedPercent }}
            text={rejectedAnswers[classId] === 0 ? '' : rejectedAnswers[classId]}
          />

          <ProgressBar
            title={`${changesAnswers[classId]} Accepted with Changes`}
            className="progress-bar-striped bg-warning"
            valueNow={changesPercent}
            style={{ width: changesPercent }}
            text={changesAnswers[classId] === 0 ? '' : changesAnswers[classId]}
          />

          <ProgressBar
            title={`${acceptedAnswers[classId]} Accepted`}
            className="progress-bar-striped bg-success"
            valueNow={acceptedPercent}
            valueMin={acceptedAnswers[classId] === 0 ? '' : '10%'}
            style={{ width: acceptedPercent, minWidth: acceptedAnswers[classId] === 0 ? 0 : '10%' }}
            text={acceptedAnswers[classId] === 0 ? '' : acceptedAnswers[classId]}
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
