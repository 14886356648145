import { toast } from 'react-toastify';
import { client, flattenItems } from '../services/contentful';
import serializeQuiz from '../serializers/quiz';
import {
  SET_CURRENT_QUIZ,
  RESET_CURRENT_QUIZ,
  SET_QUIZ_OUTCOMES_SUCCESS,
  // Content Flow
  SET_CURRENT_CLASS_QUIZZES_SUCCESS,
  SET_CURRENT_CLASS_QUIZ_OUTCOMES_SUCCESS,
  SET_CURRENT_TOPIC_QUIZ_OUTCOMES_SUCCESS,
  RESET_CURRENT_CLASS_QUIZZES,
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  RESET_CURRENT_TOPIC_QUIZ_OUTCOMES
} from './types';
import { topicApproval } from './Assessments';
import { createOutcome, fetchQuizOutcomes } from '../services/quizzes';

// Content Flow

// TODO - implement similar function like "setCurrentAnswers"
// to create <Progress /> like challenges
// setCurrentUserQuizOutcomes = () => {...}

export const setCurrentClassQuizzes = ({ classId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'quiz',
      'fields.classId': classId
    };

    return client.getEntries(config).then(({ items }) => {
      const currentClassQuizzes = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_CLASS_QUIZZES_SUCCESS,
        currentClassQuizzes
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const setCurrentClassQuizOutcomes = ({ classId, userId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'quizOutcome',
        'fields.classId': classId
      };

      if (userId) {
        config['fields.userId'] = userId;
      }

      return client.getEntries(config).then(({ items }) => {
        const currentClassQuizOutcomes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_CLASS_QUIZ_OUTCOMES_SUCCESS,
          currentClassQuizOutcomes
        });
        setTimeout(() => {
          resolve(currentClassQuizOutcomes);
        }, 250);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setCurrentTopicQuizOutcomes = ({ topicId, userId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'quizOutcome',
        'fields.topicId': topicId
      };

      if (userId) {
        config['fields.userId'] = userId;
      }

      return client.getEntries(config).then(({ items }) => {
        const currentTopicQuizOutcomes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_TOPIC_QUIZ_OUTCOMES_SUCCESS,
          currentTopicQuizOutcomes
        });
        setTimeout(() => {
          resolve(currentTopicQuizOutcomes);
        }, 250);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

// end Content Flow

export const setCurrentQuiz = (currentEntry) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_QUIZ,
      currentQuiz: serializeQuiz(currentEntry)
    });
  };
};

export const getQuizOutcomes = ({ quizId, userId, classId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchQuizOutcomes({
        quizId,
        userId,
        classId
      }).then((outcomes) => {
        dispatch({
          type: SET_QUIZ_OUTCOMES_SUCCESS,
          currentQuizOutcomes: outcomes
        });
        resolve(outcomes);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const updateQuizOutcomes = (quizOutcome) => {
  return (dispatch, getState) => {
    const { currentQuizOutcomes } = getState();
    let updatedOutcomes = [];

    if (currentQuizOutcomes) {
      updatedOutcomes = [...currentQuizOutcomes];
    }

    if (quizOutcome) {
      updatedOutcomes.push(quizOutcome);
    }

    return new Promise((resolve) => {
      dispatch({
        type: SET_QUIZ_OUTCOMES_SUCCESS,
        currentQuizOutcomes: updatedOutcomes
      });

      resolve(updatedOutcomes);
    });
  };
};

export const createQuizOutcome = (data) => {
  return (dispatch, getState) => {
    const {
      organization: { id: orgId },
      currentClass: { id: classId },
      currentTopic: { id: topicId },
      currentQuiz: { id: quizId },
      currentUser: {
        id: userId,
        locationId,
        apprenticeshipIds
      }
    } = getState();

    const dataToSave = {
      ...data,
      userId,
      orgId,
      locationId,
      classId,
      quizId,
      topicId
    };

    // Temporary solution
    // TODO Pass apprenticeship Id in data argument
    if (!dataToSave.apprenticeshipId) {
      if (apprenticeshipIds && Array.isArray(apprenticeshipIds) && apprenticeshipIds.length) {
        const [firstApprenticeshipId] = apprenticeshipIds;

        dataToSave.apprenticeshipId = firstApprenticeshipId;
      }
    }

    return new Promise((resolve) => {
      createOutcome(dataToSave).then(() => {
        toast.info('Processing...', {
          toastId: 'processingContentFlow',
          autoClose: false
        });

        dispatch(getQuizOutcomes({ quizId, userId })).then(() => {
          // Check ASSESS ENGINE if
          // Topic and Course are complete
          dispatch(setCurrentClassQuizOutcomes({ classId, userId })).then(() => {
            dispatch(setCurrentTopicQuizOutcomes({ topicId, userId })).then(() => {
              dispatch(topicApproval({ catalyst: 'quiz' })).then(() => {
                resolve();
                toast.dismiss();
              });
            });
          });
        });
      });
    });
  };
};

export const resetCurrentClassQuizzes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZZES });
  };
};

export const resetCurrentClassQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZ_OUTCOMES });
  };
};

export const resetCurrentTopicQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_QUIZ_OUTCOMES });
  };
};

export const resetCurrentQuiz = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_QUIZ });
  };
};
