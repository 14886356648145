import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import copyToClipBoard from '../utils/copyToClipBoard';

const InfoItem = styled.div`
  text-overflow: ${(props) => (props.truncate ? 'ellipsis' : 'initial')};
  overflow: hidden;
  max-width: ${(props) => (props.truncate ? '150px' : 'none')};
`;

/**
 *
 * @param {string} param.title REQUIRED
 * @param {string} param.value REQUIRED : this is the value to copy
 * @param {boolean} param.copy enables copy button
 * @param {string} param.copyButtonTitle - title on button, visible on cursor hover for accessibility
 * @param {string} param.copySuccessMsg - toast message after copying
 * @param {string} param.className
 * @returns UI that shows title and value with optional copy button
 */
const KeyValueItem = ({
  title,
  hideTitle = false,
  value,
  copy = true,
  copyButtonTitle,
  copySuccessMsg,
  className,
  labelClassName = '',
  truncate = true
}) => {
  return (
    <div
      className={`small d-flex align-items-center ${className || ''}`}
    >
      {!hideTitle && (
        <div className={labelClassName}>{title}:</div>
      )}

      <InfoItem
        className='text-nowrap'
        data-demo="disabled"
        truncate={truncate}
      >
        {value}
      </InfoItem>

      {copy && (
        <button
          type="button"
          title={copyButtonTitle || `Copy ${title}`}
          className='btn btn-sm btn-link m-0 p-1'
          onClick={() => {
            copyToClipBoard(value);
            toast.success(copySuccessMsg || `${title} Copied!`);
          }}
        >
          <i className='fas fa-copy' />
        </button>
      )}
    </div>
  );
};

export default KeyValueItem;

KeyValueItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  copy: PropTypes.bool,
  copyButtonTitle: PropTypes.string,
  copySuccessMsg: PropTypes.string,
  className: PropTypes.string
};
