import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Redirect, useParams, useHistory
} from 'react-router-dom';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import { IMAGE_MAINTENANCE } from '@apprentage/constants';
import {
  setCurrentClass,
  addClassToUserClasses
} from '../../../actions';
import { SLASH } from '../../../constants/routes';
import Loading from '../../Loading';
import {
  FLAG_MAINTENANCE_MODE,
  FLAG_USERS_READONLY,
  NOTICE_ACCOUNT_CREATION_BODY,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import ConfirmationToast from '../../Toasts/ConfirmationToast';

// TODO is this component deprecated?
const Enroll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const flags = useFlags([FLAG_USERS_READONLY, FLAG_MAINTENANCE_MODE]);
  const flagsStatus = useFlagsmithLoading();
  // Params
  const classId = params?.classId || '';
  const userId = params?.userId || '';
  // Local State
  const [showMaintenanceToast, setShowMaintenanceToast] = useState(false);
  const [showUsersReadonlyToast, setShowUsersReadonlyToast] = useState(false);

  useEffect(() => {
    if (!flagsStatus?.isFetching) {
      if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
        setShowMaintenanceToast(true);
      } else if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
        setShowUsersReadonlyToast(true);
      } else if (classId && userId) {
        dispatch(addClassToUserClasses({ classId, userId }).then(() => {
          dispatch(setCurrentClass({
            classId,
            redirectToClassDashboard: true,
            history
          }));
        }));
      }
    }
  }, [flagsStatus?.isFetching, flags?.maintenance_mode, flags?.users_readonly]);

  if (!classId || !userId) {
    return <Redirect to={SLASH} />;
  }

  if (showMaintenanceToast) {
    return (
      <>
        <div className="auth-container" />
        <ConfirmationToast
          title={NOTICE_MAINTENANCE_MODE_TITLE}
          subtitle={NOTICE_MAINTENANCE_MODE_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  if (showUsersReadonlyToast) {
    return (
      <>
        <div className="auth-container" />
        <ConfirmationToast
          title={NOTICE_USERS_READONLY_TITLE}
          subtitle={NOTICE_ACCOUNT_CREATION_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  return (
    <Loading text="Processing" />
  );
};

export default Enroll;
