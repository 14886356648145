import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortArrByKey, sortArrByArr } from '@apprentage/utils';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { withAuthorization } from '../Session';
import { SLASH } from '../../constants/routes';
import Card from './Card';
import {
  getCourses,
  getGroups,
  setCurrentAnswers,
  resetCurrentClass,
  setCurrentModal,
  getOrg
} from '../../actions';
import {
  canAccessByRole,
  canAccessCourseCatalog,
  canChangeCourseDisplayType
} from '../../services/currentUser';
import isPublished from '../../utils/isPublished';
import ListDisplayType from './ListDisplayType';
import Loading from '../Loading';
import Collapse from '../ManageContent/Collapse';
import CollapseControl from './CollapseControl';
import { MODAL_KEY_WELCOME } from '../../constants/modal';

const Classes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const groups = useSelector((state) => state.groups);
  const courseGroupIds = useSelector((state) => state.courseGroupIds);
  // Local State
  const [activeDisplayType, setActiveDisplayType] = useState('groups');
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState(false);
  // Misc
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableCourseCatalog = organization?.enableCourseCatalog || null;
  const role = currentUser?.role || [];
  const userId = currentUser?.id || '';
  const onboarded = currentUser?.onboarded || null;

  const changeDisplayType = (type) => {
    setActiveDisplayType(type);
    // TODO cookie
  };

  const filterBySearchTerm = (c) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const title = c.title.toLowerCase().includes(lowerCaseSearchTerm);
    const description = c.description
      .toLowerCase()
      .includes(lowerCaseSearchTerm);
    const topics = c.topics
      && c.topics.filter((t) => t.toLowerCase().includes(lowerCaseSearchTerm));

    if (title || description || (topics && topics.length > 0)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!canAccessCourseCatalog({ enableCourseCatalog })) {
      history.push(SLASH);
      return;
    }

    if (!onboarded) {
      dispatch(
        setCurrentModal({
          key: MODAL_KEY_WELCOME
        })
      );
    }

    dispatch(setCurrentAnswers({ userId })); // Used in <Progress />
    dispatch(getOrg({ orgId })); // Order for groups is organization.courseGroupIds
    dispatch(getCourses({ orgId }));
    dispatch(getGroups({ orgId, type: 'course' }));
    dispatch(resetCurrentClass());
  }, [orgId, userId, onboarded, enableCourseCatalog]);

  if (!currentUser) {
    return <Loading />;
  }

  let whichGroups = null;
  let whichCourses = null;

  if (courses) {
    whichCourses = courses.filter((c) => isPublished(c));
    // Exclude test courses if they exist and the user is not a test user
    whichCourses = whichCourses.filter((c) => {
      if (c.testClass && !currentUser.testUser) return c;

      return c;
    });

    if (searchTerm) {
      whichCourses = whichCourses.filter((c) => filterBySearchTerm(c));
    }
  }

  if (groups) {
    whichGroups = groups.filter((g) => isPublished(g));

    if (whichGroups) {
      // only show groups that have courses
      whichGroups = whichGroups.filter((g) => g.itemIds);
    }

    if (whichGroups) {
      // only show groups user has access to
      whichGroups = whichGroups.filter((g) =>
        canAccessByRole(role, g.userRoles));
    }

    // TODO allow group/list button to be available to all users
    // filter courses by group permissions
    // whichCourses = whichGroups.map((group) => group.itemIds && group.itemIds.map(classId => whichCourses.find(course => course.id === classId)))
  }

  return (
    <Fragment>
      <div className="row py-3 border-bottom">
        <div className="col d-flex align-items-center justify-content-between">
          <SearchBox
            onClear={() => {
              setActiveDisplayType('groups');
            }}
            onSubmit={(inputSearchValue) => {
              setSearchTerm(inputSearchValue);

              // TODO: add groupIds to course object which will allow logic
              // to determine which courses the user can see
              if (
                activeDisplayType !== 'list'
                && (orgType === 'workforce' || canChangeCourseDisplayType(role))
              ) {
                setActiveDisplayType('list');
              }
            }}
            autoFocus
            placeholder="Search courses"
          />

          <div className="d-flex">
            {activeDisplayType === 'groups' && (
              <CollapseControl
                className="mr-1"
                onChange={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            )}

            {/* TODO to show the list, need a way to check the group permissions the course lives in. */}
            {(orgType === 'workforce' || canChangeCourseDisplayType(role)) && (
              <ListDisplayType
                onChange={changeDisplayType}
                activeType={activeDisplayType}
              />
            )}
          </div>
        </div>
      </div>

      {activeDisplayType === 'list' && (
        <div className="row py-3">
          {whichCourses
            && sortArrByKey(whichCourses, 'title').map((course, i) => {
              if (course.testClass && !currentUser.testUser) {
                return;
              }

              return (
                <Card
                  key={i}
                  course={course}
                  className="col-sm-12 col-md-5 col-lg-4 col-xl-3"
                />
              );
            })}
        </div>
      )}

      {activeDisplayType === 'groups' && (
        <div className="row py-3">
          {whichCourses
            && whichGroups
            && courseGroupIds
            && sortArrByArr(whichGroups, courseGroupIds, 'id').map(
              (group, groupIndex) => {
                let groupCourses = group.itemIds
                  && group.itemIds.map((classId) =>
                    whichCourses.find((course) => course.id === classId));

                // If search term is present
                // Don't show groups that are empty
                if (searchTerm) {
                  groupCourses = groupCourses.filter((gc) => gc !== undefined);
                }

                if (!groupCourses.length) return null;

                return (
                  <Collapse
                    key={`${group.id}-${groupIndex}`}
                    className="col-sm-12 mb-4"
                    title={group.title}
                    id={`group-${group.id}-${groupIndex}`}
                    // ariaExpanded={!searchTerm}
                    ariaExpanded={expanded}
                    badge={() => {
                      return (
                        <span
                          className={`ml-1 badge ${searchTerm ? 'badge-primary' : 'bg-white border'}`}
                        >
                          {groupCourses.length}
                        </span>
                      );
                    }}
                  >
                    {/* <div className="card">
                  <div className="card-header">
                    <h6 className="font-weight-bold m-0">
                      {group.title}
                    </h6>
                  </div>
                  <div className="card-body"> */}
                    <div className="row">
                      {groupCourses
                        && groupCourses.map((course, courseIndex) => {
                          if (!course) return null; // Not available
                          if (!course.title) return null;

                          if (course.testClass && !currentUser.testUser) {
                            return null;
                          }

                          return (
                            <Card
                              key={`${course.id}-${courseIndex}`}
                              course={course}
                              className="col-sm-12 col-md-5 col-lg-4 col-xl-3"
                            />
                          );
                        })}
                    </div>
                    {/* </div>
                </div> */}
                  </Collapse>
                );
              }
            )}
        </div>
      )}
    </Fragment>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Classes);
