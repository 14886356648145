import { isEmpty, isNil } from 'ramda';
import React from 'react';

const NoCostEnroll = ({
  eUnitIds,
  chooseCohort,
  cohortId,
  courseCohorts,
  course,
  className,
  loading,
  onClick,
  disabled
}) => {
  const { id: classId, title } = course;

  return (
    <button
      title={`Enroll in ${title} `}
      className={`noCostEnrollCourseBtn btn btn-md btn-warning my-2 ${className || ''}`}
      disabled={loading || disabled}
      type="button"
      onClick={() => {
        if (courseCohorts && (isNil(eUnitIds) || isEmpty(eUnitIds))) {
          chooseCohort({ classId });
        } else {
          onClick({ classId, cohortId });
        }
      }}
    >
      {loading ? 'Enrolling...' : 'Enroll'}
    </button>
  );
};

export default NoCostEnroll;
