import React from 'react';

const NoRecords = () => {
  return (
    <div className="card my-4">
      <div className="card-body p-3">
        <p className="m-0">
          No attendance records.
        </p>
      </div>
    </div>
  );
};

export default NoRecords;
