import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import {
  fetchApprenticeship,
  fetchApprenticeships
} from '../services/apprenticeships';
import {
  SET_APPRENTICESHIPS,
  RESET_APPRENTICESHIPS,
  SET_APPRENTICESHIP,
  RESET_APPRENTICESHIP
} from './types';

export const getApprenticeships = ({
  ids,
  orgId,
  employerId,
  instructionProviderId,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeships({
        ids,
        orgId,
        employerId,
        instructionProviderId,
        page,
        limit
      }).then((response) => {
        const list = response?.items || [];
        // ORG CHECK
        // dispatch({
        //   type: SET_ENTRY_ORG_ID,
        //   entryOrgId: list && list[0] ? list[0]?.orgId : null
        // });

        dispatch({
          type: SET_APPRENTICESHIPS,
          apprenticeships: list
          // list,
          // pagination: {
          //   limit,
          //   total: response?.total,
          //   page: response?.page || 1,
          //   rangeFrom: response?.rangeFrom,
          //   rangeTo: response?.rangeTo
          // }
        });

        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getApprenticeship = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeship(id)
        .then((apprenticeship) => {
          dispatch({ type: SET_APPRENTICESHIP, apprenticeship });
          resolve(apprenticeship);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetApprenticeship = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP });
  };
};

export const resetApprenticeships = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIPS });
  };
};
