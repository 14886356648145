import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import contentTypeIcon from '../../../services/contentTypeIcon';
import Outcome from './Outcome';

const OutcomesHeader = ({ className }) => (
  <thead className={`${className || ''} border-0`}>
    <tr>
      <th scope="col">Submitted</th>
      <th scope="col">Score</th>
      <th scope="col">Percentage</th>
      <th scope="col">Attempts</th>
      <th scope="col">Points</th>
      <th scope="col">Feedback</th>
      {/* <th scope="col" className='border-0 d-none d-md-table-cell'>Details</th> */}
    </tr>
  </thead>
);

const Outcomes = () => {
  const currentQuizOutcomes = useSelector((state) => state.currentQuizOutcomes);
  const hasOutcomes = currentQuizOutcomes && Array.isArray(currentQuizOutcomes) && currentQuizOutcomes.length > 0;
  const [showPrevScores, setShowPrevScores] = useState(false);
  const [hasPrevScores, setHasPrevScores] = useState(false);

  if (!hasOutcomes) return null;

  // const showOutcomeResult = (outcomeId) => {
  //   alert(outcomeId);
  // }

  return (
    <div className="CurrentQuizOutcomes-container card mb-3">
      <div className="card-header bg-light p-3 border-0">
        <h5 className="m-0">
          {contentTypeIcon({ contentType: 'quiz' })} Outcomes
        </h5>
      </div>
      <div className="card-body p-0">
        <table className="table table-striped m-0">
          <OutcomesHeader />
          <tbody>
            {currentQuizOutcomes.map((outcome, i) => {
              if (outcome.prevScores && !hasPrevScores) {
                setHasPrevScores(true);
              }

              return (
                <Fragment key={i}>
                  <Outcome
                    key={`${outcome.id}-${i}`}
                    data={outcome}
                  />
                  {outcome.prevScores && outcome.prevScores.map((prevOutcome, prevOutcomeIndex) => (
                    <Outcome
                      key={`${outcome.id}-prevOutcome-${prevOutcomeIndex}`}
                      data={prevOutcome}
                      className={showPrevScores ? '' : 'd-none'}
                      prevOutcome
                    />
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {hasPrevScores && (
        <div className="card-footer d-flex justify-content-center">
          <button
            className="btn-link p-0"
            type="button"
            onClick={() => {
              setShowPrevScores(!showPrevScores);
            }}
          >
            {showPrevScores ? 'Hide' : 'View'} Previous Scores
          </button>
        </div>
      )}
    </div>
  );
};

export default Outcomes;
