import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const CourseDashboardButton = ({ classId, className, children }) => {
  return (
    <Link
      data-cy="courseCard-courseDashboardBtn"
      className={`${className || 'btn btn-md btn-primary'}`}
      to={`${ROUTES.CLASSES}/${classId}`}
    >
      {children || 'Course Dashboard' }
    </Link>
  );
};

export default CourseDashboardButton;
