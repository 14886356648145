import React from 'react';
import styled from 'styled-components';

const CollapseTrigger = styled.div`
  cursor: pointer;
  background-color: #f8f9fa;

  &[aria-expanded="true"] {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:hover {
    background-color: rgba(54, 162, 235, 0.2);
    border: 1px rgba(54, 162, 235, 1) solid;
  }
`;

const CollapseContainer = styled.div`
  .fa-plus,
  .fa-minus {
    display: inline-flex;
    justify-content: center;
    margin-right: .25rem!important;
    width: 12px;
  }

  [aria-expanded="true"] .fa-plus,
  [aria-expanded="false"] .fa-minus {
    display: none;
  }
`;

const Collapse = ({
  id,
  title,
  children,
  className = '',
  badge = false,
  ariaExpanded = false
}) => {
  return (
    <CollapseContainer
      className={className}
    >
      <CollapseTrigger
        className="collapseTrigger rounded border p-3 d-flex align-items-center justify-content-between"
        data-toggle="collapse"
        data-target={`#${id}`}
        aria-expanded={ariaExpanded}
        aria-controls="collapseExample"
      >
        <div className="h6 m-0 font-weight-bold">
          {title} {badge && badge()}
        </div>
        <div>
          <i className="fas fa-plus" />
          <i className="fas fa-minus" />
        </div>
      </CollapseTrigger>

      <div
        className={`collapse border-right border-left border-bottom p-3 ${ariaExpanded ? 'show' : ''}`}
        id={id}
      >
        {children}
      </div>
    </CollapseContainer>
  );
};

export default Collapse;
