import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Agreements from './Agreements';
import Membership from '../Membership';
import { withAuthorization } from '../Session';
import { UPSTREAM_CODING } from '../../constants/globals';
import { MODAL_KEY_SEND_EMAIL } from '../../constants/modal';
import { notifyManagers } from '../../actions/Email';
import { setCurrentModal } from '../../actions/Modals';
import isPublished from '../../utils/isPublished';
import './style.css';

class Billing extends Component {
  state = {
    updatePaymentSuccess: false
  }

  componentWillUnmount() {
    const { history } = this.props;

    history.replace();
  }

  paymentButtons = ({ invoice, receipt }) => {
    return (
      <Fragment>
        {invoice && (
          <a className="btn btn-sm btn-primary" href={invoice} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-file-invoice" /> Invoice
          </a>
        )}
        {receipt && (
          <a className="btn btn-sm btn-outline-primary" href={receipt} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-file-invoice" /> Receipt
          </a>
        )}
      </Fragment>
    );
  }

  payments = () => {
    const { currentUser } = this.props;
    const { payments } = currentUser; // TODO user flatten (pull payments by userId)

    if (payments) {
      return (
        <Fragment>
          {payments.map((payment, i) => {
            const {
              date,
              type,
              amount,
              class: course // TODO payment flatten (classId)
            } = payment;

            return (
              <tr key={i}>
                <th scope="row">{date}</th>
                <td data-demo="disabled">
                  {type}
                </td>
                <td data-demo="disabled">
                  ${amount}
                </td>
                <td>
                  {/* TODO payment flatten */}
                  {isPublished(course) ? course.fields.title : 'n/a'}
                </td>
                <td>
                  {this.paymentButtons(payment)}
                </td>
              </tr>
            );
          })}
        </Fragment>
      );
    }

    return (
      <tr>
        <td colSpan="5">No Payment History</td>
      </tr>
    );
  }

  showBillingQuestionsModal = () => {
    const { currentUser, userLocation, organization } = this.props;
    const { name: orgName } = organization;
    const {
      name: userName,
      email: userEmail,
      role: userRole
    } = currentUser;
    const { name: locationName } = userLocation;

    this.props.setCurrentModal({
      key: MODAL_KEY_SEND_EMAIL,
      data: {
        name: `${orgName} (${locationName})`,
        subject: `Billing Question from ${userName} (${userRole})`,
        hideSubject: true,
        disableSubject: true,
        replyTo: userEmail,
        notifyManagers: true
      }
    });
  }

  updatePaymentMethod = () => {
    const { organization, currentUser } = this.props;
    const { id: orgId } = organization;
    const {
      name: userName, email: userEmail, role: userRole
    } = currentUser;

    this.setState({
      updatePaymentSuccess: true
    });

    const query = encodeURIComponent(userEmail);
    const roleReadable = userRole[0];

    const data = {
      subject: `Update payment method (${roleReadable})`,
      message: `${userName} (${roleReadable}) requested to update their payment method`
    };

    if (orgId === UPSTREAM_CODING) {
      data.message += `, https://dashboard.stripe.com/search?query=${query}.`;
    }

    this.props.notifyManagers(data);
  }

  updateCC = () => {
    return (
      <Fragment>
        <div className="alert alert-danger mt-3" role="alert">
          <h4 className="alert-heading">
            Update Payment Method
          </h4>
          <p>
            We were unable to complete your transaction with the payment method on file.
          </p>
          <hr />
          <p className="mb-0">
            To continue using the Member Portal, please update your <b>payment method</b>:
          </p>
        </div>

        <div className="mt-2 mb-5">
          {this.state.updatePaymentSuccess ? (
            <div className="alert alert-info">
              An Upstream team member will contact you to update your payment method.
            </div>
          ) : (
            <div className="alert alert-secondary bg-light">
              <button
                onClick={this.updatePaymentMethod}
                type="button"
                className="btn btn-md btn-primary"
                target="_blank"
                rel="noreferrer noopener"
                title="Update Payment Method"
              >
                Update Payment Method
              </button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    const { organization, currentUser, userLocation } = this.props;
    const { name: orgName } = organization;
    const { updatePaymentMethod } = currentUser;
    const {
      name: locationName,
      managers // TODO location flatten (managerIds)
    } = userLocation;

    const [manager] = managers; // TODO limit returned data with 'select'

    return (
      <main role="main" className="payment-history-table col-sm-9 py-3">
        <h1 className="h4 m-0">
          Billing
        </h1>
        <hr />

        <div className="row">
          <div className="col-sm-12 ">
            <Agreements className="mt-2" />
          </div>
        </div>

        { updatePaymentMethod && this.updateCC() }

        {manager && (
          <Fragment>
            <hr className="mb-1" />
            <p>
              <span>Direct any billing questions to </span>
              <button
                className="btn btn-link px-0 pb-2"
                type="button"
                onClick={this.showBillingQuestionsModal}
              >
                {`${orgName} (${locationName})`}
              </button>
              <span>.</span>
            </p>
          </Fragment>
        )}

        <h4 className="mt-5">
          Payment History
        </h4>

        <div className="card overflow-hidden">
          <div className="card-content">
            <table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Class</th>
                  <th scope="col">Receipt</th>
                </tr>
              </thead>
              <tbody>
                { this.payments() }
              </tbody>
            </table>
          </div>
        </div>
        <p>
          <small className="text-muted"><b>Note:</b> Payments can take up to 48 hours to reflect in your payment history.</small>
        </p>

        <Membership />
      </main>
    );
  }
}

const mapStateToProps = ({
  billingAgreement,
  organization,
  userLocation
}) => ({
  billingAgreement,
  organization,
  userLocation
});
const condition = (user) => !!user;

export default compose(
  connect(mapStateToProps, { notifyManagers, setCurrentModal }),
  withAuthorization(condition)
)(Billing);
