import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { EXTERNAL_LINK } from '../../../constants/icons';
import { canManageContent, canViewQuizAnswers } from '../../../services/currentUser';
import { hasCourseCompletionCertificate } from '../../../services/certificate';
import { nextEntryRoute, prevEntryRoute } from '../../../services/entry';
import { quizAccepted } from '../../../services/contentFlow/quizzes';
import { unpublish } from '../../../services/contentful';
import { evaluateQuiz } from '../../../services/quizzes';
import goToTop from '../../../utils/goToTop';
import { createQuizOutcome, setCurrentModal, updateQuizOutcomes } from '../../../actions';
import contentTypeIcon from '../../../services/contentTypeIcon';
import { MODAL_KEY_GUIDED_QUIZ_INCORRECT } from '../../../constants/modal';
import ManageButton from '../../btns/ManageButton';
import Alert from '../../Alert';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import PracticeMode from './PracticeMode';

const QuizDescriptionContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

const QuestionTitleContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

const Form = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const currentTopic = useSelector((state) => state.currentTopic);
  const course = useSelector((state) => state.currentClass);
  const courseExperts = useSelector((state) => state.courseExperts);
  const topics = useSelector((state) => state.topics);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const currentEntry = useSelector((state) => state.currentEntry);
  const currentQuizOutcomes = useSelector((state) => state.currentQuizOutcomes);

  const [loading, setLoading] = useState(false);
  const [guidedReview, setGuidedReview] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [practiceMode, setPracticeMode] = useState(false);
  const [viewCorrectAnswers, setViewCorrectAnswers] = useState(false);

  if (!currentTopic || !course || !currentQuiz) {
    return null;
  }

  if (!currentUser?.id && !course.courseReadOnly) {
    return null;
  }

  const userId = pathOr(null, ['id'], currentUser);
  const role = pathOr([], ['role'], currentUser);
  const userName = pathOr(null, ['name'], currentUser);
  const topicId = pathOr(null, ['id'], currentTopic);
  const classId = pathOr(null, ['id'], course);
  const guidedCourse = pathOr(null, ['guidedCourse'], course);

  const {
    id: quizId,
    activeQuiz,
    guidedQuiz,
    title,
    description,
    questions,
    difficulty,
    allowPracticeAfterSubmission,
    enableNewEditor
  } = currentQuiz;

  const canSubmitQuiz = activeQuiz;
  const hasOutcomes = currentQuizOutcomes && Array.isArray(currentQuizOutcomes) && currentQuizOutcomes.length > 0;
  // Latest outcome has most number of attempts based on order from api, see src/services/quizzes.js "fetchQuizOutcomes"
  const latestOutcome = hasOutcomes ? currentQuizOutcomes[0] : null;
  const hasAcceptedOutcomes = latestOutcome ? quizAccepted({ score: latestOutcome.score, maxScore: latestOutcome.maxScore }) : null;
  const practiceAfterMode = allowPracticeAfterSubmission && hasAcceptedOutcomes;
  // const hasCompletedCourse = completedCourseIds && Array.isArray(completedCourseIds) && completedCourseIds.find((completedCourseId => completedCourseId === classId));
  const hasCompletionCertificate = !!hasCourseCompletionCertificate({ currentUser, classId });
  const nextEntryRouteStr = guidedCourse ? nextEntryRoute({
    course, currentTopic, currentEntry, topics
  }) : null;
  const prevEntryRouteStr = guidedCourse ? prevEntryRoute({
    course, currentTopic, currentEntry, topics
  }) : null;

  // PERMISSIONS
  const viewAnswers = canViewQuizAnswers({ role, courseExperts, userId });
  const manageContent = canManageContent({ currentUser, course });
  const viewAnswersOrManage = viewAnswers || manageContent;
  // /PERMISSIONS

  const toggleViewCorrectAnswers = () => {
    setViewCorrectAnswers(!viewCorrectAnswers);
  };

  const resetQuizForm = () => {
    goToTop();
    setLoading(false);
    setGuidedReview(false);
    setQuizSubmitted(false);
    setPracticeMode(false);

    document.getElementById('class-quiz').reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { elements } = e.currentTarget;
    const quizEvaluation = evaluateQuiz({ currentQuiz, elements });
    const evaluation = pathOr(null, ['evaluation'], quizEvaluation);
    const userAnswers = pathOr(null, ['userAnswers'], quizEvaluation);
    const score = pathOr(null, ['score'], quizEvaluation);
    const maxScore = pathOr(null, ['maxScore'], quizEvaluation);

    setLoading(true);

    // When a quiz has questions with multiple answers
    // it's not possible to use the require prop to ensure the user
    // has provided answers to every question
    if (questions.length !== userAnswers.length) {
      toast.error('You forgot to answer a question, try again.', { autoClose: 5000 });

      setLoading(false); // TODO may not be needed if setLoading(true) is after this conditional
      setQuizSubmitted(false);

      return false;
    }

    const dataToSave = {
      title: `${currentQuiz.title} - ${userName}`,
      difficulty,
      score,
      maxScore,
      evaluation,
      userAnswers,
      attempts: latestOutcome ? parseInt(latestOutcome.attempts, 10) + 1 : 1,
      rubric: currentQuiz.rubric,
      questions: currentQuiz.questions,
      enableNewEditor: true
    };

    if (practiceAfterMode) {
      setPracticeMode(true);
      setGuidedReview(true);
      setQuizSubmitted(true);
      setLoading(false);

      dataToSave.isPractice = true;
      dataToSave.createdAt = new Date();

      dispatch(updateQuizOutcomes(dataToSave));

      goToTop();

      return false;
    }

    // Guided Quiz
    // User got all wrong or
    // User didn't get them all correct
    if (guidedQuiz && (score === 0 || maxScore !== score)) {
      // Guide user through Quiz by showing answer hints
      setGuidedReview(true);
      setQuizSubmitted(true);
      setLoading(false);

      dispatch(setCurrentModal({ key: MODAL_KEY_GUIDED_QUIZ_INCORRECT }));

      return false;
    }

    if (hasOutcomes) {
      // If a user has submitted multiple quizOutcomes
      // in the past they were stored as individual published entries
      // moving forward, only the latest outcome is published and
      // tracks (abbreviated) previous outcomes under the key prevScores
      toast.info('Processing...', {
        toastId: 'processingContentFlow',
        autoClose: false
      });

      // Compile all existing quiz outcome ids to unpublish
      // TODO delete existing quiz outcomes programmatically
      const existingQuizOutcomeIds = currentQuizOutcomes.map((outcome) => outcome.id);

      dataToSave.prevScores = latestOutcome.prevScores ? latestOutcome.prevScores : [];

      const prevScoresData = [];

      // We must use a forEach here for backwards compatibility
      // once all data is migrated, latestOutcome data can be used
      currentQuizOutcomes.forEach((outcome) => {
        prevScoresData.push({
          score: outcome.score,
          maxScore: outcome.maxScore,
          createdAt: outcome.createdAt
        });
      });

      if (latestOutcome.prevScores) {
        // User has submitted multiple quizOutcomes
        // and the latest outcome has prevScore data
        dataToSave.prevScores = [
          ...prevScoresData,
          ...dataToSave.prevScores
        ];
      } else {
        // User has submitted one quizOutcome and is attempting
        // another time, save previous outcome data in prevScores
        dataToSave.prevScores = prevScoresData;
      }

      // Unpublish all User's existing quiz outcomes for this quiz
      unpublish(existingQuizOutcomeIds, 'Entry').then(() => {
        // User resubmitting a quiz outcome
        dispatch(createQuizOutcome(dataToSave)).then(() => {
          resetQuizForm();
        });
      });
    } else {
      // User submitting first quiz outcome
      dispatch(createQuizOutcome(dataToSave)).then(() => {
        resetQuizForm();
      });
    }
  };

  const questionAnswerTextColor = ({ isCorrectAnswer }) => {
    if (!guidedReview) return null;

    if (isCorrectAnswer) {
      return 'guided-quiz--correct';
    }
    return 'guided-quiz--incorrect';
  };

  // SUBMIT BUTTON VISIBILITY
  let showSubmitButton = true;
  if (hasAcceptedOutcomes) showSubmitButton = false;
  if (practiceAfterMode) showSubmitButton = true;
  if (hasCompletionCertificate) showSubmitButton = false;
  // if (canBypassContentFlow(role)) showSubmitButton = true; // This method has been deprecated
  if (practiceMode) showSubmitButton = false;
  if (!activeQuiz) showSubmitButton = false;
  // /SUBMIT BUTTON VISIBILITY

  // RADIO BUTTONS DISABLED
  let radioButtonsDisabled = false;
  if (guidedQuiz && (hasAcceptedOutcomes || hasCompletionCertificate)) {
    radioButtonsDisabled = true;
  }
  // if (canBypassContentFlow(role)) radioButtonsDisabled = false; // This method has been deprecated
  if (practiceAfterMode) radioButtonsDisabled = false;
  if (!activeQuiz) radioButtonsDisabled = true;
  // /RADIO BUTTONS DISABLED

  const showGreatJobAlert = guidedQuiz && (hasAcceptedOutcomes || hasCompletionCertificate);

  return (
    <form
      id="class-quiz"
      onSubmit={handleSubmit}
    >
      {practiceAfterMode && (
        <PracticeMode
          className="my-3"
          practiceMode={practiceMode}
          loading={loading}
          handlePracticeAgain={resetQuizForm}
        />
      )}

      <div className="card mb-3">

        <div className="card-header bg-dark p-3 d-flex align-items-center justify-content-between">
          <h5 className="m-0 text-white font-weight-normal">
            {contentTypeIcon({ contentType: 'quiz' })} { title || 'Quiz' }
          </h5>
        </div>

        {viewAnswersOrManage && (
          <div
            className={`px-3 py-2 d-flex align-items-center justify-content-${manageContent ? 'between' : 'end'} border-bottom`}
          >
            {viewAnswers && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="viewCorrectAnswers"
                  onChange={toggleViewCorrectAnswers}
                />
                <label
                  className="custom-control-label"
                  htmlFor="viewCorrectAnswers"
                  data-tip="Not visible to Users"
                  data-place="bottom"
                >
                  View <span className="d-none d-sm-inline">Correct</span> Answers
                </label>
                <ReactTooltip />
              </div>
            )}
            {manageContent && (
              <ManageButton
                className="btn btn-sm btn-outline-primary text-capitalize d-flex align-items-center"
                classId={classId}
                topicId={topicId}
                entryId={quizId}
                contentType="quiz"
                title={title}
                buttonText="Edit quiz"
                manageType="edit"
                tooltip
              >
                Edit quiz <small><i className={`${EXTERNAL_LINK} ml-2`} /></small>
              </ManageButton>
            )}
          </div>
        )}

        <div className={`card-content ${canSubmitQuiz ? '' : 'pointer-events-none opacity-50'}`}>
          {description && (
            <div className="mb-3 border-bottom p-3">
              <RenderMarkdown
                source={description}
                enableNewEditor={enableNewEditor}
              />
            </div>
          )}

          <div className="quiz-questions">
            {questions.map((question, questionIndex) => {
              const {
                id: questionId,
                title: questionTitle,
                questionNumber,
                description: questionDescription,
                hint: questionHint,
                answers: questionAnswers
                // rubric
              } = question;

              let hasMoreThanOneCorrectAnswer = false;

              if (questionAnswers) {
                const correctAnswers = questionAnswers.filter((q) => q.correct);
                const numCorrectAnswers = correctAnswers ? correctAnswers.length : 0;

                if (numCorrectAnswers > 1) {
                  hasMoreThanOneCorrectAnswer = true;
                }
              }

              return (
                <div
                  key={questionIndex}
                  className="quiz-question d-flex align-items-start"
                >
                  <div>
                    <p className="m-0">
                      {questionNumber || questionIndex + 1}.
                    </p>
                  </div>

                  <div
                    className="ml-3"
                  >
                    <div className={`mt-0 ${questionDescription ? 'mb-1' : 'pb-2 mb-2 border-bottom'}`}>
                      <QuestionTitleContainer>
                        <RenderMarkdown
                          source={questionTitle}
                          enableNewEditor={enableNewEditor}
                        />
                      </QuestionTitleContainer>
                    </div>

                    {questionDescription && (
                      <QuizDescriptionContainer
                        className="mb-2 pb-2 border-bottom"
                      >
                        {questionDescription && (
                          <small>
                            <RenderMarkdown
                              source={questionDescription}
                              enableNewEditor={enableNewEditor}
                            />
                          </small>
                        )}
                      </QuizDescriptionContainer>
                    )}

                    {questionAnswers && (
                      <div className="RadioGroup--container">
                        {questionAnswers.map((questionAnswer, questionAnswerIndex) => {
                          const {
                            text: questionAnswerText,
                            incorrectResponse
                          } = questionAnswer;
                          const id = `${questionId}-${questionAnswerIndex}`; // serializeQuiz() may already handle this
                          const name = `${questionId}`;
                          const isCorrectAnswer = questionAnswer.correct === true;

                          return (
                            <div
                              key={questionAnswerIndex}
                              className="custom-control custom-radio block mb-2 small"
                            >
                              <input
                                id={id}
                                name={name}
                                type={hasMoreThanOneCorrectAnswer ? 'checkbox' : 'radio'}
                                className="custom-control-input"
                                required={!hasMoreThanOneCorrectAnswer}
                                defaultValue={parseInt(questionAnswerIndex, 10)}
                                data-correct={isCorrectAnswer}
                                disabled={radioButtonsDisabled}
                                onClick={() => {
                                  // If Guided Quiz & during a Guided Review && difficulty is 4 or 5
                                  // turn off highlighting and incorrect responses after
                                  // selecting another radio option
                                  if (guidedQuiz && guidedReview && difficulty > 3) {
                                    setGuidedReview(false);
                                  }
                                }}
                              />
                              <label
                                className={`custom-control-label ${questionAnswerTextColor({ isCorrectAnswer })}`}
                                htmlFor={id}
                              >
                                <span>
                                  {questionAnswerText}
                                </span>
                                {viewCorrectAnswers && isCorrectAnswer && (
                                  <i className="fas fa-check-circle text-success ml-2" />
                                )}
                              </label>

                              {incorrectResponse && guidedReview && (
                                <div className="incorrect-response border-danger p-1 mt-2 mr-2">
                                  <b className="text-muted">Incorrect:</b>
                                  <RenderMarkdown
                                    source={incorrectResponse}
                                    enableNewEditor={enableNewEditor}
                                  />
                                  {hasMoreThanOneCorrectAnswer && (
                                    <small className="text-muted d-block">
                                      Click to deselect incorrect answer.
                                    </small>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {questionHint && (
                      <div className="card-footer">
                        <RenderMarkdown
                          source={questionHint}
                          enableNewEditor={enableNewEditor}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {showGreatJobAlert && (
            <Alert
              type=""
              icon="fas fa-check-circle text-keppel"
              className="alertWithIconSlim border mx-3"
            >
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0">
                  Great job on this knowledge check!
                </p>
              </div>
            </Alert>
          )}

          {practiceAfterMode && (
            <PracticeMode
              className="mx-3"
              practiceMode={practiceMode}
              loading={loading}
              handlePracticeAgain={resetQuizForm}
            />
          )}

          {showSubmitButton && (
            <div className="m-3 border-top pt-3 pb-5">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading || course?.courseReadOnly}
                title={quizSubmitted && guidedQuiz ? 'Resubmit' : 'Submit'}
              >
                {loading ? (
                  <span>
                    {quizSubmitted && guidedQuiz ? 'Resubmitting...' : 'Submitting...'}
                  </span>
                ) : (
                  <span>
                    {quizSubmitted && guidedQuiz ? 'Resubmit' : 'Submit'}
                  </span>
                )}
              </button>
            </div>
          )}
        </div>

        <div className="card-footer d-flex align-items-center justify-content-between">
          {guidedCourse && (prevEntryRouteStr || nextEntryRouteStr) && (hasAcceptedOutcomes || hasCompletionCertificate) ? (
            <Fragment>
              {prevEntryRouteStr && (
              <button
                className="btn btn-outline-primary"
                type="button"
                title="Previous"
                onClick={() => {
                  history.push(prevEntryRouteStr);
                }}
              >
                <i className="fas fa-chevron-circle-left mr-1" /> Previous
              </button>
              )}

              {nextEntryRouteStr && (
              <button
                className="btn btn-primary"
                type="button"
                title="Next"
                onClick={() => {
                  history.push(nextEntryRouteStr);
                }}
              >
                Next <i className="fas fa-chevron-circle-right ml-1" />
              </button>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {guidedCourse && prevEntryRouteStr && (
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  title="Previous"
                  onClick={() => {
                    history.push(prevEntryRouteStr);
                  }}
                >
                  <i className="fas fa-chevron-circle-left mr-1" /> Previous
                </button>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </form>
  );
};

export default Form;
