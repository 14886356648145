import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import { IMAGE_MAINTENANCE } from '@apprentage/constants';
import {
  FLAG_MAINTENANCE_MODE,
  FLAG_USERS_READONLY,
  NOTICE_ACCOUNT_CREATION_BODY,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import Logo from './Logo';
import PoweredBy from './PoweredBy';
import Legal from './Legal';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import './style.css';

const AuthContainer = ({
  children,
  title = '',
  logo = true,
  locationImage = '',
  locationName = '',
  footer = true,
  poweredBy = true,
  className = ''
}) => {
  const params = useParams();
  const flags = useFlags([FLAG_USERS_READONLY, FLAG_MAINTENANCE_MODE]);
  const flagsStatus = useFlagsmithLoading();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const enroll = useSelector((state) => state.enroll);
  // Params
  const organizationSlug = params?.organizationSlug || '';
  // Enroll
  const orgId = enroll?.orgId || '';
  const orgName = enroll?.orgName || '';
  const orgLogo = enroll?.orgLogo || '';
  // Local State
  const [showMaintenanceToast, setShowMaintenanceToast] = useState(false);
  const [showUsersReadonlyToast, setShowUsersReadonlyToast] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.getElementsByTagName('html')[0].className = 'authpage';

      if (currentUser?.id) {
        document.getElementsByTagName('html')[0].className = 'authpage authpage-logged-in';
      }

      if (!flagsStatus?.isFetching) {
        if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
          setShowMaintenanceToast(true);
        } else if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
          setShowUsersReadonlyToast(true);
        }
      }
    }

    return function cleanup() {
      mounted = false;
      document.getElementsByTagName('html')[0].className = '';
    };
  }, [flagsStatus?.isFetching, flags?.maintenance_mode, flags?.users_readonly, currentUser?.id]);

  if (organizationSlug && !orgId) {
    return null;
  }

  if (showMaintenanceToast) {
    return (
      <>
        <div className={`auth-container ${className}`} />
        <ConfirmationToast
          title={NOTICE_MAINTENANCE_MODE_TITLE}
          subtitle={NOTICE_MAINTENANCE_MODE_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  if (showUsersReadonlyToast) {
    return (
      <>
        <div className={`auth-container ${className}`} />
        <ConfirmationToast
          title={NOTICE_USERS_READONLY_TITLE}
          subtitle={NOTICE_ACCOUNT_CREATION_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  return (
    <>
      <div className={`auth-container ${className}`}>
        <div className="card bg-light rounded">
          {title && (
            <h5 className="card-header py-3 h6 text-capitalize">
              {title}
            </h5>
          )}

          <div className="card-body">
            {logo && (
              <Logo
                orgName={orgName}
                orgLogo={locationImage || orgLogo}
              />
            )}

            {!logo && orgName && (
              <div className='mb-3'>
                <span className='pb-3 px-2 d-inline-block border-bottom font-weight-bold text-uppercase'>
                  {locationName || orgName}
                </span>
              </div>
            )}

            {children && (
              <>
                {children}
              </>
            )}
          </div>

          {footer && (
            <div className="card-footer px-2">
              <Legal />
            </div>
          )}
        </div>

        {poweredBy && (
          <div className="my-3 text-muted">
            <PoweredBy />
          </div>
        )}
      </div>
    </>
  );
};

export default AuthContainer;
