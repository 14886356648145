import randomInt from 'random-int';
import { toast } from 'react-toastify';
import findObjectByKeyExists from '../utils/findObjectByKeyExists';
import { courseAttendanceByCohort } from './attendance';
import { getTopics } from './topics';
import { fetchSupabaseEntries, fetchSupabaseEntry, updateSupabaseEntry } from './supabaseProxy';
import { fetchUsers } from './users';
import formatData from './formatData';

export const attendanceDate = ({ utcHours }) => {
  const date = new Date();
  const timezoneOffset = date.getMinutes() + date.getTimezoneOffset();
  const timestamp = date.getTime() + timezoneOffset * 1000;
  const correctDate = new Date(timestamp);

  if (utcHours === true) {
    correctDate.setUTCHours(0, 0, 0, 0);
  }

  return correctDate.toISOString();
};

export const prevSelectedUnitCodes = ({
  classId, cohortId, eUnits, attendance
}) => {
  if (eUnits && eUnits.length > 1) {
    if (attendance && attendance[classId]) {
      const cohortAttendance = courseAttendanceByCohort({
        courseAttendance: attendance[classId],
        cohortId
      });

      if (cohortAttendance) {
        const sessionWithUnitCodes = findObjectByKeyExists(cohortAttendance, 'eUnitCodes');

        if (sessionWithUnitCodes) {
          return sessionWithUnitCodes.eUnitCodes;
        }
      }

      return [];
    }

    return [];
  }

  // Fallback behavior if there is only one eUnit code
  const eUnitCodes = eUnits.map((unit) => unit.fields.id); // TODO cohort flatten

  return eUnitCodes;
};

export const fetchUserByUid = async (uid = '') => {
  if (!uid) {
    throw new Error('UID is required!');
  }

  try {
    const response = await fetchSupabaseEntries({ 'f.uid[eq]': uid }, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Update User in Supabase
 *
 * @param {Object} data
 * @param {String} userId
 * @returns { id: '', data: '' }
 */
export const updateUser = async (data, userId) => {
  try {
    const entry = await fetchSupabaseEntry({
      table: 'users',
      id: userId
    });

    try {
      const response = await updateSupabaseEntry({
        id: userId,
        data: formatData(data, 'user', entry),
        table: 'users'
      });

      return response;
    } catch (updateError) {
      toast.error('Something went wrong updating user.');
      // TODO heap
      console.error(updateError);
    }
  } catch (fetchError) {
    toast.error('Something went wrong fetching user.');
    // TODO heap
    console.error(fetchError);
  }
};

export const userReEnrollDataCleanUp = ({
  data, completedCourseIds = [], completedCourseTopicIds = []
}) => {
  const { classId } = data;
  // TODO is extRegClassId / externalRegisteredCourseIds logic need to be added here?
  const dataToSave = { ...data };
  let completedCourseIdsIsValid = false;
  let completedCourseTopicIdsIsValid = false;

  return new Promise((resolve, reject) => {
    if (!data) {
      console.error('data argument required');
      reject();
    }

    // Check if completed course ids are valid
    if (completedCourseIds.length !== 0) {
      completedCourseIdsIsValid = true;
    }

    // Check if completed course topic ids are valid
    if (completedCourseTopicIds.length !== 0) {
      completedCourseTopicIdsIsValid = true;
    }

    // Check if user has completed this course
    if (completedCourseIdsIsValid && completedCourseIds.includes(classId)) {
      // User has completed this course, remove course from completed courses
      dataToSave.completedClassIdsToRemove = [classId];
    }

    getTopics({ classId }).then((topics) => {
      const courseTopicIds = !topics ? null : topics.map((topic) => topic.id);

      if (completedCourseTopicIdsIsValid && courseTopicIds !== null) {
        // Only include completed topic ids from the total list of topics
        dataToSave.completedTopicIdsToRemove = courseTopicIds.filter((ctId) => completedCourseTopicIds.includes(ctId));
      }

      resolve(dataToSave);
    });
  });
};

export const randomNumberByLength = (length) => {
  const minNumber = [];
  const maxNumber = [];

  for (let i = 0; i < length; i += 1) {
    if (i === 0) {
      minNumber.push(1);
      maxNumber.push(9);
    } else {
      minNumber.push(0);
      maxNumber.push(9);
    }
  }

  return randomInt(parseInt(minNumber.join(''), 10), parseInt(maxNumber.join(''), 10));
};

export const generateToken = async ({ prefix, charLength, orgId }) => {
  const uniqueCertificateId = `${prefix}${randomNumberByLength(charLength)}`;
  const response = await fetchUsers({ uniqueCertificateId, orgId });
  const hasToken = response?.items?.length > 0;

  if (hasToken) {
    generateToken({ prefix, charLength, orgId });
  } else {
    return uniqueCertificateId;
  }
};

export const courseCompleteCertificate = ({ currentUser, course }) => {
  // const uniqueCertificateIdSpec = pathOr(false, ['integration', 'uniqueCertificateIdSpec'], course);
  const uniqueCertificateData = currentUser?.integration?.uniqueCertificateData || false;

  // if (uniqueCertificateIdSpec) { // && uniqueCertificateIdSpec.oneTimeUse
  if (uniqueCertificateData) {
    const uniqueCertificates = Object.entries(uniqueCertificateData).map(([key, certInfo]) => ({
      id: key,
      ...certInfo
    }));

    if (uniqueCertificates && uniqueCertificates.length > 0) {
      const uniqueCertificate = uniqueCertificates.find((c) => c.classId === course.id);

      if (uniqueCertificate) {
        return uniqueCertificate;
      }
    }
  }
  // }

  return false;
};

export const showCourseCompleteCertificateNotice = ({ currentUser, course }) => {
  const classId = course?.id || '';
  const completionCertificate = course?.completionCertificate || false;
  const completedCourseIds = currentUser?.completedCourseIds || null;

  if (completionCertificate && completedCourseIds && completedCourseIds.includes(classId)) {
    return true;
  }

  return false;
};
