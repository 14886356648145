import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NO_NOTIFICATIONS } from '@apprentage/constants';
import { getNotifications } from '../../actions/Notifications';
import { canAccessByRole } from '../../services/currentUser';
import Notification from './Notification';

const NotificationsList = ({
  listConfig,
  className = '',
  // accessControls = true,
  showUserRoles = false,
  showNotificationError = true
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const notificationsOrgList = useSelector(
    (state) => state.notificationsOrgList
  );
  const notificationsCourseList = useSelector(
    (state) => state.notificationsCourseList
  );

  const pinnedCourseNotificiatonList = useSelector((state) => state?.pinnedCourseNotificationsList);
  const pinnedOgNotificationList = useSelector((state) => state?.pinnedOrgNotificationsList);

  const noDataText = useMemo(() => {
    if (listConfig?.isDraft) {
      return 'No draft notifications.';
    }

    if (listConfig?.includePinned) {
      return 'No pinned notifications.';
    }

    return 'No published notifications.';
  }, [listConfig?.isDraft, listConfig?.includePinned]);

  // User
  const role = currentUser?.role || [];

  useEffect(() => {
    if (listConfig?.orgId) {
      dispatch(getNotifications(listConfig));
    }
  }, [listConfig]);

  const whichNotifications = listConfig?.classId && listConfig?.includePinned
    ? pinnedCourseNotificiatonList : listConfig?.includePinned ? pinnedOgNotificationList : listConfig?.classId ? notificationsCourseList : notificationsOrgList;

  if (
    (!whichNotifications
    || (Array.isArray(whichNotifications) && whichNotifications.length === 0)) && showNotificationError
  ) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-auto"
        style={{
          padding: '1rem 0'
        }}
      >
        <img
          src={NO_NOTIFICATIONS}
          width="100"
          className="my-3"
          alt="No notifications"
        />
        <p className="m-0">{noDataText}</p>
      </div>
    );
  }

  // if (accessControls) {
  //   whichNotifications = whichNotifications.filter((n) => canAccessByRole(role, n.userRoles));
  // }

  // whichNotifications = whichNotifications.slice(0, limit);

  return (
    <div>
      <div className={`NotificationsList d-flex flex-column ${className}`}>
        {whichNotifications
          && whichNotifications.map((notification, i) => {
            if (!canAccessByRole(role)) return null;

            return (
              <Notification
                key={i}
                data={notification}
                showUserRoles={showUserRoles}
                listConfig={listConfig}
              />
            );
          })}
      </div>
    </div>
  );
};

export default NotificationsList;
