import { pathOr } from 'ramda';

export const hasCourseCompletionCertificate = ({ currentUser, classId }) => {
  const uniqueCertificateData = pathOr(false, ['integration', 'uniqueCertificateData'], currentUser);
  const uniqueCertificateIds = pathOr(false, ['uniqueCertificateIds'], currentUser);

  if (!uniqueCertificateData) return null;

  if (!uniqueCertificateIds || (uniqueCertificateIds && Array.isArray(uniqueCertificateIds) && uniqueCertificateIds.length === 0)) {
    return null;
  }

  let uniqueCertificate = null;

  uniqueCertificateIds.forEach((id) => {
    if (uniqueCertificateData[id].classId === classId) {
      uniqueCertificate = uniqueCertificateData[id];
    }
  });

  return uniqueCertificate;
};
