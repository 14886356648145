import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { activeCohortHasEUnitTracking } from '../../../services/courses';
import { USER_CHECK } from '../../../constants/icons';
import SessionAttendance from './SessionAttendance';
import Alert from '../../Alert';
import SessionAttendanceCheckIn from './SessionAttendanceCheckIn';

const StudentsOnly = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const courseCohorts = useSelector((state) => state.courseCohorts);

  if (!currentUser || !courseCohorts) {
    return null;
  }

  const cohortIds = pathOr([], ['cohortIds'], currentUser);

  if (!activeCohortHasEUnitTracking({ courseCohorts, cohortIds })) {
    return null;
  }

  return (
    <div className="pb-2">
      <Alert
        type="light"
        icon="far fa-user"
        className="alertWithIconSlim mb-0"
        hideIconOnMobile
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapse-take-attendance"
              aria-expanded="false"
              aria-controls="collapse-take-attendance"
              className="btn btn-sm btn-outline-primary"
              title="My Attendance"
            >
              <i className={`${USER_CHECK} d-none d-sm-inline`} /> <span className="d-none d-sm-inline">My</span> Attendance
            </button>
          </div>

          <div>
            <SessionAttendanceCheckIn />
          </div>

        </div>
      </Alert>

      <div id="students-only" className="mt-2">
        <SessionAttendance />
      </div>

    </div>
  );
};

export default StudentsOnly;
