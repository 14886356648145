import React, { Fragment } from 'react';

const TypeUrl = ({ loading }) => {
  return (
    <Fragment>
      <div className="my-3">
        <label htmlFor="answerUrl">URL:</label>
        <input
          type="text"
          className="form-control"
          id="answerUrl"
          required
          autoFocus
          disabled={loading}
        />
      </div>

      <div className="text-muted">
        Ex: https://drive.google.com/drive/folders/xxxxxxxI?usp=sharing
      </div>
    </Fragment>
  );
};

export default TypeUrl;
