import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { canManageContent, userHasTopicAccess } from '../../../services/currentUser';
import isPublished from '../../../utils/isPublished';
import AdminMenu from '../../AdminMenu';
import { LOCK } from '../../../constants/icons';

const RenderButtons = ({ topic, index }) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  const topicPreReqs = useSelector((state) => state.topicPreReqs);
  const topicChallenges = useSelector((state) => state.topicChallenges);
  const topicQuizzes = useSelector((state) => state.topicQuizzes);
  // Current User
  const completedCourseTopicIds = pathOr([], ['completedCourseTopicIds'], currentUser);

  if (!topic || !currentTopic || !topicPreReqs) {
    return null;
  }

  if (!currentUser && !course?.courseReadOnly) {
    return null;
  }

  if (!isPublished(topic)) {
    return null;
  }

  // Topic
  const {
    id: topicId,
    title: topicTitle
  } = topic;
  // Current Topic
  const { id: currentTopicId } = currentTopic;
  const { id: classId } = course;

  const showCompletedUi = (id) => {
    if (canManageContent({ currentUser, course })) {
      return null; // Simplifies UI to support the "..." menu.
    }

    if (completedCourseTopicIds && completedCourseTopicIds.indexOf(id) !== -1) {
      return (
        <i className="fas fa-check-circle text-keppel ml-2" />
      );
    }

    if ((topicChallenges && topicChallenges[id]) || (topicQuizzes && topicQuizzes[id])) {
      return (
        <i className="far fa-check-circle text-muted topic-not-complete-icon ml-2" />
      );
    }
  };

  if (userHasTopicAccess({
    topic, currentUser, classId, topicPreReqs
  })) {
    return (
      <div className="leftnav-topic d-flex">
        <button
          className={`leftnav-topic-btn btn btn-link text-left ${topicId === currentTopicId ? 'nav-current' : ''}`}
          type="button"
          data-toggle="collapse"
          data-target={`#subject-${index}`}
          aria-expanded={topicId === currentTopicId}
        >
          {topicTitle}

          {showCompletedUi(topicId)}
        </button>

        <AdminMenu
          id={`admin-menu-${topicId}-${index}`}
          classId={classId}
          topicId={topicId}
          title={topicTitle}
          contentType="topic"
          editContent
          className="dropdown-menu-right"
        />
      </div>
    );
  }

  return (
    <button
      className={`btn btn-link leftnav-topic-btn ${topicId === currentTopicId ? 'nav-current' : ''}`}
      type="button"
      disabled
    >
      {topicTitle} <i className={`${LOCK} ml-2`} />
    </button>
  );
};

export default RenderButtons;
