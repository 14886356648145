const getEmailsFromUsers = (users) => {
  if (!users || (Array.isArray(users) && users.length === 0)) {
    return false;
  }

  // This syntax allows for traversing our flattened and referenced data
  const emails = users.map((user) => (user.fields ? user.fields.email : user.email)).join(',');

  return emails;
};

export default getEmailsFromUsers;
