import {
  findIndex, isEmpty, isNil, pathOr, propEq
} from 'ramda';
import * as ROUTES from '../constants/routes';
import { client, flattenItems } from './contentful';

export const nextTopicRoute = ({ course, currentTopic, topics }) => {
  const currentTopicIndex = findIndex(propEq('id', currentTopic.id))(topics);
  const nextTopic = topics[currentTopicIndex + 1];

  if (nextTopic === undefined) return null;

  const nextTopicFirstEntryId = pathOr(null, ['sys', 'id'], nextTopic.sections[0]);

  if (nextTopicFirstEntryId === undefined) return null;

  return `${ROUTES.CLASSES}/${course.id}/${nextTopic.id}/${nextTopicFirstEntryId}`;
};

export const prevTopicRoute = ({ course, currentTopic, topics }) => {
  const currentTopicIndex = findIndex(propEq('id', currentTopic.id))(topics);
  const prevTopic = topics[currentTopicIndex - 1];

  if (prevTopic === undefined) return null;

  const { sections } = prevTopic;

  if (isNil(sections) || isEmpty(sections)) return null;

  const lastEntryIndex = sections.length - 1;
  const prevTopicLastEntryId = pathOr(null, ['sys', 'id'], sections[lastEntryIndex]);

  if (prevTopicLastEntryId) {
    return `${ROUTES.CLASSES}/${course.id}/${prevTopic.id}/${prevTopicLastEntryId}`;
  }

  return null;
};

export const getTopics = ({ classId }) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'topic',
      'fields.classId': classId
    };

    return client.getEntries(config).then(({ items }) => {
      const topics = items.length ? flattenItems(items) : null;

      resolve(topics);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
