import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './Toast';
import Slack from './Slack';
import './style.css';

const Toasts = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const toasts = useSelector((state) => state.toasts);

  const content = {
    integrateSlack: () => {
      return (
        <Slack />
      );
    }
  };

  if (!currentUser || !toasts.length) {
    return null;
  }

  return (
    <div
      className="toasts-container"
      aria-live="polite"
      aria-atomic="true"
    >
      <div className="toasts">
        {toasts.map(({
          id, iconClass, title, body, children
        }, i) => (
          <Toast
            key={`toast-${i}`}
            id={id}
            iconClass={iconClass}
            title={title}
            body={body}
          >
            {children ? content[id]() : null}
          </Toast>
        ))}
      </div>
    </div>
  );
};

export default Toasts;
