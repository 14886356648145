import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { pathOr } from 'ramda';
import { canManageContent, canViewLessonPlan } from '../../../services/currentUser';
import InstructionNotes from './InstructionNotes';
import Alert from '../../Alert';
import EditClassBtn from '../../btns/EditClassBtn';
import { USER_SETTINGS } from '../../../constants/icons';

const InstructorsOnly = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentTopic = useSelector((state) => state.currentTopic);
  const course = useSelector((state) => state.currentClass);
  const instructionNotes = pathOr(null, ['instructionNotes'], currentTopic);

  if (!canManageContent({ currentUser, course }) && !canViewLessonPlan(currentUser?.role)) {
    return null;
  }

  if (!instructionNotes) return null;

  return (
    <div className="pb-2">
      <Alert
        type="light"
        icon={USER_SETTINGS}
        className="alertWithIconSlim mb-0"
        hideIconOnMobile
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {currentTopic && (
              <Fragment>
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse-lesson-plan"
                  aria-expanded="false"
                  aria-controls="collapse-lesson-plan"
                  className="btn btn-sm btn-outline-secondary"
                  data-tip="Not visible to Users"
                  data-place="bottom"
                >
                  <i className="far fa-list-alt" /> Lesson Plan
                </button>
                <ReactTooltip />
              </Fragment>
            )}
          </div>

          {canManageContent({ currentUser, course }) && (
            <EditClassBtn />
          )}
        </div>
      </Alert>

      <InstructionNotes className="my-2" />

    </div>
  );
};

export default InstructorsOnly;
