import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaUser } from 'react-icons/fa';
import { handleEditUserDetails } from '../../../actions';
import { canManageDirectoryUsers, isActiveMembership } from '../../../services/currentUser';
import Loading from '../../Loading';
import ContactBlock from './ContactBlock';
import NameBlock from './NameBlock';
import WorkBlock from './WorkBlock';
import EditForms from './EditForms';
import SkillsTags from '../../SkillsTags';
import './style.css';

const UserDetails = ({
  className = '',
  contentId,
  showNameBlock,
  title = 'My Information'
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const directoryUser = useSelector((state) => state.directoryUser);
  const directoryUserEditing = useSelector((state) => state.directoryUserEditing);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  const userId = currentUser?.id || '';
  const membership = currentUser?.membership || [];
  // Directory User
  const name = directoryUser?.name || '';

  if (contentId && !directoryUser?.id) {
    return (
      <div className="col">
        <div className="card mb-1 border-bottom h-100">
          <div className="card-body p-0">
            <Loading
              className="position-relative bg-white text-dark"
            />
          </div>
        </div>
      </div>
    );
  }

  if (directoryUser?.id && directoryUserEditing?.showForms) {
    return (
      <EditForms />
    );
  }

  const canEditUserDetails = canManageDirectoryUsers(role) || directoryUser?.id === userId;

  return (
    <div className={`card mb-1 border-bottom ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6
          className="m-0 font-weight-bold d-flex align-items-center"
        >
          <FaUser />
          <span className='ml-1'>
            {title}
          </span>
        </h6>

        {canEditUserDetails && isActiveMembership(membership) && (
          <div className="btn-group align-items-center">
            <button
              className="py-0 btn btn-link btn-sm rounded text-ships-officer"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              title="Manage"
            >
              <i className="fas fa-ellipsis-h" />
            </button>
            <div className="dropdown-menu dropdown-menu-right p-0">
              {canEditUserDetails && (
                <button
                  className="dropdown-item py-2"
                  type="button"
                  title={`Edit ${name}`}
                  onClick={() => {
                    dispatch(
                      handleEditUserDetails({
                        orgId,
                        userId: directoryUser?.id
                      })
                    );
                  }}
                >
                  <i className="fas fa-pencil-alt mr-1" /> Edit Info.
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="card-body">
        {showNameBlock && (
          <NameBlock
            data={directoryUser}
          />
        )}

        <ContactBlock />

        <SkillsTags
          title="Skills"
          size='md'
          className="text-center mb-4"
          ids={directoryUser?.skillIds}
        />

        <WorkBlock />
      </div>
    </div>
  );
};

export default UserDetails;
