import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

const TitleCell = ({ value: title }) => {
  return (
    <div className='d-flex align-items-center'>

      <span className='mr-2'>
        {title}
      </span>

      <FaExternalLinkAlt className='text-muted' size={12} />
    </div>
  );
};

export default TitleCell;
