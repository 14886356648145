import { findObjectByIndex } from '@apprentage/utils';

function findObjectByKeyExists(objArray, key) {
  if (key.includes('.')) { // Ex: 'sys.id'
    const keys = key.split('.');
    return objArray.find((obj) => keys.reduce(findObjectByIndex, obj) !== undefined);
  }

  return objArray.find((obj) => obj[key] !== undefined);
}

export default findObjectByKeyExists;
