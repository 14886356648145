import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FaIdCard, FaExternalLinkAlt
} from 'react-icons/fa';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import {
  ICON_COURSE,
  ICON_CREDIT_CARD,
  ICON_TURBINE
} from '@apprentage/components/dist/constants/icons';
import {
  ATTENDANCE,
  BILLING,
  CALENDAR,
  CLASSES
} from '../../constants/routes';
import { CALENDAR_ALT, USER_CHECK } from '../../constants/icons';
import {
  canAccessAttendance,
  canAccessDirectory,
  canAccessCourseCatalog,
  isActiveMembership
} from '../../services/currentUser';
import { TURBINE_ADMIN, TURBINE_ADMIN_DOCS } from '../../constants/urls';
// import {
//   MODAL_KEY_GUIDED_QUIZ_INCORRECT,
//   MODAL_KEY_TOPIC_COMPLETE,
//   MODAL_KEY_COURSE_COMPLETE
// } from '../../constants/modal';

const UserMenuLinks = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);
  // Current User
  const role = currentUser?.role || [];
  const membership = currentUser?.membership || [];
  const locationCalendar = userLocation?.calendar || null;
  // Organization
  const orgType = organization?.type || '';
  const billing = organization?.billing || null;
  const enableCourseCatalog = organization?.enableCourseCatalog || null;

  const attendanceLink = () => {
    return (
      <>
        {canAccessAttendance(role) && (
          <Link
            className="dropdown-item border-bottom py-2"
            to={ATTENDANCE}
          >
            <i className={USER_CHECK} /> Attendance
          </Link>
        )}
      </>
    );
  };

  // If member is INACTIVE and ORG is Community, show attendance
  // so member can acccess their records
  if (!isActiveMembership(membership)) {
    if (orgType === 'community') {
      return attendanceLink();
    }

    return null;
  }

  return (
    <>

      {canAccessCourseCatalog({ enableCourseCatalog }) && (
        <Link
          className="dropdown-item border-bottom py-3"
          to={CLASSES}
        >
          <i className={`${ICON_COURSE} mr-1`} /> Course Catalog
        </Link>
      )}

      {organization?.dashboard && (
        <a
          className="dropdown-item border-bottom py-2 pr-3 d-flex justify-content-between align-items-center"
          href={TURBINE_ADMIN}
          title="Organization Dashboard"
        >
          <span className='d-flex align-items-center'>

            <MdOutlineSpaceDashboard
              size={20}
            />
            <span className='ml-2'>
              Dashboard
            </span>
          </span>

          <FaExternalLinkAlt className='text-muted' size={12} />
        </a>
      )}

      {canAccessDirectory(role, orgType) && (
        <a
          className="dropdown-item border-bottom py-2 pr-3 d-flex justify-content-between align-items-center"
          href={`${TURBINE_ADMIN}/org/users`}
          title="User Directory"
        >
          <span className='d-flex align-items-center'>
            <FaIdCard size={20} />
            <span className='ml-2'>
              User Directory
            </span>
          </span>

          <FaExternalLinkAlt className='text-muted' size={12} />
        </a>
      )}

      {locationCalendar && (
        <Link
          className="dropdown-item border-bottom py-2"
          to={CALENDAR}
        >
          <i className={`${CALENDAR_ALT} mr-1`} /> Calendar
        </Link>
      )}

      {billing && (
        <Link
          className="dropdown-item border-bottom py-2"
          to={BILLING}
        >
          <i className={`${ICON_CREDIT_CARD} mr-1`} /> Billing
        </Link>
      )}

      {attendanceLink()}

      {/* {course && canAccessCourseCompleteModalTesting(role) && (
        <button
          className="dropdown-item border-top py-2"
          onClick={() => {
            dispatch(setCurrentModal({
              key: MODAL_KEY_COURSE_COMPLETE
            }));
          }}
          type="button"
        >
          <i className="far fa-check-circle" /> Class Complete Modal
        </button>
      )}

      {course && canAccessTopicCompleteModalTesting(role) && (
        <button
          className="dropdown-item border-top py-2"
          onClick={() => {
            dispatch(setCurrentModal({
              key: MODAL_KEY_TOPIC_COMPLETE
            }));
          }}
          type="button"
        >
          <i className="far fa-check-circle" /> Topic Complete Modal
        </button>
      )}

      {course && canAccessGuidedQuizIncorrectTesting(role) && (
        <button
          className="dropdown-item border-top py-2"
          onClick={() => {
            dispatch(setCurrentModal({
              key: MODAL_KEY_GUIDED_QUIZ_INCORRECT
            }));
          }}
          type="button"
        >
          <i className="far fa-check-circle" /> Incorrect Response Feedback
        </button>
      )} */}

      <a
        className="dropdown-item border-top py-2"
        href={TURBINE_ADMIN_DOCS}
        target="_blank"
        title="Documentation"
        rel="noreferrer noopener"
      >
        <i className={`${ICON_TURBINE} mr-1`} /> Documentation
      </a>
    </>
  );
};

export default UserMenuLinks;
