import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Widget from './Widget';
import './style.css';

const ListView = () => {
  // Redux
  const widgets = useSelector((state) => state.widgets);

  return (
    <div
      className='row'
    >
      {widgets && widgets.map((widget, i) => {
        return (
          <Fragment
            key={`widget-${i}`}
          >
            <Widget
              {...widget}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ListView;
