import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentAnswers } from '../../../actions/Answers';
import AssessmentsList from '../../AssessmentsList';

const CurrentUserAssessments = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const { id: userId } = currentUser;
  const { id: orgId } = organization;

  useEffect(() => {
    dispatch(setCurrentAnswers({
      status: 'all',
      userId,
      orgId
    }));
  }, [userId, orgId]);

  return (
    <div className="card h-100">
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          <i className="fas fa-clipboard-list mr-1" /> My Submissions
        </h5>
      </div>
      <div className="card-content overflow-hidden">
        <AssessmentsList />
      </div>
    </div>
  );
};

export default CurrentUserAssessments;
