import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const todaysDate = () => {
  return dayjs().format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mdyDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dayMdyDate = (timestamp) => {
  return dayjs(timestamp).format('ddd., MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mdySimple = (timestamp) => {
  return dayjs(timestamp).format('MM/DD/YYYY'); // '01/25/2020'
};

export const ySimple = (timestamp) => {
  return dayjs(timestamp).format('YYYY'); // '2020'
};

export const hrMin = (timestamp) => {
  return dayjs(timestamp).format('h:mm A', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dateTimestamp = () => { // { utcHours }
  const date = new Date();
  const timezoneOffset = date.getMinutes() + date.getTimezoneOffset();
  const timestamp = date.getTime() + timezoneOffset * 1000;
  const correctDate = new Date(timestamp);

  // if (utcHours === true) {
  //   correctDate.setUTCHours(0, 0, 0, 0);
  // }

  return correctDate.toISOString();
};
