import { mdySimple } from '../../../../utils/date';

const CreatedAtCell = ({ value: createdAt }) => {
  if (createdAt) {
    return mdySimple(createdAt);
  }

  return '-';
};

export default CreatedAtCell;
