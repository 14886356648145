import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { activeCohorts } from '../../../services/courses';
import isPublished from '../../../utils/isPublished';
import ChooseClassCodes from '../../Classes/ChooseClassCodes';

const CourseCohorts = ({
  classTitle,
  existingSelectedCohort,
  onChange
}) => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  // local state
  const [selectedCohort, setSelectedCohort] = useState(existingSelectedCohort || null);
  // misc
  const cohortIds = pathOr(null, ['cohortIds'], currentUser);
  const userCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));
  const selectedCohortTitle = pathOr(null, ['title'], selectedCohort);
  const selectedCohortEUnitTracking = pathOr(null, ['eUnitTracking'], selectedCohort); // TODO cohort flatten
  const selectedCohortEUnits = pathOr([], ['eUnits'], selectedCohort); // TODO cohort flatten

  const selectCohort = (newCohort) => {
    setSelectedCohort(newCohort);
    onChange({ selectedCohort: newCohort });
  };

  const resetCohort = () => {
    setSelectedCohort(null);
    onChange({ selectedCohort: null, eUnitIds: [] });
  };

  if (selectedCohort) {
    return (
      <>
        <div className="card mb-2">
          <div className="card-header d-flex justify-content-between">
            <h5 className="mb-0">
              <i className="fas fa-check-circle text-keppel mr-2" /> Select a Cohort
            </h5>
            <button
              className="btn btn-sm btn-link p-0"
              onClick={resetCohort}
              type="button"
            >
              Change
            </button>
          </div>
          <div className="card-body">
            <p className="m-0">
              <b>{selectedCohortTitle}</b>
            </p>
          </div>
        </div>

        {selectedCohortEUnits.length !== 0 && (
          <div className="card text-left">
            <div className="card-header h3 mb-0">
              <h5 className="m-0">
                <i className="far fa-check-circle text-muted opacity-50 mr-2" /> Select Class Codes
              </h5>
            </div>
            <div className="card-body p-0">
              <ChooseClassCodes
                classTitle={classTitle}
                eUnitTracking={selectedCohortEUnitTracking}
                eUnits={selectedCohortEUnits}
                onChange={onChange}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  const activeCourseCohorts = activeCohorts(courseCohorts); // TODO may not be needed getCohorts only pulls active ones

  if (!activeCourseCohorts) {
    return (
      <div className="card text-left">
        <div className="card-header d-flex">
          <h5 className="mb-0">
            Select a Cohort
          </h5>
        </div>
        <div className="card-body text-left">
          <p className="m-0">
            <b>No active Cohorts.</b>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="card text-left">
      <div className="card-header d-flex">
        <h5 className="mb-0">
          Select a Cohort
        </h5>
      </div>
      <div className="card-body p-2">
        {activeCourseCohorts.map((cohort, i) => {
          const {
            id: cohortId, title, eUnits
          } = cohort;

          const isEnrolled = userCohorts ? userCohorts.find((userCohort) => userCohort.id === cohortId) : false;

          if (isEnrolled) return null;

          return (
            <div
              key={i}
              className="cohort-container border rounded mb-3"
            >
              <div className="border-bottom p-2">
                <h5 className="font-weight-bold m-0">
                  { title }
                </h5>
              </div>

              {eUnits && ( // TODO cohort flatten (educationUnitIds)
                <div className="d-flex flex-column p-2">
                  {eUnits.map((eUnit, eUnitIndex) => {
                    if (!isPublished(eUnit)) return null;

                    return (
                      <div
                        key={eUnitIndex}
                        className="bg-light border p-2 mb-2 rounded"
                      >
                        <div
                          className="font-weight-bold"
                          style={{ fontSize: '1rem' }}
                        >
                          { eUnit.fields.code }
                        </div>
                        <div>
                          { eUnit.fields.timeDate }
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="border-top p-2">
                <button
                  className="btn btn-sm btn-primary stretched-link"
                  type="button"
                  onClick={() => {
                    selectCohort(cohort);
                  }}
                >
                  Select
                </button>
              </div>

            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseCohorts;
