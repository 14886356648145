import { pathOr } from 'ramda';
import { emailManagers, emailUser } from '../services/email';
import { isLocalhost } from '../serviceWorker';
import getEmailsFromUsers from '../utils/getEmailsFromUsers';

export const notifyManagers = ({
  subject, message, to, replyTo
}) => {
  return (_dispatch, getState) => {
    // TODO add promise to show success message
    // when contacting through billing page.
    const { currentUser, userLocation, organization } = getState();
    const { name, phone, email } = currentUser;
    const { name: orgName, email: orgEmail } = organization;

    const data = {
      subject,
      message,
      orgName,
      orgEmail,
      userName: name,
      userPhone: phone,
      userEmail: email
    };

    // bcc managers and observers
    const managers = pathOr([], 'managers', userLocation); // TODO location flatten
    const observers = pathOr([], 'observers', userLocation); // TODO location flatten
    const managersObservers = [...managers, ...observers];
    const bcc = getEmailsFromUsers(managersObservers);

    if (to) data.to = to;
    if (replyTo) data.replyTo = replyTo;
    if (bcc) data.bcc = bcc;

    if (isLocalhost) {
      console.log(data);
    } else {
      emailManagers(data);
    }
  };
};

export const notifyUser = ({ message, to, toName }) => {
  return (_dispatch, getState) => {
    const { currentUser, organization } = getState();
    const { name: orgName } = organization;
    const { name: userName, email: userEmail } = currentUser;

    const data = {
      to, // recipient email
      toName, // recipient name
      replyTo: userEmail, // sender's email
      fromName: userName, // sender's name, current user
      subject: `New message from ${userName}`,
      message,
      orgName
    };

    if (isLocalhost) {
      console.log(data);
    } else {
      emailUser(data);
    }
  };
};
