import React from 'react';
import * as ICONS from '../../../constants/icons';
import './style.css';

const displayTypes = {
  list: {
    title: 'List',
    icon: ICONS.LIST
  },
  groups: {
    title: 'Groups',
    icon: ICONS.GROUP
  }
};

const ListDisplayType = ({
  onChange,
  activeType = 'list'
}) => {
  const displayTypesArray = Object.entries(displayTypes).map(([key, displayType]) => ({
    type: key,
    ...displayType
  }));

  return (
    <div className="dropdown">
      <button
        data-cy="displayTypesBtn"
        className="displayItemType d-flex align-items-center border p-2 rounded"
        type="button"
        id="displayItemTypeDropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className={displayTypes[activeType].icon} />

        <span className="ml-2 d-none d-md-block">
          {displayTypes[activeType].title}
        </span>
      </button>

      <div
        className="displayItemTypeDropdown dropdown-menu dropdown-menu-right p-0"
        aria-labelledby="displayItemTypeDropdownMenuButton"
      >
        {displayTypesArray.map(({ title, type, icon }, i) => (
          <button
            key={`${type}-${i}`}
            title={title}
            type="button"
            className={`dropdown-item displayItemType py-2 align-items-center ${activeType === type ? 'd-none' : 'd-flex'}`}
            onClick={() => onChange(type)}
          >
            <i className={icon} />
            <span className="ml-2">
              {title}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ListDisplayType;
