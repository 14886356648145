import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { percentComplete } from '@apprentage/utils';
import RenderMarkdown from '../../../ManageContent/RenderMarkdown';
import ProgressBar from '../../../ProgressBar';
import findObjectIndexByKey from '../../../../utils/findObjectIndexByKey';
import isPublished from '../../../../utils/isPublished';
import { courseAttendanceByCohort } from '../../../../services/attendance';
import { courseCohortUserHasEnrolled, activeCohorts } from '../../../../services/courses';
import './style.css';

const pluralize = (items, singular, plural) => {
  if (items === 1) return singular;

  return plural;
};

const SessionAttendance = () => {
  const course = useSelector((state) => state.currentClass);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const currentUser = useSelector((state) => state.currentUser);
  const topics = useSelector((state) => state.topics);

  if (!course || !currentUser) return null;

  const { attendance, cohortIds } = currentUser;
  const { id: classId, title: classTitle } = course;
  const userCourseCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));
  const userCohorts = activeCohorts(userCourseCohorts);
  const activeCohort = courseCohortUserHasEnrolled({ userCohorts, courseCohorts });

  if (!activeCohort) return null;

  const { id: cohortId, eUnitTracking } = activeCohort;

  if (!isPublished(eUnitTracking)) return null;

  // TODO cohort flatten (fetch eUnitTracking by cohortId or migrate into Cohort)
  const attendancePerSession = pathOr(null, ['fields', 'attendancePerSession'], eUnitTracking);

  const {
    fields: { // TODO cohort flatten
      sessions,
      sessionsTopicSync,
      info,
      enableNewEditor
    }
  } = eUnitTracking;

  const whichSessions = (sessionsTopicSync && topics && topics.length > 0) ? topics.length : sessions;
  const eUnitSessions = [...Array(whichSessions).keys()];

  return (
    <div className="collapse" id="collapse-take-attendance" data-parent="#students-only">
      <div className="card mb-5">
        <div className="card-header p-3">
          <h1 className="h5 m-0">
            Attendance: <strong>{classTitle}</strong>
          </h1>
        </div>
        <div className="card-content p-3">
          <div>
            {info ? (
              <RenderMarkdown
                source={info}
                enableNewEditor={enableNewEditor}
              />
            ) : (
              <p>
                To complete this session, <b>Check In</b> {attendancePerSession} {attendancePerSession === 1 ? 'time' : 'times'}.
                {/* Each session is worth {integration.sessionCredits} credits. */}
              </p>
            )}
          </div>

          {eUnitSessions.map((session) => {
            let index = -1;
            let valueNow = 0;
            let valueNowPercent = '0%';

            if (attendance && attendance[classId]) {
              const cohortAttendance = courseAttendanceByCohort({
                courseAttendance: attendance[classId],
                cohortId
              });

              if (cohortAttendance) {
                index = findObjectIndexByKey(cohortAttendance, 'session', session + 1);
              }

              const sessionAttendance = index !== -1 && cohortAttendance[index] ? cohortAttendance[index] : 0;

              valueNow = (sessionAttendance) ? sessionAttendance.records.length : 0;
              const attendedPercent = percentComplete({
                current: valueNow,
                total: attendancePerSession
              });

              valueNowPercent = sessionAttendance ? attendedPercent : '0%';
            }

            return (
              <div className="mb-3" key={session}>
                <div className="d-flex align-items-center justify-content-between">
                  <b>Session: {session + 1}</b>
                  <div>{valueNow} / {attendancePerSession} Check-ins</div>
                </div>
                <div className={`progress h-100 ${valueNow === 0 ? 'p-1 justify-content-center' : ''}`}>
                  <ProgressBar
                    className="bg-success"
                    valueNow={valueNowPercent}
                    text={`${valueNow} ${pluralize(valueNow, 'Check-in', 'Check-ins')}`}
                    style={{ width: valueNowPercent, padding: valueNowPercent !== '0%' ? '.25rem' : 0 }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SessionAttendance;
