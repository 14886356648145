import { fetchUserProfile } from '../services/userProfiles';
import { RESET_DIRECTORY_USER_PROFILE, RESET_USER_PROFILE, SET_DIRECTORY_USER_PROFILE } from './types';

export const getDirectoryUserProfile = (userProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUserProfile(userProfileId)
        .then((directoryUserProfile) => {
          dispatch({ type: SET_DIRECTORY_USER_PROFILE, directoryUserProfile });
          resolve(directoryUserProfile);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetUserProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_PROFILE });
  };
};

export const resetDirectoryUserProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIRECTORY_USER_PROFILE });
  };
};
