import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CLASSES } from '../../constants/routes';
import { canViewAnswerFeedback } from '../../services/currentUser';
import { mdyDate } from '../../utils/date';
import Feedback from '../Class/Challenge/Feedback';
import statusFeedback from './statusFeedback';

const AssessmentStatus = styled.span`
  align-items: center;
  border-radius: 100%;
  color: white;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-right: .5rem;
  width: 25px;
`;

const statusUi = (status) => {
  const {
    alertType, iconClass, title
  } = statusFeedback(status);
  return (
    <Fragment>
      <AssessmentStatus
        className={`badge badge-${alertType}`}
      >
        <i className={iconClass} />
      </AssessmentStatus>
      <span className="d-none d-md-inline">
        {title}
      </span>
    </Fragment>
  );
};

const Rows = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  const courses = useSelector((state) => state.courses);

  if (!currentAnswers || !currentUser) return null;

  // Misc
  const { role } = currentUser;

  return (
    <Fragment>
      {currentAnswers.map((answer, i) => {
        const {
          id: answerId,
          createdAt,
          status,
          challengeId,
          classId,
          topicId,
          feedback
        } = answer;
        const course = courses && courses.find((c) => c.id === classId);
        const challengeTitle = answer.title.split('-')[0];
        return (
          <tr key={`${answerId}-${i}`}>
            <td className="border-right pr-0 d-flex align-items-center">
              { statusUi(status) }
            </td>
            <td className="border-right pr-0 d-none d-md-table-cell">
              {mdyDate(createdAt)}
            </td>
            <td className="border-right pr-0 d-none d-md-table-cell">
              <Link
                to={`${CLASSES}/${classId}/${topicId}/${challengeId}`}
                title={challengeTitle}
                className="btn btn-link text-left"
              >
                <b>
                  {challengeTitle}
                </b>
              </Link>
            </td>
            <td className="border-right pr-0">
              {course ? course.title : ''}
            </td>

            {canViewAnswerFeedback(role) && (
              <td
                data-demo="disabled"
                className="border-right"
              >
                <Feedback
                  feedback={feedback}
                />
              </td>
            )}
          </tr>
        );
      })}
    </Fragment>
  );
};

export default Rows;
