import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RESOURCE } from '../../../constants/icons';
import CdnFilesList from './List';
import './style.css';

const CdnFiles = ({
  id = 'integration-files',
  title,
  files,
  editMenu,
  theme = 'dark',
  className = '',
  header = true
}) => {
  const currentUser = useSelector((state) => state.currentUser);

  if (!currentUser) return null;

  /**
   * @summary - array of files stored on external CDN
   * @id {string} uuid created on turbine api (also used as filename in url)
   * @type {string} 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/vnd.ms-excel'
   * @name {string} 'cool-file.png'
   * @size {int} 301082
   * @url {string} 'https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/1a120758-389e-43b2-be2a-78c736e814b9.jpg'
   */

  return (
    <Fragment>
      <div id={id} className={`card ${className}`}>
        {header && (
          <div
            className={`card-header d-flex align-items-center justify-content-between ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light'}`}
          >
            <h5 className="m-0">
              <i className={`${RESOURCE} mr-1`} /> {title || 'Files'}
            </h5>
          </div>
        )}
        <div className="cdn-files card-body p-0 overflow-auto">
          {files ? (
            <CdnFilesList
              data={files}
              currentUser={currentUser}
              editMenu={editMenu}
            />
          ) : (
            <div className="p-3">
              Files will appear here when uploaded from fields above.
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

// {
//   "url": "https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/37126d32-abd9-4849-9f2e-8fb0b39ee411.jpg",
//   "name": "portfolio--example-5.jpg",
//   "type": "image/jpeg",
//   "size": 301082
// }

export default CdnFiles;
