import {
  findIndex, isEmpty, isNil, pathOr, propEq
} from 'ramda';
import { clientManagement, CSPACE } from './contentful';
import convertObjectToUrlParams from '../utils/convertObjectToUrlParams';
import removeKeysWithUndefinedFromObject from '../utils/removeKeysWithUndefinedFromObject';
import { nextTopicRoute, prevTopicRoute } from './topics';
import * as ROUTES from '../constants/routes';
import { answerAcceptedOrRequestChanges } from './answers';

export const createEntry = ({ contentType, data }) => {
  return new Promise((resolve, reject) => {
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.createEntry(contentType, data))
      .then((entry) => entry.publish())
    // .then((entry) => console.log(`Entry ${entry.sys.id} published.`))
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const updateEntry = ({ id, data }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((space) => space.getEntry(id))
      .then((entry) => {
        entry.fields = {
          ...entry.fields,
          ...data.fields
        };

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const manageDataPrep = (data) => {
  if (data.addTypes) data.addTypes = data.addTypes.join(',');

  return removeKeysWithUndefinedFromObject(data);
};

export const manageEntry = (data) => {
  const prepData = manageDataPrep(data);

  return convertObjectToUrlParams(prepData);
};

export const nextEntryRoute = ({
  course,
  currentTopic,
  topics,
  currentEntry,
  currentAnswer
}) => {
  const { id: entryId, contentType } = currentEntry;
  const { sections } = currentTopic;

  if (isNil(sections) || isEmpty(sections)) return null;

  const currentEntryIndex = findIndex(propEq('id', entryId))(sections);
  const nextEntryIndex = currentEntryIndex + 1;
  const nextEntryId = pathOr(null, ['id'], sections[nextEntryIndex]);

  if (nextEntryId) {
    return `${ROUTES.CLASSES}/${course.id}/${currentTopic.id}/${nextEntryId}`;
  }

  const nextTopicRouteStr = nextTopicRoute({ course, currentTopic, topics });

  if (contentType === 'challenge' && currentAnswer) {
    if (!answerAcceptedOrRequestChanges({ currentAnswer })) {
      return null;
    }
  }

  // if (contentType === 'quiz') {
  // TODO move logic here that is currently
  // in src/components/Class/Quiz/index.js
  // }

  if (nextTopicRouteStr) {
    return nextTopicRouteStr;
  }

  return null;
};

export const prevEntryRoute = ({
  course, currentTopic, topics, currentEntry
}) => {
  const { id: entryId } = currentEntry;
  const { sections } = currentTopic;

  if (isNil(sections) || isEmpty(sections)) return null;

  const currentEntryIndex = findIndex(propEq('id', entryId))(sections);
  const prevEntryIndex = currentEntryIndex - 1;
  const prevEntryId = pathOr(null, ['id'], sections[prevEntryIndex]);

  if (prevEntryId) {
    return `${ROUTES.CLASSES}/${course.id}/${currentTopic.id}/${prevEntryId}`;
  }

  const prevTopicRouteStr = prevTopicRoute({ course, currentTopic, topics });

  if (prevTopicRouteStr) {
    return prevTopicRouteStr;
  }

  return null;
};
