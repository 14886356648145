import { fetchCurrentWeather } from '../services/weather';
import { SET_LOCATIONS_WEATHER_CURRENT, SET_USER_WEATHER_CURRENT } from './types';

export const getUserWeather = ({ postalCode }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCurrentWeather({
        postalCodes: [postalCode]
      }).then(({ location, current }) => {
        dispatch({
          type: SET_USER_WEATHER_CURRENT,
          location,
          current
        });
        resolve({ location, current });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getLocationsWeather = ({ postalCodes }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCurrentWeather({
        postalCodes
      }).then((weatherCurrentList) => {
        dispatch({
          type: SET_LOCATIONS_WEATHER_CURRENT,
          weatherCurrentList
        });
        resolve(weatherCurrentList);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};
