import { fetchCourses, fetchSyndicatedCourses } from '../services/courses';
import { encodePayload } from '../services/payload';
import {
  SET_COURSES, RESET_COURSES, SET_ENROLL, RESET_ENROLL
} from './types';

export const getCourses = ({ orgId, courseIds }) => {
  return (dispatch) => {
    let allCourses = [];

    if (courseIds) {
      fetchCourses({ courseIds }).then((courses) => {
        dispatch({
          type: SET_COURSES,
          courses
        });
      });
    } else {
      fetchCourses({ orgId }).then((courses) => {
        if (courses && Array.isArray(courses) && courses.length > 0) {
          allCourses = [...courses];
        }

        fetchSyndicatedCourses({ orgIds: [orgId] }).then((syndicatedCourses) => {
          if (syndicatedCourses && Array.isArray(syndicatedCourses) && syndicatedCourses.length > 0) {
            allCourses = [
              ...allCourses,
              ...syndicatedCourses
            ];
          }

          dispatch({
            type: SET_COURSES,
            courses: allCourses.length > 0 ? allCourses : null
          });
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  };
};

export const resetCourses = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COURSES });
  };
};

export const setEnroll = (data) => {
  const spice = encodePayload(data);

  return (dispatch) => {
    return new Promise((resolve) => {
      const enroll = { ...data, spice };

      dispatch({ type: SET_ENROLL, enroll });
      resolve(enroll);
    });
  };
};

export const resetEnroll = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ENROLL });
  };
};
