import React, { Fragment } from 'react';
import { mdyDate } from '../../utils/date';

const rows = ({ attendance }) => {
  const attendanceDescending = attendance.slice().reverse();

  return (
    <Fragment>
      {attendanceDescending.map((item, i) => (
        <tr key={`${item.date}-${i}`}>
          <td className="border-right pr-0">
            {mdyDate(item.date)}
          </td>
          <td className="border-right pr-0">
            {item.class.title}
          </td>
          <td
            data-demo="disabled"
            className="border-right pr-0"
          >
            {item.instructor ? item.instructor.name : 'Student'}
          </td>
        </tr>
      ))}
    </Fragment>
  );
};

const UserAttendance = ({ attendance }) => {
  if (!attendance) return null;

  return (
    <Fragment>
      <table className="table table-striped m-0">
        <thead>
          <tr>
            <th scope="col" className="border-0">Date</th>
            <th scope="col" className='border-0 d-none d-md-table-cell'>Classes</th>
            <th scope="col" className='border-0 d-none d-md-table-cell'>Record Taker</th>
          </tr>
        </thead>
        <tbody>
          { rows({ attendance }) }
        </tbody>
      </table>
    </Fragment>
  );
};

export default UserAttendance;
