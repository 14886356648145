import React from 'react';
import { SLACK_ICON } from '@apprentage/constants';

const DirectMessageButton = ({
  name,
  organization,
  slackUserId,
  buttonText,
  className = 'btn btn-sm btn-white text-nowrap',
  showIcon = false
}) => {
  if (!organization || !name || !slackUserId || !buttonText) {
    return null;
  }

  const { integration } = organization;

  if (!integration) return null;

  const href = `slack://user?team=${integration.slack.team.id}&id=${slackUserId}`;
  const text = buttonText || 'Direct Message';

  return (
    <a
      href={href}
      className={`d-flex align-items-center ${className}`}
      target="_blank"
      rel="noreferrer noopener"
      title={`${text}`}
    >
      {showIcon && (
        <img
          src={SLACK_ICON}
          className='mr-1'
          alt="Slack"
          height="14"
          style={{
            height: '14px'
          }}
        />
      )}
      <span>
        {text}
      </span>
    </a>
  );
};

export default DirectMessageButton;
