import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import isPublished from '../../utils/isPublished';
import UserCeAttendance from '../UserCeAttendance';
import NoRecords from './NoRecords';

const showCertificateButton = ({ courseCohortRecord }) => {
  const { eUnitTracking } = courseCohortRecord; // attendance,

  // TODO loop through attendance and check number of check-ins against attendancePerSession

  // if all matches, show button
  // if not, hide and show warning to student

  // const attendanceLen = attendance.length;
  // let attendancePerSession = 0;

  // attendance.forEach((items) => {
  // });

  // TODO cohort flatten
  if (isPublished(eUnitTracking) && eUnitTracking.fields.certificate) {
    return true;
  }
};

const CohortAttendance = ({
  courseIndex,
  attendance,
  openCertificate,
  classId,
  classTitle
}) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <Fragment>
      {attendance ? attendance.map((courseCohortRecord, cohortIndex) => (
        <div className="card" key={cohortIndex}>
          <div className="card-header" id={`heading-${courseIndex}-${cohortIndex}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse-${courseIndex}-${cohortIndex}`}
                aria-expanded={courseIndex === 0 && cohortIndex === 0 ? 'true' : ''}
                aria-controls={`collapse-${courseIndex}-${cohortIndex}`}
              >
                { courseCohortRecord.title }
              </button>
            </h2>
          </div>

          <div
            id={`collapse-${courseIndex}-${cohortIndex}`}
            className={`collapse ${courseIndex === 0 && cohortIndex === 0 ? 'show' : ''}`}
            aria-labelledby={`heading-${courseIndex}-${cohortIndex}`}
            data-parent="#attendanceCourseCohorts"
          >
            <div className="card-body">
              <div className="d-flex justify-content-end">
                {(showCertificateButton({ courseCohortRecord }) || currentUser.testUser) && (
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      openCertificate({
                        courseCohortRecord,
                        classTitle,
                        classId
                      });
                    }}
                    type="button"
                  >
                    Request Certificate
                  </button>
                )}
              </div>
              <div className="p-3">
                <UserCeAttendance
                  student={currentUser}
                  // attendance coming from currentUser in generateCourseRecords
                  attendance={courseCohortRecord.attendance}
                  // eUnitTracking coming from course in generateCourseRecords
                  eUnitTracking={courseCohortRecord.eUnitTracking || null}
                  // eUnites coming from course in generateCourseRecords
                  eUnits={courseCohortRecord.eUnits || null}
                />
              </div>
            </div>
          </div>
        </div>
      )) : (
        <NoRecords />
      )}
    </Fragment>
  );
};

export default CohortAttendance;
