import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import { getOrg } from '../../actions/Organizations';
import {
  setClassCertificate,
  resetClassCertificate,
  setCurrentClass,
  resetCurrentClass
} from '../../actions/Class';
import { getUserByUniqueCertificateId } from '../../actions/Users';
import { COMPLETION_CERTIFICATE, SLASH } from '../../constants/routes';
import { PHOTONICS_COURSE_ID, TRUST_COURSE_ID } from '../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';
import { CCAC_PHOTONICS_CERTIFICATE_BKG, CCAC_TRUST_CERTIFICATE_BKG } from '../../constants/assets';
import { mdyDate } from '../../utils/date';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import { updateUser } from '../../services/user';
import { unpublish } from '../../services/contentful';
import { fetchQuizOutcomes, getQuizzes } from '../../services/quizzes';
import Loading from '../Loading';
import './style.css';

const CompletionCertificate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // Redux
  const classCertificate = useSelector((state) => state.classCertificate);
  const user = useSelector((state) => state.user);
  const course = useSelector((state) => state.currentClass);
  // Params
  const id = params?.uniqueCertificateId || null;
  const orgSlug = params?.orgSlug || null;
  // Course Certificate
  const uniqueCertificateId = classCertificate?.id || null;
  const userName = classCertificate?.userName || null;
  const issueDate = classCertificate?.issueDate || null;
  const orgId = classCertificate?.orgId || null;
  const orgName = classCertificate?.orgName || null;
  const orgLogo = classCertificate?.orgLogo || null;

  const showOneTimeUseWarning = !!user && course;

  const customCertificateCourses = [TRUST_COURSE_ID, PHOTONICS_COURSE_ID];
  // https://app.turbinelms.com/completion-certificate/[slug]/[CertID]

  const customCertBkgUrl = useMemo(() => {
    switch (course?.id) {
      case TRUST_COURSE_ID:
        return CCAC_TRUST_CERTIFICATE_BKG;
      case PHOTONICS_COURSE_ID:
        return CCAC_PHOTONICS_CERTIFICATE_BKG;
      default:
        return '';
    }
  }, [course?.id]);

  useEffect(() => {
    document.getElementsByTagName('html')[0].className = 'completion-certificate';

    return function cleanup() {
      document.getElementsByTagName('html')[0].className = '';
      dispatch(resetClassCertificate());
      dispatch(resetCurrentClass());
    };
  }, [dispatch]);

  useEffect(() => {
    /**
     * Legacy
     * This handles the case when certificates were issued w/o orgSlug
     */
    if (!orgSlug) {
      dispatch(getUserByUniqueCertificateId({
        uniqueCertificateId: id
      })).then((currentUser) => {
        const uniqueCertificateData = currentUser?.integration?.uniqueCertificateData || false;
        const uniqueCertificate = uniqueCertificateData && uniqueCertificateData[id] ? uniqueCertificateData[id] : null;

        if (!uniqueCertificate) {
          history.replace(SLASH);
          return;
        }

        dispatch(getOrg({
          orgId: uniqueCertificate?.orgId
        })).then(({ organization }) => {
          document.location = `${COMPLETION_CERTIFICATE}/${organization?.slug}/${id}`;
        });
      }).catch((error) => {
        toast.error('No Certificate Found!');
        console.error(error);
      });
    }
    /**
     * End Legacy logic
     */
  }, [dispatch, history, id, orgSlug]);

  useEffect(() => {
    if (orgSlug) {
      dispatch(getOrg({
        slug: orgSlug
      })).then(({ organization }) => {
        if (!organization?.id) {
          console.error('No Organization');
          history.replace(SLASH);
          return;
        }

        setTimeout(() => {
          dispatch(getUserByUniqueCertificateId({
            uniqueCertificateId: id,
            orgId: organization?.id
          })).then((currentUser) => {
            const uniqueCertificateData = currentUser?.integration?.uniqueCertificateData || false;
            const uniqueCertificate = uniqueCertificateData && uniqueCertificateData[id] ? uniqueCertificateData[id] : null;

            if (!uniqueCertificate) {
              console.error('No Cert');
              history.replace(SLASH);
              return;
            }

            //
            // TODO make user inactive
            // TODO unpublish all Challenge Answers +others
            // TODO make uid = n/a
            // TODO delete user in firebase
            //
            // const { membership } = currentUser;
            // const dataToSave = { membership };

            // if (uniqueCertificate.oneTimeUse) {
            //   if (!membership.includes('inactive')) {
            //     dataToSave.membership.push('inactive');

            //     updateUser(dataToSave, currentUser?.id);
            //   }
            // }

            // TODO move into function
            // TODO delete quizOutcome once migrated to Firebase
            // DATA CLEAN UP
            if (currentUser?.id && uniqueCertificate?.oneTimeUse) {
              if (currentUser?.completedCourseIds) {
                // User has completed Classes
                const completedClassIdsHash = {};

                currentUser.completedCourseIds.forEach((completedCourseId) => {
                  completedClassIdsHash[completedCourseId] = true;
                });

                if (completedClassIdsHash[uniqueCertificate?.classId] !== undefined) {
                  // User has completed the class associated with this certificate

                  getQuizzes(uniqueCertificate?.classId).then((quizzes) => {
                    fetchQuizOutcomes({
                      classId: uniqueCertificate?.classId,
                      userId: currentUser?.id
                    }).then((outcomes) => {
                      if (Array.isArray(outcomes) && outcomes.length > 0) {
                        const guidedQuizTopicIds = [];
                        const guidedQuizIdsHash = {};
                        const guidedQuizOutcomeIds = [];

                        quizzes.forEach((quiz) => {
                          if (quiz.guidedQuiz === true) {
                            guidedQuizIdsHash[quiz?.id] = true;
                            guidedQuizTopicIds.push(quiz?.topicId);
                          }
                        });

                        outcomes.forEach((outcome) => {
                          if (guidedQuizIdsHash && guidedQuizIdsHash[outcome.quizId] !== undefined) {
                            guidedQuizOutcomeIds.push(outcome.id);
                          }
                        });

                        const dataToSave = {
                          completedTopicIdsToRemove: guidedQuizTopicIds
                        };
                        // TODO delete all quiz outcomes programmatically
                        unpublish(guidedQuizOutcomeIds, 'Entry');
                        updateUser(dataToSave, currentUser?.id);
                      }
                    });
                  });
                }
              }
            }

            dispatch(getOrg({
              orgId: uniqueCertificate?.orgId
            })).then((response) => {
              dispatch(setClassCertificate({
                id,
                ...uniqueCertificate,
                userName: currentUser?.name,
                userEmail: currentUser?.email,
                orgId: response?.organization?.id,
                orgName: response?.organization?.name,
                orgLogo: response?.organization?.orgLogo,
                orgSlug: response?.organization?.slug
              }));
            });
            dispatch(setCurrentClass({
              classId: uniqueCertificate.classId
            }));
          }).catch((error) => {
            toast.error('No Certificate Found!');
            console.error(error);
          });
        }, 1000);
      });
    }
  }, [dispatch, history, id, orgSlug]);

  if (!id) {
    return (
      <Redirect to={SLASH} />
    );
  }

  if (!classCertificate?.id || !course?.id) {
    return (
      <Loading className="bg-white text-muted" />
    );
  }

  if (!orgId) {
    return (
      <Loading className="bg-white text-muted" />
    );
  }

  // COMMUNITY COLLEGE ALLEGHENY COUNTY
  // - TRUST
  // - SILICON PHOTONICS
  if (customCertificateCourses.includes(course.id)) {
    return (
      <main
        role="main"
        id="trust-certificate-container"
        className="col-sm-12 py-3"
      >
        <div
          className={`d-flex align-items-center justify-content-${showOneTimeUseWarning ? 'between' : 'end'} mb-3`}
        >
          {showOneTimeUseWarning && (
            <div className="align-items-center hide-when-printing">
              <i className="fas fa-exclamation-triangle text-danger mr-1" /> Do not close this window until you have saved or printed your certificate.
            </div>
          )}
          <button
            className="btn btn-md btn-primary ml-3 hide-when-printing"
            title="Print"
            type="button"
            onClick={() => {
              window.print();
            }}
          >
            <i className="fas fa-print" /> Print
          </button>
        </div>

        <div
          id="trust-certificate"
          style={{ backgroundImage: `url(${customCertBkgUrl})` }}
        >
          <div id="trust-certificate-data">
            <div>
              {userName}
            </div>
            <div>
              {uniqueCertificateId}
            </div>
            <div>
              {orgName} on {mdyDate(issueDate)}
            </div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main role="main" className="col-sm-12 py-3">

      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
        {course?.completionCertificateLogo ? (
          <img
            src={course?.completionCertificateLogo}
            alt="Logo"
            height="45"
            style={{
              height: '45px'
            }}
          />
        ) : (
          <div className="watermark-id">
            {uniqueCertificateId}
          </div>
        )}

        <div className="d-flex align-items-center">
          {course.completionCertificateLogo && (
            <div className="watermark-id">
              {uniqueCertificateId}
            </div>
          )}
          <button
            className="btn btn-md btn-primary hide-when-printing ml-3"
            title="Print"
            type="button"
            onClick={() => {
              window.print();
            }}
          >
            <i className="fas fa-print" /> Print
          </button>
        </div>
      </div>

      <h1>
        {course?.title}
      </h1>
      <h3>
        Completion Certificate
      </h3>

      <div className="my-5">
        <p>
          <b>Name:</b>
          <br />
          {userName}
        </p>
        <p>
          <b>Authentication Token:</b>
          <br />
          {uniqueCertificateId}
        </p>

        <p>
          <b>Issued by:</b>
          <br />
          {orgName} on {mdyDate(issueDate)}
        </p>
      </div>

      <RenderMarkdown
        source={course.completionCertificateDescription}
        enableNewEditor={course.enableNewEditor}
      />

      <div className="d-flex justify-content-between align-items-center border-top border-bottom py-4">
        <img
          src={orgLogo}
          alt={`${orgName} Logo`}
          height="50"
          style={{
            height: '50px'
          }}
        />

        <div className="d-flex align-items-center">
          Powered by <a className="text-ships-officer ml-1" title="Visit Turbine Workforce" href={TURBINE_MARKETING_SITE} target="_blank" rel="noopener noreferrer">Turbine Workforce</a>
        </div>

        <QRCode
          value={document.location.href}
          size={50}
          bgColor="#ffffff"
          fgColor="#000000"
          level="L"
          includeMargin={false}
          renderAs="svg"
        />
      </div>

      {!!user && course?.id && (
        <div className="my-4 pt-3 text-center hide-when-printing">
          <i className="fas fa-exclamation-triangle text-danger mr-1" /> Do not close this window until you have saved or printed your certificate.
        </div>
      )}
    </main>
  );
};

export default CompletionCertificate;
