import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withAuthorization } from '../Session';
import { getDirectoryUser, resetDirectoryUser, setCurrentModal } from '../../actions';
import { MODAL_KEY_APPRENTICE_INFO } from '../../constants/modal';
import Location from '../Location';
import Membership from '../Membership';
import ProfileImageUpload from '../Dashboard/Profile';
import UserDetails from '../Directory/UserDetails';
import Account from '../Account';
import './style.css';

const Profile = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const directoryUser = useSelector((state) => state.directoryUser);
  const directoryUserEditing = useSelector((state) => state.directoryUserEditing);
  // Current user
  const userId = currentUser?.id || null;
  const isApprentice = currentUser?.isApprentice || null;
  const userProfileId = currentUser?.userProfileId || '';
  // Directory User
  const phone = directoryUser?.phone || null;
  const mobilePhone = directoryUser?.mobilePhone || null;
  const hasPhone = phone || mobilePhone;
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;

  useEffect(() => {
    return function cleanup() {
      dispatch(resetDirectoryUser());
    };
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getDirectoryUser({ orgId, userId }));
    }
  }, [dispatch, orgId, userId]);

  useEffect(() => {
    // Org has Workforce Suite
    // User marked "isApprentice"
    // User does NOT have a Phone or Mobile Phone listed
    // User does NOT have a userProfile
    if (
      directoryUser?.id
      && !directoryUserEditing?.showForms
      && enableWorkforceSuite
      && isApprentice
      && (!userProfileId || !hasPhone)
    ) {
      dispatch(setCurrentModal({
        key: MODAL_KEY_APPRENTICE_INFO
      }));
    }
  }, [
    directoryUser?.id,
    directoryUserEditing?.showForms,
    directoryUserEditing?.activeTab,
    userProfileId,
    enableWorkforceSuite
  ]);

  return (
    <main role="main" className="py-4">
      <div className="row">
        <div className="col-sm-12 col-md-6">

          <ProfileImageUpload className="mb-4" />

          <UserDetails
            className="overflow-hidden"
            showNameBlock={false}
            contentId={userId}
          />
        </div>

        <div
          className={`col-sm-12 col-md-6 ${directoryUserEditing?.showForms && 'blur-elem'}`}
        >
          <Location
            showHeader
            showWeather={orgType === 'workforce'}
          />
          <Membership />
          <Account />
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const condition = (user) => !!user;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition)
)(Profile);
