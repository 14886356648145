import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal, generateCompletionCertificate } from '../../../actions';
import { CLASSES, COMPLETION_CERTIFICATE, DASHBOARD } from '../../../constants/routes';
import { nextEntryRoute } from '../../../services/entry';
import { canAccessCourseCatalog } from '../../../services/currentUser';
import { courseCompleteCertificate } from '../../../services/user';
import Modal from '../../Modal';

const CourseComplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  const topics = useSelector((state) => state.topics);
  const currentEntry = useSelector((state) => state.currentEntry);
  // Local State
  const [loading, setLoading] = useState(false);
  const [canCloseModal, setCanCloseModal] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  useEffect(() => {
    return function cleanup() {
      handleClose();
    };
  }, []);

  const redirectWhenNextEntryExists = () => {
    const nextEntryRouteStr = nextEntryRoute({
      course, currentTopic, topics, currentEntry
    });

    if (nextEntryRouteStr) {
      history.push(nextEntryRouteStr);
    }
  };

  const generateCertificate = () => {
    setLoading(true);

    dispatch(generateCompletionCertificate())
      .then(() => {
        redirectWhenNextEntryExists();
      })
      .catch(() => {
        toast.error('Something went wrong, try again.');
        setLoading(false);
        setCanCloseModal(true);
      });
  };

  if (!currentUser || !course || !organization) {
    return null;
  }

  const orgSlug = organization?.slug || '';
  const enableCourseCatalog = organization?.enableCourseCatalog;
  const completionCertificateData = courseCompleteCertificate({ course, currentUser });

  return (
    <Modal
      cssClassName={`turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={
        course.completionCertificate
          && (completionCertificateData || canCloseModal)
          ? handleClose
          : false
      }
      pos="middle"
    >
      <div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <i className="far fa-check-circle text-keppel" style={{ fontSize: '5rem' }} />
          <h4 className="font-weight-bold mt-3 text-center">
            {course.title}.
          </h4>
        </div>
        <div className="mt-3 text-center">
          <p>
            You have completed {course.title}!
          </p>

          <div>
            <div className="font-weight-bold">
              Next steps:
            </div>

            {course?.completionCertificate ? (
              <>
                <p>
                  Generate your certificate.
                </p>
                {completionCertificateData && completionCertificateData.id ? (
                  <a
                    title="View Certificate"
                    className="btn btn-md btn-success"
                    href={`${COMPLETION_CERTIFICATE}/${orgSlug}/${completionCertificateData.id}`}
                    onClick={() => {
                      handleClose();
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    View Certificate
                  </a>
                ) : (
                  <button
                    title="Generate Certificate"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      generateCertificate();
                    }}
                    type="button"
                    disabled={loading}
                  >
                    {loading ? 'Generating...' : 'Generate Certificate'}
                  </button>
                )}
              </>
            ) : (
              <>
                <p>
                  Checkout other courses in the catalog.
                </p>
                {canAccessCourseCatalog({ enableCourseCatalog }) ? (
                  <button
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      handleClose();
                      history.replace(CLASSES);
                    }}
                    type="button"
                  >
                    Course Catalog
                  </button>
                ) : (
                  <button
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      handleClose();
                      history.replace(DASHBOARD);
                    }}
                    type="button"
                  >
                    Dashboard
                  </button>
                )}
              </>
            )}
          </div>

          {course?.completionCertificate && !canCloseModal && (
            <div
              className="border-top mt-4 pt-3"
            >
              <i className="fas fa-exclamation-triangle text-danger mr-1" /> Do not close this window until you have generated your certificate.
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CourseComplete;
