import { isAdminOwnerSuperAdmin } from '@apprentage/utils';

// ROLES
export const isSuperAdmin = (role = []) => {
  return (role.indexOf('superAdmin') !== -1);
};

export const isOwner = (role = []) => {
  return (role.indexOf('owner') !== -1);
};

export const isAdmin = (role = []) => {
  return (role.indexOf('admin') !== -1);
};

export const isTeacher = (role = []) => {
  return (role.indexOf('teacher') !== -1);
};

export const isStudent = (role = []) => {
  return (role.indexOf('student') !== -1);
};

export const getUserRole = ({ role }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'superAdmin';
    case isOwner(role):
      return 'owner';
    case isAdmin(role):
      return 'admin';
    case isTeacher(role):
      return 'teacher';
    default:
      return 'student';
  }
};

export const userOrgIsCourseOrg = ({ course, currentUser }) => {
  if (course.orgId !== currentUser.orgId) {
    return false;
  }

  return true;
};

export const userOrgInCourseOrgIds = ({ course, currentUser }) => {
  if (course && currentUser) {
    if (course.orgIds && course.orgIds.includes(currentUser.orgId)) {
      return true;
    }
  }

  return false;
};

export const roleReadable = ({ role, orgType }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'Super Admin';
    case isOwner(role):
      return 'Owner';
    case isAdmin(role):
      return 'Admin';
    case isTeacher(role):
      return orgType !== 'workforce' ? 'Teacher' : 'Expert';
    default:
      return orgType !== 'workforce' ? 'Student' : 'User';
  }
};

// PERMISSIONS
export const canAccessDirectory = (role = [], orgType) => {
  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce' && (isStudent(role) || isTeacher(role))) {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canAccessCourseCatalog = ({ enableCourseCatalog }) => {
  if (enableCourseCatalog) {
    return true;
  }

  return false;
};

export const canManageDirectoryUsers = (role) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canAccessByRole = (role, roles) => {
  function evalRoles(arrayItem) { // index, array
    return role.includes(arrayItem);
  }

  if (isOwner(role) || isSuperAdmin(role)) {
    return true;
  } if (roles === undefined) {
    return true;
  } if (roles.some(evalRoles)) {
    return true;
  }

  return false;
};

export const canActivateDemoMode = (role = []) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canAccessAttendance = (role = []) => {
  return (isStudent(role));
};

export const canViewAssessments = (role = []) => {
  return (isStudent(role));
};

export const canViewAnswerFeedback = (role = []) => {
  return (isStudent(role));
};

export const canChangeCourseDisplayType = (role = []) => {
  return (isOwner(role) || isSuperAdmin(role));
};

export const canAccessTurbineAdmin = (role = [], orgType) => {
  if (!role.length || orgType === undefined) {
    return false;
  }

  if (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role)) {
    return true;
  }

  if (isStudent(role) && orgType === 'workforce') {
    return true;
  }

  return false;
};

export const canAccessGuidedQuizIncorrectTesting = (role = []) => {
  return (isSuperAdmin(role));
};

export const canAccessCourseCompleteModalTesting = (role = []) => {
  return (isSuperAdmin(role));
};

export const canAccessTopicCompleteModalTesting = (role = []) => {
  return (isSuperAdmin(role));
};

export const canViewQuizAnswers = ({
  role, courseExperts, userId
}) => {
  if (isAdmin(role) || isOwner(role) || isSuperAdmin(role)) {
    return true;
  }

  const courseExpert = courseExperts ? courseExperts.find(((ce) => ce.id === userId)) : null;

  if (courseExpert) return true;

  return false;
};

export const canBypassCourseEnroll = (role = [], course = {}) => {
  if (course?.id && course?.courseReadOnly) {
    return true;
  }

  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canBypassContentTopicFlow = (role = []) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

// export const canViewLeaderboard = (role = []) => {
//   return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
// }

export const canScheduleSupport = (role = []) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canManageContent = ({ currentUser, course }) => {
  if (!currentUser || !course) {
    return false;
  }

  if (userOrgIsCourseOrg({ currentUser, course })) {
    const role = currentUser?.role || [];

    return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
  }

  return false;
};

export const canDirectMessageUser = (role = []) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canViewLessonPlan = (role = []) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canViewRoleInTopNav = (role = []) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

// TODO move to user service
export const isActiveMembership = (membership = []) => {
  return membership.indexOf('inactive') === -1;
};

export const membershipReadable = (membership = []) => { // TODO switch this to one value in content model.
  switch (true) {
    case membership.includes('pro'):
      return 'pro';
    case membership.includes('premium'):
      return 'premium';
    case membership.includes('starter'):
      return 'starter';
    case membership.includes('inactive'):
    default:
      return 'inactive';
  }
};

export const showJoinSlackUpgrade = (currentUser, organization) => {
  const { slackUserId, membership } = currentUser;
  const { integrateSlack } = organization;

  if (integrateSlack && !slackUserId && membershipReadable(membership) === 'starter') {
    return true;
  }

  return false;
};

export const membershipEligible = (membership, feature) => {
  const starterFeatures = [];
  const premiumFeatures = ['slack']; // this + starter
  const proFeatures = []; // this + premium

  const features = {
    inactive: [],
    starter: starterFeatures,
    premium: premiumFeatures.concat(starterFeatures),
    pro: proFeatures.concat(premiumFeatures)
  };

  const hash = features[membershipReadable(membership)];

  return (hash.indexOf(feature) >= 0);
};

export const userHasClassAccess = ({
  orgType, course, currentUser, classPreReqs
}) => {
  let hasAccess = false;

  if (!course || !currentUser || !classPreReqs) return null;

  const locked = course?.locked || null;

  if (!locked) {
    // class NOT locked, No need to check students or user roles
    return true;
  }

  if (currentUser) {
    const role = currentUser?.role || [];
    const courseIds = currentUser?.courseIds || [];
    const completedCourseIds = currentUser?.completedCourseIds || [];

    if (completedCourseIds.length && classPreReqs && classPreReqs[course.id]) {
      if (completedCourseIds.includes(classPreReqs[course.id])) {
        hasAccess = true;
      }
    }

    if (role.length > 0 && courseIds.length > 0) {
      // Class exists in User.courseIds
      if (courseIds.includes(course.id)) {
        // Teachers in Community can access courses
        // Teachers in Workforce need to follow Pacing rules
        if (isTeacher(role) && orgType !== 'workforce') {
          hasAccess = true;
        }
      }

      if (isAdmin(role)) {
        hasAccess = true;
      }
    }
  }

  return hasAccess;
};

export const userHasTopicAccess = ({
  topic, currentUser, classId, topicPreReqs
}) => {
  let hasAccess = false;

  // TODO base this on completion of prev sections
  const locked = topic?.locked || null;
  if (locked !== true) {
    // topic is NOT locked,  No need to check students or user roles
    return true;
  }

  if (currentUser) {
    const {
      completedCourseIds,
      completedCourseTopicIds,
      role
    } = currentUser;

    // User has completed the course
    if (classId && completedCourseIds) {
      if (completedCourseIds.includes(classId)) {
        hasAccess = true;
      }
    }

    if (topicPreReqs) {
      // User has completed the prerequisite topic
      if (completedCourseTopicIds && topicPreReqs && topicPreReqs[topic.id]) {
        if (completedCourseTopicIds.includes(topicPreReqs[topic.id])) {
          hasAccess = true;
        }
      }

      // User can move freely based on role
      if (canBypassContentTopicFlow(role)) {
        hasAccess = true;
      }
    }
  }

  return hasAccess;
};

export const userHasEnrolled = ({ classId, currentUser }) => {
  let hasEnrolled = false;

  if (!classId || !currentUser) return null;

  const { courseIds } = currentUser;

  if (courseIds && courseIds.indexOf(classId) !== -1) {
    hasEnrolled = true;
  }

  return hasEnrolled;
};

export const userHasGoLiveAccess = ({
  classId, currentUser, streamingLink, isLive
}) => {
  const role = currentUser?.role || [];

  if (streamingLink && isLive) {
    if (userHasEnrolled({ classId, currentUser }) || canBypassCourseEnroll(role)) {
      return true;
    }
  }

  return false;
};

export const classCompleted = ({ classId, currentUser }) => {
  let classComplete = false;

  if (!classId || !currentUser) return null;

  const { completedCourseIds } = currentUser;

  if (completedCourseIds && classId) {
    if (completedCourseIds.includes(classId)) {
      classComplete = true;
    }
  }

  return classComplete;
};
