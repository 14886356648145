function formatQuiz({
  title,
  description,
  difficulty,
  questions,
  classId
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (difficulty !== undefined) {
    data.difficulty = {
      'en-US': difficulty
    };
  }

  if (questions !== undefined) {
    data.questions = {
      'en-US': questions
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  return { fields: { ...data } };
}

export default formatQuiz;
