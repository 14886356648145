import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { withAuthorization } from '../Session';
import { setCurrentUser, upgradeMembershipModal } from '../../actions/Users';
import { notifyManagers } from '../../actions/Email';
import { updateUser } from '../../services/user';
import { isActiveMembership, membershipReadable } from '../../services/currentUser';
import { UPSTREAM_CODING } from '../../constants/globals';
import { TURBINE } from '../../constants/urls';
import {
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import Modal from '../Modal';
import './style.css';
import { fetchFlags } from '../../services/flagsmith';

const Membership = () => {
  const dispatch = useDispatch();

  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const billing = organization?.billing || false;
  const integration = organization?.integration || null;
  // Current User
  const userId = currentUser?.id || '';
  const userName = currentUser?.name || '';
  const userEmail = currentUser?.email || '';
  const userRole = currentUser?.role || [];
  const membership = currentUser?.membership || [];

  const [showDialogConfirmCancelMembership, setShowDialogConfirmCancelMembership] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancelMembership = () => {
    const prevMembership = membership?.filter((m) => m !== 'inactive');
    const prevMembershipReadable = membershipReadable(prevMembership);

    setLoading(true);
    setShowDialogConfirmCancelMembership(false);

    const roleReadable = userRole[0];

    const data = {
      subject: `Deactivated Account (${roleReadable})`,
      message: `${userName} (${userEmail}) has deactivated their account.`
    };

    const query = encodeURIComponent(userEmail);

    if (orgId === UPSTREAM_CODING) {
      data.subject = `Request to cancel membership (${roleReadable})`;
      data.message = `${userName} requested to cancel their membership. Go to <a href="https://dashboard.stripe.com/search?query=${query}">Stripe Dashboard</a> to cancel their membership.`;
    }

    dispatch(notifyManagers(data));

    toast.info('Processing...');

    updateUser({
      membership: prevMembershipReadable ? [prevMembershipReadable, 'inactive'] : 'inactive'
    }, userId).then(() => {
      // TODO better UI to show membership cancelled.
      // dispatch(getCurrentUser({ userId }));
      document.location.reload();
    });
  };

  const confirmCancelMembership = () => {
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.users_readonly?.enabled && fetchedFlags?.users_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        }));
        return;
      }

      setShowDialogConfirmCancelMembership(true);
    });
  };

  const activateMembership = () => {
    if (!organization?.id || !currentUser?.id) {
      return null;
    }

    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.users_readonly?.enabled && fetchedFlags?.users_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        }));
        return;
      }

      const prevMembership = membership.filter((m) => m !== 'inactive');
      const prevMembershipReadable = membershipReadable(prevMembership);
      const starterActivateUrl = `${TURBINE}/subscribe/starter/${orgId}/${userId}`;

      if (billing && integration?.membership) {
        // ORG has integrated membership billing
        dispatch(upgradeMembershipModal({
          membership: prevMembershipReadable,
          title: 'Activate Membership',
          starterActivateUrl
        }));
      } else {
        // ORG DOES NOT have integrated membership billing
        setLoading(true);
        document.location = starterActivateUrl;
      }
    });
  };

  const textByOrgType = {
    workforce: 'Once you deactivate your account, content will not be accessible until your account is reactivated.',
    community: 'Once your membership is cancelled, content will not be accessible until you reactivate your membership.'
  };

  const dialogConfirmCancelMembership = {
    visible: showDialogConfirmCancelMembership,
    title: orgType === 'workforce' ? 'Deactivate Account?' : 'Cancel Membership?',
    text: orgType === 'workforce' ? textByOrgType.workforce : textByOrgType.community,
    primaryButton: {
      func: cancelMembership,
      text: 'Yes'
    },
    secondaryButton: {
      func: () => {
        setShowDialogConfirmCancelMembership(false);
      },
      text: 'No'
    },
    close: () => {
      setShowDialogConfirmCancelMembership(false);
    }
  };

  const isActive = isActiveMembership(membership);
  const dictionary = {
    membershipCancel: orgType === 'workforce' ? 'Deactivate' : 'Cancel'
  };

  return (
    <div className="my-5">
      <h4>
        Membership <span className={`text-capitalize badge badge-${currentUser?.membershipReadable === 'inactive' ? 'danger' : 'dark'}`}>{currentUser?.membershipReadable}</span>
      </h4>
      <div className="card overflow-hidden">
        <div className="card-content">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col">Member Name</th>
                <th scope="col">Member ID</th>
                <th scope="col">Membership Type</th>
                {billing && integration && integration.membership && (
                  <>
                    {integration.membership[currentUser?.membershipReadable] && (
                      <th scope="col">Amount</th>
                    )}
                  </>
                )}
                <th scope="col">Manage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  data-demo="disabled"
                  className="vertical-align-middle"
                >
                  {userName}
                </td>
                <td
                  data-demo="disabled"
                  className="vertical-align-middle"
                >
                  {userId}
                </td>
                <td className="vertical-align-middle text-capitalize">
                  {currentUser?.membershipReadable}
                </td>
                {/* !isAdmin(role) why was this removed? */}
                {billing && integration && integration.membership && (
                  <>
                    {integration.membership[currentUser?.membershipReadable] && (
                      <td className="vertical-align-middle">
                        {integration.membership[currentUser?.membershipReadable].price}
                      </td>
                    )}
                  </>
                )}

                <td className="vertical-align-middle">
                  {isActive && (
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={confirmCancelMembership}
                      type="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {loading ? 'Processing...' : dictionary.membershipCancel}
                    </button>
                  )}

                  {!isActive && orgType === 'community' && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={activateMembership}
                      type="button"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Activate Membership"
                    >
                      Activate
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Modal.Dialog {...dialogConfirmCancelMembership} />
    </div>
  );
};

const mapStateToProps = ({
  currentUser,
  userLocation,
  organization
}) => ({
  currentUser,
  userLocation,
  organization
});
const condition = (user) => !!user;

export default compose(
  connect(mapStateToProps, { notifyManagers, setCurrentUser, upgradeMembershipModal }),
  withAuthorization(condition)
)(Membership);
