import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import {
  setCurrentAnswer, resetCurrentAnswer, resetCurrentEntry
} from '../../../actions';
import Collapse from '../../ManageContent/Collapse';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import CurrentEntry from '../CurrentEntry';
import { createEntry, updateEntry } from '../../../services/entry';
import formatData from '../../../services/formatData';
import { answerReviewedOrAccepted } from '../../../services/answers';
import { generateAnswerUrl, emailReviewers } from '../../../services/assessments';
import { isLocalhost } from '../../../serviceWorker';
import getEmailsFromUsers from '../../../utils/getEmailsFromUsers';
import TypeUrl from './TypeUrl';
import TypeForm from './TypeForm';
import Results from './Results';
import Submission from './Submission';
import Solution from './Solution';

class Challenge extends Component {
  state = {
    loading: false
  }

  componentWillUnmount() {
    this.props.resetCurrentAnswer();
  }

  notifyInstructors = ({ reviewerUrl }) => {
    const { organization, currentUser, course } = this.props;
    const { name: orgName, email: orgEmail } = organization;
    const { title: classTitle } = course;
    const userName = pathOr(null, ['name'], currentUser);
    // bcc experts and observers
    const courseExperts = pathOr([], 'courseExperts', this.props);
    const observers = pathOr([], 'observers', course); // TODO course flatten (observerIds)
    const expertsObservers = [...courseExperts, ...observers];
    const bcc = getEmailsFromUsers(expertsObservers);

    const emailData = {
      userName,
      classTitle,
      reviewerUrl,
      orgEmail,
      orgName
    };

    if (bcc) emailData.bcc = bcc;

    // TODO set flag on org notifyUsersByEmail
    // implement firebase messaging and browser notifications to push messages to user
    if (isLocalhost) {
      console.log(emailData);
    } else {
      emailReviewers(emailData);
    }
  }

  createAnswer = (data) => {
    const { solution, results } = data;
    const {
      course,
      currentEntry,
      organization,
      currentUser,
      currentTopic
    } = this.props;
    const { id: orgId } = organization;
    const userId = pathOr(null, ['id'], currentUser);
    const userName = pathOr(null, ['name'], currentUser);
    const { id: classId, title: classTitle } = course;
    const { id: topicId } = currentTopic;
    const {
      title: challengeTitle,
      id: challengeId
    } = currentEntry;

    const dataToSave = {
      title: `${challengeTitle} - ${userName}`,
      userId,
      userName,
      challengeId,
      challengeTitle,
      orgId,
      classId,
      classTitle,
      topicId
    };

    if (solution) {
      dataToSave.solution = solution;
    }

    if (results) {
      dataToSave.results = results;
    }

    const formattedData = formatData(dataToSave, 'answer');

    createEntry({
      contentType: 'answer',
      data: formattedData
    }).then((createdAnswer) => {
      const { sys: { id: answerId } } = createdAnswer;

      this.props.setCurrentAnswer(answerId).then(() => {
        this.notifyInstructors({
          reviewerUrl: generateAnswerUrl({
            answerId, classId, topicId, userId
          })
        });
        this.setState({ loading: false });
      });
    });
  }

  updateAnswer = (data) => {
    const { solution, results } = data;
    const { currentAnswer } = this.props;
    const {
      id: answerId, classId, topicId, userId
    } = currentAnswer;
    const dataToSave = { answerId };

    if (solution) {
      dataToSave.solution = solution;
    }

    if (results) {
      dataToSave.results = results;
    }

    const formattedData = formatData(dataToSave, 'answer');

    updateEntry({
      id: answerId,
      data: formattedData
    }).then(() => {
      this.props.setCurrentAnswer(answerId).then(() => {
        this.notifyInstructors({
          reviewerUrl: generateAnswerUrl({
            answerId, classId, topicId, userId
          })
        });
        this.setState({ loading: false });
      });
    });
  }

  handleAnswerSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const { elements } = e.target;
    const { answerUrl } = elements;
    const { currentEntry, currentAnswer } = this.props;
    const { challengeType } = currentEntry;

    const dataToSave = {};

    if (challengeType === 'url') {
      dataToSave.solution = answerUrl.value; // TODO change name to solution.
    }

    if (challengeType === 'form') {
      dataToSave.results = { formData: {} };

      for (let i = 0; i < elements.length; i += 1) {
        const { type, dataset, value } = elements[i];

        if (type === 'radio') {
          dataToSave.results.formData[dataset.id] = {
            title: dataset.title,
            value,
            type: dataset.type
          };
        }

        // "hidden" is used for types: header and file
        if (type === 'text' || type === 'textarea' || type === 'hidden') {
          dataToSave.results.formData[dataset.id] = {
            title: dataset.title,
            value,
            type: dataset.type
          };
        }

        //  || type === 'radio'
      }
    }

    if (currentAnswer) {
      this.updateAnswer(dataToSave);
    } else {
      this.createAnswer(dataToSave);
    }
  }

  submitForm() {
    const { loading } = this.state;
    const { currentAnswer, course } = this.props;

    // Check if user has submitted an Answer or
    // their submitted Answer's status = 'inReview' or 'accepted'
    // Don't show the submission form
    if (answerReviewedOrAccepted({ currentAnswer })) return null;

    const { currentEntry } = this.props;
    const {
      challengeType,
      id: challengeId,
      submissionPromptText,
      questions,
      enableNewEditor
    } = currentEntry;

    if (!challengeId) return null;

    // No Answers or any existing answers are status:rejected
    return (
      <div className="challenge--answer card bg-light mb-4">
        <div className="card-header bg-dark p-3">
          <h5 className="m-0 text-white font-weight-normal text-capitalize">
            <i className="fas fa-clipboard-list mr-1" /> {challengeType === 'url' ? 'Challenge' : challengeType}
          </h5>
        </div>
        <form
          name="submit-answer"
          onSubmit={this.handleAnswerSubmit}
        >
          <div className="card-body">
            {submissionPromptText && (
              <RenderMarkdown
                source={submissionPromptText}
                enableNewEditor={enableNewEditor}
              />
            )}

            {challengeType === 'form' && (
              <TypeForm
                loading={loading}
                questions={questions}
              />
            )}

            {challengeType === 'url' && (
              <TypeUrl
                loading={loading}
              />
            )}
          </div>

          <div className="card-footer">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading || course?.courseReadOnly}
            >
              { loading ? 'Loading...' : 'Submit' }
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const {
      currentEntry, currentAnswer, course, currentTopic, currentUser
    } = this.props;

    if (!currentEntry || !course || !currentTopic) {
      return null;
    }

    if (!course?.courseReadOnly && !currentUser) {
      return null;
    }

    const {
      id: challengeId,
      solution,
      challengeType,
      enableNewEditor
    } = currentEntry;

    return (
      <>

        <Submission currentAnswer={currentAnswer}>
          {challengeType === 'url' && (
            <Solution currentAnswer={currentAnswer} />
          )}

          {challengeType === 'form' && (
            <Collapse
              title="Submission"
              id="challengeSubmission"
            >
              <Results
                currentAnswer={currentAnswer}
                challenge={currentEntry}
              />
            </Collapse>
          )}
        </Submission>

        {challengeType === 'url' && this.submitForm(currentEntry)}

        <CurrentEntry
          className="challenge--question"
          currentAnswer={currentAnswer}
          content={currentEntry}
        />

        {challengeType === 'form' && this.submitForm(currentEntry)}

        {solution && (
          <div className="challenge--solution card mb-3">
            <div
              className="card-header bg-light p-3 d-flex align-items-center justify-content-between"
            >
              <h5 className="m-0 font-weight-normal">
                Need a Hint?
              </h5>
              <button
                className="btn btn-outline-primary"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse--challenge-${challengeId}`}
                aria-expanded="false"
                aria-controls={`collapse--challenge-${challengeId}`}
              >
                Reveal Hint
              </button>
            </div>

            <div
              className="collapse"
              id={`collapse--challenge-${challengeId}`}
            >
              <div className="card-body">
                <RenderMarkdown
                  source={solution}
                  enableNewEditor={enableNewEditor}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  organization,
  currentClass,
  courseExperts,
  currentTopic,
  currentEntry,
  currentAnswer,
  currentUser,
  user
}) => ({
  organization,
  course: currentClass,
  courseExperts,
  currentTopic,
  currentEntry,
  currentAnswer,
  currentUser,
  user
});

export default withRouter(connect(mapStateToProps, {
  setCurrentAnswer,
  resetCurrentAnswer,
  resetCurrentEntry
})(Challenge));
