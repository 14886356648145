export const COURSE = 'fas fa-layer-group';
export const RESOURCE = 'fas fa-photo-video';
export const TAGS = 'fas fa-tags';
export const COHORT = 'fas fa-users';
export const TOPIC = 'far fa-sticky-note';
export const MATERIAL = 'far fa-file-alt';
export const CHALLENGE = 'fas fa-clipboard-list';
export const QUIZ = 'fas fa-list-ol';
export const ROLE = 'fas fa-shield-alt';
export const SEND = 'fas fa-paper-plane';
export const COPY = 'fas fa-copy'; // 'far fa-clipboard'
export const LINK = 'fas fa-link';
export const EXPERT = 'fas fa-user-ninja';
export const GROUP = 'far fa-object-group';
export const LIST = 'far fa-list-alt';
export const USERS = 'fas fa-users';
export const USER = 'fas fa-user';
export const USER_EDIT = 'fas fa-user-edit';
export const USER_SETTINGS = 'fas fa-user-cog';
export const USER_CHECK = 'fas fa-user-check';
export const USER_CIRCLE = 'far fa-user-circle';
export const USER_INFO = 'far fa-address-card';
export const DASHBOARD = 'fas fa-th-large';
export const TURBINE = 'fas fa-fan';
export const BELL = 'fas fa-bell';
export const EXTERNAL_LINK = 'fas fa-external-link-alt';
export const CALENDAR_ALT = 'far fa-calendar-alt';
export const SETTINGS = 'fas fa-cog';
export const LOCK = 'fas fa-lock';
export const EYE_SLASH = 'fas fa-eye-slash';
export const INFO_CIRCLE = 'fas fa-info-circle';
export const REFRESH = 'fas fa-sync-alt';
export const WIDGET = 'fas fa-vector-square';
export const CITY = 'fas fa-city';
export const MAP_PIN = 'fas fa-map-marker-alt';
export const MAP_LOCATION = 'fas fa-map-marked-alt';
// WEATHER
export const CLOUD = 'fas fa-cloud';
