import React, { Component, Fragment } from 'react';
import { dayMdyDate, hrMin } from '../../utils/date';
import isPublished from '../../utils/isPublished';

class UserCeAttendance extends Component {
  rows() { // TODO refactor into <Rows />
    const {
      // student,
      // migrateData,
      attendance,
      eUnitTracking,
      eUnits
    } = this.props;

    if (!attendance) return null;

    return (
      <Fragment>
        {attendance.map((item, i) => (
          <tr
            key={`${item.date}-${i}`}
          // onClick={() => {
          //   migrateData({
          //     attendanceIndex: i,
          //     student
          //   });
          // }}
          >
            <td className="border-right pr-0">
              {dayMdyDate(item.date)}
            </td>
            <td className="border-right pr-0">
              {item.session ? item.session : '-'}
            </td>

            <td className="border-right pr-0">
              {isPublished(eUnitTracking) && eUnitTracking.fields.semesterCode ? ( // TODO cohort flatten
                <Fragment>
                  {item.semesterCode ? item.semesterCode : '-'}
                </Fragment>
              ) : '-'}
            </td>

            <td className="border-right pr-0">
              {isPublished(eUnitTracking) && eUnitTracking.fields.code ? ( // TODO cohort flatten
                <Fragment>
                  {item.eUnitCode ? item.eUnitCode : '-'}
                </Fragment>
              ) : '-'}
            </td>

            <td className="border-right pr-0">
              {eUnits && eUnits.length ? (
                <Fragment>
                  {item.eUnitCodes ? item.eUnitCodes.join(', ') : '-'}
                </Fragment>
              ) : '-'}
            </td>

            {/* {eUnits && eUnits.length && (
              <td className="border-right pr-0">
                {item.sessionCredits ? item.sessionCredits.map((credit) => (<span>{ credit.number } {credit.type || '' }</span>)) : '-'}
              </td>
            )} */}
            <td className="border-right pr-0">
              {item.records ? item.records.map((record, recordIndex) => {
                return (
                  <span
                    key={`${record}-${recordIndex}`}
                    className="badge bg-light border px-3 py-2 mr-1 mb-1"
                  >
                    {hrMin(record)}
                  </span>
                );
              }) : (
                <span className="badge bg-light border px-3 py-2 mr-1">
                  {hrMin(item.date)}
                </span>
              )}
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }

  render() {
    const { attendance, student } = this.props;

    if (!student || !attendance) return null;

    return (
      <Fragment>
        {attendance ? (
          <table className="table table-striped m-0">
            <thead>
              <tr>
                <th scope="col" className="border-0">Date</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Session</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Semester</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Unit Code</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Unit Codes</th>
                {/* <th scope="col" className='border-0 d-none d-md-table-cell'>Credits</th> */}
                <th scope="col" className='border-0 d-none d-md-table-cell'>Records</th>
              </tr>
            </thead>
            <tbody>
              {this.rows()}
            </tbody>
          </table>
        ) : (
          <div className="dash-module-no-content">
            No entries.
          </div>
        )}
      </Fragment>
    );
  }
}

export default UserCeAttendance;
