import { client, flattenItem, flattenItems } from '../services/contentful';
import isPublished from '../utils/isPublished';
// import leaderboard from '../serializers/leaderboard';
import {
  SET_CURRENT_ANSWER,
  RESET_CURRENT_ANSWER,
  SET_CURRENT_ANSWERS,
  SET_CURRENT_CLASS_ANSWERS_SUCCESS,
  RESET_CURRENT_CLASS_ANSWERS,
  SET_CURRENT_TOPIC_ANSWERS_SUCCESS,
  RESET_CURRENT_TOPIC_ANSWERS
} from './types';

export const setCurrentAnswer = (answerId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // const config = {
      //   content_type: 'answer',
      //   'sys.id': answerId,
      //   include: 2
      // };

      return client.getEntry(answerId).then((response) => {
        const currentAnswer = isPublished(response) ? flattenItem(response) : null;

        dispatch({
          type: SET_CURRENT_ANSWER,
          currentAnswer
        });
        resolve(currentAnswer);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetCurrentAnswer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ANSWER });
  };
};

export const setCurrentAnswers = ({
  status,
  skip,
  limit,
  userId,
  challengeId,
  classId,
  orgId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'answer',
        order: '-sys.createdAt'
      };

      if (status) {
        if (status === 'all') {
          config['fields.status[exists]'] = true;
        } else if (status === 'acceptedAndChanges') {
          config['fields.status[nin]'] = 'inReview,rejected';
        } else {
          config['fields.status'] = status;
        }
      }

      if (challengeId) {
        config['fields.challengeId'] = challengeId;
      }

      if (skip) {
        config.skip = skip;
      }

      if (limit) {
        config.limit = limit;
      }

      if (userId) {
        config['fields.userId'] = userId;
      }

      if (orgId && !userId) { // TODO may not be necessary to check !userId
        config['fields.orgId'] = orgId;
      }

      if (classId) {
        config['fields.classId'] = classId;
      }

      return client.getEntries(config).then(({
        items,
        limit: entriesLimit,
        skip: entriesSkip,
        total
      }) => {
        const currentAnswers = items.length ? flattenItems(items) : null;
        const pagination = {
          status,
          limit: entriesLimit,
          skip: entriesSkip,
          total
        };

        dispatch({
          type: SET_CURRENT_ANSWERS,
          currentAnswers,
          // leaderboard: currentAnswers ? leaderboard(currentAnswers) : null,
          currentAnswersPagination: pagination
        });
        resolve({ currentAnswers, pagination });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setCurrentAnswerByChallenge = (challengeId, userId) => {
  return (dispatch) => {
    const config = {
      content_type: 'answer',
      'fields.challengeId': challengeId
    };

    if (userId) {
      config['fields.userId'] = userId;
    }

    return client.getEntries(config).then(({ items }) => {
      const currentAnswer = items[0] !== undefined ? flattenItem(items[0]) : null;

      dispatch({
        type: SET_CURRENT_ANSWER,
        currentAnswer
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const setCurrentClassAnswers = ({ classId, userId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'answer',
      'fields.classId': classId
    };

    if (userId) {
      config['fields.userId'] = userId;
    }

    return client.getEntries(config).then(({ items }) => {
      const currentClassAnswers = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_CLASS_ANSWERS_SUCCESS,
        currentClassAnswers
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const setCurrentTopicAnswers = ({ topicId, userId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'answer',
      'fields.topicId': topicId
    };

    if (userId) {
      config['fields.userId'] = userId;
    }

    return client.getEntries(config).then(({ items }) => {
      const currentTopicAnswers = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_TOPIC_ANSWERS_SUCCESS,
        currentTopicAnswers
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentClassAnswers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_ANSWERS });
  };
};

export const resetCurrentTopicAnswers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_ANSWERS });
  };
};
