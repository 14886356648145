import { TURBINE_ADMIN_EMAIL } from '../constants/globals';
import { TURBINE_ADMIN, TURBINE_MARKETING_SITE } from '../constants/urls';
import { GMAIL_SEND } from '../constants/api';
import * as twApi from './tw-api';

export const generateAnswerUrl = ({
  answerId,
  classId,
  topicId,
  userId
}) => {
  return `${TURBINE_ADMIN}/assessments/${answerId}/${classId}/${topicId}/${userId}`;
};

export const subjectIcon = (status) => {
  switch (status) {
    case 'accepted':
      return '✅';
    case 'rejected':
      return '❌';
    case 'requestChanges':
      return '⚠️';
    case 'inReview':
      return '👀';
    default:
      return '';
  }
};

/**
 * Email an update about a submission to a challenge
 * @param {string} status // accepted, rejected, requestChanges
 * @returns {Promise<string>} emails, link
 */
export const emailReviewers = ({
  orgEmail,
  orgName,
  bcc,
  reviewerUrl,
  classTitle,
  // User who submitted answer to challenge
  userName
}) => {
  const linkText = 'Review Answer';
  const ctaText = 'Click this link to:';

  return new Promise((resolve, reject) => {
    const emailParts = {
      to: orgEmail || TURBINE_ADMIN_EMAIL,
      from: {
        name: orgName
      },
      bcc,
      subject: `${subjectIcon('inReview')} New answer submitted in ${classTitle}`,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi,
        </p>
        <p>
          ${subjectIcon('inReview')} ${userName} submitted an answer in <b>${classTitle}</b>.
        </p>
        <p>
          ${ctaText} <a href="${reviewerUrl}" target="_blank" title="${linkText}">${linkText}</a>
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${TURBINE_MARKETING_SITE}?track=reviewAnswer" target="_blank" title="Turbine Workforce">Turbine Workforce</a>
        </p>
      `
    };

    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        resolve();
      } else {
        console.error(response.status);

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};
