import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import { changeUserPassword, reauthenticateUser } from '../../../services/auth';

const ChangeUserPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    mode: 'onChange'
  });
  const currentModal = useSelector((state) => state.currentModal);
  const dispatch = useDispatch();
  const [newPassScore, setNewPassScore] = useState({
    score: 0,
    warning: '',
    suggestions: []
  });

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
    setLoading(false);
  };

  const updatePasswordForUser = async (dataValues) => {
    const { currentPassword, confirmNewPassword } = dataValues;
    await reauthenticateUser(currentPassword)
      .then(() =>
        changeUserPassword(confirmNewPassword).then(() => {
          toast.success('Password updated successfully.');
        }))
      .catch((error) => {
        toast.error(`Please try again. Error: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleSubmitChangePassword = async (dataValues) => {
    if (
      newPassScore.score >= 2
      && dataValues.newPassword === dataValues.confirmNewPassword
    ) {
      setLoading(true);
      updatePasswordForUser(dataValues);
    }
  };
  const passwordWatchValue = watch('newPassword');
  return (
    <Modal
      cssClassName="turbine-modal--style-card"
      visible={currentModal.visible}
      close={false}
      pos="center"
    >
      <form
        id="update-password"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(handleSubmitChangePassword)();
        }}
      >
        <div className="card">
          <div className="card-header h3 mb-0 text-center">
            <h5 className="m-0">Update Password</h5>
          </div>
          <div className="card-content p-3">
            <div>
              <div className="mb-3">
                <label htmlFor="currentPassword">
                  Current Password <span className="text-danger">*</span>
                </label>
                <div className="mb-2">
                  <input
                    className="w-100 form-control"
                    type="password"
                    id="currentPassword"
                    {...register('currentPassword', {
                      required: 'Field is required.'
                    })}
                    disabled={loading}
                    autoComplete="off"
                  />
                </div>
                {errors.currentPassword && (
                  <div className="mb-2">
                    <span className="text-danger">
                      {errors.currentPassword.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="newPassword">
                  New Password <span className="text-danger">*</span>
                </label>
                <div className="mb-2">
                  <input
                    className="w-100 form-control"
                    type="password"
                    id="newPassword"
                    {...register('newPassword', {
                      required: 'Field is required.',
                      minLength: {
                        value: 12,
                        message: 'Password should be minimum 12 characters'
                      }
                    })}
                    name="newPassword"
                    disabled={loading}
                    autoComplete="off"
                  />
                  <PasswordStrengthBar
                    password={passwordWatchValue}
                    minLength={12}
                    onChangeScore={(score, feedback) => {
                      setNewPassScore({
                        score,
                        warning: feedback.warning,
                        suggestions: feedback.suggestions
                      });
                    }}
                  />
                </div>
                <div className="mb-2">
                  {errors.newPassword && (
                    <span className="text-danger">
                      {errors.newPassword.message}
                    </span>
                  )}
                  {!errors.newPassword && newPassScore.score < 2 && newPassScore.warning && (
                    <span className="text-danger">
                      {newPassScore.warning}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="confirmNewPassword">
                  Confirm New Password <span className="text-danger">*</span>
                </label>
                <div className="mb-2">
                  <input
                    className="w-100 form-control"
                    type="password"
                    id="confirmNewPassword"
                    {...register('confirmNewPassword', {
                      required: 'Field is required.',
                      validate: (value, values) => (value !== values.newPassword ? 'Should be same as new password.' : null)
                    })}
                    disabled={loading}
                    autoComplete="off"
                  />
                </div>
                {errors.confirmNewPassword && (
                  <div className="mb-2">
                    <span className="text-danger">
                      {errors.confirmNewPassword.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
              {loading && (
                <span
                  className="ml-2 spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
            <button
              type="button"
              className="btn btn-link ml-2"
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeUserPassword;
