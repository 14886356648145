import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import { IMAGE_MAINTENANCE } from '@apprentage/constants';
import { getOrg, setManageMembership } from '../../../actions';
import { MANAGE_MEMBERSHIP } from '../../../constants/routes';
import {
  FLAG_MAINTENANCE_MODE,
  FLAG_USERS_READONLY,
  NOTICE_ACCOUNT_CREATION_BODY,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import Loading from '../../Loading';
import ConfirmationToast from '../../Toasts/ConfirmationToast';

const Subscribe = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const flags = useFlags([FLAG_USERS_READONLY, FLAG_MAINTENANCE_MODE]);
  const flagsStatus = useFlagsmithLoading();
  // Redux
  const manageMembership = useSelector((state) => state.manageMembership);
  // Params
  const membership = params?.membership || '';
  const userId = params?.userId || '';
  const orgId = params?.orgId || '';
  // Local State
  const [showMaintenanceToast, setShowMaintenanceToast] = useState(false);
  const [showUsersReadonlyToast, setShowUsersReadonlyToast] = useState(false);

  useEffect(() => {
    if (!flagsStatus?.isFetching) {
      if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
        setShowMaintenanceToast(true);
      } else if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
        setShowUsersReadonlyToast(true);
      } else if (orgId) {
        dispatch(getOrg({ orgId, include: 1 })).then(({ organization }) => {
          const integration = organization?.integration || null;

          dispatch(setManageMembership({
            membership,
            userId,
            orgId,
            ...(integration ? { integration } : {})
          }));
        });
      }
    }

    return function cleanup() {
      document.getElementsByTagName('html')[0].className = '';
    };
  }, [flagsStatus?.isFetching, flags?.maintenance_mode, flags?.users_readonly]);

  if (showMaintenanceToast) {
    return (
      <>
        <div className="auth-container" />
        <ConfirmationToast
          title={NOTICE_MAINTENANCE_MODE_TITLE}
          subtitle={NOTICE_MAINTENANCE_MODE_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  if (showUsersReadonlyToast) {
    return (
      <>
        <div className="auth-container" />
        <ConfirmationToast
          title={NOTICE_USERS_READONLY_TITLE}
          subtitle={NOTICE_ACCOUNT_CREATION_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </>
    );
  }

  if (manageMembership) {
    return <Redirect to={MANAGE_MEMBERSHIP} />;
  }

  return (
    <Loading text="Processing..." />
  );
};

export default Subscribe;
