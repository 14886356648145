import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import Table from './Table';
import TitleCell from './TitleCell';

const List = ({
  data,
  courseIds,
  hiddenColumns
}) => {
  const organization = useSelector((state) => state.organization);
  const orgId = pathOr(null, ['id'], organization);

  const columns = React.useMemo(() => [
    {
      Header: 'Courses',
      accessor: 'title',
      disableSortBy: true,
      Cell: (props) => (
        <TitleCell {...props} />
      )
    }
  ], [orgId]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Courses.
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      courseIds={courseIds}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
