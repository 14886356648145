import { client, flattenItems } from '../services/contentful';
import { SET_LOCATIONS, RESET_LOCATIONS } from './types';

export const getLocations = ({ orgId, include = 1 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'location',
        'fields.orgId': orgId,
        order: 'fields.name',
        include
      };

      return client.getEntries(config).then(({ items }) => {
        const locations = items.length ? flattenItems(items) : null;

        dispatch({ type: SET_LOCATIONS, locations });
        resolve(locations);
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const resetLocations = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOCATIONS });
  };
};
