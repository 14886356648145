import React, { Fragment } from 'react';

const ExternalRegister = ({
  course,
  handleClickSecondary,
  secondaryText
}) => {
  const {
    name: className,
    title: classTitle,
    registerUrl
  } = course;
  return (
    <Fragment>
      <div>
        <a
          className={`registerCourseBtn btn btn-md btn-warning my-2 ${className}`}
          title="Register"
          href={registerUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Register
        </a>
      </div>
      <div className="ml-4 border-left pl-4">
        <div className="text-muted font-weight-bold">
          Already Registered?
        </div>
        <button
          type="button"
          className="btn btn-link mt-2 p-0"
          title={`Enroll in ${classTitle} `}
          onClick={() => {
            handleClickSecondary();
          }}
        >
          { secondaryText }
        </button>
      </div>
    </Fragment>
  );
};

export default ExternalRegister;
