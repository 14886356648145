import React from 'react';
import { mdyDate } from '../../../utils/date';
import Feedback from './Feedback';
import statusFeedback from './statusFeedback';

const Submission = ({ currentAnswer, children }) => {
  // No answer to challenges in this Class
  if (!currentAnswer) return null;

  // Answers found in this Class
  const {
    status,
    feedback,
    createdAt,
    enableNewEditor
  } = currentAnswer;
  const {
    alertType, iconClass, title
  } = statusFeedback(status);

  return (
    <div className="row">
      <div className="col-12">
        <div className={`alert alert-${alertType}`} role="alert">
          <h4 className="alert-heading">
            <i className={`mr-2 ${iconClass}`} />
            {title}
          </h4>
          <p>
            Originally submitted on {mdyDate(createdAt)}.
          </p>
          <div className="mb-0 p-3 bg-light text-dark rounded">
            {feedback && status !== 'inReview' && (
              <div>
                <div className="font-weight-bold mb-1">
                  Feedback:
                </div>
                <Feedback
                  feedback={feedback}
                  enableNewEditor={enableNewEditor}
                />
              </div>
            )}
            <div className="mt-2">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submission;
