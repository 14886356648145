import React from 'react';
import * as URLS from '../../../../constants/urls';
import './style.css';

const PoweredBy = () => {
  return (
    <p className="poweredby m-0">
      Powered by <a target="_blank" rel="noopener noreferrer" href={`${URLS.TURBINE_MARKETING_SITE}?track=auth`} title="Visit Turbine Workforce">Turbine Workforce</a>
    </p>
  );
};

export default PoweredBy;
