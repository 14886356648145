import { referenceType } from '../contentful';

function formatChallenge({
  difficulty,
  type = 'url',
  title,
  question,
  solution, // Solution (Hint)
  instructorNotes,
  classId
}) {
  const data = {};

  if (difficulty !== undefined) {
    data.difficulty = {
      'en-US': difficulty
    };
  }

  if (type !== undefined) {
    data.type = {
      'en-US': type
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (classId !== undefined) {
    data.class = {
      'en-US': referenceType(classId)
    };
  }

  if (question !== undefined) {
    data.question = {
      'en-US': question
    };
  }

  // Solution (Hint)
  if (solution !== undefined) {
    data.solution = {
      'en-US': solution
    };
  }

  if (instructorNotes !== undefined) {
    data.instructorNotes = {
      'en-US': instructorNotes
    };
  }

  return { fields: { ...data } };
}

export default formatChallenge;
