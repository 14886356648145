import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canAccessAllLocations } from '@apprentage/utils';
import { getApprenticeships } from '../../actions/Apprenticeships';
import Loading from '../Loading';
import List from './List';

const ApprenticeshipsList = ({ ids }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  const apprenticeshipsFetched = useSelector(
    (state) => state.apprenticeshipsFetched
  );
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;
  // Organization
  const orgId = organization?.id || null;

  useEffect(() => {
    if (orgId) {
      dispatch(
        getApprenticeships({
          orgId,
          ids,
          ...(canAccessAllLocations(role) ? {} : { employerId: locationId })
        })
      );
    }
  }, [orgId, locationId, dispatch, ids, role]);

  if (!apprenticeshipsFetched && !apprenticeships) {
    return <Loading text="Loading Apprenticeships..." />;
  }

  return (
    <div className="apprenticeships-list">
      <List
        data={apprenticeships}
        {...(canAccessAllLocations(role)
          ? {}
          : { hiddenColumns: ['employerIds'] })}
      />
    </div>
  );
};

export default ApprenticeshipsList;
