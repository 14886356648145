import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { CLASS_COLOR_DEFAULT } from '../../constants/globals';

const CourseBar = () => {
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  const topicTitle = pathOr(null, ['title'], currentTopic);
  const classTitle = pathOr(null, ['title'], course);
  const classColor = pathOr(null, ['classColor'], course);
  const classImage = pathOr(null, ['classImage'], course);

  return (
    <div className="courseBar d-block d-md-none mt-3">
      <div className="border-bottom">
        <div
          className="px-1 py-2 d-flex align-items-center"
          style={{
            backgroundColor: classColor,
            borderRadius: '.25rem .25rem 0 0'
          }}
        />

        {classTitle && (
          <div
            className="px-2 py-3 border-bottom border-left border-right d-flex align-items-center"
          >
            {classImage ? (
              <img
                src={classImage}
                className="nav-icon mr-2 d-none d-sm-block"
                alt={classTitle}
              />
            ) : (
              <i
                className="fab fa-buffer mr-2 d-none d-sm-block"
                style={{
                  color: classColor || CLASS_COLOR_DEFAULT,
                  fontSize: '1.5rem'

                }}
              />
            )}
            <span className="h6 m-0 font-weight-bold">
              {classTitle}
            </span>
          </div>
        )}

        {topicTitle && (
          <div className="border-left border-right p-2">
            <i className="fas fa-cube mx-1" /> {topicTitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseBar;
