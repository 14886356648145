import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  userHasClassAccess,
  userHasEnrolled,
  classCompleted
} from '../../../services/currentUser';
import {
  activeCohorts, courseCohortUserHasEnrolled
} from '../../../services/courses';
import {
  setCurrentClass,
  addClassToUserClasses,
  upgradeMembershipModal,
  setCurrentModal,
  resetCurrentModal
} from '../../../actions';
import ExternalRegister from './ExternalRegister';
import NoEnroll from './NoEnroll';
import CostEnroll from './CostEnroll';
import NoCostEnroll from './NoCostEnroll';
import { userReEnrollDataCleanUp } from '../../../services/user';
import { MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS } from '../../../constants/modal';

class CtaButton extends Component {
  state = {
    loading: false,
    selectedCohortId: this.props.cohortId || undefined
  }

  setCurrentClassRedirectToClass = (classId) => {
    const { history } = this.props;

    this.props.setCurrentClass({
      classId,
      redirectToClass: true,
      history
    });
  }

  setCurrentClassRedirectToClassDashboard = (classId) => {
    const { history } = this.props;

    this.props.setCurrentClass({
      classId,
      redirectToClassDashboard: true,
      history
    });
  }

  handleClickClass = ({ classId }) => {
    this.setCurrentClassRedirectToClass(classId);
  }

  upgradeMembership = ({
    classId, classTitle, membership, purchaseCourse, price
  }) => {
    const { organization } = this.props;
    if (!organization) return null;

    const { integration } = organization;
    let content = `You could save 50% off ${classTitle} with a Premium Membership.`;

    if (integration && integration.membership) {
      // TODO change to courses
      if (integration.membership[membership] && integration.membership[membership].classes) {
        if (integration.membership[membership].classes.includes(classId)) {
          content = `${classTitle} is included with a Premium Membership.`;
        }
      }
    }

    this.props.upgradeMembershipModal({ content, purchaseCourse, price });
  }

  enrollUser = ({ data, classId }) => {
    this.props.addClassToUserClasses(data).then(() => {
      this.setCurrentClassRedirectToClassDashboard(classId);
      this.setState({ loading: false });
      this.props.resetCurrentModal();
    });
  }

  enroll = ({ classId, cohortId }) => {
    const { currentUser } = this.props;
    const {
      id: userId,
      courseIds,
      completedCourseIds,
      completedCourseTopicIds
    } = currentUser;

    this.setState({ loading: true });

    const dataToSave = { classId, userId };

    if (cohortId) dataToSave.cohortId = cohortId;

    // Check if user has enrolled in this course before
    if (dataToSave.cohortId && courseIds && courseIds.includes(classId)) {
      // User has enrolled before
      userReEnrollDataCleanUp({
        data: dataToSave, completedCourseIds, completedCourseTopicIds
      }).then((data) => {
        this.enrollUser({ data, classId });
      });
    } else {
      // First time enrolling
      this.enrollUser({ data: dataToSave, classId });
    }
  }

  handleEnrollCourseCohort = ({ title, courseCohorts }) => {
    const { course } = this.props;
    const { selectedCohortId } = this.state;
    const selectedCohort = selectedCohortId ? courseCohorts.find(((c) => c.id === selectedCohortId)) : undefined;

    const { id: classId } = course;

    this.props.setCurrentModal({
      key: MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS,
      data: {
        title,
        selectedCohort,
        classId
      }
    });
  }

  buttonText() {
    const { children } = this.props;

    return children || 'Course Content';
  }

  render() {
    const { loading, selectedCohortId } = this.state;
    const {
      className,
      organization,
      course,
      currentClassRoute,
      courseCohorts,
      enroll,
      currentUser,
      classPreReqs
    } = this.props;
    const { type: orgType } = organization;

    if (!course || !currentUser) return null;

    const { courseIds, cohortIds } = currentUser;
    const userCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));

    const hasClassAccess = userHasClassAccess({
      orgType, course, currentUser, classPreReqs
    });
    const {
      id: classId,
      title: classTitle,
      openEnrollment,
      integration,
      registerUrl,
      inviteOnly
    } = course;

    const hasEnrolled = userHasEnrolled({ classId, currentUser });

    if (course && !currentClassRoute) return null;

    /**
     * @summary External Registration - Users can register for the Class outside Turbine
     * @hasEnrolled (bool) checking if class ref exists on User
     * @courseCohorts (arr)
    * */

    const activeCourseCohorts = activeCohorts(courseCohorts); // TODO may not be needed, getCohorts only pulls active ones
    const hasEnrolledActiveCohort = courseCohortUserHasEnrolled({
      userCohorts: activeCohorts(userCohorts),
      courseCohorts
    });
    const hasEnrolledCohort = courseCohortUserHasEnrolled({
      userCohorts,
      courseCohorts
    });

    if (inviteOnly && !enroll && courseIds) {
      if (!courseIds.includes(classId)) {
        return (
          <div>
            Invitation Only
          </div>
        );
      }
    }

    // NO COHORTS
    // OPEN ENROLLMENT = false
    if (!openEnrollment && !courseCohorts) {
      return (
        <NoEnroll course={course} className={className} />
      );
    }

    // COHORTS
    // OPEN ENROLLMENT = true
    if (openEnrollment && courseCohorts) {
      if (!hasEnrolledCohort && !activeCourseCohorts) {
        // User has never enrolled in any this Course's Cohorts
        // Course has NO active Cohorts available to enroll in
        return (
          <NoEnroll course={course} className={className} />
        );
      }

      // Course has active Cohorts to enroll in
      if (activeCourseCohorts) {
        // User has NO active ("openCohortEnrollment") Cohorts
        if (!hasEnrolledActiveCohort || selectedCohortId) {
          // External Registration - Users can register for the Class outside Turbine
          if (registerUrl) {
            return (
              <ExternalRegister
                course={course}
                handleClickSecondary={() => {
                  this.handleEnrollCourseCohort({
                    title: 'Already Registered?',
                    courseCohorts: activeCourseCohorts,
                    cohortId: selectedCohortId
                  });
                }}
                secondaryText="Enroll"
              />
            );
          }

          if (integration && integration.stripe) {
            if (enroll && enroll.wc) {
              return (
                <NoCostEnroll
                  course={course}
                  className={className}
                  loading={loading}
                  courseCohorts={courseCohorts}
                  cohortId={selectedCohortId}
                  eUnitIds={enroll && enroll.eUnitIds}
                  onClick={this.enroll}
                  chooseCohort={() => {
                    this.handleEnrollCourseCohort({
                      title: 'Enroll',
                      courseCohorts: activeCourseCohorts,
                      cohortId: selectedCohortId
                    });
                  }}
                  disabled={this.props.disabled}
                />
              );
            }
            return (
              <CostEnroll
                course={course}
                courseCohorts={courseCohorts}
                cohortId={selectedCohortId}
                eUnitIds={enroll && enroll.eUnitIds}
                enroll={enroll}
                currentUser={currentUser}
                chooseCohort={() => {
                  this.handleEnrollCourseCohort({
                    title: 'Enroll',
                    courseCohorts: activeCourseCohorts,
                    cohortId: selectedCohortId
                  });
                }}
                upgradeMembership={this.upgradeMembership}
                disabled={this.props.disabled}
              />
            );
          }
        }
      }
    }

    if (hasClassAccess && hasEnrolled) {
      const btnColor = classCompleted({ classId, currentUser }) ? 'btn-outline-primary' : 'btn-primary';

      return (
        <Fragment>
          <button
            data-cy="ctaButton-primaryAction"
            className={`courseBtn btn btn-md my-2 ${btnColor || ''} ${className || ''}`}
            onClick={() => {
              this.handleClickClass({ classId });
            }}
            type="button"
            title={classTitle}
            disabled={!hasClassAccess || !openEnrollment}
          >
            {this.buttonText()}
          </button>

          {classCompleted({ classId, currentUser }) && (
            <button
              type="button"
              className="disabledCourseBtn btn btn-md btn-link my-2 text-success"
              disabled
            >
              <i className="fas fa-check" /> Complete
            </button>
          )}
        </Fragment>
      );
    }

    if ((hasEnrolled && !courseCohorts && !hasEnrolledActiveCohort) || (hasEnrolled && courseCohorts && hasEnrolledActiveCohort)) {
      // User is enrolled, but hasn't completed the prerequisite
      return (
        <Fragment>
          <button
            type="button"
            className={`btn btn-md btn-primary my-2 ${className}`}
            title="Prerequisite Incomplete"
            disabled
          >
            {this.buttonText()}
          </button>
          <button type="button" className="btn btn-md btn-link my-2" disabled>
            <i className="fas fa-check" /> Enrolled
          </button>
        </Fragment>
      );
    }

    // Open Enrollment - Users can enroll / register / purchase Class
    if (openEnrollment) { // && !courseCohorts
      // Purchase - Users can purchase this Class and/or Upgrade Membership
      if (integration && integration.stripe) {
        if (enroll && enroll.wc) {
          // Has Cost, but has been waived
          return (
            <NoCostEnroll
              course={course}
              className={className}
              loading={loading}
              onClick={this.enroll}
            />
          );
        }
        // Has cost
        // if membership enabled, upgrade if starter member
        // else allow purchase
        return (
          <CostEnroll
            course={course}
            currentUser={currentUser}
            upgradeMembership={this.upgradeMembership}
          />
        );
      }

      // Default - Users can enroll in Class (no cost)
      return (
        <NoCostEnroll
          course={course}
          className={className}
          loading={loading}
          onClick={this.enroll}
        />
      );
    }

    // Default - Users can NOT enroll, register or purchase this Class
    return (
      <NoEnroll course={course} className={className} />
    );
  }
}

const mapStateToProps = ({
  currentUser,
  organization,
  classPreReqs,
  currentClass,
  courseCohorts,
  currentClassRoute,
  enroll
}) => ({
  currentUser,
  organization,
  classPreReqs,
  course: currentClass,
  courseCohorts,
  currentClassRoute,
  enroll
});

export default withRouter(connect(mapStateToProps, {
  setCurrentClass,
  addClassToUserClasses,
  upgradeMembershipModal,
  setCurrentModal,
  resetCurrentModal
})(CtaButton));
