import * as twApi from './tw-api';
import { FIREBASE_STORE_COLLECTION } from '../constants/api';

/**
 * @summary saves document to firebase collection
 * @param {object} data
 * @param {string} collection
 */
export const createFirebaseEntry = (data, collection) => {
  return new Promise((resolve, reject) => {
    return twApi
      .post(
        `${FIREBASE_STORE_COLLECTION}/${collection}`,
        '',
        JSON.stringify(data)
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Get firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @param {object} data - firebase document id
 * @returns {Promise<string>} - firebase document object.
 */
export async function updateFirebaseEntry({ collection, id, data }) {
  const dataToSave = JSON.stringify({ id, ...data });
  const response = await twApi.put(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    '',
    dataToSave
  );

  return response;
}

/**
 * Get firebase document by collection & id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @returns {Promise<string>} - firebase document object.
 */
export async function getFirebaseEntry({ collection, id, bypass = false }) {
  const response = await twApi.get({
    endpoint: `${FIREBASE_STORE_COLLECTION}/${collection}`,
    params: { id },
    bypass
  });

  return response;
}

/**
 * Get firebase documents
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} orgId - organization id (contentful)
 * @returns {Promise<string>} - firebase document object.
 */
export async function getFirebaseEntries({
  collection,
  id,
  orgId,
  bypass = false
}) {
  const params = {};

  if (id) params.id = id;
  if (orgId) params.orgId = orgId;

  const response = await twApi.get({
    endpoint: `${FIREBASE_STORE_COLLECTION}/${collection}`,
    params,
    bypass
  });

  return response;
}

/**
 * [NEW] Get firebase entries
 *
 * @param {} params
 * @param {string} params.collection - firebase collection name
 * @param {string} params.ids - comma separate string of IDs
 *
 * Field queries with operators: (up to 5 supported)
 * @param {string} ['f.locationId[eq]']
 * @param {string} ['f.apprenticeshipId[eq]']
 *
 * @returns {Promise<string>} - firebase documents
 */
export async function getFirebaseEntriesNew(params, version = 'v1') {
  // fields
  const { collection, ...remainingParams } = params;
  let query = {
    ...remainingParams
  };
  /**
   * Query Operators
   *
   * Learn more: https://github.com/upstreamcoding/lms-api#firestore-query-operators
   *
   * Ex: config['f.locationId[eq]'] = locationId;
   * Ex: config['f.apprenticeshipIds[ac]'] = apprenticeshipId;
   *
   */

  if (remainingParams?.ids) {
    query = {}; // ids take precedence over all other queries
    query.ids = remainingParams?.ids;
  }

  const response = await twApi.get({
    endpoint: `/${version}/external/firebase/store/entries/${collection}`,
    params: query,
    bypass: false
  });

  return response;
}

/**
 * Delete firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @returns {Promise<string>}
 */
export async function deleteFirebaseEntry({ collection, id, bypass = false }) {
  if (!id) {
    return false;
  }

  const response = await twApi.requestDelete(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    { id },
    undefined,
    bypass
  );

  return response;
}
