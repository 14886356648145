import React from 'react';

const Solution = ({ currentAnswer }) => {
  if (!currentAnswer) return null;

  const { solution } = currentAnswer;

  if (!solution) return null;

  return (
    <div>
      Answer: <a target="_blank" rel="noopener noreferrer" href={solution}>{solution}</a>
    </div>
  );
};

export default Solution;
