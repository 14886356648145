import { sortArrByArr } from '@apprentage/utils';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchSupabaseEntries } from './supabaseProxy';

const table = 'widgets';

export const fetchWidgets = async ({
  orgId,
  type,
  isFeatured = null,
  sortByIds,
  ids,
  order = 'title',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit
  };

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (type) {
    params['f.type[eq]'] = type;
  }

  if (isFeatured) {
    params['f.isFeatured[eq]'] = isFeatured;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  const response = await fetchSupabaseEntries(params, table);

  const serializedResponse = {
    ...response
  };

  let list = Array.isArray(response?.items) && response?.items?.length ? response?.items : null;

  if (sortByIds && list && type === 'link') {
    list = sortArrByArr(list, sortByIds, 'id');

    serializedResponse.items = list;
  }

  return serializedResponse;
};

export const constructWidgetUrl = ({
  email,
  externalUserId,
  includeEmail,
  includeExternalUserId,
  url
}) => {
  if (!includeEmail && !includeExternalUserId) {
    return url;
  }

  const splitUrl = url?.split('?');
  const urlHref = splitUrl[0];
  const urlSearch = splitUrl[1];
  const searchParams = new URLSearchParams(url.includes('?') ? urlSearch : '');

  if (includeEmail && email) {
    searchParams.append('email', email);
  }

  if (includeExternalUserId && externalUserId) {
    searchParams.append('externalUserId', externalUserId);
  }

  if (searchParams?.size > 0) {
    return `${urlHref}?${searchParams.toString()}`;
  }

  return urlHref;
};
