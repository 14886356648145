import React from 'react';
import * as GLOBALS from '../constants/globals';

export const getClassImage = (classImage, classColor, title) => {
  if (classImage) {
    return <img className="card-img-top" src={classImage} alt={title} />;
  }

  return <div className="card-header" style={{ backgroundColor: classColor || GLOBALS.CLASS_COLOR_DEFAULT }} />;
};
