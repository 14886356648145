// MAIN ROUTES
export const SLASH = '/';
export const DASHBOARD = '/dashboard';
export const ATTENDANCE = '/attendance';
export const COMPLETION_CERTIFICATE = '/completion-certificate';
export const COMPLETION_CERTIFICATE_ID = `${COMPLETION_CERTIFICATE}/:orgSlug/:uniqueCertificateId`;
export const COMPLETION_CERTIFICATE_ID_LEGACY = `${COMPLETION_CERTIFICATE}/:uniqueCertificateId`;
export const CERTIFICATE = '/certificate';
export const CERTIFICATE_CLASS = `${CERTIFICATE}/:classId`;
export const CLASSES = '/classes'; // TODO change to courses
export const DIRECTORY = '/directory';
export const DIRECTORY_USER = '/directory/:directoryUserId/:deepLinkActiveTab?';
export const CLASS_DASHBOARD = '/classes/:classId'; // TODO change to courses
export const CLASS_CURRICULUM = '/classes/:classId/:topicId/:entryId'; // TODO change to courses
export const CALENDAR = '/calendar';
export const BILLING = '/billing';
export const PROFILE = '/profile';
export const NOTIFICATIONS = '/notifications';

// AUTH
export const SSO = '/sso'; // ?token=JWT (generated in auth.turbinelms.app)
export const LOGIN = '/login'; // redirect (auth.turbinelms.app)
export const LOGIN_ORG = '/login/:organizationSlug?'; // redirect (auth.turbinelms.app)

export const COURSE_INVITATION = '/course/invite';
export const APPRENTICESHIP_INVITATION = '/apprenticeship/invite';

// Change User (membership, courseIds)
export const ENROLL = '/enroll/:userId?/:classId?/:cohortId?';
export const MANAGE_MEMBERSHIP = '/manage-membership';
export const SUBSCRIBE = '/subscribe/:membership/:orgId/:userId';

// Instructors & Admins Only
export const MEMBERS = '/members';
export const MANAGE_CURRENT_ENTRY = '/entry/manage';
