import React, { useEffect, useState } from 'react';
import { fetchLocation } from '../../services/locations';
import { fetchUser } from '../../services/users';
import CertificateLocation from './CertificateLocation';

/**
 * Only one param can be set
 * @param {string} locationId
 * @param {string} managerIds
 * @returns renders a signature block based on the manager of the location's info
 */
const SignatureBlock = ({ locationId, userIds }) => {
  const [manager, setManager] = useState(null);
  const [managerIds, setManagerIds] = useState(userIds || null);

  useEffect(() => {
    let mounted = true;

    if (mounted && !userIds && locationId) {
      fetchLocation({ locationId }).then((newLocation) => {
        const { managerIds: locationManagerIds } = newLocation;

        if (mounted) setManagerIds(locationManagerIds);
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [locationId, userIds]);

  useEffect(() => {
    let mounted = true;

    if (mounted && managerIds) {
      const [managerId] = managerIds;

      fetchUser({
        userId: managerId,
        select: ['id', 'name', 'title', 'userSignature']
      }).then((newUser) => {
        if (mounted) setManager(newUser);
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [managerIds]);

  if (!manager) return null;

  const { userSignature, name, title } = manager;

  return (
    <div className="certificate-signature-block">
      <div
        data-demo="disabled"
        className="mb-2"
      >
        {userSignature && (
          <img
            src={userSignature}
            alt={name}
            width="200"
          />
        )}

      </div>
      <div data-demo="disabled">
        <div>
          {name}
        </div>

        {title && (
          <div>
            {title}
          </div>
        )}
      </div>
      <hr />
      <CertificateLocation />
    </div>

  );
};

export default SignatureBlock;
