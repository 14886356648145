import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';

class GuidedQuizIncorrect extends Component {
  componentWillUnmount() {
    this.handleClose();
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  render() {
    const { currentModal } = this.props;

    return (
      <Modal
        cssClassName={`turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        close={false}
        pos="top"
      >
        <div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h4 className="font-weight-bold mt-3 text-center">
              Knowledge Check
            </h4>
          </div>
          <div className="mt-3 text-center">
            <p>
              Good job, but some of your answers are incorrect (<span className="text-danger" title="red = incorrect">red</span>).
              <br />
              Please select each correct (<span className="text-keppel" title="green = correct">green</span>) answer and resubmit the knowledge check.
            </p>

            <h5 className="text-left">
              Example:
            </h5>
            <img
              src="https://turbine-content.s3.us-east-2.amazonaws.com/incorrect-response-example.jpg"
              className="w-100 border mb-3"
              alt="Incorrect Response Feedback Example"
            />

            <div>
              <div className="font-weight-bold">
                Next steps:
              </div>

              <p>
                Select correct answers & resubmit.
              </p>
              <button
                title="Ok"
                className="btn btn-md btn-primary"
                style={{ minWidth: '200px' }}
                onClick={() => {
                  this.handleClose();
                }}
                type="button"
              >
                Ok
              </button>

            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ currentModal }) => ({ currentModal });

export default connect(mapStateToProps, { resetCurrentModal })(GuidedQuizIncorrect);
