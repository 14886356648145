import { MODAL_KEY_FEATURE_FLAG_NOTICE } from '../constants/modal';
import { setCurrentModal } from './Modals';

export const showFeatureFlagNoticeModal = ({
  modalTitle = 'Read-only',
  modalBody = 'Routine maintenance will temporarily restrict this feature to read-only mode.',
  modalImage,
  preventClose = false
}) => {
  return (dispatch) => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_FEATURE_FLAG_NOTICE,
      data: {
        modalTitle,
        modalImage,
        modalBody,
        preventClose
      }
    }));
  };
};
