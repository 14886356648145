import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import {
  canManageContent, userHasGoLiveAccess, userHasClassAccess, userHasEnrolled
} from '../../../services/currentUser';
import { getClassImage } from '../../../utils/image';
import CourseDashboardButton from '../../btns/CourseDashboardButton';
import PreReqStatus from '../PreReqStatus';
import GoLive from '../../GoLive';
import Progress from '../Progress';
import OrgAuthor from '../OrgAuthor';
import './ribbon.css';
import './style.css';

const Card = ({
  course,
  classDashboard,
  showCourseDashboardButton = true,
  className = ''
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const classPreReqs = useSelector((state) => state.classPreReqs);
  // Misc
  const completedCourseIds = pathOr([], ['completedCourseIds'], currentUser);
  const { id: orgId, type: orgType } = organization;
  const hasClassAccess = userHasClassAccess({
    orgType, course, currentUser, classPreReqs
  });
  const {
    id: classId,
    title,
    description,
    topics: tags,
    schedule,
    testClass,
    isLive,
    streamingLink,
    classColor,
    classImage,
    enableNewEditor,
    orgId: courseOrgId,
    orgIds: courseOrgIds
  } = course;
  const hasEnrolled = userHasEnrolled({ classId, currentUser });
  const hasGoLiveAccess = userHasGoLiveAccess({
    isLive, streamingLink, classId, currentUser
  });
  const courseIds = pathOr([], ['courseIds'], currentUser);

  const classNames = () => {
    let conditionalClasses = className || '';

    if (!completedCourseIds.length && hasClassAccess) {
      conditionalClasses += ' unblock-class';
    }

    return conditionalClasses;
  };

  return (
    <div
      className={`class-card-container ${classNames()}`}
    >
      <div className="card mb-4 shadow-sm">
        { getClassImage(classImage, classColor, title) }

        {classImage && (
          <div
            className="p-1"
            style={{ backgroundColor: classColor }}
          />
        )}

        <div className="card-body p-3">
          <h5>
            <b>{ title }</b> {testClass && (<span className="badge badge-warning">Test Class</span>)}
          </h5>
          <div className="card-text">
            <RenderMarkdown
              source={description}
              enableNewEditor={enableNewEditor}
            />
          </div>

          {tags && (
            <div className="mb-2">
              <b>Tags:</b>
              <p className="text-muted mt-1 mb-0">
                { tags.join(', ') }
              </p>
            </div>
          )}

          {schedule && (
            <div className='mb-2'>
              <b>Schedule:</b>
              <div className="text-muted mt-1">
                <RenderMarkdown
                  source={schedule}
                  enableNewEditor={enableNewEditor}
                />
              </div>
            </div>
          )}

          <PreReqStatus
            course={course}
            currentUser={currentUser}
            className="mb-2"
          />

          {showCourseDashboardButton && (
            <div className="d-flex justify-content-between align-items-center">
              <CourseDashboardButton classId={classId} />
            </div>
          )}
        </div>

        {courseOrgIds && (
          <>
            {courseOrgIds.includes(orgId) ? (
              <OrgAuthor
                className="border-top bg-white px-3 py-2"
                orgId={courseOrgId}
              />
            ) : (
              <>
                {canManageContent({ course, currentUser }) && (
                  <div className="p-3 h6 m-0 border-top">
                    <span className="badge badge-warning">Syndicated</span>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {hasGoLiveAccess && (
          <div className="go-live-slim-banner-container bg-light p-2 border-top">
            <GoLive
              streamingLink={streamingLink}
              title={title}
              slim
              className="mb-0"
            />
          </div>
        )}

        {courseIds.length !== 0 && courseIds.indexOf(classId) !== -1 ? (
          <Progress
            classId={classId}
            className="card-footer p-3"
          />
        ) : (
          <div className="card-footer p-3">
            {hasEnrolled ? (
              <span>No Progress</span>
            ) : (
              <span>Not enrolled</span>
            )}
          </div>
        )}

        {hasGoLiveAccess && (
          <div className={`ribbon-wrapper ${classDashboard ? 'ribbon-class-dashboard' : ''}`}>
            <div className="ribbon alert-info">
              <i className="fas fa-chalkboard-teacher" /> Live!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
