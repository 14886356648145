import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import {
  isStudent,
  isTeacher,
  roleShouldStartInTurbineAdmin
} from '@apprentage/utils';
import { resetEnroll } from '../../../actions';
import { redirectToAuth, redirectToTurbineAdmin } from '../../../services/auth';
import { updateUser } from '../../../services/user';
import { deleteInvite } from '../../../services/invites';
import { DASHBOARD, SLASH } from '../../../constants/routes';
import AuthContainer from '../Container';
import Loading from '../../Loading';
import CoursesList from '../../CoursesList';
import '../Container/invitation.css';

const ApprenticeshipInvitation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const locations = useSelector((state) => state.locations);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const enroll = useSelector((state) => state.enroll);
  // Misc
  const turbineToken = localStorage.getItem('turbine_token');
  // Enroll
  const userName = pathOr(undefined, ['userName'], enroll);
  const userRole = pathOr(undefined, ['userRole'], enroll);
  const userEmail = pathOr(undefined, ['userEmail'], enroll);
  const inviteId = pathOr(undefined, ['inviteId'], enroll);
  const userLocationId = pathOr(undefined, ['userLocationId'], enroll);
  // Current User
  const userId = pathOr(undefined, ['id'], currentUser);
  const currentUserRole = pathOr([], ['role'], currentUser);
  // const userProfileId = pathOr(undefined, ['userProfileId'], currentUser);
  const currentUserOrgId = pathOr(undefined, ['orgId'], currentUser);
  // apprenticeship
  const apprenticeshipId = pathOr(undefined, ['id'], apprenticeship);
  const apprenticeshipTitle = pathOr(undefined, ['title'], apprenticeship);
  const title = pathOr(undefined, ['title'], apprenticeship);
  const courseIds = pathOr([], ['courseIds'], apprenticeship);
  const hasCourseIds = Boolean(courseIds && courseIds.length !== 0);
  // employer
  const employer = useMemo(() => {
    return locations ? locations.find(({ id }) => userLocationId === id) : null;
  }, [locations, userLocationId]);
  const employerName = pathOr(null, ['name'], employer);
  const employerImage = pathOr(null, ['locationImage'], employer);
  // Local state
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return function cleanup() {
      // TODO is this needed here, maybe needed on Login?
      dispatch(resetEnroll());
    };
  }, [dispatch]);

  const handleContinue = () => {
    const {
      orgId, orgName, orgLogo, orgSlug, ...remainingEnrollData
    } = enroll;

    setLoading(true);

    redirectToAuth({
      organizationSlug: orgSlug, // TODO rename organizationSlug => orgSlug
      enroll: remainingEnrollData
    });
  };

  const joinApprenticeship = () => {
    const dataToSave = {
      apprenticeshipId
    };

    // Conditionally set a user's role
    // by default use their current role (if logged in)
    // if userRole is included in the invitation, and is
    // either programTrainer or programManager, use one of those roles
    let conditionalUserRole = currentUserRole;

    // If role is present and is trainer or manager of a training program
    // allow the user
    if (userRole && (userRole === 'programTrainer' || userRole === 'programManager')) {
      conditionalUserRole = [userRole];
      dataToSave.role = [userRole];
    }

    // Only enroll students & teachers in Courses
    if (hasCourseIds) {
      // New Invitations will always have a role, support legacy
      // Check if student or teacher before enrolling user in courses
      if (!userRole || isStudent([userRole]) || isTeacher([userRole])) {
        dataToSave.classIds = courseIds;
      }
    }

    setLoading(true);
    toast.info('Joining Training Program...');

    updateUser(dataToSave, userId).then(() => {
      toast.success(`Successfully joined ${apprenticeshipTitle}!`);

      deleteInvite(inviteId).then(() => {
        if (roleShouldStartInTurbineAdmin(conditionalUserRole)) {
          redirectToTurbineAdmin();
        } else {
          history.replace(DASHBOARD);
        }
      });

      // if (userProfileId) {
      //   // TODO create Apprenticeship page
      //   history.replace(DASHBOARD);
      // } else {
      //   history.replace(PROFILE);
      // }
    });
  };

  // Page was refreshed or access directly
  // without enroll data, this page is useless
  // Redirect to SLASH
  // If user is logged in, they will see dashboard
  // If they are logged out, they will be taken to auth
  if (!enroll) {
    return <Redirect to={SLASH} />;
  }

  // User logged in, waiting on Redux.
  if (!currentUser && turbineToken) {
    return <Loading text="Authorizing..." />;
  }

  // Wait for org and course data to load
  if (!enroll.orgId || !apprenticeship) {
    return <Loading text="Loading Invitation..." />;
  }

  // If user is logged in and userEmail is present
  // And userEmail is different than logged in user
  // Redirect to SLASH (dashboard)
  if (userEmail && currentUser?.id && currentUser?.email) {
    if (currentUser.email.toLowerCase() !== userEmail.toLowerCase()) {
      toast.error('Invitation Error: Recipient mismatch', { autoClose: false });
      return <Redirect to={SLASH} />;
    }
  }

  const orgUserLoggedIn = !!(currentUser && currentUserOrgId === enroll?.orgId);
  const notOrgUserLoggedIn = !!(
    currentUser && currentUserOrgId !== enroll?.orgId
  );

  return (
    <div
      className={`row user-invite-container ${hasCourseIds ? 'has-additional-content' : ''}`}
    >
      {hasCourseIds && (
        <div className="course-video-container col-sm-12 col-md-6 d-none d-md-block">
          <div className="card bg-light rounded p-1">
            <div className="embed-container position-relative">
              <CoursesList courseIds={courseIds} />
            </div>
          </div>
        </div>
      )}

      <AuthContainer
        title="Apprenticeship Invitation"
        logo={!!employerImage}
        locationImage={employerImage}
        locationName={employerName}
      >
        <>
          {userName && <h5 className="font-weight-bold">{userName}</h5>}

          <p className="mb-0">
            You've been invited to join
            <br />
            <b>{title}</b>.
            <br />
          </p>
          <small className="mb-3">( Sponsored by {enroll.orgName} )</small>

          {orgUserLoggedIn && (
            <>
              <div className="d-flex border-top pt-4 mt-3 justify-content-center">
                <button
                  className="btn btn-md btn-primary mb-2"
                  onClick={joinApprenticeship}
                  type="button"
                  disabled={loading}
                >
                  {loading ? 'Joining...' : 'Join Now'}
                </button>
              </div>
            </>
          )}

          {hasCourseIds && (
            <div className="d-block d-md-none mt-3">
              <CoursesList courseIds={courseIds} />
            </div>
          )}

          {(!currentUser?.id || notOrgUserLoggedIn) && (
            <>
              <div className="my-3">Create an account or login to join.</div>
              <div className="d-flex justify-content-center flex-column w-50 m-auto">
                <button
                  className="btn btn-md btn-primary mb-2"
                  onClick={handleContinue}
                  type="button"
                  disabled={loading}
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </>
      </AuthContainer>
    </div>
  );
};

export default ApprenticeshipInvitation;
