/**
 * Note : When Creating new modal key please prefix it with "MODAL_KEY"
 */

export const MODAL_KEY_FEATURE_FLAG_NOTICE = 'featureFlagNotice';
export const MODAL_KEY_CHANGE_EMAIL = 'change-user-email';
export const MODAL_KEY_CHANGE_PASSWORD = 'change-user-password';
export const MODAL_KEY_SEND_EMAIL = 'sendEmail';
export const MODAL_KEY_IMAGE_CROP = 'imageCrop';
export const MODAL_KEY_APPRENTICE_INFO = 'apprenticeInfo';
export const MODAL_KEY_USER_SIGNATURE = 'userSignature';
export const MODAL_KEY_COURSE_COMPLETE = 'courseComplete';
export const MODAL_KEY_TOPIC_COMPLETE = 'topicComplete';
export const MODAL_KEY_GUIDED_QUIZ_INCORRECT = 'guidedQuizIncorrect';
export const MODAL_KEY_UPGRADE_MEMBERSHIP = 'upgradeMembership';
export const MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS = 'enrollExternalRegisteredClass';
export const MODAL_KEY_SEARCH_SELECT_USERS = 'searchSelectUsers';
export const MODAL_KEY_COURSE_MENU = 'courseMenu';
export const MODAL_KEY_CHOOSE_UNIT_CODES = 'chooseUnitCodes';
export const MODAL_KEY_WELCOME = 'welcome';
export const MODAL_KEY_BILLING_AGREEMENT = 'billingAgreement';
export const MODAL_KEY_RELOAD = 'reload';
export const MODAL_KEY_CHOOSE_COHORT = 'chooseCohort'; // NOTE: Currently not in use?
export const MODAL_KEY_NOTIFICATIONS_MENU = 'notificationsMenu';
export const MODAL_KEY_APPS_MENU = 'appsMenu';
export const MODAL_KEY_VELA_HANDOFF = 'velaHandoff';
