import isPublished from '../utils/isPublished';

export const answerReviewedOrAccepted = ({ currentAnswer }) => {
  // Answer found for this Class
  if (isPublished(currentAnswer)) {
    const { status } = currentAnswer;

    // if answer approved or in review, don't display a submission box
    if (status === 'inReview' || status === 'accepted') {
      return true;
    }
  }

  return false;
};

export const answerAcceptedOrRequestChanges = ({ currentAnswer }) => {
  // Answer found for this Class
  if (isPublished(currentAnswer)) {
    const { status } = currentAnswer;

    // if answer approved or in review, don't display a submission box
    if (status === 'requestChanges' || status === 'accepted') {
      return true;
    }
  }

  return false;
};
