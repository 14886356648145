import React from 'react';
import KeyValueItem from '../../../KeyValueItem';

const UrlCell = ({ value: url }) => {
  return (
    <KeyValueItem
      title='URL'
      hideTitle
      value={url}
    />
  );
};

export default UrlCell;
