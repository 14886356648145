import React from 'react';
import Table from './Table';
import NameCell from './NameCell';
import UrlCell from './UrlCell';
import SizeCell from './SizeCell';
import CreatedAtCell from './CreatedAtCell';

const CdnFilesList = ({ data }) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
      Cell: (props) => (
        <NameCell {...props} />
      )
    },
    {
      Header: 'URL',
      accessor: 'url',
      className: 'testing',
      disableSortBy: true,
      Cell: (props) => (
        <UrlCell {...props} />
      )
    },
    {
      Header: 'Size',
      accessor: 'size',
      Cell: (props) => (
        <SizeCell {...props} />
      )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: (props) => (
        <CreatedAtCell {...props} />
      )
    }
  ], []);

  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default CdnFilesList;
