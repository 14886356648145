import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  setCurrentTopic, resetCurrentTopic
} from '../../actions/Topic';
import {
  setCurrentClass, resetCurrentClass
} from '../../actions/Class';
import { getCourses } from '../../actions/Courses';
import * as ROUTES from '../../constants/routes';
import { withAuthorization } from '../Session';
import { canUseLeftNav } from '../../services/courses';
import { userOrgInCourseOrgIds, userOrgIsCourseOrg } from '../../services/currentUser';
import { MODAL_KEY_COURSE_MENU } from '../../constants/modal';
import LeftNav from './LeftNav';
import Content from './Content';
import Loading from '../Loading';
import CourseBar from './CourseBar';
import './style.css';

class Class extends Component {
  componentDidMount() {
    const {
      organization,
      match: { params: { classId, topicId } }
    } = this.props;
    const { id: orgId } = organization;
    this.props.getCourses({ orgId }); // TODO course flatten (create new action to get topic and class preReqs)
    this.props.setCurrentClass({ classId });
    this.props.setCurrentTopic(topicId);
  }

  componentWillUnmount() {
    this.props.resetCurrentClass();
    this.props.resetCurrentTopic();
  }

  render() {
    const {
      history,
      currentUser,
      course,
      currentModal
    } = this.props;

    if (!course) {
      return (
        <Loading className="bg-white text-muted" />
      );
    }

    if (!course?.courseReadOnly && !currentUser) {
      return (
        <Loading className="bg-white text-muted" />
      );
    }

    // User accessing class outside of their organization, send to '/'
    if (!course?.courseReadOnly) {
      if (!userOrgIsCourseOrg({ course, currentUser })) {
        // Course Syndication
        // Check if user has access to Org
        if (!userOrgInCourseOrgIds({ course, currentUser })) {
          history.replace(ROUTES.SLASH);

          return (
            <Loading className="position-fixed" />
          );
        }
      }
    }

    const useLeftNav = canUseLeftNav({ course, currentUser });
    const hideLeftNav = currentModal && currentModal.key === MODAL_KEY_COURSE_MENU;

    return (
      <>
        <CourseBar />

        <div className="class-content row h-100">
          {!hideLeftNav && (
            <LeftNav
              className={`sidebar collapse col-md-3 d-none d-md-block pr-0 bg-white d-print-none mt-3 border-0 pl-3 ${useLeftNav ? '' : 'pointer-events-none'}`}
            />
          )}
          <Content currentClass={course} />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user,
  currentClass,
  currentTopic,
  currentEntry,
  organization,
  currentModal
}) => ({
  user,
  course: currentClass,
  currentTopic,
  currentEntry,
  organization,
  currentModal
});
const condition = (user) => !!user;

export default compose(
  connect(mapStateToProps, {
    getCourses,
    setCurrentClass,
    resetCurrentClass,
    setCurrentTopic,
    resetCurrentTopic
  }),
  withAuthorization(condition)
)(Class);
