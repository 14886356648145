import React from 'react';

const Tiers = ({
  handleUpgrade,
  loading,
  canSelectStarterMembership,
  selectStarerMembership
}) => {
  return (
    <div className="container--pricing">
      <div className="container">

        <div className="row">
          <div className="col-sm-12 col-md-6 d-none d-md-block">
            <div className="card mb-4 shadow-sm">
              <div className="card-header text-center">
                <h4 className="my-0 font-weight-normal">
                  Starter
                </h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  Free
                </h1>
                <hr />
                Email Instructors
                <hr />
                Web Foundations
                <hr />
                Interview Prep Intensive
                <hr />

                {canSelectStarterMembership ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-outline-primary"
                    onClick={selectStarerMembership}
                  >
                    Select
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-link"
                    onClick={null}
                    disabled
                  >
                    Current Membership
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="card mb-4 shadow-sm">
              <div className="card-header bg-dark text-center">
                <h4 className="my-0 text-white font-weight-normal">
                  Premium
                </h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$139/mo.</h1>
                <hr />
                <b>50% discount</b> on all Courses
                <hr />
                Chat with Instructors on Slack
                <hr />
                <i className="fas fa-check-circle text-keppel" /> Web Dev Orbit ($599 value)
                <hr />
                <i className="fas fa-check-circle text-keppel" /> Git Intensive ($299 value)
                <hr />
                Review all <a href="https://www.upstreamcoding.com/membership?upgrade=premium" target="_blank" rel="noopener noreferrer">membership benefits</a>.
                <hr />

                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                  onClick={() => {
                    handleUpgrade('premium');
                  }}
                >
                  {loading ? 'Processing...' : 'Upgrade'}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Tiers;
