import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { canHaveLogbook } from '@apprentage/utils';
import { showJoinSlackUpgrade } from '../../services/currentUser';
import * as analytics from '../../utils/analytics';
import Location from '../Location';
import Attendance from './Attendance';
import CurrentUserAssessments from './CurrentUserAssessments';
import MyCourses from './MyCourses';
import Profile from './Profile';
import Slack from '../Toasts/Slack';
import VelaBanner from './VelaBanner';
import './style.css';

const StudentDashboard = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);
  // misc
  const orgType = organization?.type || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const apprenticeshipIds = useMemo(() => {
    return currentUser?.apprenticeshipIds || [];
  }, [currentUser?.apprenticeshipIds]);

  // misc
  const userId = currentUser?.id || null;
  const locationId = userLocation?.id || null;
  const locationName = userLocation?.name || null;
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;

  useEffect(() => {
    if (orgId) {
      const analyticsData = {
        page_title: 'Dashboard',
        page_type: 'User Dashboard',
        orgId,
        orgName,
        ...(userId ? { userId } : {}),
        ...(locationId ? { locationId } : {}),
        ...(locationName
          ? { orgLocationName: `${orgName} ${locationName}` }
          : {})
      };

      analytics.logEvent('page_view', analyticsData);
      analytics.logEvent('dashboard_user_view', analyticsData);
    }
  }, [userId, orgId, locationId, orgName, locationName]);

  const showVelaBanner = useMemo(() => {
    if (canHaveLogbook(role) && Array.isArray(apprenticeshipIds) && apprenticeshipIds.length > 0) {
      return true;
    }

    return false;
  }, [role, apprenticeshipIds]);

  if (!currentUser?.id || !organization?.id) {
    return null;
  }

  return (
    <>
      {showVelaBanner && <VelaBanner />}

      <div className="row mt-4">
        <div className="col-sm-12 col-md-4 mb-4">
          <Profile className="h-100" />
        </div>
        <div className="col-sm-12 col-md-8 mb-4">
          <MyCourses />
        </div>
      </div>

      {/* {canViewAssessments(role) && ()} */}
      <div className="row mb-4">
        <div className="col-sm-12">
          <CurrentUserAssessments />
        </div>
      </div>

      <div className="row mb-3">
        {/* {canAccessAttendance(role) && ()} */}
        <div className="col-sm-12 col-md-6 mb-4">
          <Attendance />
        </div>
        <div className="col-sm-12 col-md-6 mb-4">
          {showJoinSlackUpgrade(currentUser, organization) ? (
            <div className="card h-100">
              <div className="card-header bg-dark p-3">
                <h5 className="m-0 text-white font-weight-normal">
                  <i className="fab fa-slack" /> Slack
                </h5>
              </div>
              <div className="card-content p-3">
                <Slack />
              </div>
            </div>
          ) : (
            <Location showHeader showWeather={orgType === 'workforce'} />
          )}
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;
