import React from 'react';
import { useSelector } from 'react-redux';
import { USER_SETTINGS } from '../../../constants/icons';
import { canManageContent } from '../../../services/currentUser';
import Alert from '../../Alert';
import EditClassBtn from '../../btns/EditClassBtn';

const CourseManageBar = ({ className = '' }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);

  if (!canManageContent({ currentUser, course })) return null;

  return (
    <div className={className}>
      <Alert
        type="light"
        icon={USER_SETTINGS}
        className="alertWithIconSlim mb-0"
        hideIconOnMobile
      >
        <div className="d-flex justify-content-end align-items-center">
          <EditClassBtn />
        </div>
      </Alert>
    </div>
  );
};

export default CourseManageBar;
