import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import Quiz from '../Quiz';
import Challenge from '../Challenge';
import CurrentEntry from '../CurrentEntry';
import InstructorsOnly from '../InstructorsOnly';
import StudentsOnly from '../StudentsOnly';
import GoLive from '../../GoLive';
import {
  setCurrentEntry,
  resetCurrentEntry,
  setCurrentTopic,
  addUserToSlackChannel
} from '../../../actions';
import CompletionCertificateBanner from '../../CompletionCertificate/Banner';
import './style.css';

class Content extends Component {
  componentDidMount() {
    const {
      match: { params: { entryId } },
      course,
      currentUser,
      organization
    } = this.props;

    this.props.setCurrentEntry(entryId);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (!course) {
      return null;
    }

    if (!course.courseReadOnly && !currentUser) {
      return null;
    }

    // INTEGRATE SLACK ///////////////////////////////////////////
    const slackUserId = pathOr(null, ['slackUserId'], currentUser);
    const { integrateSlack } = organization;
    const { slackChannel } = course;

    if (integrateSlack && slackChannel && slackUserId) {
      this.props.addUserToSlackChannel({
        channelId: slackChannel,
        slackUserId
      });
    }
    // ///////////////////////////////////////////////////////////
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { entryId, topicId } } } = this.props;

    if (prevProps) {
      const { match: { params: prevParams } } = prevProps;

      if (entryId !== prevParams.entryId) {
        this.props.setCurrentEntry(entryId);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }

      if (topicId !== prevParams.topicId) {
        this.props.setCurrentTopic(topicId);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCurrentEntry();
  }

  render() {
    const {
      currentEntry,
      course,
      currentTopic,
      currentUser,
      topics
    } = this.props;

    if (!currentEntry || !course || !currentTopic || !topics) {
      return null;
    }

    if (!course.courseReadOnly && !currentUser) {
      return null;
    }

    const {
      title,
      isLive,
      streamingLink
    } = course;

    const { contentType } = currentEntry;
    // Material
    return (
      <main role="main" className="main-content col-md-9 ml-sm-auto p-3">

        {isLive && streamingLink && (
          <GoLive
            streamingLink={streamingLink}
            title={title}
            buttonText="Join Class"
            buttonClassName=""
            className="mb-3"
            slim
          />
        )}

        <InstructorsOnly />

        <StudentsOnly />

        <CompletionCertificateBanner />

        {contentType === 'challenge' && (
          <Challenge />
        )}

        {contentType === 'quiz' && (
          <Quiz />
        )}

        {contentType === 'material' && (
          <CurrentEntry
            content={currentEntry}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = ({
  organization,
  topics,
  currentClass,
  currentTopic,
  currentEntry,
  currentUser
}) => ({
  organization,
  topics,
  course: currentClass,
  currentTopic,
  currentEntry,
  currentUser
});

export default withRouter(connect(mapStateToProps, {
  setCurrentEntry,
  resetCurrentEntry,
  setCurrentTopic,
  addUserToSlackChannel
})(Content));
