import { toast } from 'react-toastify';
import {
  PLATFORM_NAME,
  TURBINE_ADMIN_BCC_EMAILS,
  TURBINE_ADMIN_EMAIL
} from '../constants/globals';
import * as twApi from './tw-api';
import { GMAIL_SEND } from '../constants/api';
import { TURBINE_ADMIN, TURBINE_MARKETING_SITE } from '../constants/urls';
import { client, flattenItem } from './contentful';
import { dateTimestamp } from '../utils/date';

export const fetchOrg = ({
  orgId, slug, select, include
}) => {
  const config = {
    content_type: 'organization',
    include
  };

  if (orgId) {
    config['sys.id'] = orgId;
  } else if (slug) {
    config['fields.slug'] = slug;
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const organization = items[0] !== undefined ? flattenItem(items[0]) : null;

      resolve(organization);
    }).catch((error) => {
      console.error(error.message);
      reject(error);
    });
  });
};

export const setUserAppVersion = (appVersion) => {
  window.localStorage?.setItem('turbine_lms', appVersion);
};

export const getUserAppVersion = () => {
  return window.localStorage?.getItem('turbine_lms');
};

export const setUserLoggedIn = () => {
  window.localStorage?.setItem('turbine_auth_active', dateTimestamp());
};

export const getUserLoggedIn = () => {
  return window.localStorage?.getItem('turbine_auth_active');
};

export const rmUserLoggedIn = () => {
  window.localStorage?.removeItem('turbine_auth_active');
};

export const usingLatestAppVersion = (appVersion) => {
  const versionFromBrowser = getUserAppVersion();

  if (!versionFromBrowser && appVersion) {
    setUserAppVersion(appVersion);
    return true;
  }

  if (versionFromBrowser && appVersion === versionFromBrowser) {
    return true;
  }

  return false;
};

export const orgResourcesUrl = (orgId) => {
  return `${TURBINE_ADMIN}/org/resources?orgId=${orgId}`;
};

/**
 * @summary Emails Turbine Admins
 * @param {string} subject // 'Billing Question: [orgName]
 * @param {string} message // message
 * @param {string} fromName // User requesting support
 * @param {string} replyTo // User requesting support's email
 * @returns {Promise<string>} emails, link
 */
export const notifyTurbineSupport = ({
  subject,
  message,
  replyTo,
  fromName
}) => {
  return new Promise((resolve, reject) => {
    const to = TURBINE_ADMIN_EMAIL;
    const emailParts = {
      to,
      from: {
        name: fromName
      },
      replyTo,
      bcc: TURBINE_ADMIN_BCC_EMAILS,
      subject,
      body: '',
      htmlBody: `
        <p>Turbine Workforce Admin,</p>
        <p>${message}</p>
        <br />
        <p>
          Best,
          <br />
          ${fromName}
        </p>
        <font color='#888888'>--</font>
        <br>
        Powered by <a href='${TURBINE_MARKETING_SITE}?track=sendEmail' target='_blank' title='${PLATFORM_NAME}'>${PLATFORM_NAME}</a>
      `
    };

    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          toast.error(response.message); // ex: 'Something went wrong, try again'
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
