import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaThumbtack } from 'react-icons/fa';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { mdySimple } from '../../utils/date';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import UserProfilePicture from '../UserProfilePicture';

const Notification = ({ data }) => {
  const {
    createdAt,
    postAsOrg = false,
    authorName,
    authorId,
    authorUid,
    message,
    isPinned = false
  } = data;
  const organization = useSelector((state) => state.organization);
  const orgLogo = organization?.orgLogo || '';
  const orgName = organization?.name || '';

  return (
    <div className="d-flex mb-4">
      <div className="">
        {postAsOrg ? (
          <OrgAvatarContainer className="border p-1">
            <OrgLogo src={orgLogo} alt={orgName} height="40" width="40" />
          </OrgAvatarContainer>
        ) : (
          <UserProfilePicture userId={authorId} alt={authorName} />
        )}
      </div>
      <div className="ml-3 w-100">
        <NotificationMessageContainer
          data-demo="disabled"
          className="notification-message-container m-0"
        >
          <div className="d-flex align-items-center justify-content-between mb-1">
            <div className="font-weight-bold" style={{ fontSize: '1rem' }}>
              {postAsOrg ? orgName : authorName}
            </div>
            <div
              className="text-muted"
              data-demo="disabled"
              data-author-id={authorId}
              data-author-uid={authorUid}
            >
              {mdySimple(createdAt)}
            </div>
          </div>
          <div
            className={`shadow p-3 rounded position-relative ${isPinned && 'border border-primary-light shadow-primary-light'}`}
          >
            <RenderMarkdown source={message} enableNewEditor={false} />
            {isPinned && (
              <div
                className="position-absolute"
                style={{
                  top: '5px',
                  left: '5px'
                }}
              >
                <FaThumbtack className="text-primary" />
              </div>
            )}
          </div>
        </NotificationMessageContainer>
      </div>
    </div>
  );
};

const NotificationMessageContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const OrgAvatarContainer = styled.div`
  height: 40px;
  width: 40px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: white;
`;

export default Notification;
