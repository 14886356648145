import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { dateTimestamp } from '../../../utils/date';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import * as aws from '../../../services/aws';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';

const TypeForm = ({ loading, questions }) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentAnswer = useSelector((state) => state.currentAnswer);
  const { id: orgId } = organization;
  const userId = pathOr(null, ['id'], currentUser);
  const userName = pathOr(null, ['name'], currentUser);
  const formData = pathOr(null, ['results', 'formData'], currentAnswer);

  if (!questions || (questions && Array.isArray(questions) && questions.length === 0)) {
    return null;
  }

  const fileUploadCallback = (data, questionId) => {
    const fileInput = document.getElementById(`question-${questionId}`);

    const fileObj = { ...data };
    // Add user id and name for permissions & knowing who uploaded
    fileObj.userId = userId;
    fileObj.userName = userName;
    fileObj.createdAt = dateTimestamp();

    fileInput.value = JSON.stringify(fileObj);
  };

  const uploadFile = (blob, questionId) => {
    aws.uploadFile({
      prefix: `${AWS_USER_PREFIX}/${orgId}`,
      file: blob,
      orgId
    }).then(({ url, id }) => {
      const fileObj = { url, id };

      if (blob.name) fileObj.name = blob.name;
      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      fileUploadCallback(fileObj, questionId);
    });
  };

  const onFileChange = (e, questionId) => {
    const { files } = e.currentTarget;
    const blob = files[0];
    const fileInput = document.getElementById(`question-${questionId}`);
    // Prev Uploaded File
    const hasSubmittedFile = formData && formData[questionId] !== undefined;
    const submittedFile = hasSubmittedFile ? JSON.parse(formData[questionId].value) : null;
    // Selected New file to upload
    const hasPrevSelectedFile = fileInput && fileInput.value !== '';
    const prevSelectedFile = hasPrevSelectedFile ? JSON.parse(fileInput.value) : null;

    const dataToSave = {
      organizationId: orgId
    };

    if (hasSubmittedFile || hasPrevSelectedFile) {
      // User has previously submitted an uploaded file
      if (hasSubmittedFile) {
        dataToSave.url = submittedFile.url;
      }

      // User has previously selected a new file to upload
      if (hasPrevSelectedFile) {
        dataToSave.url = prevSelectedFile.url;
      }

      aws.deleteFile(dataToSave).then(() => {
        uploadFile(blob, questionId);
      });
    } else {
      uploadFile(blob, questionId);
    }
  };

  return (
    <Fragment>
      {questions.map(({
        questionId,
        questionType,
        title,
        description,
        answers: questionOptions
      }, questionIndex) => (
        <Fragment key={questionIndex}>
          {questionType === 'header' ? (
            <div
              key={questionIndex}
              className="h5 font-weight-bold bg-secondary text-white p-3"
            >
              {/* This type of title does not support HTML */}
              {title}
              <input
                type="hidden"
                data-id={questionId}
                data-title={title}
                data-type={questionType}
              />
            </div>
          ) : (
            <div
              key={questionIndex}
              className="my-3 quiz-question border"
            >
              <label
                htmlFor={`question-${questionId}`}
                className='d-flex'
              >
                {/* This field now supports HTML after import from Moodle integration */}
                <RenderMarkdown
                  source={title}
                  enableNewEditor
                />
                <span className="text-danger ml-1">*</span>
              </label>

              {description && (
                <RenderMarkdown
                  source={description}
                  enableNewEditor
                />
              )}

              {questionType === 'text' && (
                <input
                  type="text"
                  className="form-control"
                  id={`question-${questionId}`}
                  data-id={questionId}
                  data-title={title}
                  data-type={questionType}
                  required
                  disabled={loading}
                />
              )}

              {questionType === 'file' && (
                <Fragment>
                  <input
                    type="file"
                    className="form-control"
                    required
                    disabled={loading}
                    onChange={(e) => {
                      if (currentUser?.id) {
                        onFileChange(e, questionId);
                      }
                    }}
                  />
                  <input
                    type="hidden"
                    id={`question-${questionId}`}
                    data-id={questionId}
                    data-title={title}
                    data-type={questionType}
                  />
                </Fragment>
              )}

              {questionType === 'textarea' && (
                <textarea
                  type="text"
                  className="form-control"
                  id={`question-${questionId}`}
                  data-id={questionId}
                  data-title={title}
                  data-type={questionType}
                  required
                  disabled={loading}
                />
              )}

              {questionType === 'radio' && (
                <Fragment>
                  {questionOptions && (
                    <div className="RadioGroup--container">
                      {questionOptions.map(({ text }, optionIndex) => {
                        const id = `${questionId}-${optionIndex}`;
                        const name = `${questionId}`;

                        return (
                          <div
                            key={optionIndex}
                            className="custom-control custom-radio block mb-2 small"
                          >
                            <input
                              id={id}
                              name={name}
                              type='radio'
                              className="custom-control-input"
                              defaultValue={text}
                              data-id={questionId}
                              data-title={title}
                              data-type={questionType}
                              required
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={id}
                            >
                              <span>
                                {text}
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default TypeForm;
