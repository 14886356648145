import React, { Fragment } from 'react';
import { pathOr } from 'ramda';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import RenderFile from './RenderFile';

const Results = ({
  currentAnswer, challenge
}) => {
  if (!currentAnswer) return null;

  const results = pathOr(null, ['results'], currentAnswer);
  const questions = pathOr(null, ['questions'], challenge);

  let serializedResults = null;

  if (results && results.formData) {
    serializedResults = Object.entries(results.formData).map(([key, obj]) => ({
      title: obj.title,
      value: obj.value,
      type: obj.type,
      id: key
    }));
  }

  return (
    <div>
      {serializedResults && serializedResults.map(({
        title,
        value,
        id,
        type
      }) => {
        const question = questions ? questions.find(({ questionId }) => questionId && questionId === id) : null;

        return (
          <Fragment key={id}>
            {type === 'header' ? (
              <div className="border-bottom mb-3">
                <div className="h5 font-weight-bold border-bottom pb-3">
                  {title}:
                </div>
              </div>
            ) : (
              <div
                className={`mb-3 ${question && question.description ? 'border' : ''} p-2`}
              >
                <div>
                  <b>{title}:</b>
                </div>

                {question && question.description && (
                  <RenderMarkdown
                    source={question.description}
                    enableNewEditor
                  />
                )}

                {(type === 'text' || type === 'textarea' || type === 'radio') && (
                  <div className="border p-2">
                    {value}
                  </div>
                )}

                {type === 'file' && (
                  <RenderFile
                    id={id}
                    value={value}
                    type={type}
                  />
                )}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Results;
