import React from 'react';
import PrivacyPolicyLink from '../../../Legal/PrivacyPolicyLink';
import TermsOfServiceLink from '../../../Legal/TermsOfServiceLink';

const Legal = () => {
  return (
    <small>
      By signing in you agree to our <PrivacyPolicyLink /> and <TermsOfServiceLink />.
    </small>
  );
};

export default Legal;
