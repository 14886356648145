import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { COPY, MAP_LOCATION } from '../../constants/icons';
import { getFullAddress, locationTerminology } from '../../services/locations';
import copyToClipBoard from '../../utils/copyToClipBoard';

const DotMenu = ({ location }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const orgType = pathOr(null, ['type'], organization);
  const locationTerm = locationTerminology({ orgType });

  const locationName = pathOr(null, ['name'], location);
  const mapLink = pathOr(null, ['mapLink'], location);

  const fullAddress = getFullAddress({ location });

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage"
      >
        <i className="fas fa-ellipsis-h" />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap">
          <small>
            <i className={`${locationTerm.icon} mr-1`} />
          </small> {locationName}
        </div>

        {mapLink && (
          <a
            className="dropdown-item border-bottom py-2"
            href={mapLink}
            role="button"
            title="View on map"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={MAP_LOCATION} /> Open Map
          </a>
        )}

        <button
          type="button"
          title='Copy URL'
          className="dropdown-item border-bottom py-2"
          onClick={() => {
            copyToClipBoard(fullAddress);
            toast.success('Address Copied!');
          }}
        >
          <i className={`${COPY} mr-1`} /> Copy Address
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
