import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import Tiers from './Tiers';
import { resetCurrentModal } from '../../../actions';
import { buySubscription } from '../../../services/stripe';

class UpgradeMembership extends Component {
  state = {
    loading: false,
    ...this.props.currentModal.data
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  handleUpgrade = (membership) => {
    const { currentUser, organization } = this.props;

    if (!currentUser) return null;

    this.setState({ loading: true });

    const { id: userId } = currentUser;
    const { id: orgId, integration } = organization;

    buySubscription({
      plans: integration.stripe.plans[membership],
      membership,
      orgId,
      userId,
      cancelUrl: document.location.href
    });
  }

  selectStarerMembership = () => {
    const { starterActivateUrl } = this.state;

    document.location = starterActivateUrl;
  }

  render() {
    const { currentModal } = this.props;

    const {
      loading, title, content, price, purchaseCourse, membership
    } = this.state;

    return (
      <Modal
        visible={currentModal.visible}
        close={this.handleClose}
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        pos="middle"
      >
        <div className="card">
          <div className="card-header h3 mb-0 text-center">
            <h5 className="m-0">
              <i className="fas fa-users" aria-hidden="true" /> {title}
            </h5>
          </div>
          <div className="card-content p-3">
            {content && (
              <p className="py-3 text-center">
                {content}
              </p>
            )}

            {/* If user is inactive, but was starter, they can activate starter or premium */}
            {/* If user is inactive, but was premium, they can activate premium */}
            <Tiers
              handleUpgrade={this.handleUpgrade}
              loading={loading}
              selectStarerMembership={this.selectStarerMembership}
              canSelectStarterMembership={membership === 'starter'}
            />

            {purchaseCourse && (
              <p className="d-flex align-items-center justify-content-center">
                <span className="mr-1">No thanks, I'll</span> <button className="btn btn-link p-0" onClick={purchaseCourse} type="button">pay full price ({price})</button>.
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  currentUser,
  organization
}) => ({
  currentModal,
  currentUser,
  organization
});

export default connect(mapStateToProps, { resetCurrentModal })(UpgradeMembership);
