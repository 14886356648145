import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { CALENDAR_ALT } from '../../../../constants/icons';
import SendEmailButton from '../../../btns/SendEmailButton';
import DirectMessageButton from '../../../btns/DirectMessageButton';
import RenderMarkdown from '../../../ManageContent/RenderMarkdown';
import './style.css';

const InstructorsList = ({
  className = 'col-md-6 col-lg-4 my-4'
}) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const courseExperts = useSelector((state) => state.courseExperts);
  // Organization
  const { integrateSlack } = organization;
  // User
  const currentUserSlackUserId = currentUser?.slackUserId || null;

  if (!courseExperts) {
    return null;
  }

  return (
    <div className="row">
      {courseExperts && courseExperts.map((courseExpert, i) => {
        const {
          title,
          profilePicture,
          name,
          email,
          slackUserId,
          testUser,
          aboutMe,
          calendarLink
        } = courseExpert;

        if (testUser) return null;

        return (
          <div
            key={`courseExpert-${i}`}
            className={className}
          >
            <div className="experts-list-item card h-100 text-center">
              <div className="card-body">
                <img
                  data-demo="disabled"
                  className="bd-placeholder-img rounded-circle mb-2"
                  width="120"
                  height="120"
                  style={{
                    height: '120px',
                    width: '120px'
                  }}
                  src={profilePicture || DEFAULT_PROFILE_PIC}
                  alt={name}
                />
                <h5
                  data-demo="disabled"
                  className="card-title"
                >
                  {name}
                </h5>

                <div className="card-text">
                  <div className="font-weight-bold mb-1">
                    {title || 'Instructor'}
                  </div>

                  {aboutMe && (
                    <RenderMarkdown
                      source={aboutMe}
                      enableNewEditor
                    />
                  )}
                </div>
              </div>
              <div className="card-footer">
                {integrateSlack && slackUserId && currentUserSlackUserId && (
                  <DirectMessageButton
                    name={name}
                    organization={organization}
                    slackUserId={slackUserId}
                    buttonText="Slack"
                    className="btn btn-sm btn-white mr-2"
                    showIcon
                  />
                )}

                <SendEmailButton
                  userName={name}
                  userEmail={email}
                  className="btn btn-sm btn-outline-primary"
                  showIcon
                />

                {calendarLink && (
                  <a
                    className="btn btn-sm btn-outline-primary ml-2"
                    title="Schedule Meeting"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={calendarLink}
                  >
                    <i className={`${CALENDAR_ALT} mr-1`} /> Schedule Meeting
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InstructorsList;
