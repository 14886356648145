import {
  logEvent as firebaseLogEvent,
  setUserProperties as firebaseSetUserProperties,
  setUserId as firebaseSetUserId,
  getAnalytics
} from '@firebase/analytics';
import { app } from '../firebaseConfig';

// https://firebase.google.com/docs/analytics/events?platform=web#log_events_5
export const logEvent = (eventName = '', data = {}) => {
  const analytics = getAnalytics(app);

  if (eventName) {
    firebaseLogEvent(analytics, eventName, {
      ...data,
      app: 'TURBINE_APP'
    });
  }
};

// https://firebase.google.com/docs/analytics/userid#setting_the_user_id
export const setUserId = (userId = '') => {
  const analytics = getAnalytics(app);

  if (userId) {
    firebaseSetUserId(analytics, userId);
  }
};

// https://firebase.google.com/docs/analytics/user-properties?platform=web#set_user_properties_5
export const setUserProperties = (data = {}) => {
  const analytics = getAnalytics(app);

  firebaseSetUserProperties(analytics, data);
};
