import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { canAccessCourseCatalog, canViewAnswerFeedback } from '../../services/currentUser';
import Rows from './Rows';

class AssessmentsList extends Component {
  render() {
    const {
      organization,
      currentAnswers,
      currentUser,
      className = ''
    } = this.props;

    if (!currentUser) return null;

    const { role } = currentUser;
    const { enableCourseCatalog } = organization;

    return (
      <Fragment>
        {currentAnswers && currentAnswers.length ? (
          <table className={`table table-striped m-0 ${className}`}>
            <thead>
              <tr>
                <th scope="col" className='border-0'>Status</th>
                <th scope="col" className="border-0 d-none d-md-table-cell">Submitted</th>
                <th scope="col" className="border-0 d-none d-md-table-cell">Challenge</th>
                <th scope="col" className='border-0'>Class</th>
                {/* <th scope="col" className='border-0 d-none d-md-table-cell'>Topic</th> */}
                {canViewAnswerFeedback(role) && (
                  <th scope="col" className='border-0 d-none d-md-table-cell'>Instructor Feedback</th>
                )}
              </tr>
            </thead>
            <tbody>
              <Rows />
            </tbody>
          </table>
        ) : (
          <div className="dash-module-no-content">
            <h4>
              <i className="fas fa-laptop-code" />
            </h4>
            <p>
              Get started by opening a class.
            </p>
            {canAccessCourseCatalog({ enableCourseCatalog }) && (
              <Link
                to={ROUTES.CLASSES}
                title="View Courses"
                className="btn btn-primary btn-md"
              >
                View Courses
              </Link>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  currentUser,
  currentAnswers,
  organization
}) => ({
  currentUser,
  currentAnswers,
  organization
});

export default connect(mapStateToProps)(AssessmentsList);
