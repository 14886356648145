import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { canManageContent } from '../../../services/currentUser';
import { EXTERNAL_LINK } from '../../../constants/icons';
import { nextEntryRoute, prevEntryRoute } from '../../../services/entry';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import ManageButton from '../../btns/ManageButton';
import * as analytics from '../../../utils/analytics';

const CurrentEntry = ({ content, currentAnswer }) => {
  const history = useHistory();
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const topics = useSelector((state) => state.topics);
  const currentEntry = useSelector((state) => state.currentEntry);
  const classId = pathOr('', ['id'], course);
  const guidedCourse = pathOr(null, ['guidedCourse'], course);
  const userId = pathOr('', ['id'], currentUser);
  const orgId = pathOr('', ['id'], organization);
  const orgName = pathOr('', ['name'], organization);
  const locationId = pathOr('', ['id'], userLocation);
  const locationName = pathOr('', ['name'], userLocation);
  const topicId = pathOr('', ['id'], currentTopic);
  const topicTitle = pathOr('', ['title'], currentTopic);

  const nextEntryRouteStr = guidedCourse ? nextEntryRoute({
    course, currentTopic, currentEntry, topics, currentAnswer
  }) : null;

  const prevEntryRouteStr = guidedCourse ? prevEntryRoute({
    course, currentTopic, currentEntry, topics
  }) : null;

  const {
    id: entryId,
    contentType,
    title,
    body,
    question,
    enableNewEditor
  } = content;

  useEffect(() => {
    if (orgId && classId && entryId) {
      const analyticsData = {
        page_title: `${title} | ${topicTitle} | ${course?.title}`,
        page_type: `Course ${contentType}`,
        orgId,
        orgName,
        classId,
        entryId,
        ...(userId ? { userId } : {}),
        ...(topicId ? { topicId } : {}),
        ...(topicTitle ? { topicTitle } : {}),
        ...(locationId ? { locationId } : {}),
        ...(locationName ? { orgLocationName: `${orgName} ${locationName}` } : {})
      };

      analytics.logEvent('page_view', analyticsData);
      analytics.logEvent('course_content_view', analyticsData);
    }
  }, [userId, orgId, locationId, classId, topicId, entryId]);

  return (
    <>
      <div className="mb-3 card">
        <div
          className="card-header p-3 d-flex justify-content-between align-items-center"
        >
          <h1 className="h5 m-0">
            {title}
          </h1>
        </div>

        {canManageContent({ currentUser, course }) && (
          <div className="d-flex justify-content-end py-2 px-3 border-bottom">
            <ManageButton
              className="btn btn-sm btn-outline-primary text-capitalize d-flex align-items-center"
              classId={classId}
              topicId={topicId}
              entryId={entryId}
              contentType={contentType}
              title={title}
              buttonText={`Edit ${contentType}`}
              manageType="edit"
              tooltip
            >
              Edit {contentType} <small><i className={`${EXTERNAL_LINK} ml-2`} /></small>
            </ManageButton>
          </div>
        )}

        <div className="card-body">
          <RenderMarkdown
            source={body || question}
            enableNewEditor={enableNewEditor}
          />
        </div>

        {guidedCourse && (prevEntryRouteStr || nextEntryRouteStr) && (
          <div className="card-footer d-flex align-items-center justify-content-between">
            {guidedCourse && prevEntryRouteStr && (
              <button
                className="btn btn-outline-primary"
                type="button"
                title="Previous"
                onClick={() => {
                  history.push(prevEntryRouteStr);
                }}
              >
                <i className="fas fa-chevron-circle-left mr-1" /> Previous
              </button>
            )}

            {guidedCourse && nextEntryRouteStr && (
              <button
                className="btn btn-primary"
                type="button"
                title="Next"
                onClick={() => {
                  history.push(nextEntryRouteStr);
                }}
              >
                Next <i className="fas fa-chevron-circle-right ml-1" />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentEntry;
