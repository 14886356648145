function formatQuizOutcome({
  difficulty,
  title,
  score,
  maxScore,
  attempts,
  questions,
  userAnswers,
  rubric,
  evaluation,
  prevScores,
  quizId,
  classId,
  topicId,
  userId,
  orgId,
  locationId,
  apprenticeshipId
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (difficulty !== undefined) {
    data.difficulty = {
      'en-US': difficulty
    };
  }

  if (score !== undefined) {
    data.score = {
      'en-US': score
    };
  }

  if (maxScore !== undefined) {
    data.maxScore = {
      'en-US': maxScore
    };
  }

  if (attempts !== undefined) {
    data.attempts = {
      'en-US': attempts
    };
  }

  if (questions !== undefined) {
    data.questions = {
      'en-US': questions
    };
  }

  if (userAnswers !== undefined) {
    data.userAnswers = {
      'en-US': userAnswers
    };
  }

  if (rubric !== undefined) {
    data.rubric = {
      'en-US': rubric
    };
  }

  if (evaluation !== undefined) {
    data.evaluation = {
      'en-US': evaluation
    };
  }

  if (quizId !== undefined) {
    data.quizId = {
      'en-US': quizId
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  if (topicId !== undefined) {
    data.topicId = {
      'en-US': topicId
    };
  }

  if (userId !== undefined) {
    data.userId = {
      'en-US': userId
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  if (locationId !== undefined) {
    data.locationId = {
      'en-US': locationId
    };
  }

  if (apprenticeshipId !== undefined) {
    data.apprenticeshipId = {
      'en-US': apprenticeshipId
    };
  }

  if (prevScores !== undefined) {
    data.prevScores = {
      'en-US': prevScores
    };
  }

  return { fields: { ...data } };
}

export default formatQuizOutcome;
