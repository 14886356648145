function serializeQuiz(quiz) {
  const currentQuiz = {
    rubric: [],
    questions: []
  };

  const {
    id: quizId,
    title,
    questions,
    description,
    difficulty,
    guidedQuiz,
    activeQuiz,
    allowPracticeAfterSubmission
  } = quiz;

  currentQuiz.id = quizId;
  currentQuiz.title = title;
  currentQuiz.description = description;
  currentQuiz.difficulty = parseInt(difficulty, 10);
  currentQuiz.guidedQuiz = guidedQuiz;
  currentQuiz.activeQuiz = activeQuiz;
  currentQuiz.allowPracticeAfterSubmission = allowPracticeAfterSubmission;

  questions.forEach((question, i) => {
    const data = {
      id: `${quizId}_${i}`, // TODO can this be removed, we have questionId
      title: question.title,
      answers: question.answers,
      questionNumber: question.questionNumber
    };

    if (question.description) {
      data.description = question.description;
    }

    if (question.hint) {
      data.hint = question.hint;
    }

    currentQuiz.questions.push(data);

    const correctAnswers = question.answers.filter((q) => q.correct);
    const numCorrectAnswers = correctAnswers ? correctAnswers.length : 0;

    question.answers.forEach((answer, answerIndex) => {
      if (answer.correct) {
        // Question has more than one correct answer
        if (numCorrectAnswers > 1) {
          // Create empty array in rubric to store
          // all correct answers for this question
          if (currentQuiz.rubric[i] === undefined) {
            currentQuiz.rubric.push([]);
          }
          // Push correct answer's index to array of correct answers
          // for this question
          currentQuiz.rubric[i].push(answerIndex);
        } else {
          // Push correct answer's index
          currentQuiz.rubric.push(answerIndex);
        }
      }
    });
  });

  return currentQuiz;
}

export default serializeQuiz;
