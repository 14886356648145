import React from 'react';
import { isEmpty, isNil, pathOr } from 'ramda';
import { buyClass } from '../../../services/stripe';

const handleEnroll = ({
  classId,
  classTitle,
  upgradeBeforeBuy,
  upgradeMembership,
  skus
}) => {
  return new Promise((resolve) => {
    if (upgradeBeforeBuy) {
      upgradeMembership({
        classId,
        classTitle,
        membership: 'premium',
        price: skus.price,
        purchaseCourse: () => {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
};

const CostEnroll = ({
  course,
  courseCohorts,
  cohortId,
  currentUser,
  upgradeMembership,
  chooseCohort,
  eUnitIds,
  disabled = false
}) => {
  const { id: classId, title: classTitle, integration } = course;
  const { id: userId, membershipReadable } = currentUser;
  const stripeSkus = pathOr(null, ['stripe', 'skus'], integration);
  const skus = stripeSkus[membershipReadable];

  return (
    <button
      disabled={disabled}
      title={`Enroll in ${classTitle} `}
      className="costEnrollCourseBtn btn btn-md btn-warning my-2 d-flex align-items-center"
      type="button"
      onClick={() => {
        if (courseCohorts && (isNil(eUnitIds) || isEmpty(eUnitIds))) {
          chooseCohort({ classId });
        } else {
          handleEnroll({
            classId,
            classTitle,
            upgradeMembership,
            upgradeBeforeBuy: membershipReadable === 'starter',
            skus: stripeSkus[membershipReadable]
          }).then(() => {
            buyClass({
              skus,
              classId,
              cohortId,
              userId,
              cancelUrl: document.location.href
            });
          });
        }
      }}
    >
      <span>Enroll</span>
      {skus && skus.price && (
        <small className="ml-1">
          ({skus.price})
        </small>
      )}
    </button>
  );
};

export default CostEnroll;
