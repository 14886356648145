import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCohorts } from '../../actions/Cohorts';
import { resetCurrentClass, setClassCertificate, setCurrentClass } from '../../actions/Class';
import { withAuthorization } from '../Session';
import { CERTIFICATE } from '../../constants/routes';
import { generateCourseRecords } from '../../services/attendance';
import CourseAttendance from './CourseAttendance';
import CohortAttendance from './CohortAttendance';
import NoRecords from './NoRecords';
import Loading from '../Loading';

const Attendance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const userCourses = useSelector((state) => state.userCourses);
  const userCohorts = useSelector((state) => state.userCohorts);
  // Local State
  const [loading, setLoading] = useState(true);
  // Misc
  const {
    cohortIds,
    attendance // TODO user flatten (migrate attendance to firebase entries)
  } = currentUser;

  useEffect(() => {
    let mounted = true;

    dispatch(getCohorts({ cohortIds })).then(() => {
      if (mounted) setLoading(false);
    });

    return function cleanup() {
      mounted = false;
      dispatch(resetCurrentClass());
    };
  }, [cohortIds]);

  const openCertificate = ({
    courseCohortRecord,
    classId
    // classTitle
  }) => {
    dispatch(setCurrentClass({ classId }));

    dispatch(setClassCertificate(courseCohortRecord)).then(() => {
      history.push(`${CERTIFICATE}/${courseCohortRecord.id}`);
    });
  };

  if (loading) {
    return (
      <Loading text="Loading attendance..." />
    );
  }

  const courseAttendance = generateCourseRecords({
    userCourses,
    userCohorts,
    attendance
  });

  return (
    <main role="main" className="col-sm-12 pt-3">
      <h1 className="h4 m-0">
        Attendance
      </h1>
      <hr />
      <div className="pb-5">
        {courseAttendance && courseAttendance.length ? (
          <div className="accordion" id="attendanceCourseCohorts">
            {courseAttendance.map((courseRecord, i) => {
              return (
                <div
                  key={i}
                  className="card border-bottom my-4"
                >
                  <div className="card-header bg-dark text-white">
                    <h5 className="m-0">
                      { courseRecord.title }
                    </h5>
                  </div>
                  <div className="card-body">
                    {courseRecord.cohortAttendance ? (
                      <CohortAttendance
                        courseIndex={i}
                        attendance={courseRecord.cohortAttendance}
                        openCertificate={openCertificate}
                        classId={courseRecord.id}
                        classTitle={courseRecord.title}
                      />
                    ) : (
                      <CourseAttendance
                        attendance={courseRecord.attendance}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoRecords />
        )}
      </div>
    </main>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Attendance);
