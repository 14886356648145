import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { MAP_LOCATION, MAP_PIN } from '../../constants/icons';
import AddressReadable from '../AddressReadable';
import Alert from '../Alert';
import CurrentWeather from '../Weather/CurrentWeather';
import DotMenu from './DotMenu';

const Location = ({
  className = '',
  showWeather = false,
  showHeader = false
}) => {
  const organization = useSelector((state) => state.organization);
  const userLocation = useSelector((state) => state.userLocation);
  const userWeatherCurrent = useSelector((state) => state.userWeatherCurrent);
  const userWeatherLocation = useSelector((state) => state.userWeatherLocation);

  if (!userLocation || !organization) {
    return null;
  }

  // Organization
  const orgName = pathOr(null, ['name'], organization);
  // Location
  const locationName = pathOr(null, ['name'], userLocation);
  const subtitle = pathOr(null, ['subtitle'], userLocation);
  const locationImage = pathOr(null, ['locationImage'], userLocation);
  // Address : Location
  const addressLine1 = pathOr(null, ['addressLine1'], userLocation);
  const addressLine2 = pathOr(null, ['addressLine2'], userLocation);
  const addressCity = pathOr(null, ['addressCity'], userLocation);
  const addressState = pathOr(null, ['addressState'], userLocation);
  const addressPostalCode = pathOr(null, ['addressPostalCode'], userLocation);
  // const addressCountry = pathOr(null, ['addressCountry'], userLocation);

  return (
    <div className={`card ${className}`}>
      {showHeader && (
        <div
          className="card-header px-3 py-2 d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className='h4 m-0'>
              <i className={MAP_PIN} />
            </div>
            <div className='ml-3'>
              <div className='h5 font-weight-bold m-0'>
                {locationName}
              </div>
              {subtitle && (
                <div>
                  {subtitle}
                </div>
              )}
            </div>
          </div>
          <DotMenu
            location={userLocation}
          />
        </div>
      )}

      <div className="card-content p-3">
        <div className="row">
          {locationImage && (
            <div className="col-md-5">
              <img
                className="img-fluid d-none d-sm-inline-block img-fluid mx-auto"
                src={locationImage}
                alt={orgName + locationName}
              />
            </div>
          )}

          <div className="col-md-7">
            {showWeather && userWeatherCurrent && userWeatherLocation && (
              <CurrentWeather
                className='py-3 px-1'
                current={userWeatherCurrent}
                location={userWeatherLocation}
                iconHeight={60}
                icon
              />
            )}

            <div className='mt-2'>
              <Alert
                type=""
                icon={MAP_LOCATION}
                className="m-0 mb-4 w-100 border bg-light"
              >
                <AddressReadable
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  addressCity={addressCity}
                  addressState={addressState}
                  addressPostalCode={addressPostalCode}
                  // addressCountry={addressCountry}
                />
              </Alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
