import {
  client,
  flattenItem,
  flattenItems
} from '../services/contentful';
import isPublished from '../utils/isPublished';
import {
  SET_CURRENT_TOPIC_SUCCESS,
  RESET_CURRENT_TOPIC
} from './types';

export const setCurrentTopic = (topicId) => {
  return (dispatch) => {
    return client.getEntry(topicId).then((response) => {
      const currentTopic = isPublished(response) && flattenItem(response);
      const data = {
        type: SET_CURRENT_TOPIC_SUCCESS,
        currentTopic
      };

      const { sections } = currentTopic;

      if (sections && sections.length > 0) {
        data.currentTopic = {
          ...currentTopic,
          sections: flattenItems(sections)
        };
      }

      dispatch(data);
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentTopic = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC });
  };
};
