import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { isLocalhost } from '../../serviceWorker';
import { setCurrentAnswers } from '../../actions/Answers';
import { getCourses } from '../../actions/Courses';
import { userHasGoLiveAccess } from '../../services/currentUser';
import { CLASS_COLOR_DEFAULT } from '../../constants/globals';
import PreReqStatus from './PreReqStatus';
import CourseDashboardButton from '../btns/CourseDashboardButton';
import Progress from './Progress';
import GoLive from '../GoLive';

const Container = styled.div`
  .progress {
    min-width: 200px;
  }
`;

const ClassColorImage = styled.div`
  width: 145px;
  min-height: 100px;
  font-size: 2rem;
  padding: 1rem;
`;

const MyCoursesList = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const classPreReqs = useSelector((state) => state.classPreReqs);
  const userCourses = useSelector((state) => state.userCourses);
  // Misc
  // const completedCourseIds = pathOr(null, ['completedCourseIds'], currentUser);
  const orgId = pathOr(null, ['id'], organization);
  const userId = pathOr(null, ['id'], currentUser);
  const hasClassPreReqs = (Object.keys(classPreReqs).length !== 0);

  useEffect(() => {
    dispatch(getCourses({ orgId }));
    dispatch(setCurrentAnswers({ userId }));
  }, [orgId, userId]);

  if (userCourses && userCourses.length !== 0) {
    return (
      <Container>
        <table className="table m-0">
          <tbody>
            {userCourses && userCourses.map((course, i) => {
              const {
                id: classId,
                title: classTitle,
                streamingLink,
                isLive,
                classColor,
                classImage
              } = course;

              const hasGoLiveAccess = userHasGoLiveAccess({
                isLive, streamingLink, classId, currentUser
              });

              return (
                <tr key={`${classId}-${i}`}>
                  <td className="py-2 px-1 d-flex align-items-center">
                    <CourseDashboardButton
                      classId={classId}
                      className="btn btn-link mr-2 text-decoration-none"
                    >
                      {classImage ? (
                        <img
                          data-classid={isLocalhost ? classId : null}
                          src={classImage}
                          alt={classTitle}
                          height="100"
                          width="145"
                          style={{
                            height: '100px',
                            width: '145px'
                          }}
                        />
                      ) : (
                        <ClassColorImage
                          className="class-color-image d-flex align-items-center justify-content-center bg-light border"
                        >
                          <i className="fab fa-buffer" style={{ color: classColor || CLASS_COLOR_DEFAULT }} />
                        </ClassColorImage>
                      )}
                    </CourseDashboardButton>

                    <div>
                      <CourseDashboardButton
                        classId={classId}
                        className="btn btn-link pl-0"
                      >
                        <h5 className="text-left">{classTitle}</h5>
                      </CourseDashboardButton>

                      {course && hasClassPreReqs && (
                        <PreReqStatus
                          course={course}
                          currentUser={currentUser}
                          className="text-muted"
                          slim
                        />
                      )}

                    </div>
                  </td>
                  <td className="border-left d-none d-md-table-cell">
                    <Progress
                      classId={classId}
                    />
                    {hasGoLiveAccess && (
                      <GoLive
                        streamingLink={streamingLink}
                        title={classTitle}
                        className="mt-2 mb-0"
                        slim
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
    );
  }

  return null;
};

export default MyCoursesList;
