import React from 'react';
import WidgetLink from './WidgetLink';

const Widget = (props) => {
  if (props?.type === 'link') {
    return (
      <WidgetLink
        {...props}
      />
    );
  }

  return null;
};

export default Widget;
