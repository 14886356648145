import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withAuthorization } from './Session';
import { NOTIFICATIONS_PAGINATION_LIMIT } from '../constants/api';
import PinnedAndUnpinnedNotifications from './NotificationList/PinnedAndUnpinnedNotifications';

const Notifications = () => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Pagination
  const listConfig = useMemo(() => ({
    orgId,
    classId: null,
    limit: NOTIFICATIONS_PAGINATION_LIMIT
  }), [orgId]);

  return (
    <>
      <div className="row py-3 border-bottom">
        <div className="col d-flex align-items-center justify-content-center">
          <h5 className="font-weight-bold m-0">
            Notifications
          </h5>
        </div>
      </div>

      <div className="col-sm-12 col-md-9 col-lg-6 p-3 m-auto">
        <PinnedAndUnpinnedNotifications listConfig={listConfig} orgId={orgId} />
      </div>
    </>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Notifications);
