import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ERROR_ACCOUNT_INACTIVE } from '@apprentage/constants';
import { isStudent } from '@apprentage/utils';
import { CLASSES, DASHBOARD, PROFILE } from '../../constants/routes';
import { redirectToAuth } from '../../services/auth';
import { isActiveMembership } from '../../services/currentUser';
import { withAuthorization } from '../Session';

const Slash = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const userCourses = useSelector((state) => state.userCourses);
  // Organization
  const orgType = organization?.type || null;
  const orgSlug = organization?.slug || null;
  const enableCourseCatalog = organization?.enableCourseCatalog || null;
  // Current User
  const role = currentUser?.role || [];

  if (!isActiveMembership(currentUser?.membership)) {
    if (orgType === 'workforce') {
      redirectToAuth({
        organizationSlug: orgSlug,
        error: {
          code: ERROR_ACCOUNT_INACTIVE
        }
      });
      return;
    }

    return <Redirect to={PROFILE} />;
  }

  if (userCourses && userCourses.length === 1 && isStudent(role)) {
    const [firstCourse] = userCourses;
    const { id: classId } = firstCourse;

    if (classId) {
      return <Redirect to={`${CLASSES}/${classId}`} />;
    }
  }

  if (enableCourseCatalog && orgType !== 'workforce') {
    return <Redirect to={CLASSES} />;
  }

  return <Redirect to={DASHBOARD} />;
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Slash);
