import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  canAccessTopNavOrgNotifications,
  canAccessTurbineAdmin
} from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import {
  FaBell, FaObjectGroup, FaLifeRing, FaTh
} from 'react-icons/fa';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { CLASSES, NOTIFICATIONS, PROFILE } from '../../constants/routes';
import { isDemoModeActive, toggleDemoMode } from '../../services/demoMode';
import { setCurrentModal } from '../../actions/Modals';
import {
  canActivateDemoMode,
  isActiveMembership
} from '../../services/currentUser';
import { logUserOut, redirectToTurbineAdmin } from '../../services/auth';
import { logout, resetUser } from '../../actions';
import {
  MODAL_KEY_NOTIFICATIONS_MENU,
  MODAL_KEY_SEND_EMAIL,
  MODAL_KEY_APPS_MENU,
  MODAL_KEY_RELOAD
} from '../../constants/modal';
import TopNavWeather from '../Weather/TopNavWeather';
import TopNavUserDetails from './TopNavUserDetails';
import UserMenuLinks from './UserMenuLinks';
import TopLeftButton from './TopLeftButton';
import TopLeftCourseInfo from './TopLeftCourseInfo';
import {
  FLAG_APP_TURBINE_LMS,
  FLAG_MAINTENANCE_MODE,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import './style.css';
import { usingLatestAppVersion } from '../../services/organizations';

const TopNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const flags = useFlags([FLAG_APP_TURBINE_LMS, FLAG_MAINTENANCE_MODE]);
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  // redux
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);
  const currentEntry = useSelector((state) => state.currentEntry);
  const currentPage = useSelector((state) => state.currentPage);
  // Current User
  const role = currentUser?.role || [];
  const name = currentUser?.name || '';
  const userEmail = currentUser?.email || '';
  const membership = currentUser?.membership || [];
  const profilePicture = currentUser?.profilePicture || '';
  // Organization
  const orgType = organization?.type || '';
  // Route Match
  const isOrgNotificationsRoute = useRouteMatch(NOTIFICATIONS);

  useEffect(() => {
    isDemoModeActive();
  }, []);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading) {
      getFlags();
    }

    if (flags?.app_turbine_lms?.enabled && flags?.app_turbine_lms?.value !== undefined) {
      if (!usingLatestAppVersion(flags?.app_turbine_lms?.value)) {
        dispatch(setCurrentModal({
          key: MODAL_KEY_RELOAD,
          data: {
            appVersion: flags?.app_turbine_lms?.value
          }
        }));
      }
    }

    if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value !== undefined) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_MAINTENANCE_MODE_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY,
        preventClose: true
      }));
    }
  }, [location, getFlags, isLoading, flags?.maintenance_mode, flags?.app_turbine_lms, dispatch]);
  // /FLAGS & REMOTE CONFIG

  const handleLogout = () => {
    logUserOut().then(() => {
      dispatch(logout());
      dispatch(resetUser());
    });
  };

  const openReportIssueModal = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEND_EMAIL,
        data: {
          modalTitle: 'Report Issue',
          name: 'Turbine Workforce Support',
          fromName: name,
          subject: `⚠️ Issue Reported from ${name}`,
          hideSubject: true,
          disableSubject: true,
          replyTo: userEmail,
          notifyTurbineEmail: true,
          notifyTurbineSlack: true
        }
      })
    );
  };

  if (!organization?.id) {
    return;
  }

  if ((!currentUser?.id || !user) && course && !course?.courseReadOnly) {
    return;
  }

  if (location.pathname.includes('/login')) {
    return;
  }

  if (location.pathname.includes('/currentEntry/invite')) {
    return;
  }

  if (location.pathname.includes('/completion-certificate')) {
    return;
  }

  return (
    <nav className="navbar sticky-top bg-white border-bottom flex-md-nowrap p-0">
      <div className="d-flex align-items-center">
        <TopLeftButton />

        <TopLeftCourseInfo />

        {/* TODO change to courses */}
        {document.location.pathname === CLASSES && (
          <div className="d-flex align-items-center">
            <FaObjectGroup className='mr-2 d-none d-md-block' />

            <span className="h5 m-0 font-weight-bold d-none d-sm-block">
              Course Catalog
            </span>
            <span className="h6 m-0 font-weight-bold d-block d-sm-none">
              Courses
            </span>
          </div>
        )}
      </div>

      {currentUser?.id && currentPage !== 'Update Required' && (
        <div className="d-flex align-items-center">
          {orgType === 'workforce' && isActiveMembership(membership) && (
            <TopNavWeather />
          )}

          {!isOrgNotificationsRoute && canAccessTopNavOrgNotifications(role) && isActiveMembership(membership) && (
            <div className="dropdown">
              <button
                className="py-3 pr-2 pl-0 mr-2 topNav-icon-dropdown-btn"
                data-tip={`${course && currentEntry ? 'Course' : ''} Notifications`}
                type="button"
                onClick={() => {
                  dispatch(setCurrentModal({
                    key: MODAL_KEY_NOTIFICATIONS_MENU
                  }));
                }}
              >
                <FaBell />
              </button>
              <ReactTooltip />
            </div>
          )}

          {orgType === 'workforce' && (
            <div className="dropdown">
              <button
                type="button"
                className="py-3 pr-2 pl-0 mr-2 topNav-icon-dropdown-btn"
                onClick={() => {
                  dispatch(
                    setCurrentModal({
                      key: MODAL_KEY_APPS_MENU
                    })
                  );
                }}
                aria-label="Widgets"
                style={{
                  width: '35px'
                }}
              >
                <FaTh size={18} />
              </button>
              <ReactTooltip />
            </div>
          )}

          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <div
                className="top-nav-trigger nav-link dropdown-toggle text-nowrap border-left d-flex align-items-center"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                type="button"
              >
                <span className="user-profile">
                  <img src={profilePicture || DEFAULT_PROFILE_PIC} alt={name} />
                </span>
              </div>
              <div
                className="dropdown-menu my-0 py-0 dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <TopNavUserDetails>
                  <Link
                    className="btn btn-sm btn-white my-2"
                    to={PROFILE}
                    title="My Profile"
                  >
                    My Profile
                  </Link>

                  {isActiveMembership(membership) && canAccessTurbineAdmin(role, orgType) && (
                    <div>
                      <a
                        className="btn btn-sm btn-primary"
                        href={redirectToTurbineAdmin()}
                      >
                        <span className="d-flex align-items-center">
                          Turbine Admin
                        </span>
                      </a>
                    </div>
                  )}
                </TopNavUserDetails>

                {canActivateDemoMode(role) && (
                  <div className="py-2 px-3 border-bottom">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="privateMode"
                        defaultChecked={isDemoModeActive()}
                        onClick={toggleDemoMode}
                      />
                      <label
                        className="custom-control-label text-nowrap"
                        htmlFor="privateMode"
                      >
                        Private Mode
                      </label>
                    </div>
                  </div>
                )}
                <button
                  className="dropdown-item border-bottom py-2"
                  onClick={openReportIssueModal}
                  type="button"
                >
                  <span className='d-flex align-items-center'>
                    <FaLifeRing />
                    <span className='ml-1'>
                      Report Issue
                    </span>
                  </span>
                </button>
                <UserMenuLinks />

                <button
                  className="dropdown-item border-top py-2"
                  onClick={handleLogout}
                  type="button"
                >
                  <i className="fas fa-sign-out-alt text-danger" /> Sign out
                </button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default TopNav;
