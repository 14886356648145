import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Modal from '../../Modal';
import {
  getCurrentUser,
  getDirectoryUser,
  resetCurrentModal
} from '../../../actions';
import {
  reauthenticateUser,
  updateEmailAddressCurrentUser
} from '../../../services/auth';
import { updateUser } from '../../../services/user';

const ChangeEmailCurrentUser = () => {
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /** Redux */
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.currentModal);
  const currentUser = useSelector((state) => state.currentUser);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  /** Control Submit */
  const [loading, setLoading] = useState(false);

  /** Handle Close Modal */
  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  /** Handle Submit Form */
  const handleSubmitForm = async (dataValues) => {
    const { currentPassword, newEmail } = dataValues;
    setLoading(true);
    await reauthenticateUser(currentPassword)
      .then(() => {
        updateEmailAddressCurrentUser(newEmail);
      })
      .then(() => {
        updateUser({
          email: newEmail
        }, currentUser?.id).then(() => {
          dispatch(getCurrentUser({ userId: currentUser?.id }))
            .then(() => {
              setLoading(false);
              handleClose();
              toast.success('Email updated successfully.');
            })
            .then(() => {
              dispatch(
                getDirectoryUser({
                  userId: currentUser?.id,
                  orgId: currentUser?.orgId
                })
              );
            });
        });
      })
      .catch((error) => {
        toast.error(`Please try again Error:${error.message}`);
        setLoading(false);
        handleClose();
      });
  };

  return (
    <Modal
      cssClassName="turbine-modal--style-card"
      visible={currentModal.visible}
      close={false}
      pos="top"
    >
      <div className="card">
        <form>
          <div className="card-header h3 mb-0 text-center">
            <h5 className="m-0">Update Email Address</h5>
          </div>
          <div className="card-content p-3">
            <div className="mb-3">
              <label htmlFor="currentEmail">Current Email</label>
              <div className="mb-2">
                <input
                  className="border-0 w-100 form-control"
                  disabled
                  id="currentEmail"
                  name="currentEmail"
                  value={currentUser.email}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="currentPassword">Current Password</label>
              <div className="mb-2">
                <input
                  className="w-100 form-control"
                  {...register('currentPassword', {
                    required: 'Field is required.'
                  })}
                  type="password"
                  id="currentPassword"
                  disabled={loading}
                />
              </div>
              {errors.currentPassword && (
                <div className="mb-2">
                  <span className="text-danger">
                    {errors.currentPassword?.message}
                  </span>
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="newEmail">New Email</label>
              <div className="mb-2">
                <input
                  className="w-100 form-control"
                  type="email"
                  id="newEmail"
                  {...register('newEmail', {
                    required: 'Field is required.',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Email is invalid.'
                    }
                  })}
                  disabled={loading}
                />
              </div>
              {errors.newEmail && (
                <div className="mb-2">
                  <span className="text-danger">{errors.newEmail.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading}
              onClick={() => handleSubmit(handleSubmitForm)()}
            >
              Save
              {loading && (
                <span
                  className="ml-2 spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
            <button
              type="button"
              className="btn btn-link ml-2"
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangeEmailCurrentUser;
