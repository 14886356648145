import { fetchWidgets } from '../services/widgets';
import { RESET_WIDGETS, SET_WIDGETS } from './types';

export const getWidgets = ({
  orgId, sortByIds, type, isFeatured, ids
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        type,
        ids,
        isFeatured
      };

      if (type === 'link' && sortByIds) {
        config.sortByIds = sortByIds;
      }

      fetchWidgets(config)
        .then((response) => {
          const list = response?.items || [];

          dispatch({
            type: SET_WIDGETS, // type === 'link' ? SET_WIDGET_LINKS : SET_WIDGET_EMBEDS,
            list
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetWidgets = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WIDGETS });
  };
};
