import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { updateUser } from '../../../services/user';
import { redirectToAuth } from '../../../services/auth';
import {
  resetManageMembership,
  setManageMembership,
  setUser
} from '../../../actions';
import Loading from '../../Loading';

class ManageMembership extends Component {
  componentDidMount = () => {
    const { manageMembership, user, currentUser } = this.props;
    const { organization } = this.props;

    if (!manageMembership || !organization) {
      if (user && currentUser) {
        this.props.history.replace(ROUTES.SLASH);
        return;
      }

      redirectToAuth({}); // TODO pass along data needed to manage membership
      return;
    }

    const { userId, membership } = manageMembership;
    const { integration } = organization;
    const data = {
      membership
    };

    if (integration && integration.membership && integration.membership[membership]) {
      data.classIds = integration.membership[membership].classes;
    }

    updateUser(data, userId).then(() => {
      this.props.history.replace(ROUTES.SLASH);
    });
  }

  componentWillUnmount() {
    this.props.resetManageMembership();
  }

  render() {
    const { manageMembership } = this.props;

    if (!manageMembership) {
      redirectToAuth({});
      return;
    }

    return (
      <Loading text="Processing..." />
    );
  }
}

const mapStateToProps = ({
  user,
  currentUser,
  manageMembership,
  organization
}) => ({
  user,
  currentUser,
  manageMembership,
  organization
});

export default withRouter(connect(mapStateToProps, {
  setManageMembership,
  resetManageMembership,
  setUser,
  redirectToAuth
})(ManageMembership));
