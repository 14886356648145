import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { resetCurrentModal } from '../../../actions/Modals';
import { USER } from '../../../constants/icons';
import isPublished from '../../../utils/isPublished';
import Modal from '../../Modal';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import './style.css';

const ChooseUnitCodes = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const course = useSelector((state) => state.currentClass);
  // Local State
  const [loading, setLoading] = useState(false);
  const [selectedUnitCodes, setSelectedUnitCodes] = useState(currentModal.data.selectedUnitCodes || []);
  // Misc
  const classTitle = pathOr('', ['title'], course);
  const eUnitTracking = pathOr(undefined, ['data', 'eUnitTracking'], currentModal);
  const eUnits = pathOr(undefined, ['data', 'eUnits'], currentModal);

  const onSubmit = (e) => {
    e.preventDefault();

    if (selectedUnitCodes.length === 0) {
      toast.error('Select at least one Class Code.');
      return null;
    }

    setLoading(true);

    currentModal.data.callback(selectedUnitCodes);
  };

  const handleOnChange = (e) => {
    const { value } = e.currentTarget;

    const data = [
      ...selectedUnitCodes
    ];

    if (!selectedUnitCodes.includes(value)) {
      data.push(value);
    } else {
      const i = selectedUnitCodes.indexOf(value);

      data.splice(i, 1);
    }

    setSelectedUnitCodes(data);
  };

  return (
    <Modal
      visible={currentModal.visible}
      close={() => {
        dispatch(resetCurrentModal());
      }}
      cssClassName={`modal-${currentModal?.key}`}
      pos="middle"
    >
      <div className="card">
        <form
          onSubmit={onSubmit}
        >
          <div className="card-header h3 mb-0 text-center">
            <h5 className="m-0">
              Attendance Check In
            </h5>
          </div>
          <div className="card-content p-3 text-center">
            <p className="py-3 text-center">
              <b>{classTitle}</b>
            </p>

            <div className="border p-3 mb-3 mx-md-5 text-left">

              <h6>
                <b>Select at least one Class Code:</b>
              </h6>

              {eUnits && eUnits.map((eUnit) => {
                if (!isPublished(eUnit)) return null;

                const {
                  fields: { // TODO cohort flatten
                    code, timeDate, credits
                  }
                } = eUnit;

                return (
                  <div
                    key={code}
                    className="border p-2 mb-1"
                  >
                    <div
                      className="custom-control custom-checkbox mb-2 text-left"
                    >
                      <input
                        id={`eUnitCode-${code}`}
                        name='eUnitCode'
                        value={code}
                        className="custom-control-input"
                        defaultChecked={selectedUnitCodes && selectedUnitCodes.includes(code)}
                        type="checkbox"
                        onChange={handleOnChange}
                        disabled={loading}
                      />
                      <label
                        className="custom-control-label d-flex"
                        htmlFor={`eUnitCode-${code}`}
                      >
                        <span>
                          <span className="d-block">
                            <b>{code}</b>
                          </span>
                          <span className="d-block">
                            {timeDate}
                          </span>
                          {credits && (
                            <Fragment>
                              {credits.number && (
                                <span className="d-block">
                                  Credits: {credits.number}
                                </span>
                              )}
                              {credits.type && (
                                <span className="d-block">
                                  Type: {credits.type}
                                </span>
                              )}
                            </Fragment>
                          )}
                        </span>
                      </label>
                    </div>
                  </div>
                );
              })}

              {isPublished(eUnitTracking) && eUnitTracking.fields.info && ( // TODO cohort flatten
                <div className="pt-2">
                  <h6>
                    <b>Additional Information:</b>
                  </h6>
                  <RenderMarkdown
                    source={eUnitTracking.fields.info}
                    enableNewEditor={eUnitTracking.fields.enableNewEditor}
                  />
                </div>
              )}
            </div>

          </div>
          <div className="card-footer d-flex justify-content-center align-items-center">
            <button
              className="btn btn-lg btn-primary"
              title="Check In"
              type="submit"
              disabled={loading || !selectedUnitCodes.length}
            >
              <i className={USER} /> {loading ? 'Checking In...' : 'Check In'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChooseUnitCodes;
