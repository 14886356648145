import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { CLASSES, DASHBOARD, SLASH } from '../../../constants/routes';
// import { isActiveMembership } from '../../../services/currentUser';
// import OrgLogo from '../../OrgLogo';
import './style.css';

const TopLeftButton = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);
  const currentEntry = useSelector((state) => state.currentEntry);
  // Current User
  // const membership = pathOr([], ['membership'], currentUser);
  // Organization
  const enableCourseCatalog = organization?.enableCourseCatalog || null;
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  const orgType = organization?.type || '';
  const orgLogo = organization?.orgLogo;

  const TEXT_BACK_DASHBOARD = 'Back to Dashboard';
  const TEXT_BACK_COURSE_DASHBOARD = 'Back to Course Dashboard';
  const TEXT_BACK_COURSE_CATALOG = 'Back to Course Catalog';

  const dataTipCourseDashboard = useMemo(() => {
    // Given: User is on the Course Dashboard
    // And: User hovers over top left arrow

    if (course?.id && enableCourseCatalog) {
      // Then: Show "Back to Course Catalog"
      return TEXT_BACK_COURSE_CATALOG;
    }

    // Then: Show "Back to Dashboard"
    return TEXT_BACK_DASHBOARD;
  }, [enableCourseCatalog]);

  const showLogoWithoutLink = useMemo(() => {
    // Given: User is on Dashboard
    if (document.location.pathname.includes(DASHBOARD)) {
      // Then: Show OrgLogo without link
      return true;
    }

    // Given: User is on Course Catalog
    // And: Not Enterprise
    if (
      document.location.pathname.includes(CLASSES)
      && orgType !== 'workforce'
    ) {
      // Then: Show OrgLogo without link
      return true;
    }

    return false;
  }, [orgId, document.location.pathname]);

  const defaultRoute = useMemo(() => {
    // On course and has course catalog
    if (course?.id && enableCourseCatalog) {
      return CLASSES;
    }

    return DASHBOARD;
  }, [document.location.pathname]);

  /**
   * Course : Read Only
   */
  if (
    course?.id
    && !currentEntry?.id
    && !currentUser?.id
    && course?.courseReadOnly
  ) {
    return (
      <div
        className="navbar-brand cursor-none px-3 border-right mr-3"
        style={{ height: '62px' }}
        data-debug="2.2 TopLeftButton"
      >
        <OrgLogo src={orgLogo} alt={orgName} height="40" />
      </div>
    );
  }

  /**
   * Course : Dashboard
   *
   * Given: User is on the Course Dashboard
   */
  if (course?.id && !currentEntry?.id) {
    // Then: Show Left Arrow
    return (
      <>
        <Link
          data-cy="topNav-backToDynamic"
          className="navbar-brand px-3 border-right mr-3"
          style={{ height: '62px' }}
          to={SLASH}
          data-tip={dataTipCourseDashboard}
          data-place="bottom"
          data-debug="4 TopLeftButton"
        >
          <i className="fas fa-arrow-left" style={{ fontSize: '1.25rem' }} />
        </Link>
        <ReactTooltip />
      </>
    );
  }

  // Given: User is in the Course Content
  if (course?.id && currentEntry?.id) {
    // Then: Show Left Arrow
    return (
      <>
        <Link
          data-cy="topNav-backToCourseDashboard"
          className="navbar-brand px-3 border-right mr-3"
          style={{ height: '62px' }}
          to={`${CLASSES}/${course.id}`}
          data-tip={TEXT_BACK_COURSE_DASHBOARD}
          data-place="bottom"
          data-debug="3 TopLeftButton"
        >
          <i className="fas fa-arrow-left" style={{ fontSize: '1.25rem' }} />
        </Link>
        <ReactTooltip />
      </>
    );
  }

  if (showLogoWithoutLink) {
    return (
      <div
        className="navbar-brand cursor-none px-3 border-right mr-3"
        style={{ height: '62px' }}
        data-debug="2 TopLeftButton"
      >
        <OrgLogo src={orgLogo} alt={orgName} height="40" />
      </div>
    );
  }

  return (
    <>
      <Link
        className="navbar-brand px-3 border-right mr-3"
        style={{ height: '62px' }}
        to={defaultRoute}
        data-tip={dataTipCourseDashboard}
        data-place="right"
        data-debug="1 TopLeftButton"
      >
        <OrgLogo src={orgLogo} alt={orgName} height="40" />
      </Link>
      <ReactTooltip />
    </>
  );
};

export default TopLeftButton;
