export const fileType = (type) => {
  switch (type) {
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: 'far fa-file-image'
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: 'far fa-file-pdf'
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: 'far fa-file-excel'
      };
    default:
      return {
        name: 'file',
        icon: 'far fa-file'
      };
  }
};
