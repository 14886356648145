import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../../../services/user';
import { canManageDirectoryUsers } from '../../../services/currentUser';
import {
  getCurrentUser, getDirectoryUser, resetDirectoryUserEditing
} from '../../../actions/Users';
import SearchSelectUsers from '../../ManageContent/SearchSelectedUsers';
import Users from '../../ManageContent/Users';

const ManageSupervisors = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  // Local State
  const [loading, setLoading] = useState(false);
  // Misc
  const orgId = organization?.id || '';
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];

  const handleCancel = () => {
    dispatch(resetDirectoryUserEditing());
  };

  const handleSaveSupervisors = (newSupervisorIds) => {
    setLoading(true);
    toast.info('Saving...');

    const dataToSave = {
      supervisorIds: newSupervisorIds && Array.isArray(newSupervisorIds) && newSupervisorIds.length !== 0 ? newSupervisorIds : null
    };

    updateUser(dataToSave, directoryUser?.id).then(() => {
      toast.success('Supervisors saved!');
      if (directoryUser?.id === userId) {
        dispatch(getCurrentUser({ userId })).then(() => {
          setLoading(false);
          handleCancel();
        });
      }

      if (directoryUser?.id !== userId) {
        dispatch(getDirectoryUser({ orgId, userId: directoryUser?.id })).then(() => {
          setLoading(false);
          handleCancel();
        });
      }
    });
  };

  // View/Edit Supervisors
  if (canManageDirectoryUsers(role)) {
    return (
      <SearchSelectUsers
        currentUserIds={directoryUser?.supervisorIds}
        loading={loading}
        handleCancel={handleCancel}
        callbackPrimaryAction={handleSaveSupervisors}
      />
    );
  }

  // View supervisors, no permissions to edit
  return (
    <div className='card-body'>
      <Users
        key={directoryUser?.supervisorIds.join(',')}
        userIds={directoryUser?.supervisorIds}
      />
    </div>
  );
};

export default ManageSupervisors;
