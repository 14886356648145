import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ATTENDANCE, CALENDAR, CLASSES } from '../../../constants/routes';
import { CALENDAR_ALT, USER_CHECK } from '../../../constants/icons';
import { mdyDate } from '../../../utils/date';
import { canAccessCourseCatalog } from '../../../services/currentUser';

const Attendance = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);

  if (!userLocation || !organization) return null;

  const enableCourseCatalog = pathOr(null, ['enableCourseCatalog'], organization);
  const courseIds = pathOr([], ['courseIds'], currentUser);
  const attendance = pathOr(null, ['attendance'], currentUser);
  const calendar = pathOr(null, ['calendar'], userLocation);

  const renderRows = () => {
    if (!courseIds.length || !attendance) {
      return null;
    }

    return (
      <Fragment>
        {courseIds.map((classId, classIndex) => {
          if (attendance[classId]) {
            const attendanceDescending = attendance[classId].slice().reverse();

            return (
              <Fragment key={classIndex}>
                {attendanceDescending.map((item, i) => (
                  <tr key={`${item.date}-${i}`}>
                    <td className="border-right pr-0">
                      {mdyDate(item.date)}
                    </td>
                    <td className="border-right pr-0">
                      {item.class.title}
                    </td>
                    <td
                      data-demo="disabled"
                      className="border-right pr-0"
                    >
                      {item.instructor ? item.instructor.name : 'Student'}
                    </td>
                  </tr>
                ))}
              </Fragment>
            );
          }

          return null;
        })}
      </Fragment>
    );
  };

  return (
    <div className="card h-100 bg-light">
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          <i className={USER_CHECK} /> Attendance
        </h5>
      </div>

      <div className={`h-100 card-content bg-white ${!attendance ? 'd-flex justify-content-center align-items-center' : ''}`}>
        {!attendance ? (
          <div className="dash-module-no-content">
            <h4>
              {calendar ? (
                <i className={CALENDAR_ALT} />
              ) : (
                <i className={USER_CHECK} />
              )}
            </h4>

            {calendar ? (
              <Fragment>
                <p>
                  Check the calendar for upcoming sessions.
                </p>
                <Link
                  to={CALENDAR}
                  title="Calendar"
                  className="btn btn-primary btn-md"
                >
                  Calendar
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  No attendance records.
                </p>
                {canAccessCourseCatalog({ enableCourseCatalog }) && (
                  <Link
                    to={CLASSES}
                    title="View Courses"
                    className="btn btn-primary btn-md"
                  >
                    View Courses
                  </Link>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <table className="table table-striped m-0">
            <thead>
              <tr>
                <th scope="col" className="border-0">Date</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Classes</th>
                <th scope="col" className='border-0 d-none d-md-table-cell'>Record Taker</th>
              </tr>
            </thead>
            <tbody>
              { renderRows() }
            </tbody>
          </table>
        )}
      </div>

      {attendance && (
        <div className="card-footer p-3 d-flex align-items-center justify-content-center bg-light">
          <Link
            className="btn btn-link p-0"
            to={ATTENDANCE}
            title="View all records"
          >
            View all records
          </Link>
          {/* {currentUser.slackUserId && (
            <Fragment>
              <span className="mr-2">
                See a missing record?
              </span>
              <span>
                <DirectMessageButton
                  name={`${orgName} ${locationName}`}
                  organization={organization}
                  slackUserId={managerSlackUserId}
                  buttonText={`Contact ${orgName} ${locationName}`}
                  className="btn btn-sm btn-link px-0"
                />.
              </span>
            </Fragment>
          )} */}
        </div>
      )}
    </div>
  );
};

export default Attendance;
