import React from 'react';
import CdnFiles from '../../ManageContent/CdnFiles';

const RenderFile = ({
  id,
  value
}) => {
  const fileObj = JSON.parse(value);

  return (
    <CdnFiles
      className="mt-2"
      id={`question-${id}-files`}
      files={[fileObj]}
      header={false}
    />
  );
};

export default RenderFile;
