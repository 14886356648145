import React from 'react';

function contentTypeIcon({ contentType, active }) {
  if (active) {
    return (
      <i className="fas fa-arrow-right text-keppel" />
    );
  }

  if (contentType === 'challenge') {
    return (
      <i className="fas fa-clipboard-list" />
    );
  }

  if (contentType === 'quiz') {
    return (
      <i className="fas fa-list-ol" />
    );
  }

  // TODO: answer approved
  // <i className="fas fa-clock text-info" /> // undefined
  // <i className="fas fa-check-circle text-sucess" /> // true
  // <i className="fas fa-exclamation-circle text-danger" /> // false

  return null;
}

export default contentTypeIcon;
