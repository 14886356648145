import React, { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { fetchOrg } from '../../services/organizations';

const OrgAuthor = ({ orgId, className = '' }) => {
  const [orgName, setOrgName] = useState(null);
  const [orgLogo, setOrgLogo] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (orgId && mounted) {
      fetchOrg({
        orgId,
        select: ['fields.name', 'fields.orgLogo']
      }).then((organization) => {
        const name = pathOr(null, ['name'], organization);
        const logo = pathOr(null, ['orgLogo'], organization);

        if (mounted) {
          setOrgLogo(logo);
          setOrgName(name);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [orgId]);

  if (!orgName) return null;

  // TODO make org name clickable and take user to Org's public course catalog
  return (
    <div className={className}>
      <p
        className="text-muted m-0 d-flex"
        style={{ fontSize: '.9rem' }}
      >
        {/* <i className="fas fa-at" /> {orgName} */}
        <img
          src={orgLogo}
          alt={orgName}
          height="20"
          style={{
            height: '20px'
          }}
        />
        <span className="font-weight-bold ml-2">
          {orgName}
        </span>
      </p>
    </div>
  );
};

export default OrgAuthor;
