import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import {
  addClassToUserClasses, resetCurrentModal, setEnroll
} from '../../../actions';
import { activeCohorts } from '../../../services/courses';
import { CLASSES } from '../../../constants/routes';
import { userReEnrollDataCleanUp } from '../../../services/user';
import Modal from '../../Modal';
import CtaButton from '../../Classes/CtaButton';
import CourseCohorts from './CourseCohorts';
import './style.css';

const EnrollExternalRegisteredClass = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const course = useSelector((state) => state.currentClass);
  const courseCohorts = useSelector((state) => state.courseCohorts);

  // Modal
  const visible = pathOr(null, ['visible'], currentModal);
  const title = pathOr(null, ['data', 'title'], currentModal);
  const dataSelectedCohort = pathOr(null, ['data', 'selectedCohort'], currentModal);
  const classId = pathOr(null, ['data', 'classId'], currentModal);
  // Current User
  const userId = pathOr(null, ['id'], currentUser);
  const courseIds = pathOr([], ['courseIds'], currentUser);
  const completedCourseIds = pathOr([], ['completedCourseIds'], currentUser);
  const completedCourseTopicIds = pathOr([], ['completedCourseTopicIds'], currentUser);
  // Course
  const classTitle = pathOr(null, ['title'], course);
  const registerUrl = pathOr(null, ['registerUrl'], course);
  // local state
  const [loading, setLoading] = useState(false);
  const [selectedCohort, setSelectedCohort] = useState(dataSelectedCohort || null);
  const [selectedEUnitIds, setSelectedEUnitIds] = useState([]);

  const selectedCohortEUnits = pathOr([], ['eUnits'], selectedCohort);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleCourseCohortsChange = (data) => {
    dispatch(setEnroll({
      ...data
    }));

    if (data.selectedCohort !== undefined) {
      setSelectedCohort(data.selectedCohort);
    }

    if (data.eUnitIds !== undefined) {
      setSelectedEUnitIds(data.eUnitIds);
    }
  };

  const enrollUser = (data, id) => {
    dispatch(addClassToUserClasses(data)).then(() => {
      toast.success('Successful Enrollment!');
      history.replace(`${CLASSES}/${id}`); // redirect to class dash
      handleClose(); // Close modal
    });
  };

  const handleOnClick = () => {
    const dataToSave = {
      classId,
      extRegClassId: classId,
      userId
    };

    // Cohorts & Education Units
    if (courseCohorts) {
      if (!selectedCohort) {
        toast.error('Select a Cohort');
        return false;
      }

      // Include Cohort Id
      dataToSave.cohortId = selectedCohort.id;

      if (selectedCohort.eUnits && !selectedEUnitIds.length) { // TODO cohort flatten
        toast.error('Select at least one Class Code');
        return false;
      }

      // Include Education Units
      if (selectedEUnitIds.length) {
        dataToSave.eUnitIds = selectedEUnitIds;
      }
    }

    setLoading(true);

    // Check if user has enrolled in course before
    if (dataToSave.cohortId && courseIds.length && courseIds.includes(classId)) {
      // User has enrolled before
      userReEnrollDataCleanUp({
        data: dataToSave, completedCourseIds, completedCourseTopicIds
      }).then((data) => {
        toast.info('Enrolling...');
        // Add class to user "courseIds" & "externalRegisteredCourseIds"
        enrollUser(data, classId);
      });
    } else {
      // First time enrolling
      toast.info('Enrolling...');
      // Add class to user "courseIds" & "externalRegisteredCourseIds"
      enrollUser(dataToSave, classId);
    }
  };

  if (!visible || !currentUser) {
    return null;
  }

  const activeCourseCohorts = activeCohorts(courseCohorts); // TODO may not be needed getCohorts only pulls active ones

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      cssClassName={`modal-${currentModal?.key}`}
      pos="middle"
    >
      <div className="card">
        <div className="card-header h3 mb-0 text-center">
          <h5 className="m-0">
            {title}
          </h5>
        </div>
        <div className="card-body">
          <p className="text-center">
            Enroll in <b>{classTitle}</b>
          </p>

          {activeCourseCohorts && (
            <CourseCohorts
              classTitle={classTitle}
              courseCohorts={activeCourseCohorts}
              existingSelectedCohort={selectedCohort}
              currentUser={currentUser}
              onChange={handleCourseCohortsChange}
            />
          )}

          {activeCourseCohorts && selectedCohort && (
            <div className="mt-3">
              {registerUrl ? (
                <button
                  className="btn btn-md btn-primary"
                  type="submit"
                  onClick={handleOnClick}
                  disabled={loading || (selectedCohortEUnits.length && !selectedEUnitIds.length)}
                >
                  {loading ? 'Enrolling...' : 'Enroll'}
                </button>
              ) : (
                <CtaButton
                  className="btn-md"
                  cohortId={selectedCohort && selectedCohort.id}
                  key={selectedCohort}
                  disabled={loading || !selectedCohort || (selectedCohortEUnits.length && !selectedEUnitIds.length)}
                />
              )}
            </div>
          )}

          {/* If class has external register and no courseCohorts */}
          {!activeCourseCohorts && (
            <div className="mt-3 text-center">
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                onClick={handleOnClick}
                disabled={loading}
              >
                {loading ? 'Enrolling...' : 'Enroll'}
              </button>
            </div>
          )}
        </div>

        {registerUrl && (
          <div className="card-footer text-muted">
            <i className="fas fa-exclamation-triangle text-warning" /> Once enrolled, an administrator will verify your registration before you can attend class and earn credits.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EnrollExternalRegisteredClass;
