import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, propEq } from 'ramda';
import { toast } from 'react-toastify';
import { sortArrByArr } from '@apprentage/utils';
import { resetCurrentModal } from '../../../actions';
import { fetchUsers } from '../../../services/users';
import Modal from '../../Modal';
import NameBlock from '../../Directory/UserDetails/NameBlock';

const SearchSelectUsers = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = organization?.id || '';
  // Modal
  const title = currentModal?.data?.title || 'Search Users';
  const currentUserIds = currentModal?.data?.currentUserIds || [];
  const callbackPrimaryAction = currentModal?.callbackPrimaryAction || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (currentUserIds && Array.isArray(currentUserIds) && currentUserIds.length !== 0) {
      fetchUsers({
        userIds: currentUserIds,
        orgId,
        select: ['id', 'name', 'title', 'profilePicture']
      }).then((response) => {
        const whichUsers = sortArrByArr(response?.items, currentUserIds, 'id');

        setSelectedUsers(whichUsers);
      });
    }
  }, [currentUserIds, orgId]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleFocus = (e) => {
    e.preventDefault();

    if (!users) {
      setUsers([]);
    }
  };

  const handleSearchUsers = (e) => {
    e.preventDefault();
    const { userName } = e.currentTarget;

    userName.blur();

    if (userName.value === '') {
      toast.info('Please try again');

      return setUsers(null);
    }

    setSearchValue(userName.value);

    fetchUsers({
      searchValue: userName.value,
      orgId,
      select: ['id', 'name', 'title', 'profilePicture']
    }).then((response) => {
      setUsers(response?.items);
    });
  };

  const selectUser = (user) => {
    const newSelectedUsers = [
      ...(selectedUsers || []),
      user
    ];
    const userIndex = findIndex(propEq('id', user.id))(users);
    const updatedUsers = users.slice();

    updatedUsers.splice(userIndex, 1);

    setUsers(updatedUsers);
    const userExists = selectedUsers.find((u) => u.id === user.id);

    if (!userExists) {
      setSelectedUsers(newSelectedUsers);
    }
  };

  const removeUser = (user) => {
    const userIndex = findIndex(propEq('id', user.id))(selectedUsers);
    const updatedSelectedUsers = selectedUsers.slice();

    updatedSelectedUsers.splice(userIndex, 1);

    setSelectedUsers(updatedSelectedUsers);
  };

  const saveUsers = () => {
    setLoading(true);

    const selectedUserIds = selectedUsers.map((user) => user.id);

    callbackPrimaryAction(selectedUserIds);
    handleClose();
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={false}
      pos="top"
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">
            {title}
          </h1>
        </div>
        <div className="card-body">
          {selectedUsers && selectedUsers.length !== 0 && (
            <>
              <div className="mt-3 mb-2">
                <div className="h6 font-weight-bold">
                  <i className="fas fa-check text-keppel" /> Selected:
                </div>
              </div>
              {selectedUsers.map((user) => (
                <div
                  key={`searchUsers-selected-user-${user.id}`}
                  className="border p-2 d-flex align-items-center justify-content-between mb-1 position-relative"
                >
                  <NameBlock
                    data={user}
                    className=""
                    pictureWidth="60"
                    pictureHeight="60"
                  />
                  <div className="p-2">
                    <button
                      title="Remove user"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        removeUser(user);
                      }}
                      type="button"
                    >
                      <span className="d-none d-sm-block">
                        Remove
                      </span>
                      <i
                        className="fas fa-times d-block d-sm-none"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          <form
            onSubmit={handleSearchUsers}
            className="mt-3"
          >
            <div className="input-group input-group-md">
              <input
                type="text"
                className="form-control mr-2"
                name="userName"
                defaultValue={searchValue}
                placeholder=""
                autoFocus
                onFocus={handleFocus}
              />
              <button
                type="submit"
                className="btn btn-primary btn-md"
              >
                Search
              </button>
            </div>
          </form>

          <div className="text-muted mt-1">
            Search Users by Name
          </div>
        </div>
        <div className="card-body pt-0">
          {!users && (
            <div className="bg-light p-2">
              No results.
            </div>
          )}
          {users && users.length !== 0 && (
            <>
              <div className="mb-2">
                <div className="font-weight-bold">
                  Users:
                </div>
              </div>
              {users.map((user) => (
                <div
                  key={`searchUsers-user-${user.id}`}
                  className="border p-2 d-flex align-items-center justify-content-between mb-1"
                >
                  <NameBlock
                    data={user}
                    className=""
                    pictureWidth="60"
                    pictureHeight="60"
                  />
                  <div className="p-2">
                    <button
                      title="Select user"
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => {
                        selectUser(user);
                      }}
                      type="button"
                    >
                      <span className="d-none d-sm-block">
                        Select
                      </span>
                      <i
                        className="fas fa-check d-block d-sm-none"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="card-footer d-flex align-items-center">
          <button
            onClick={saveUsers}
            type="button"
            className="btn btn-primary btn-md mr-2"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-link"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SearchSelectUsers;
