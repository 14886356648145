import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { findIndex, propEq } from 'ramda';
import { toast } from 'react-toastify';
import { sortArrByArr } from '@apprentage/utils';
import { fetchUsers } from '../../services/users';
import NameBlock from '../Directory/UserDetails/NameBlock';

const SearchSelectUsers = ({
  callbackPrimaryAction,
  handleCancel,
  currentUserIds
  // loading
}) => {
  const organization = useSelector((state) => state.organization);
  // local state
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // Organization
  const orgId = organization?.id || '';

  useEffect(() => {
    if (currentUserIds && Array.isArray(currentUserIds) && currentUserIds.length !== 0) {
      fetchUsers({
        userIds: currentUserIds,
        orgId,
        select: ['id', 'name', 'title', 'profilePicture']
      }).then((response) => {
        const whichUsers = sortArrByArr(response?.items, currentUserIds, 'id');

        setSelectedUsers(whichUsers);
      });
    }
  }, [currentUserIds, orgId]);

  const handleFocus = (e) => {
    e.preventDefault();

    if (!users) {
      setUsers([]);
    }
  };

  const handleSearchUsers = (e) => {
    e.preventDefault();
    const { searchValueInput } = e.currentTarget;

    searchValueInput.blur();

    if (searchValueInput.value === '') {
      toast.info('Please try again');

      return setUsers(null);
    }

    setSearchValue(searchValueInput.value);

    fetchUsers({
      searchValue: searchValueInput.value,
      orgId,
      select: ['id', 'name', 'title', 'profilePicture']
    }).then((response) => {
      setUsers(response?.items);
    });
  };

  const selectUser = (user) => {
    const newSelectedUsers = [
      ...(selectedUsers || []),
      user
    ];
    const userIndex = findIndex(propEq('id', user.id))(users);
    const updatedUsers = users.slice();

    updatedUsers.splice(userIndex, 1);

    setUsers(updatedUsers);
    const userExists = selectedUsers.find((u) => u.id === user.id);

    if (!userExists) {
      setSelectedUsers(newSelectedUsers);
    }
  };

  const removeUser = (user) => {
    const userIndex = findIndex(propEq('id', user.id))(selectedUsers);
    const updatedSelectedUsers = selectedUsers.slice();

    updatedSelectedUsers.splice(userIndex, 1);

    setSelectedUsers(updatedSelectedUsers);
  };

  const saveUsers = () => {
    setLoading(true);

    const selectedUserIds = selectedUsers.map((user) => user.id);

    callbackPrimaryAction(selectedUserIds);
    handleCancel();
  };

  return (
    <div>
      <div className='card-body'>
        {selectedUsers && selectedUsers.length !== 0 && (
          <>
            <div className="mt-3 mb-2">
              <div className="h6 font-weight-bold">
                <i className="fas fa-check text-keppel" /> Selected:
              </div>
            </div>
            {selectedUsers.map((user) => (
              <div
                key={`searchUsers-selected-user-${user.id}`}
                className="border p-2 d-flex align-items-center justify-content-between mb-1 position-relative"
              >
                <NameBlock
                  data={user}
                  className=""
                  pictureWidth="60"
                  pictureHeight="60"
                />
                <div className="p-2">
                  <button
                    title="Remove user"
                    className="btn btn-sm btn-outline-secondary"
                    type="button"
                    onClick={() => {
                      removeUser(user);
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Remove
                    </span>
                    <i
                      className="fas fa-times d-block d-sm-none"
                    />
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <form
          onSubmit={handleSearchUsers}
          className="mt-3"
        >
          <div className="input-group input-group-md">
            <input
              type="text"
              className="form-control mr-2"
              name="searchValueInput"
              defaultValue={searchValue}
              placeholder=""
              autoFocus
              onFocus={handleFocus}
            />
            <button
              type="submit"
              className="btn btn-primary btn-md"
            >
              Search
            </button>
          </div>
        </form>

        <div className="text-muted mt-1">
          Search Users by Name
        </div>
      </div>

      <div className="card-body pt-0">
        {!users && (
          <div className="bg-light p-2">
            No results.
          </div>
        )}
        {users && users.length !== 0 && (
          <>
            <div className="mb-2">
              <div className="font-weight-bold">
                Users:
              </div>
            </div>
            {users.map((user) => (
              <div
                key={`searchUsers-user-${user.id}`}
                className="border p-2 d-flex align-items-center justify-content-between mb-1"
              >
                <NameBlock
                  data={user}
                  className=""
                  pictureWidth="60"
                  pictureHeight="60"
                />
                <div className="p-2">
                  <button
                    title="Select user"
                    className="btn btn-sm btn-outline-primary"
                    type="button"
                    onClick={() => {
                      selectUser(user);
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Select
                    </span>
                    <i
                      className="fas fa-check d-block d-sm-none"
                    />
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="card-footer d-flex align-items-center">
        <button
          onClick={saveUsers}
          className="btn btn-primary btn-md mr-2"
          disabled={loading}
          type="button"
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
        <button
          onClick={handleCancel}
          className="btn btn-link"
          disabled={loading}
          type="button"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SearchSelectUsers;
