import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import $ from 'jquery';
import { canManageContent } from '../../../../services/currentUser';
import RenderMarkdown from '../../../ManageContent/RenderMarkdown';
import isPublished from '../../../../utils/isPublished';
// import AdminMenu from '../../../AdminMenu';
import Alert from '../../../Alert';
import './style.css';

const Topics = ({ currentClassRoute }) => {
  // redux
  const course = useSelector((state) => state.currentClass);
  const currentUser = useSelector((state) => state.currentUser);
  // Course
  const subjects = pathOr([], ['subjects'], course); // TODO course flatten

  useEffect(() => {
    $(document).on('click', '.admin-menu--class-dashboard-topics .dropdown-collapse-container', (e) => {
      e.stopPropagation();
    });
  });

  if (!course?.id) {
    return;
  }

  if (!course.courseReadOnly && !currentUser?.id) {
    return;
  }

  const {
    // id: classId,
    enableNewEditor
    // title: classTitle,
  } = course;

  let hasFeaturedTopics = false;
  // let hasTopicDescriptions = false;

  // Check for featured Sections
  if (subjects.length > 0) {
    subjects.forEach((subject) => {
      // TODO course flatten (use course.topicIds and sort topics order)
      if (isPublished(subject)) {
        if (subject.fields.featured) hasFeaturedTopics = true;
        // if (subject.fields.description) hasTopicDescriptions = true;
      }
    });
  }

  return (
    <div className="col-md-12 mt-3 px-0">
      <div className="card h-100">
        <div className="card-header bg-light p-3">
          <h5 className="m-0">Sections</h5>
        </div>
        <div className="card-content h-100">

          {subjects && course && !currentClassRoute && canManageContent({ currentUser, course }) && (
            <div className="m-3">
              <Alert type="danger" icon="fas fa-exclamation-triangle">
                <div>
                  The first Topic in a Class must contain one Material, Challenge or Quiz.
                </div>
                <ul className="mt-2 mb-3 pl-4">
                  <li>
                    <span className="mr-2">
                      {/* Click here to fix: */} Click Class Console Button.
                    </span>
                  </li>
                </ul>
                <hr className="my-1 mx-0" />
                <div className="small font-weight-bold">
                  <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
                </div>
              </Alert>
            </div>
          )}

          {!subjects && canManageContent({ currentUser, course }) && (
            <div className="m-3">
              <Alert type="danger" icon="fas fa-exclamation-triangle">
                <div>
                  Classes require at least one Topic.
                </div>
                <ul className="mt-2 mb-3 pl-4">
                  <li>
                    <span className="mr-2">
                      {/* Click here to fix: */} Click Class Console Button.
                    </span>
                  </li>
                </ul>
                <hr className="my-1 mx-0" />
                <div className="small font-weight-bold">
                  <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
                </div>
              </Alert>
            </div>
          )}

          {subjects && (
            <ul className="class-dashboard-topics list-group m-0">
              {subjects.map((subject, i) => {
                if (!isPublished(subject)) return null;

                const {
                  fields: { // TODO course flatten (use topics and sort by course.topicIds)
                    featured,
                    title: topicTitle,
                    description: topicDescription
                  }
                } = subject;
                // Class has at least 1 featured Topic, this topic is not featured
                if (!featured && hasFeaturedTopics) return null;

                // Class has no featured Sections, show the first 4 Sections.
                if (!hasFeaturedTopics && i > 3) return null;

                return (
                  <li
                    key={i}
                    className="list-group-item border-left-0 border-right-0 d-flex justify-content-between lh-condensed"
                  >
                    <div>
                      <h5 className="my-0">
                        { topicTitle }
                      </h5>
                      {topicDescription && (
                        <div className="text-muted pt-2">
                          <RenderMarkdown
                            source={topicDescription}
                            enableNewEditor={enableNewEditor}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* {subjects && !hasFeaturedTopics && canManageContent({ currentUser, course }) && (
          <div className="p-3">
            <Alert type="warning" icon="fas fa-info-circle">
              <div>
                Control which Sections appear here by <b>featuring</b> Sections that best illustrate what will be learned in this Class.
              </div>
              <ul className="mt-2 mb-3 pl-4">
                <li>
                  Currently displaying the first 4 Sections.
                </li>
              </ul>
              <hr className="my-1 mx-0" />
              <div className="small font-weight-bold">
                <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
              </div>
            </Alert>
          </div>
        )}

        {subjects && hasFeaturedTopics && !hasTopicDescriptions && canManageContent({ currentUser, course }) && (
          <div className="p-3">
            <Alert type="warning" icon="fas fa-info-circle">
              <div>
                Consider adding Topic <b>descriptions</b> to each featured Topic.
              </div>
              <hr className="mt-3 mb-1 mx-0" />
              <div className="small font-weight-bold">
                <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
              </div>
            </Alert>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Topics;
