import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NO_NOTIFICATIONS } from '@apprentage/constants';
import NotificationsList from './index';
import {
  resetNotificationsCourse, resetNotificationsOrg, resetPinnedNotificationsCourse, resetPinnedNotificationsOrg
} from '../../actions/Notifications';

const PinnedAndUnpinnedNotifications = ({
  className,
  listConfig,
  entity = '',
  showUserRoles = false,
  orgId
}) => {
  const dispatch = useDispatch();
  const notificationsOrgList = useSelector(
    (state) => state.notificationsOrgList
  );
  const notificationsCourseList = useSelector(
    (state) => state.notificationsCourseList
  );
  const pinnedCourseNotificiatonList = useSelector(
    (state) => state?.pinnedCourseNotificationsList
  );
  const pinnedOrgNotificationList = useSelector(
    (state) => state?.pinnedOrgNotificationsList
  );

  const hasNotifications = !(
    (listConfig?.classId
      && pinnedCourseNotificiatonList?.length === 0
      && notificationsCourseList?.length === 0)
    || (!listConfig?.classId
      && notificationsOrgList?.length === 0
      && pinnedOrgNotificationList?.length === 0)
  );

  const pinnedListConfig = useMemo(() => {
    return { ...listConfig, includePinned: true };
  }, [listConfig]);

  useEffect(() => {
    return () => {
      dispatch(resetNotificationsOrg());
      dispatch(resetNotificationsCourse());
      dispatch(resetPinnedNotificationsCourse());
      dispatch(resetPinnedNotificationsOrg());
    };
  }, []);

  return (
    <div className={className}>
      {!hasNotifications && (
        <div
          className="d-flex flex-column justify-content-center align-items-center mx-auto"
          style={{
            padding: '1rem 0'
          }}
        >
          <img
            src={NO_NOTIFICATIONS}
            width="100"
            className="my-3"
            alt="No notifications"
          />
          <p className="m-0">No Notifications</p>
        </div>
      )}
      {orgId && (
        <>
          <NotificationsList
            listConfig={pinnedListConfig}
            entity={entity}
            showUserRoles={showUserRoles}
            showNotificationError={false}
          />
          <NotificationsList
            listConfig={listConfig}
            entity={entity}
            showUserRoles={showUserRoles}
            showNotificationError={false}
          />
        </>
      )}
    </div>
  );
};

export default PinnedAndUnpinnedNotifications;
