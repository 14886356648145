import React, { Component } from 'react';
import './style.css';

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    const { minutes } = props;

    this.state = {
      minutes: minutes || 3,
      seconds: 0
    };
  }

  componentDidMount() {
    const { onTimeExpired } = this.props;

    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState?.seconds - 1
        }));
      }

      if (seconds === 0) {
        if (minutes === 0) {
          window.clearInterval(this.myInterval);
          if (onTimeExpired) {
            onTimeExpired();
          }
        } else {
          this.setState((prevState) => ({
            minutes: prevState?.minutes - 1,
            seconds: 59
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.myInterval);
  }

  render() {
    const { text, mobileText } = this.props;
    const { minutes, seconds } = this.state;

    return (
      <div className="countdown-timer-container">
        <span className="countdown-timer-text mr-2 d-none d-sm-inline">
          {text || 'Time Remaining:'}
        </span>
        <span className="countdown-timer-text mr-2 d-inline d-sm-none">
          {mobileText || 'Time Remaining:'}
        </span>
        <span className="countdown-timer-time bg-secondary py-1 px-2 text-white rounded">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </div>
    );
  }
}

export default CountdownTimer;
