import { client, flattenItems } from '../services/contentful';
import {
  SET_CURRENT_CLASS_CHALLENGES,
  RESET_CURRENT_CLASS_CHALLENGES
} from './types';

export const setCurrentClassChallenges = ({ classId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'challenge',
      'fields.classId': classId
    };

    return client.getEntries(config).then(({ items }) => {
      const currentClassChallenges = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_CLASS_CHALLENGES,
        currentClassChallenges
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentClassChallenges = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_CHALLENGES });
  };
};
