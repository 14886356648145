import React, { PureComponent } from 'react';
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { coy } from "react-syntax-highlighter/dist/styles/prism";

class CodeBlock extends PureComponent {
  // static defaultProps = {
  //   language: null
  // };

  componentDidMount() {
    window.Prism.highlightAll();
  }

  render() {
    const { language, value } = this.props;

    return (
      <pre>
        <code className={`language-${language}`}>
          { value }
        </code>
      </pre>
    );

    // <SyntaxHighlighter language={language} style={coy}>
    //     {value}
    //   </SyntaxHighlighter>
  }
}

export default CodeBlock;
