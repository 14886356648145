import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'; /* eslint-disable-line */
import Popper from 'popper.js'; /* eslint-disable-line */
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import TopNav from '../TopNav';
import Login from '../auth/Login';
import CourseInvitation from '../auth/CourseInvitation';
import ApprenticeshipInvitation from '../auth/ApprenticeshipInvitation';
import SSO from '../auth/SSO';
import ManageMembership from '../auth/ManageMembership';
import Subscribe from '../auth/Subscribe';
import Enroll from '../auth/Enroll';
import Dashboard from '../Dashboard';
import Attendance from '../Attendance';
import Certificate from '../Certificate';
import CompletionCertificate from '../CompletionCertificate';
import Calendar from '../Calendar';
import Billing from '../Billing';
import Profile from '../Profile';
import Classes from '../Classes';
import Class from '../Class';
import ClassDashboard from '../Class/Dashboard';
import Toasts from '../Toasts';
import Modals from '../Modals';
import Notifications from '../Notifications';
import Slash from '../Slash';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/index.css';
import './style.css';

const App = () => {
  return (
    <Router>
      <Fragment>
        <TopNav />
        <div className="container-fluid">
          <Switch>
            <Route
              exact
              path={ROUTES.SSO}
              component={SSO}
            />
            <Route
              exact
              path={ROUTES.LOGIN_ORG}
              component={Login}
            />
            <Route
              path={ROUTES.LOGIN}
              component={Login}
            />
            <Route
              path={ROUTES.COURSE_INVITATION}
              component={CourseInvitation}
            />
            <Route
              path={ROUTES.APPRENTICESHIP_INVITATION}
              component={ApprenticeshipInvitation}
            />
            <Route
              path={ROUTES.SUBSCRIBE}
              component={Subscribe}
            />
            <Route
              path={ROUTES.MANAGE_MEMBERSHIP}
              component={ManageMembership}
            />
            <Route
              path={ROUTES.ENROLL}
              component={Enroll}
            />
            <Route
              path={ROUTES.CALENDAR}
              component={Calendar}
            />
            <Route
              path={ROUTES.ATTENDANCE}
              component={Attendance}
            />
            <Route
              path={ROUTES.CERTIFICATE_CLASS}
              component={Certificate}
            />
            <Route
              path={ROUTES.COMPLETION_CERTIFICATE_ID}
              component={CompletionCertificate}
            />
            <Route
              path={ROUTES.COMPLETION_CERTIFICATE_ID_LEGACY}
              component={CompletionCertificate}
            />
            <Route
              path={ROUTES.BILLING}
              component={Billing}
            />
            <Route // TODO REMOVE (moved to admin.turbine.is/org/users)
              path={ROUTES.DIRECTORY_USER}
              component={Slash}
            />
            <Route // TODO REMOVE (moved to admin.turbine.is/org/users)
              path={ROUTES.DIRECTORY}
              component={Slash}
            />
            <Route
              exact
              path={ROUTES.NOTIFICATIONS}
              component={Notifications}
            />
            <Route
              path={ROUTES.PROFILE}
              component={Profile}
            />
            <Route
              path={ROUTES.CLASS_CURRICULUM}
              component={Class}
            />
            <Route
              path={ROUTES.CLASS_DASHBOARD}
              component={ClassDashboard}
            />
            <Route
              path={ROUTES.CLASSES}
              component={Classes}
            />
            <Route
              path={ROUTES.DASHBOARD}
              component={Dashboard}
            />
            <Route
              exact
              path={ROUTES.SLASH}
              component={Slash}
            />
          </Switch>
        </div>
        <Toasts />
        <Modals />
        <ToastContainer
          className="text-center"
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        />
      </Fragment>
    </Router>
  );
};

export default withAuthentication(App);
