import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ERROR_INVITE_MISSING_ID, IMAGE_MAINTENANCE } from '@apprentage/constants';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import {
  APPRENTICESHIP_INVITATION,
  COURSE_INVITATION,
  SLASH
} from '../../../constants/routes';
import { redirectToAuth } from '../../../services/auth';
import { setEnroll } from '../../../actions/Courses'; // TODO move setEnroll
import { setCurrentClass } from '../../../actions/Class'; // TODO move setEnroll
import { getOrg } from '../../../actions/Organizations';
import { getApprenticeship } from '../../../actions/Apprenticeships';
import Loading from '../../Loading';
import {
  FLAG_MAINTENANCE_MODE,
  FLAG_USERS_READONLY,
  NOTICE_ACCOUNT_CREATION_BODY,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import ConfirmationToast from '../../Toasts/ConfirmationToast';

const Login = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY, FLAG_MAINTENANCE_MODE]);
  const flagsStatus = useFlagsmithLoading();
  // Redux
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.currentUser);
  // Local State
  const [loading, setLoading] = useState(true);
  // Params
  const organizationSlug = pathOr(false, ['organizationSlug'], params);
  // Search Params
  const searchParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const action = pathOr(null, ['action'], searchParams);
  const classId = pathOr(null, ['classId'], searchParams);
  const inviteId = pathOr(null, ['inviteId'], searchParams);
  const apprenticeshipId = pathOr(null, ['apprenticeshipId'], searchParams);
  const userEmail = pathOr(null, ['userEmail'], searchParams);
  const wc = pathOr(null, ['wc'], searchParams);
  const io = pathOr(null, ['io'], searchParams);
  const pcp = pathOr(null, ['pcp'], searchParams);
  const canChange = pathOr(null, ['canChange'], searchParams);
  const canChangeEmail = pathOr(null, ['canChangeEmail'], searchParams);
  const canChangePhone = pathOr(null, ['canChangePhone'], searchParams);
  const canChangeName = pathOr(null, ['canChangeName'], searchParams);
  const canChangeLocation = pathOr(null, ['canChangeLocation'], searchParams);
  const hideLocation = pathOr(null, ['hideLocation'], searchParams);
  // Local State
  const [showMaintenanceToast, setShowMaintenanceToast] = useState(false);
  const [showUsersReadonlyToast, setShowUsersReadonlyToast] = useState(false);

  useEffect(() => {
    if (!organizationSlug) {
      // User has entered in the generic login link
      // Ex (local): http://localhost:3000/login
      // Ex (prod): https://app.turbinelms.com/login
      redirectToAuth({});
    }

    dispatch(
      getOrg({
        slug: organizationSlug
      })
    ).then(({ organization }) => {
      if (!flagsStatus?.isFetching) {
        if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
          setShowMaintenanceToast(true);
        } else if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
          setShowUsersReadonlyToast(true);
        } else if (action !== 'signup') {
          // User has entered in the standard login link for an Org
          // Redirect to Org Login
          redirectToAuth({
            organizationSlug
          });
          return;
        }
      }

      const dataToSave = {
        ...searchParams,
        orgId: organization?.id,
        orgName: organization?.name,
        orgLogo: organization?.orgLogo,
        orgSlug: organization?.slug
      };

      if (userEmail) {
        dataToSave.userEmail = userEmail.trim().replaceAll(' ', '+');
      }

      /**
       * Enrollment Data
       *
       * Learn more: https://github.com/apprentage/docs-internal/blob/main/AUTH.md#enrollment-data
       */

      // Set defaults & convert strings to booleans
      dataToSave.wc = wc === 'true'; // Waive Costs for enrollment fees
      dataToSave.io = io === 'true'; // Invitation Only (inviteOnly)
      dataToSave.pcp = pcp === 'true'; // Public Course Page - https://github.com/apprentage/docs-internal/blob/main/CONSOLE.md#public-course-pages
      dataToSave.canChange = canChange === 'true'; // Change alter the provided values (userName, userEmail, userPhone)
      dataToSave.canChangeEmail = canChangeEmail === 'true';
      dataToSave.canChangePhone = canChangePhone === 'true';
      dataToSave.canChangeName = canChangeName === 'true';
      dataToSave.canChangeLocation = canChangeLocation === 'true';
      dataToSave.hideLocation = hideLocation === 'true';

      // Prevent Legacy Apprenticeship Invitations from working
      if (apprenticeshipId && !inviteId) {
        redirectToAuth({
          organizationSlug,
          error: {
            code: ERROR_INVITE_MISSING_ID
          }
        });
      }

      if (!flagsStatus?.isFetching) {
        if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
          setShowMaintenanceToast(true);
        } else if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
          setShowUsersReadonlyToast(true);
        } else if (classId) {
          // Course Invitation
          dispatch(setEnroll(dataToSave)).then(() => {
            dispatch(setCurrentClass({ classId })).then(() => {
              // TODO set currentCohort?
              setLoading(false);
            });
          });
        } else if (apprenticeshipId) {
          // Apprenticeship Invitation
          dispatch(getApprenticeship(apprenticeshipId)).then((response) => {
            dispatch(
              setEnroll({
                ...dataToSave,
                ...(response?.courseIds ? { classIds: response.courseIds } : {})
              })
            ).then(() => {
              setLoading(false);
            });
          });
        } else {
          // Organization Invitation
          dispatch(setEnroll(dataToSave)).then((enroll) => {
            redirectToAuth({
              enroll,
              organizationSlug
            });
          });
        }
      }
    });
  }, [flagsStatus.isFetching, flags?.maintenance_mode, flags?.users_readonly]);

  if (showMaintenanceToast) {
    return (
      <ConfirmationToast
        title={NOTICE_MAINTENANCE_MODE_TITLE}
        subtitle={NOTICE_MAINTENANCE_MODE_BODY}
        image={IMAGE_MAINTENANCE}
      />

    );
  }

  if (showUsersReadonlyToast) {
    return (
      <ConfirmationToast
        title={NOTICE_USERS_READONLY_TITLE}
        subtitle={NOTICE_ACCOUNT_CREATION_BODY}
        image={IMAGE_MAINTENANCE}
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (action === 'signup' && classId && organizationSlug) {
    return <Redirect to={COURSE_INVITATION} />;
  }

  if (action === 'signup' && apprenticeshipId && organizationSlug) {
    return <Redirect to={APPRENTICESHIP_INVITATION} />;
  }

  if (user && currentUser) {
    return <Redirect to={SLASH} />;
  }

  return <Loading />;
};

export default Login;
