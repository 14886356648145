import React from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { locationTerminology } from '../../../services/locations';
import Supervisors from './Supervisors';

const WorkBlock = () => {
  // Local State
  const organization = useSelector((state) => state.organization);
  const orgGroups = useSelector((state) => state.groups);
  const directoryUser = useSelector((state) => state.directoryUser);
  const locationsHash = useSelector((state) => state.locationsHash);
  // Organization
  const orgType = organization?.type || '';
  // Directory User
  const supervisorIds = directoryUser?.supervisorIds || null;
  const groupIds = directoryUser?.groupIds || null;
  const locationId = directoryUser?.locationId || null;
  const directoryUserLocation = locationsHash[locationId];
  const locationName = directoryUserLocation?.name || null;
  // TODO create groupsHash like locationsHash
  const userGroups = orgGroups && groupIds && orgGroups.filter(((g) => groupIds.includes(g.id) === true));

  return (
    <div className="d-flex justify-content-center text-center mb-4">
      <div className="border-bottom pb-4">
        <div className="h6 font-weight-bold mb-3">
          Work Information
        </div>

        {userGroups && (
          <div className="contact-row">
            <div className="contact-label mr-2">
              Department{userGroups && userGroups.length > 1 ? 's' : ''}:
            </div>
            {userGroups && userGroups.map((g) => (
              <div key={`userGroups-group-${g.id}`} data-demo="disabled">
                {g.title}
              </div>
            ))}
          </div>
        )}

        {supervisorIds && (
          <div className="contact-row">
            <div className="contact-label mr-2">
              {pluralize('Supervisor', Array.isArray(supervisorIds) ? supervisorIds.length : 0)}:
            </div>
            <div data-demo="disabled">
              <Supervisors
                userIds={supervisorIds}
              />
            </div>
          </div>
        )}

        {locationName && ( // TODO make locationName clickable
          <div className="contact-row">
            <div className="contact-label mr-2">
              {locationTerminology(orgType).text}:
            </div>
            <div data-demo="disabled">
              {locationName}
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default WorkBlock;
