import { TURBINE_ADMIN } from '../constants/urls';
import removeKeysWithUndefinedFromObject from '../utils/removeKeysWithUndefinedFromObject';

export const redirectToAdmin = ({ pathname, data }) => {
  const paramsObj = {
    ...removeKeysWithUndefinedFromObject(data), // manage entry route state
    redirectUrl: window.location.href,
    provider: 'turbine',
    token: window.localStorage.getItem('turbine_token')
  };

  const redirectUrlSearchParams = new URLSearchParams(paramsObj);

  return `${TURBINE_ADMIN + pathname}?${redirectUrlSearchParams.toString()}`; // window.location
};
