import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Widgets from '../../Widgets';
import Modal from '../../Modal';

const AppsMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Misc

  return (
    <Modal
      cssClassName='turbine-modal--style-card'
      visible={currentModal.visible}
      theme="dark"
      close={() => {
        dispatch(resetCurrentModal());
      }}
      pos="middle"
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h5 className="m-0 font-weight-bold text-white">Widgets</h5>
        </div>
        <div className="card-body">
          <Widgets />
        </div>
      </div>
    </Modal>
  );
};

export default AppsMenu;
