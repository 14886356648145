import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../../actions';
import CtaButton from '../../../Classes/CtaButton';
import ExternalRegister from '../../../Classes/CtaButton/ExternalRegister';
import { MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS } from '../../../../constants/modal';

const Cohorts = ({
  classId, className
}) => {
  const dispatch = useDispatch();
  // Redux
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  // Misc
  const { registerUrl } = course;
  const userCohortIds = pathOr([], ['cohortIds'], currentUser);

  if (!courseCohorts) {
    return null;
  }

  const handleEnroll = ({ cohort: selectedCohort }) => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS,
      data: {
        title: 'Already Registered?',
        selectedCohort,
        classId
      }
    }));
  };

  return (
    <div className={`${className || ''} card`}>
      <div className="card-header bg-light p-3 h5 m-0">
        Cohorts
      </div>
      <div className="card-content p-3 overflow-hidden">
        {courseCohorts.map((cohort, i) => {
          const { id: cohortId, title } = cohort;
          const isEnrolled = Boolean(userCohortIds.length && userCohortIds.includes(cohortId));

          return (
            <div
              key={i}
              className={`d-flex flex-column ${i === courseCohorts.length - 1 ? '' : 'mb-3'}`}
            >
              <div className="h6 m-0 font-weight-bold p-2 bg-dark text-white">
                {title}
              </div>
              {isEnrolled && (
                <div className="border p-3">
                  Enrolled <i className="fas fa-check-circle text-keppel" />
                </div>
              )}

              {!isEnrolled && registerUrl && (
                <div className="d-flex border p-3">
                  <ExternalRegister
                    course={course}
                    handleClickSecondary={() => {
                      handleEnroll({ cohort });
                    }}
                    secondaryText="Enroll"
                  />
                </div>
              )}

              {!isEnrolled && !registerUrl && (
                <div className="d-flex border p-3">
                  <CtaButton
                    className="btn-md"
                    cohortId={cohortId}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Cohorts;
