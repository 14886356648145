import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { FaCubes } from 'react-icons/fa';
import { CLASS_COLOR_DEFAULT } from '../../../constants/globals';
import { setCurrentModal } from '../../../actions';
import './style.css';
import { MODAL_KEY_COURSE_MENU } from '../../../constants/modal';

const TopLeftCourseInfo = () => {
  const dispatch = useDispatch();
  // redux
  const course = useSelector((state) => state.currentClass);
  const currentEntry = useSelector((state) => state.currentEntry);
  // Course
  const classTitle = pathOr(null, ['title'], course);
  const classImage = pathOr(null, ['classImage'], course);
  const classColor = pathOr(null, ['classColor'], course);

  const openCourseMenu = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_COURSE_MENU
      })
    );
  };

  // NOT IN COURSE
  if (!course?.id) {
    return;
  }

  // IN COURSE CONTENT
  if (currentEntry?.id) {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-md d-block d-md-none"
          onClick={openCourseMenu}
          type="button"
          title="Sections"
        >
          <span className='d-flex align-items-center'>
            <FaCubes className='mr-1' />
            <span>
              Sections
            </span>
          </span>
        </button>
        <div className="d-none d-md-flex align-items-center">
          {classImage ? (
            <img
              src={classImage}
              className="nav-icon mr-3"
              alt={classTitle}
            />
          ) : (
            <i
              className="fab fa-buffer mr-2 pr-1"
              style={{
                color: classColor || CLASS_COLOR_DEFAULT,
                fontSize: '1.5rem'
              }}
            />
          )}
          <span className="h5 m-0 font-weight-bold d-none d-lg-block">
            {classTitle}
          </span>
          <span className="h6 m-0 font-weight-bold d-block d-lg-none">
            {classTitle}
          </span>
        </div>
      </>
    );
  }

  // IN COURSE DASHBOARD
  return (
    <div className="d-flex align-items-center">
      {classImage ? (
        <img
          src={classImage}
          className="nav-icon mr-3 d-none d-md-block"
          alt={classTitle}
        />
      ) : (
        <i
          className="fab fa-buffer mr-2 pr-1 d-none d-sm-block"
          style={{
            color: classColor || CLASS_COLOR_DEFAULT,
            fontSize: '1.5rem'
          }}
        />
      )}

      <span className="h5 m-0 font-weight-bold d-none d-lg-block">
        {classTitle}
      </span>
      <span className="classTitle-responsive h6 m-0 font-weight-bold d-block d-lg-none">
        {classTitle}
      </span>
    </div>
  );
};

export default TopLeftCourseInfo;
