import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from '../../../constants/globals';
import Alert from '../../Alert';

const RenderTinyMceEditor = ({
  id,
  className = '',
  title,
  subtitle,
  maxCharCount = 50000,
  defaultValue = '',
  editorRef,
  height = 500,
  onChange,
  onExceedMaxChars,
  menubar = 'insert format table tools',
  mobileMenubar = 'edit insert format',
  required
}) => {
  const [maxCharsExceeded, setMaxCharsExceeded] = useState(false);
  const [charCount, setCharCount] = useState(defaultValue.length || 0);

  const getCharacterCount = () => {
    let content = '';
    let count = 0;
    if (editorRef && editorRef.current) {
      content = editorRef.current.getContent();

      if (content) {
        count = content.length;
      }
    }

    setCharCount(count);

    if (!maxCharsExceeded && count > maxCharCount) {
      setMaxCharsExceeded(true);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(true);
      }
    }

    if (maxCharsExceeded && count <= maxCharCount) {
      setMaxCharsExceeded(false);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(false);
      }
    }

    return charCount;
  };

  const plugins = [
    'autolink',
    'autoresize',
    'codesample',
    'link',
    'lists',
    // 'powerpaste',
    'table',
    'codesample'
    // 'quickbars'
  ];

  const init = {
    toolbar: 'removeformat formatselect | bold italic strikethrough | alignleft aligncenter alignright | bullist numlist | link',
    // undo redo |
    toolbar_sticky: true,
    menubar,
    contextmenu: false,
    statusbar: false,
    height,
    plugins,
    mobile: {
      menubar: mobileMenubar,
      toolbar: 'bold italic | bullist numlist | link',
      selection_toolbar: false,
      inline: false
    },
    // link_assume_external_targets: true,
    convert_newlines_to_brs: true,
    powerpaste_word_import: 'clean',
    powerpaste_html_import: 'clean',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
  };

  return (
    <div
      id={id}
      className={className}
    >
      {title && (
        <label className="mb-1 text-capitalize">
          <b>{title}:</b> {required && (<span className="text-danger">*</span>)}
        </label>
      )}
      {subtitle && (
        <div className="text-muted medium mb-2">
          {subtitle}
        </div>
      )}

      <Editor
        apiKey={TINYMCE_KEY}
        onInit={(evt, editor) => editorRef.current = editor} // eslint-disable-line no-return-assign
        initialValue={defaultValue}
        init={init}
        onChange={() => {
          getCharacterCount();

          if (onChange) {
            onChange();
          }
        }}
        onKeyUp={getCharacterCount}
      />

      <div className="d-flex justify-content-end mb-2">
        <div>
          <strong>{maxCharCount - charCount}</strong> characters remaining
        </div>
      </div>

      {charCount > maxCharCount && (
        <Alert
          type="danger"
          icon="fas fa-exclamation-triangle text-danger"
          className="m-0 mb-5 w-100 border"
        >
          <div>
            This field exceeds the maximum number of characters ({maxCharCount}) allowed.
          </div>
        </Alert>
      )}
    </div>
  );
};

export default RenderTinyMceEditor;
