import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { resetEnroll } from '../../../actions';
import { redirectToAuth } from '../../../services/auth';
import isPublished from '../../../utils/isPublished';
import { CLASSES, SLASH } from '../../../constants/routes';
import CtaButton from '../../Classes/CtaButton';
import AuthContainer from '../Container';
import Loading from '../../Loading';
import '../Container/invitation.css';

const CourseInvitation = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const enroll = useSelector((state) => state.enroll);
  const course = useSelector((state) => state.currentClass); // TODO course flatten
  const courseCohorts = useSelector((state) => state.courseCohorts);
  // Local Storage
  const turbineToken = localStorage.getItem('turbine_token');
  // Enroll
  const userName = enroll?.userName || '';
  // Course
  const title = course?.title || '';
  const courseVideo = course?.courseVideo || '';

  useEffect(() => {
    return function cleanup() {
      dispatch(resetEnroll()); // TODO is this needed here, maybe needed on Login?
    };
  }, []);

  const handleContinue = () => {
    const {
      orgId,
      orgName,
      orgLogo,
      orgSlug,
      ...remainingEnrollData
    } = enroll;

    redirectToAuth({
      organizationSlug: orgSlug, // TODO rename organizationSlug => orgSlug
      enroll: remainingEnrollData
    });
  };

  // Page was refreshed or access directly
  // without enroll data, this page is useless
  // Redirect to SLASH
  // If user is logged in, they will see dashboard
  // If they are logged out, they will be taken to auth
  if (!enroll?.orgId) {
    return (
      <Redirect to={SLASH} />
    );
  }

  // User logged in, waiting on Redux.
  if (!currentUser?.id && turbineToken) {
    return (
      <Loading />
    );
  }

  // Wait for org and course data to load
  if (!enroll?.orgId || !course?.id) {
    return (
      <Loading />
    );
  }

  const orgUserLoggedIn = Boolean(currentUser?.orgId === enroll?.orgId);
  const notOrgUserLoggedIn = Boolean(currentUser?.orgId !== enroll?.orgId);
  const hasAdditionalContent = Boolean(courseVideo);

  return (
    <div
      className={`row user-invite-container ${hasAdditionalContent ? 'has-additional-content' : ''}`}
    >
      {hasAdditionalContent && (
        <div
          className="course-video-container col-sm-12 col-md-6"
        // d-none d-md-block
        >
          <div
            className="card bg-light rounded p-1"
          >
            <div className="embed-container position-relative">
              {parse(courseVideo)}
            </div>
          </div>
        </div>
      )}

      <AuthContainer
        title="Invitation"
      >
        <>
          {userName && (
            <h5 className="font-weight-bold">
              {userName}
            </h5>
          )}

          <p className='mb-0'>
            You've been invited to join
            <br />
            <b>{title}</b>.
            <br />
          </p>

          {/*
            children
          */}

          <small className='mb-3'>
            ( Sponsored by {enroll.orgName} )
          </small>

          {courseCohorts && courseCohorts.map((cohort) => {
            if (isPublished(cohort) && cohort.id === enroll?.cohortId) {
              return (
                <div
                  key={cohort.id}
                  className="mb-2"
                >
                  <div className="mb-2">
                    {cohort.title}
                  </div>

                  {cohort.eUnits && cohort.eUnits.map((eUnit) => { // TODO cohort flatten
                    if (!isPublished(eUnit)) return null;

                    return (
                      <div
                        key={eUnit.fields.code}
                        className="border p-2 mb-2"
                      >
                        <div className="font-weight-bold">{eUnit.fields.code}</div>
                        <div>{eUnit.fields.timeDate}</div>
                      </div>
                    );
                  })}
                </div>
              );
            }

            return null;
          })}

          {orgUserLoggedIn && (
            <>
              <div
                className="d-flex border-top border-bottom py-4 mb-3 justify-content-center"
              >
                <CtaButton
                  className="btn-lg"
                  cohortId={enroll?.cohortId}
                />
              </div>
              <Link
                className="btn-link"
                to={`${CLASSES}/${course?.id}`}
              >
                Learn more
              </Link>
            </>
          )}

          {(!currentUser?.id || notOrgUserLoggedIn) && (
            <>
              <div className="my-3">
                Create an account or login to join.
              </div>
              <div className="d-flex justify-content-center flex-column w-50 m-auto">
                <button
                  className="btn btn-md btn-primary mb-2"
                  onClick={handleContinue}
                  type="button"
                >
                  Continue
                </button>
              </div>
            </>
          )}

          {/* {hasAdditionalContent && (
            <div className='d-block d-md-none mt-3 overflow-hidden'>
              <div className="embed-container position-relative">
                {parse(courseVideoForceNoAutoPlay)}
              </div>
            </div>
          )} */}

        </>
      </AuthContainer>
    </div>
  );
};

export default CourseInvitation;
