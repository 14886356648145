import { referenceType } from '../contentful';

function formatClass({
  title,
  overview,
  schedule,
  subjects,
  description,
  topics,
  prerequisiteId, // prerequisite
  image,
  integration,
  slug,
  locked,
  openEnrollment,
  trackAttendance,
  // stripeSku, // deprecated
  // stripeSkuTest, // deprecated
  duration,
  slackChannel,
  streamingLink,
  isLive,
  showStreamLink
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (overview !== undefined) {
    data.overview = {
      'en-US': overview
    };
  }

  if (schedule !== undefined) {
    data.schedule = {
      'en-US': schedule
    };
  }

  if (subjects !== undefined) { // TODO, may need to handle references here
    data.subjects = {
      'en-US': subjects
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (topics !== undefined) { // TODO more descriptive key would be tags
    data.topics = {
      'en-US': topics // array
    };
  }

  if (prerequisiteId !== undefined) {
    data.prerequisite = {
      'en-US': referenceType(prerequisiteId)
    };
  }

  if (image !== undefined) { // TODO replace with AWS
    data.image = {
      'en-US': image
    };
  }

  if (integration !== undefined) {
    data.integration = {
      'en-US': integration
    };
  }

  if (slug !== undefined) {
    data.slug = {
      'en-US': slug
    };
  }

  if (locked !== undefined) {
    data.locked = {
      'en-US': locked // boolean
    };
  }

  if (openEnrollment !== undefined) {
    data.openEnrollment = {
      'en-US': openEnrollment // boolean
    };
  }

  if (trackAttendance !== undefined) {
    data.trackAttendance = {
      'en-US': trackAttendance // boolean
    };
  }

  if (duration !== undefined) {
    data.duration = {
      'en-US': duration
    };
  }

  if (slackChannel !== undefined) {
    data.slackChannel = {
      'en-US': slackChannel
    };
  }

  if (streamingLink !== undefined) {
    data.streamingLink = {
      'en-US': streamingLink
    };
  }

  if (isLive !== undefined) {
    data.isLive = {
      'en-US': isLive // boolean
    };
  }

  if (showStreamLink !== undefined) {
    data.showStreamLink = {
      'en-US': showStreamLink // boolean
    };
  }

  return { fields: { ...data } };
}

export default formatClass;
