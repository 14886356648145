import * as twApi from './tw-api';
import { WEATHER_BASE_URL } from '../constants/api';

/**
* Get Current Weather
* @summary retrieve current weather forecast by postalCode(s)
* @param {Array} postalCodes // customer id
* @returns {Promise<object>} - A list of invoices
*/
export async function fetchCurrentWeather({ postalCodes }) {
  const response = await twApi.get({
    endpoint: `${WEATHER_BASE_URL}/current`,
    params: {
      location: postalCodes.join(',')
    }
  });

  // Multiple [{ location, current }, { location, current }] || null
  let currentWeather = response && Array.isArray(response) && response.length ? response : null;

  if (postalCodes.length === 1) {
    // Single   { location, current }
    [currentWeather] = response;
  }

  return currentWeather;
}
