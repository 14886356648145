import React from 'react';
import { useSelector } from 'react-redux';
import Loading from '../Loading';
import AddressReadable from '../AddressReadable';

const CertificateLocation = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);

  if (!currentUser) return null;

  if (!organization || !userLocation) {
    return (
      <Loading text="Loading user info..." />
    );
  }

  const {
    name,
    subtitle,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressPostalCode
    // addressCountry
  } = userLocation;

  return (
    <div className="location-addressInfo">
      {name && (
        <small>
          <p className="font-weight-bold m-0">
            {name}
          </p>
        </small>
      )}
      {subtitle && (
        <small>
          <p className="m-0">
            {subtitle}
          </p>
        </small>
      )}

      <AddressReadable
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        addressCity={addressCity}
        addressState={addressState}
        addressPostalCode={addressPostalCode}
        // addressCountry={addressCountry}
      />

    </div>
  );
};

export default CertificateLocation;
