import { client, flattenItems } from '../services/contentful';
import { SET_COHORTS, RESET_COHORTS } from './types';

export const getCohorts = ({ classId, cohortIds, openCohortEnrollment }) => {
  return (dispatch) => {
    const config = {
      content_type: 'cohort',
      order: '-sys.createdAt',
      include: 1
    };

    if (classId !== undefined) {
      config['fields.classId'] = classId;
    }

    if (cohortIds !== undefined && Array.isArray(cohortIds)) {
      config['sys.id[in]'] = cohortIds.join(',');
    }

    if (openCohortEnrollment !== undefined) {
      config['fields.openCohortEnrollment'] = openCohortEnrollment;
    }

    return new Promise((resolve, reject) => {
      client.getEntries(config).then(({ items }) => {
        const courseCohorts = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_COHORTS,
          ...(classId ? { courseCohorts } : {}),
          ...(cohortIds ? { userCohorts: courseCohorts } : {})
        });

        resolve(courseCohorts);
      }).catch((error) => {
        console.error('getCohorts', error);
        reject(error);
      });
    });
  };
};

export const resetCohorts = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COHORTS });
  };
};
