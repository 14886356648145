import { pathOr } from 'ramda';
import { client, flattenItem, flattenItems } from '../services/contentful';
import * as ROUTES from '../constants/routes';
import { getCurrentClassRoute } from '../services/courses';
import {
  SET_CLASS_CERTIFICATE,
  RESET_CLASS_CERTIFICATE,
  SET_CURRENT_CLASS,
  RESET_CURRENT_CLASS
} from './types';
import { getCohorts } from './Cohorts';

export const setCurrentClass = ({
  classId,
  redirectToClass,
  redirectToClassDashboard,
  history
}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'class',
        include: 2,
        'sys.id': classId
      };

      const { currentUser } = getState();

      // TODO change this to getEntry after refactoring data references
      return client.getEntries(config).then(({ items }) => {
        const course = items[0] !== undefined ? flattenItem(items[0]) : null;

        // TODO course flatten (courseExpertIds)
        let courseExperts = pathOr(null, ['teachers'], course);
        if (courseExperts) courseExperts = flattenItems(courseExperts);

        // TODO course flatten (courseTopicIds)
        let topics = pathOr(null, ['subjects'], course);
        if (topics) topics = flattenItems(topics);

        dispatch(getCohorts({
          classId,
          openCohortEnrollment: true
        })).then((courseCohorts) => {
          const currentClassRoute = topics ? getCurrentClassRoute({
            currentUser, topics, classId, courseCohorts
          }) : null;

          const data = {
            type: SET_CURRENT_CLASS,
            currentClass: course, // TODO course flatten (remove currentClass)
            courseExperts,
            currentClassDashboardRoute: `${ROUTES.CLASSES}/${classId}`
          };

          // TODO course flatten (courseTopicIds)
          // TODO dispatch(getTopics({ classId }))
          if (topics) data.topics = topics;

          if (currentClassRoute) data.currentClassRoute = currentClassRoute;

          dispatch(data);

          if (redirectToClass) {
            if (data.currentClassRoute) {
              history.push(data.currentClassRoute);
            } else {
              // Fallback behavior because the class route can not be constructed properly
              history.push(data.currentClassDashboardRoute);
            }
          }

          if (redirectToClassDashboard) {
            history.push(data.currentClassDashboardRoute);
          }

          resolve(data);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetCurrentClass = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS });
  };
};

export const setClassCertificate = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CLASS_CERTIFICATE,
        classCertificate: data
      });

      resolve(data);
    });
  };
};

export const resetClassCertificate = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CLASS_CERTIFICATE });
  };
};
