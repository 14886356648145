import * as contentful from 'contentful';
import * as contentfulManagement from 'contentful-management';
import { pathOr } from 'ramda';

export const CSPACE = process.env.REACT_APP_CSPACE;

export const referenceType = (id) => {
  return {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id
    }
  };
};

export const referenceTypes = (references) => {
  return references.map((reference) => referenceType(reference.sys.id));
};

export const client = contentful.createClient({
  space: CSPACE,
  accessToken: process.env.REACT_APP_CDT,
  environments: 'master' // TODO move to heroku var, pull dev data on local
});

export const clientManagement = contentfulManagement.createClient({
  accessToken: process.env.REACT_APP_CMT
});

/**
 *
 * @param {object} item  // ex, { fields: {...}, sys: {...} }
 */
export const flattenItem = (data) => {
  const fields = pathOr(null, ['fields'], data);
  const sys = pathOr(null, ['sys'], data);
  const id = pathOr(null, ['sys', 'id'], data);
  const createdAt = pathOr(null, ['sys', 'createdAt'], data);
  const updatedAt = pathOr(null, ['sys', 'updatedAt'], data);
  const contentType = pathOr(null, ['sys', 'contentType', 'sys', 'id'], data);
  const dataToSave = {
    ...(fields || {})
  };

  if (sys) dataToSave.sys = sys; // TODO rm after refactor with flattenItems in codebase
  if (id) dataToSave.id = id;
  if (contentType) dataToSave.contentType = contentType;
  if (createdAt) dataToSave.createdAt = createdAt;
  if (updatedAt) dataToSave.updatedAt = updatedAt;

  return dataToSave;
};

/**
 *
 * @param {array of objects} arr // ex, [ { fields: {...}, sys: {...} }, { fields: {...}, sys: {...} } ]
 */
export const flattenItems = (arr) => {
  const flattenedArr = arr.map((item) => flattenItem(item));

  return flattenedArr;
};

/**
 *
 * @param {array of strings} arr ex: ['contentfulIDxYZ']
 * @param {string} type ex: 'Entry', 'Asset'
 *
 */
export const unpublish = (arr, type) => {
  const items = arr.map((id) => referenceType(id, type));

  const unpublishPayload = {
    entities: {
      sys: { type: 'Array' },
      items
    }
  };

  return new Promise((resolve, reject) => {
    clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.createUnpublishBulkAction(unpublishPayload))
      .then((bulkActionInProgress) => bulkActionInProgress.waitProcessing())
      .then((bulkActionCompleted) => {
        resolve(bulkActionCompleted);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
