import React from 'react';
import { useSelector } from 'react-redux';
import { withAuthorization } from '../Session';

const Calendar = () => {
  const userLocation = useSelector((state) => state.userLocation);
  const { calendar } = userLocation;

  return (
    <main role="main" className="col-sm-12 py-4">
      <iframe
        title="Upstream Schedule Calendar"
        src={calendar}
        style={{
          border: 0, minWidth: 'auto', width: '100%', height: '90vh'
        }}
        frameBorder="0"
        scrolling="no"
      />
    </main>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Calendar);
