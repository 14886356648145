import {
  getAuth,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateEmail,
  updatePassword
} from '@firebase/auth';
import { isLocalhost } from '../serviceWorker';
import {
  TURBINE,
  TURBINE_ADMIN,
  TURBINE_AUTH,
  TURBINE_VELA
} from '../constants/urls';
import { SSO } from '../constants/routes';
import { app } from '../firebaseConfig';

const createContinueUrl = ({ href, error }) => {
  if (error && (error.code || error.message)) {
    return TURBINE;
  }

  if (href.includes('/login') || href.includes('/course/invite')) {
    return TURBINE;
  }

  if (!isLocalhost && href.includes('http:')) {
    // current href isn't secure, make it secure when they redirect back to app/admin
    href = href.replace('http:', 'https:');
  }

  return href;
};

export const redirectToTurbineAdmin = (href) => {
  const turbineToken = localStorage.getItem('turbine_token');

  if (!turbineToken) {
    return TURBINE_ADMIN;
  }

  const continueUrl = createContinueUrl({ href: href || TURBINE_ADMIN });

  const params = new URLSearchParams({
    token: turbineToken,
    continueUrl
  });

  const url = `${TURBINE_ADMIN + SSO}?${params.toString()}`;

  return url;
};

export const redirectToVela = () => {
  const turbineToken = localStorage.getItem('turbine_token');

  if (!turbineToken) {
    return TURBINE_VELA;
  }

  return `${TURBINE_VELA + SSO}?token=${turbineToken}`;
};

export const redirectToAuth = ({
  organizationSlug,
  enroll,
  continueUrl,
  error
}) => {
  const {
    location: { href, search }
  } = window;
  const urlSearchParams = new URLSearchParams(search);
  // If user is logged in and logs out, retain the organization slug to use on login screen
  // If user is logged out and the URL param orgName is present, use that as slug on login screen
  const orgSlug = organizationSlug || urlSearchParams.get('orgName');
  const redirectUrl = `${TURBINE_AUTH}/login/${orgSlug || ''}`;
  const paramsObj = {
    continueUrl: createContinueUrl({ href: continueUrl || href, error })
  };

  if (enroll?.action && enroll?.spice) {
    paramsObj.spice = enroll.spice;

    /**
     * Enrollment Data
     *
     * Learn more: https://github.com/apprentage/docs-internal/blob/main/AUTH.md#enrollment-data
     */
  }

  if (error) {
    const { code, message } = error;

    if (code) {
      paramsObj.errorCode = code;
    }

    if (message) {
      paramsObj.errorMessage = message;
    }
  }

  logUserOut().then(() => {
    const redirectUrlSearchParams = new URLSearchParams(paramsObj);

    window.location = `${redirectUrl}?${redirectUrlSearchParams.toString()}`;
  });
};

export const logUserOut = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth(app);

    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const reauthenticateUser = (password) => {
  const auth = getAuth();
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  );
  return reauthenticateWithCredential(auth.currentUser, credential);
};

export const updateEmailAddressCurrentUser = (newEmail) => {
  const auth = getAuth();
  return updateEmail(auth.currentUser, newEmail);
};

export const changeUserPassword = (newPassword) => {
  const auth = getAuth();
  return updatePassword(auth.currentUser, newPassword);
};
