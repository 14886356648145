const formatAnswer = ({
  title,
  solution,
  results,
  userId,
  userName,
  classId,
  topicId,
  challengeId,
  challengeTitle,
  orgId
}) => {
  const data = {};

  data.status = {
    'en-US': 'inReview'
  };

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (solution !== undefined) {
    data.solution = {
      'en-US': solution
    };
  }

  if (results !== undefined) {
    data.results = {
      'en-US': results
    };
  }

  if (userId !== undefined) {
    data.userId = {
      'en-US': userId
    };
  }

  if (userName !== undefined) {
    data.userName = {
      'en-US': userName
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  if (topicId !== undefined) {
    data.topicId = {
      'en-US': topicId
    };
  }

  if (challengeId !== undefined) {
    data.challengeId = {
      'en-US': challengeId
    };
  }

  if (challengeTitle !== undefined) {
    data.challengeTitle = {
      'en-US': challengeTitle
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  return { fields: { ...data } };
};

export default formatAnswer;
