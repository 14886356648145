import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaCubes } from 'react-icons/fa';
import $ from 'jquery';
import RenderButtons from './RenderButtons';
import RenderSections from './RenderSections';
import './style.css';

const LeftNav = ({
  className = '',
  header = true
}) => {
  // Redux
  const course = useSelector((state) => state.currentClass);
  const topics = useSelector((state) => state.topics);
  const currentTopic = useSelector((state) => state.currentTopic);
  const currentEntry = useSelector((state) => state.currentEntry);

  useEffect(() => {
    $(document).on('click', '.admin-menu--left-nav .dropdown-collapse-container', (e) => {
      e.stopPropagation();
    });
  }, []);

  if (!currentEntry || !currentTopic || !course) {
    return null;
  }

  const { id: topicId } = currentTopic;

  return (
    <nav
      data-cy="course-LeftNav"
      className={className}
    >
      <div>
        {header && (
          <div className="card-header bg-secondary p-3 d-flex justify-content-between align-items-center">
            <div className="h5 m-0 text-white font-weight-bold">
              <i className="fas fa-cubes mr-1" /> Sections
            </div>
          </div>
        )}

        <div
          id="portal-left-nav"
          className='sidebar-sticky'
        >
          <ul
            className="nav nav-sidenav-list flex-column"
          >
            {topicId !== null && topics && topics.map((topic, i) => (
              <>
                {topic?.topicHeader && (
                  <li
                    key={`header-${i}`}
                    className='border-0'
                    style={{
                      backgroundColor: '#4d5359'
                    }}
                  >
                    <h6 className='py-3 pl-3 pr-1 mb-0'>
                      <span className='d-flex align-items-center'>
                        <FaCubes className='mr-2' />
                        <span className='font-weight-bold text-white'>
                          {topic?.topicHeader}
                        </span>
                      </span>
                    </h6>
                  </li>
                )}
                <li
                  key={i}
                  style={{
                    borderRight: '1px solid #e5e7eb',
                    borderLeft: '1px solid #e5e7eb'
                  }}
                >

                  <RenderButtons
                    topic={topic}
                    index={i}
                  />

                  <div
                    id={`subject-${i}`}
                    className={`collapse ${topic.id === topicId ? 'show' : ''}`}
                    data-parent="#portal-left-nav"
                  >
                    <RenderSections
                      topic={topic}
                    />
                  </div>
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>

    </nav>
  );
};

export default LeftNav;
