import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useHistory, Redirect
} from 'react-router-dom';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { SLASH } from '../../../constants/routes';
import { withAuthorization } from '../../Session';
import {
  userHasGoLiveAccess,
  userHasEnrolled,
  canBypassCourseEnroll,
  userOrgIsCourseOrg,
  userOrgInCourseOrgIds
} from '../../../services/currentUser';
import {
  setCurrentAnswers,
  setCurrentClass,
  resetCurrentClass,
  resetCurrentTopic,
  getCourses
  // reEnroll
} from '../../../actions';
import { EXTERNAL_LINK } from '../../../constants/icons';
import { fetchUser, fetchUsers } from '../../../services/users';
import * as analytics from '../../../utils/analytics';
import Alert from '../../Alert';
import Topics from './Topics';
import Overview from './Overview';
import Instructors from './Instructors';
import Card from '../../Classes/Card';
// import Leaderboard from '../../Leaderboard';
import GoLive from '../../GoLive';
import CtaButton from '../../Classes/CtaButton';
import PreReqStatus from '../../Classes/PreReqStatus';
import Loading from '../../Loading';
import Cohorts from './Cohorts';
import CompletionCertificateBanner from '../../CompletionCertificate/Banner';
import Notifications from './Notifications';
import CourseManageBar from './CourseManageBar';
import './style.css';

const Dashboard = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const course = useSelector((state) => state.currentClass);
  const currentClassRoute = useSelector((state) => state.currentClassRoute);
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);

  // Local State
  const [loading] = useState(false);
  const [author, setAuthor] = useState({});
  const [contributors, setContributors] = useState([]);

  // Course
  const classId = params?.classId || null;
  const courseAuthorId = course?.userId || null;
  const courseContributorIds = course?.userIds || null;
  // User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;
  const locationName = userLocation?.name || '';

  useEffect(() => {
    if (courseAuthorId) {
      fetchUser({
        userId: courseAuthorId,
        select: ['id', 'profilePicture', 'name', 'title']
      })
        .then((res) => {
          setAuthor(res);
        })
        .catch(() => {
          setAuthor({});
        });
    }
  }, [courseAuthorId]);

  useEffect(() => {
    if (courseContributorIds && orgId) {
      fetchUsers({
        userIds: courseContributorIds,
        orgId,
        select: ['id', 'profilePicture', 'name', 'title']
      }).then((res) => {
        setContributors(res.items);
      }).catch(() => {
        setContributors([]);
      });
    }
  }, [courseContributorIds, orgId]);

  useEffect(() => {
    if (!course?.courseReadOnly) {
      dispatch(setCurrentAnswers({ userId }));
    }
    dispatch(getCourses({ orgId })); // TODO course flatten (create new action to get topic and class preReqs)
    dispatch(setCurrentClass({ classId }));

    if (orgId && classId) {
      const analyticsData = {
        page_title: course?.title,
        page_type: 'Course Dashboard',
        orgId,
        orgName,
        classId,
        ...(userId ? { userId } : {}),
        ...(locationId ? { locationId } : {}),
        ...(locationName ? { orgLocationName: `${orgName} ${locationName}` } : {})
      };

      analytics.logEvent('page_view', analyticsData);
      analytics.logEvent('course_dashboard_view', analyticsData);
    }

    return function cleanup() {
      dispatch(resetCurrentClass());
      dispatch(resetCurrentTopic());
    };
  }, [userId, orgId, locationId, classId]);

  // const reEnroll = () => {
  //   setLoading(true);

  //   dispatch(reEnroll({ course }).then(() => {
  //     document.location.reload();
  //   }))
  // }

  if (!course) {
    return;
  }

  if (!course?.courseReadOnly && !currentUser) {
    return;
  }

  if (loading) {
    return <Loading className="position-fixed" />;
  }

  // User accessing class outside of their organization, send to '/'
  if (!course?.courseReadOnly) {
    if (!userOrgIsCourseOrg({ course, currentUser })) {
      // Course Syndication
      // Check if user has access to Org
      if (!userOrgInCourseOrgIds({ course, currentUser })) {
        history.replace(SLASH);

        return <Loading className="position-fixed" />;
      }
    }
  }

  // Given: User arrived from a Public Course page
  // And: autoForward=true is present in url params
  if (course?.courseReadOnly && searchParams.get('autoForward') && currentClassRoute) {
    // Then: redirect to course content
    return <Redirect to={currentClassRoute} />;
  }

  const {
    title,
    locked,
    isLive,
    streamingLink,
    // leaderboard,
    registerUrl,
    externalCourseUrl,
    enableCourseContent
  } = course;
  const hasGoLiveAccess = userHasGoLiveAccess({
    isLive,
    streamingLink,
    classId,
    currentUser
  });
  const hasEnrolled = userHasEnrolled({ classId, currentUser });

  return (
    <>
      <div className="row class-dashboard-container">
        <div className="col-md-7 col-lg-8 py-3">
          <div className="bg-light p-4 border mb-3 d-flex align-items-center justify-content-center">
            {canBypassCourseEnroll(role, course) && currentClassRoute ? (
              <Link
                data-cy="courseDashboard-courseContentBtn"
                className="internalCourseBtn btn btn-lg btn-primary"
                to={currentClassRoute}
              >
                Course Content
              </Link>
            ) : (
              <CtaButton className="btn-lg" />
            )}

            {enableCourseContent === false && externalCourseUrl && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="externalCourseUrlBtn btn btn-lg btn-outline-primary d-flex align-items-center"
                href={externalCourseUrl}
              >
                <span className="mr-2">Course Content</span>
                <small>
                  <i className={EXTERNAL_LINK} />
                </small>
              </a>
            )}

            <PreReqStatus
              course={course}
              currentUser={currentUser}
              className="ml-4 border-left pl-4"
            />
          </div>

          {/* <Cohorts
            classId={classId}
            className="my-3 d-block d-md-none"
          /> */}

          {hasGoLiveAccess && (
            <GoLive
              className="d-none d-md-block"
              streamingLink={streamingLink}
              title={title}
              buttonText="Join Class"
              buttonClassName="btn btn-md btn-info"
            />
          )}

          {hasGoLiveAccess && (
            <GoLive
              streamingLink={streamingLink}
              title={title}
              slim
              className="d-block d-md-none"
            />
          )}

          {hasEnrolled && locked && registerUrl && (
            <Alert type="success" icon="fas fa-thumbs-up" className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h5 mb-0">
                  <b>Successful Enrollment!</b> {title} will be available by the scheduled start date.
                </div>
              </div>
            </Alert>
          )}

          <CompletionCertificateBanner
            currentUser={currentUser}
            course={course}
          />

          <Overview />

          {enableCourseContent && currentClassRoute && (
            <Topics currentClassRoute={currentClassRoute} />
          )}

          <Notifications className="my-3" limit={3} />
        </div>

        <div className="d-none d-md-block col-md-5 col-lg-4 pt-3">
          {course && (
            <Card
              course={course}
              currentClassRoute={currentClassRoute}
              classDashboard
              showCourseDashboardButton={false}
              className="course-dashboard-card"
            />
          )}

          <CourseManageBar className="mt-3" />

          {author && (
            <div className="mt-3">
              <div>
                <div className='mb-1 font-weight-bold h6'>Author:</div>
                <NameBlock
                  profilePicture={author?.profilePicture || DEFAULT_PROFILE_PIC}
                  pictureSize='sm'
                  name={author?.name || ''}
                  role={author?.title || ''}
                />
              </div>
            </div>
          )}

          {contributors && contributors?.length > 0 && (
            <div className="mt-3">
              <div>
                <div className='mb-1 font-weight-bold h6'>
                  Contributors:
                </div>
                <div>
                  {contributors?.map((user) => (
                    <div
                      key={user?.id}
                    >
                      <NameBlock
                        className='mb-2'
                        pictureSize='sm'
                        profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
                        name={user?.name || ''}
                        role={user?.title || ''}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <Cohorts classId={classId} className="mt-3" />
        </div>
      </div>

      {course && (
        <div className="row mb-3">
          <div className="col-sm-12">
            <Instructors className="my-3" />
          </div>
        </div>
      )}

      {/* {canViewLeaderboard(role) && leaderboard &&  (
        <div className="row mb-3">
          <div className="col-sm-12">
            <Leaderboard />
          </div>
        </div>
      )} */}
    </>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Dashboard);
