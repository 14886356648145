import React, { Fragment } from 'react';
import UserAttendance from '../UserAttendance';
import NoRecords from './NoRecords';

const CourseAttendance = ({ attendance }) => {
  return (
    <Fragment>
      {attendance && attendance.length ? (
        <UserAttendance
          attendance={attendance}
        />
      ) : (
        <NoRecords />
      )}
    </Fragment>
  );
};

export default CourseAttendance;
