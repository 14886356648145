import React, { Fragment } from 'react';
import isPublished from '../../../utils/isPublished';
import { classCompleted, userHasEnrolled } from '../../../services/currentUser';
import CourseDashboardButton from '../../btns/CourseDashboardButton';

const IconStatus = ({ hasCompletedClass, hasEnrolled }) => {
  return (
    <Fragment>
      {!hasCompletedClass && (
        <i
          className={`fas fa-exclamation-circle ml-2 ${hasEnrolled ? 'text-danger' : 'text-light'}`}
          title="Prerequisite Incomplete"
        />
      )}
      {hasCompletedClass && (
        <i className="fas fa-check-circle text-keppel ml-2" title="Prerequisite Complete" />
      )}
    </Fragment>
  );
};

const PreReqStatus = ({
  course,
  currentUser,
  className = '',
  slim
}) => {
  if (!course || !currentUser) return null;

  const { prerequisite } = course; // TODO course flatten (prerequisteIds)
  if (!isPublished(prerequisite)) return null;

  const hasEnrolled = userHasEnrolled({ course, currentUser });
  const hasCompletedClass = classCompleted({ classId: prerequisite.sys.id, currentUser });

  if (slim) {
    return ( // TODO course flatten
      <div className="text-muted">
        <b>Prereq:</b> {prerequisite.fields.title}
        <IconStatus
          hasCompletedClass={hasCompletedClass}
          hasEnrolled={hasEnrolled}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <b>Prerequisite:</b>
      <p className="text-muted mt-1 mb-0 d-flex align-items-center">
        <CourseDashboardButton
          classId={prerequisite.sys.id}
          className="btn btn-link p-0 m-0"
        >
          { prerequisite.fields.title }
        </CourseDashboardButton>
        <IconStatus
          hasCompletedClass={hasCompletedClass}
          hasEnrolled={hasEnrolled}
        />
      </p>
    </div>
  );
};

export default PreReqStatus;
