import React from 'react';
import './style.css';

const Toast = ({
  id,
  iconClass,
  title,
  body,
  dismissable,
  children
}) => {
  return (
    <div
      id={id}
      className="toast fade show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-autohide="false"
    >
      <div className="toast-header">
        {iconClass && (
          <div className="toast-icon rounded mr-2">
            <i className={iconClass} />
          </div>
        )}
        <strong className="mr-auto">
          { title }
        </strong>

        {dismissable && (
          <button
            className="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <div className="toast-body">
        { body }
        {children && (
          <>
            <hr className="my-4" />
            { children }
          </>
        )}
      </div>
    </div>
  );
};

export default Toast;
