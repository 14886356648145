import React, { Fragment } from 'react';
import { LOCK } from '../../../constants/icons';

const NoEnroll = ({ course, className }) => {
  const { locked } = course;

  return (
    <Fragment>
      <button
        type="button"
        className={`comingSoonCourseBtn btn btn-md btn-primary my-2 ${className || ''}`}
        title="Coming Soon"
        disabled
      >
        Coming Soon
      </button>
      {locked && (
        <button type="button" className={`btn btn-md btn-link my-2 ${className || ''}`} disabled>
          <i className={LOCK} />
        </button>
      )}
    </Fragment>
  );
};

export default NoEnroll;
