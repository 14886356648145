import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sortArrByArr } from '@apprentage/utils';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../../../services/users';
import NameBlock from '../../Directory/UserDetails/NameBlock';
import { DIRECTORY } from '../../../constants/routes';
import './style.css';

const Users = ({ userIds }) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Local State
  const [usersList, setUsersList] = useState(null);

  useEffect(() => {
    if (userIds && Array.isArray(userIds) && userIds.length !== 0) {
      fetchUsers({ userIds, orgId }).then((response) => {
        setUsersList(response?.items);
      });
    }
  }, [userIds, orgId]);

  if (!usersList) {
    return <div>No users selected.</div>;
  }

  const whichUsers = sortArrByArr(usersList, userIds, 'id');

  return (
    <div className="users d-flex flex-column">
      {whichUsers.map((user) => (
        <Link
          className="text-decoration-none text-ships-officer team-member p-2 rounded mb-2"
          to={`${DIRECTORY}/${user.id}`}
          key={user.id}
          title={user?.name}
        >
          <NameBlock
            data={user}
            className="d-flex align-items-center"
            pictureWidth="50"
            pictureHeight="50"
          />
        </Link>
      ))}
    </div>
  );
};

export default Users;
