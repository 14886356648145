import { updateUser } from '../services/user';
import * as twApi from '../services/tw-api';
import {
  SLACK_JOIN_CHANNEL_SUCCESS,
  SLACK_ALREADY_JOINED_WORKSPACE_SUCCESS
} from './types';
import {
  SLACK_CONVO_INVITE,
  SLACK_USERS_LOOKUP_BY_EMAIL
} from '../constants/api';

export const addUserToSlackChannel = ({ channelId, slackUserId }) => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { slackToken } = organization;

    twApi.post(SLACK_CONVO_INVITE, {
      slackToken,
      channelId,
      slackUserId
    }).then((response) => {
      dispatch({
        type: SLACK_JOIN_CHANNEL_SUCCESS,
        response
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const alreadyJoinedWorkspace = ({ email, userId }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { organization } = getState();
      const { slackToken } = organization;

      twApi.post(SLACK_USERS_LOOKUP_BY_EMAIL, {
        email,
        slackToken
      }).then((response) => {
        if (response && response.user) {
          updateUser({
            slackUserId: response?.user?.id
          }, userId).then(() => {
            dispatch({ type: SLACK_ALREADY_JOINED_WORKSPACE_SUCCESS, response });
            resolve(response);
          });
        } else {
          console.error(response.error);
          reject(response.error);
        }
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};
