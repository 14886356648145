import * as URLS from '../constants/urls';

export const buyClass = ({
  skus,
  userId,
  classId,
  cohortId,
  cancelUrl
}) => {
  const { stripe } = window;
  // eslint-disable-next-line no-underscore-dangle
  const test = (window.stripe._keyMode === 'test');
  const sku = (test) ? skus.test : skus.live;
  const items = [
    { sku, quantity: 1 }
  ];

  let successUrl = `${URLS.TURBINE}/enroll/${userId}/${classId}`;

  // COHORT
  if (cohortId) successUrl += `/${cohortId}`;

  stripe.redirectToCheckout({
    items,
    successUrl,
    cancelUrl: cancelUrl || URLS.TURBINE
  }).then((result) => {
    console.error(result);

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      document.location = cancelUrl || URLS.TURBINE;
    }
  });
};

export const buySubscription = ({
  plans,
  membership,
  orgId,
  userId,
  cancelUrl
}) => {
  const { stripe } = window;
  // eslint-disable-next-line no-underscore-dangle
  const test = window.stripe._keyMode === 'test';
  const plan = (test) ? plans.test : plans.live;
  const items = [
    { plan, quantity: 1 }
  ];

  const successUrl = `${URLS.TURBINE}/subscribe/${membership}/${orgId}/${userId}`;

  stripe.redirectToCheckout({
    items,
    successUrl,
    cancelUrl: cancelUrl || URLS.TURBINE
  }).then((result) => {
    console.error(result);

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      document.location = cancelUrl || URLS.TURBINE;
    }
  });
};
