import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentModal } from '../../../actions';
import { MODAL_KEY_SEND_EMAIL } from '../../../constants/modal';

const SendEmailButton = ({
  text = 'Email',
  className = 'btn btn-sm btn-primary text-nowrap',
  userName,
  userEmail,
  showIcon = false
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_SEND_EMAIL,
      data: {
        name: userName,
        email: userEmail,
        hideSubject: true,
        disableSubject: true
      }
    }));
  };

  return (
    <button
      className={`d-flex align-items-center ${className}`}
      title={text}
      onClick={handleClick}
      type="button"
    >
      {showIcon && (
        <i className="fas fa-paper-plane" />
      )}
      <span className='ml-1'>
        {text}
      </span>
    </button>
  );
};

export default SendEmailButton;
