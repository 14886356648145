import { sortCaseInsensitive } from '@apprentage/utils';
import {
  fetchSupabaseEntries,
  fetchSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'skills';

export const fetchSkills = async ({
  orgId,
  name,
  difficulty,
  ids,
  order = 'name',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (difficulty) {
    params['f.difficulty[eq]'] = difficulty;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  if (Array.isArray(response?.items) && response?.items.length > 0) {
    response.items = response?.items.sort((a, b) => sortCaseInsensitive(a, b, order));
  }

  return response;
};

export const fetchSkill = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};
