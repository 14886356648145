// Turbine
export const APPRENTICESHIP_PUZZLE = 'https://turbine-content.s3.us-east-2.amazonaws.com/apprenticeship-puzzle.jpg';
export const VELA_HANDOFF_QRCODE_CONTAINER = 'https://turbine-content.s3.us-east-2.amazonaws.com/mobile-handoff-qrcode-container.jpg';
export const QRCODE_VELA = 'https://turbine-content.s3.us-east-2.amazonaws.com/QRCode-VELA.jpg';

// WORKFORCE
export const DASHBOARD_RESOURCES = 'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-resources.jpg';
// Upstream Coding
export const TURBINE_MARKDOWN_CHEATSHEET = 'https://turbine-content.s3.us-east-2.amazonaws.com/markdown-cheatsheet-online.pdf';
// PVE
export const PVE_UNANET = 'https://turbine-content.s3.us-east-2.amazonaws.com/pve-unanet.jpg';
// CCAC TRUST COURSE
export const CCAC_TRUST_CERTIFICATE_BKG = 'https://turbine-content.s3.us-east-2.amazonaws.com/TRUST-certificate-background-image.jpg';
// CCAC SILICON PHOTONICS COURSE
export const CCAC_PHOTONICS_CERTIFICATE_BKG = 'https://turbine-content.s3.us-east-2.amazonaws.com/silicon-photonics-certificate-1050x1380.jpg';
