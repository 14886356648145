import React, { Fragment } from 'react';
import Alert from '../../Alert';

const PracticeMode = ({
  className,
  practiceMode,
  loading,
  handlePracticeAgain
}) => {
  return (
    <Alert
      type={practiceMode ? '' : 'warning'}
      icon={`fas fa-info-circle ${practiceMode ? 'text-keppel' : ''}`}
      className={`alertWithIconSlim border ${className}`}
    >
      <div>
        <p className="m-0">
          Practice Mode
        </p>
        <div>
          Retake this quiz without impacting your overall score.
        </div>
        {practiceMode && (
          <Fragment>
            <hr className="my-2" />
            <button
              className="btn btn-warning"
              type="button"
              disabled={loading}
              title="Practice Again"
              onClick={handlePracticeAgain}
            >
              Practice again
            </button>
          </Fragment>
        )}
      </div>
    </Alert>
  );
};

export default PracticeMode;
