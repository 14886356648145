import React from 'react';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { withFirebase } from '../Firebase';
import {
  setUser,
  resetUser,
  getOrg,
  setCurrentUser,
  setLoading
} from '../../actions';
import {
  getUserLoggedIn,
  rmUserLoggedIn
} from '../../services/organizations';
import * as analytics from '../../utils/analytics';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      this.props.setLoading(true);

      this.listener = this.props.firebase.onAuthUserListener((response) => {
        localStorage.setItem('authUser', JSON.stringify(response.authUser));

        const userId = pathOr(null, ['currentUser', 'id'], response);
        const role = pathOr([], ['currentUser', 'role'], response);
        const currentUserOrgId = pathOr(null, ['currentUser', 'orgId'], response);

        this.props.setUser(response.authUser);

        analytics.setUserId(userId);

        this.props.getOrg({ orgId: currentUserOrgId }).then((responseOrg) => {
          const orgName = pathOr(null, ['organization', 'name'], responseOrg);

          analytics.setUserProperties({
            orgId: currentUserOrgId,
            orgName,
            orgRole: role.join(',')
          });

          this.props.setCurrentUser(response.currentUser);
          this.props.setLoading(false);
        });

        const userLoggedIn = getUserLoggedIn();

        if (userLoggedIn) {
          rmUserLoggedIn();
        }
      }, () => {
        this.props.setLoading(false);
        localStorage.removeItem('authUser');
        localStorage.removeItem('turbine_token');
        this.props.resetUser();
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withFirebase(connect(null, {
    setUser,
    resetUser,
    getOrg,
    setCurrentUser,
    setLoading
  })(WithAuthentication));
};

export default withAuthentication;
