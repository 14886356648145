import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import {
  updateAgreement,
  getAgreement,
  resetCurrentModal
} from '../../../actions';
import { dayMdyDate } from '../../../utils/date';
import * as ROUTES from '../../../constants/routes';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Modal from '../../Modal';
import './style.css';

class BillingAgreement extends Component {
  state = {
    loading: false
  };

  handleClose = () => {
    this.props.resetCurrentModal();
  };

  handleError = () => {
    toast.error('Something went wrong, refresh and try again.');
    this.setState({ loading: false });
  };

  handleBillingAccept = ({ id, title }) => {
    const { currentUser, location } = this.props;
    const { name, email } = currentUser;

    this.setState({ loading: true });

    toast.info('Processing acceptance...');

    this.props.updateAgreement({
      id,
      accepted: true,
      acceptedDate: dayMdyDate(new Date()),
      acceptedName: name,
      acceptedEmail: email
    }).then(() => {
      toast.success(`You accepted the ${title}`);

      if (location.search.includes('agreement=decline')) {
        // Rm the "?agreement=decline"
        this.props.history.replace(ROUTES.BILLING);
      }

      this.handleClose();
    }).catch(() => {
      this.handleError();
    });
  }

  handleDecline = () => {
    const { location } = this.props;

    if (location.pathname === ROUTES.BILLING) {
      this.handleClose();
    } else {
      this.props.history.replace(`${ROUTES.BILLING}?agreement=decline`);
    }
  };

  render() {
    const { currentModal, billingAgreement } = this.props;

    const { loading } = this.state;

    if (!billingAgreement) return null;

    const {
      id,
      title,
      body,
      accepted,
      acceptedDate,
      acceptedName,
      acceptedEmail,
      updatedAt,
      enableNewEditor
    } = billingAgreement;

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        pos="top"
        close={accepted ? this.handleClose : false}
      >
        <div className="card">
          <div className="card-header">
            <h5 className="m-0 font-weight-bold">
              {title}
            </h5>
          </div>

          {accepted && acceptedDate && acceptedName && acceptedEmail && (
            <div className="alert alert-secondary m-0">
              <i className="fas fa-check-circle text-keppel" /> Accepted on {acceptedDate} by {acceptedName} ({acceptedEmail}).
            </div>
          )}

          {body && (
            <div className="card-body">
              <div className="text-muted text-right mb-2">
                Last updated: {dayMdyDate(updatedAt)}
              </div>

              <RenderMarkdown
                source={body}
                enableNewEditor={enableNewEditor}
              />

              {!accepted && (
                <p className="m-0">
                  Clicking <b>"I Accept"</b> indicates acceptance of all the above terms as of {dayMdyDate(new Date())} (the "Effective Date").
                </p>
              )}
            </div>
          )}

          {!accepted && (
            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                disabled={loading}
                onClick={() => {
                  this.handleBillingAccept({ id, title });
                }}
                type="button"
              >
                I Accept
              </button>
              <button
                className="btn btn-link"
                disabled={loading}
                onClick={this.handleDecline}
                type="button"
              >
                Decline
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentUser,
  billingAgreement,
  currentModal
}) => ({
  currentUser,
  billingAgreement,
  currentModal
});

export default compose(
  withRouter,
  connect(mapStateToProps, { updateAgreement, getAgreement, resetCurrentModal })
)(BillingAgreement);
