function statusFeedback(status) {
  if (status === 'accepted') {
    return {
      title: 'Accepted',
      alertType: 'success',
      iconClass: 'fas fa-check'
    };
  }

  if (status === 'requestChanges') {
    return {
      title: 'Accepted with Changes',
      alertType: 'warning',
      iconClass: 'fas fa-highlighter'
    };
  }

  if (status === 'rejected') {
    return {
      title: 'Rejected',
      alertType: 'danger',
      iconClass: 'fas fa-flag'
    };
  }

  // status === 'inReview'
  return {
    title: 'In Review',
    alertType: 'info',
    iconClass: 'far fa-clock'
  };
}

export default statusFeedback;
