import React from 'react';
import { useSelector } from 'react-redux';
import ProfilePicture from '../../ManageContent/ProfilePicture';
import { mdyDate } from '../../../utils/date';
import './style.css';

const Profile = ({ className = '' }) => {
  const currentUser = useSelector((state) => state.currentUser);

  if (!currentUser) return null;

  const {
    membershipReadable,
    createdAt,
    name
  } = currentUser;

  return (
    <div className={`card card-dashboard--profile ${className}`}>
      <ProfilePicture
        className="card-content overflow-hidden pt-4 pb-2"
      >
        <h5
          className="mt-3"
          data-demo="disabled"
        >
          { name }
        </h5>
      </ProfilePicture>
      <div className="card-footer p-3 bg-light">
        <b className="text-capitalize">{ membershipReadable } Member</b>
        <span>
          Joined {mdyDate(createdAt)}
        </span>
      </div>
    </div>
  );
};

export default Profile;
