import React, { Component } from 'react';

export default class ModalDialog extends Component {
  render() {
    const {
      title, text, primaryButton, secondaryButton, close, visible
    } = this.props;

    if (!visible) return null;

    return (
      <div className="turbine-modal dialog">
        <div className="modal-container">
          <div className="modal-content">
            {title && <h2 className="title">{title}</h2>}
            <div className="body">{text}</div>
            <div className="control">
              {primaryButton && (
                <button
                  onClick={primaryButton.func || close}
                  className="btn btn-md btn-primary"
                  type="button"
                >
                  {primaryButton.text}
                </button>
              )}
              <button
                onClick={secondaryButton.func || close}
                className="btn btn-md btn-link"
                type="button"
              >
                {secondaryButton.text || 'Cancel'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
