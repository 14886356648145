import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { membershipEligible } from '../../../services/currentUser';
import { removeToast, alreadyJoinedWorkspace, upgradeMembershipModal } from '../../../actions';

class Slack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorIntegratingSlack: false
    };

    this.altEmail = React.createRef();
  }

  componentWillUnmount() {
    this.setState({
      errorIntegratingSlack: false,
      loading: false
    });
  }

  handleAlreadyJoined = () => {
    const { currentUser } = this.props;
    const { email } = currentUser;

    this.checkSlackAccount(email);
  }

  handleAltEmail = (e) => {
    e.preventDefault();

    this.altEmail.current.blur();
    this.checkSlackAccount(e.target.altEmail.value);
  }

  membershipNotEligible = () => {
    this.props.upgradeMembershipModal({
      content: 'Chatting with Instructors on Slack requires a Premium membership.'
    });
  }

  checkSlackAccount(email) {
    const { currentUser } = this.props;
    const { id: userId } = currentUser;

    this.setState({ loading: true });

    this.props.alreadyJoinedWorkspace({ email, userId }).then(() => {
      this.props.removeToast('integrateSlack');
    }).catch((error) => {
      console.error(error);
      this.setState({
        errorIntegratingSlack: true,
        loading: false
      });
    });
  }

  render() {
    const { organization, currentUser } = this.props;

    if (!currentUser || !organization) return null;

    const { slackUserId, email, membershipReadable } = currentUser;
    const { integration, integrateSlack } = organization;

    if (integrateSlack === false) return null;
    if (slackUserId) return null;

    return (
      <Fragment>
        <div>
          <p className="mb-3 font-weight-bold">
            Create an account with your email:
          </p>
          <div className="mt-2">
            <p>
              <code className="bg-light p-3 d-inline-block">
                {email}
              </code>
            </p>
          </div>
        </div>

        {this.state.errorIntegratingSlack && (
          <div className="alert alert-warning my-4" role="alert">
            <h4 className="alert-heading">
              We can't find your Slack account.
            </h4>
            <p>
              If you created an account with a different email, enter your email below:
            </p>
            <hr />
            <form onSubmit={this.handleAltEmail}>
              <label>Email</label>
              <input
                name="altEmail"
                type="email"
                className="form-control"
                placeholder="Email address"
                ref={this.altEmail}
                required
                autoFocus
              />
              <button
                className="btn btn-sm btn-warning mt-3"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        )}

        <div className="d-flex mt-3">
          {membershipEligible(membershipReadable, 'slack') ? (
            <Fragment>
              <a
                href={integration.slack.links.join}
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-md btn-primary"
                title="Click to Join"
                disabled={this.state.loading}
              >
                Create Account
              </a>
              <button
                onClick={this.handleAlreadyJoined}
                type="button"
                className="btn btn-link ml-3"
                disabled={this.state.loading}
              >
                Already joined?
              </button>
            </Fragment>
          ) : (
            <button
              onClick={this.membershipNotEligible}
              type="button"
              className="btn btn-md btn-primary"
              title="Click to Join"
            >
              Create Account
            </button>
          )}
        </div>

        {this.state.loading && (
          <div className="card-loading-turbine text-info">
            <i className="fas fa-fan fa-spin" />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  currentUser,
  organization
}) => ({
  currentUser,
  organization
});

export default connect(mapStateToProps, {
  removeToast,
  alreadyJoinedWorkspace,
  upgradeMembershipModal
})(Slack);
