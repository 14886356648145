import { API_AWS_UPLOAD } from '../constants/api';
import * as twApi from './tw-api';

/**
 * Uploads a file to the user-data AWS bucket in
 * exchange for a URL.
 *
 * @param {string} prefix - The prefix/folder to upload the file into.
 * @param {File} file - The file to upload.
 * @param {string} orgId - Organization Id
 * @returns {Promise<FileObject>} - { url, id } url the uploaded file is accessible at & id which is a uuid and the new filename in the url
 */

export async function uploadFile({
  prefix, file, fileExt, orgId
}) {
  const formData = new FormData();
  formData.append('file', file);

  const { url, id } = await twApi.post(
    API_AWS_UPLOAD,
    {
      ...(fileExt ? { fileExt } : {}),
      prefix,
      organizationId: orgId
    },
    formData
  );

  return {
    url,
    id
  };
}

/**
 * Move a previously uploaded file to another location.
 *
 * @param {} previousUrl - The "previous" / current URL of the file to move.
 * @param {*} newPrefix - The new prefix (path) that the file should be moved to (excluding its file name).
 * @param {string} orgId - Organization Id
 * @returns {Promise<string>} - A URL that the copied file is accessible at.
 */
export async function moveFile({ previousUrl, newPrefix, orgId }) {
  const response = await twApi.post('/v1/external/aws/copy', {
    file: previousUrl,
    to: newPrefix,
    organizationId: orgId
  });

  return response.url;
}

/**
 * Deletes a previously uploaded image by its URL.
 *
 * @param {string} url - The URL of the uploaded image.
 * @param {string} orgId - Organization Id
 * @returns {Promise<void>}
 */
export async function deleteFile({ url, orgId }) {
  await twApi.post('/v1/external/aws/delete-file', {
    file: url,
    organizationId: orgId
  });
}
