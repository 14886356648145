import { client, flattenItems } from '../services/contentful';
import { SET_GROUPS, RESET_GROUPS } from './types';

export const getGroups = ({
  orgId,
  type = 'course'
}) => {
  return (dispatch) => {
    const config = {
      content_type: 'group',
      include: 1,
      'fields.orgId': orgId,
      'fields.type': type
    };

    return new Promise((resolve, reject) => {
      client.getEntries(config).then(({ items }) => {
        const groups = items.length ? flattenItems(items) : []; // TODO default to null?

        dispatch({
          type: SET_GROUPS,
          groups
        });

        resolve(groups);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetGroups = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GROUPS });
  };
};
