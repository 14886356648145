import React from 'react';
import { useSelector } from 'react-redux';
import { isStudent } from '@apprentage/utils';
import { FaUser, FaUsers, FaEyeSlash } from 'react-icons/fa';

const Tabs = ({ activeTab, setActiveTab, className = 'pt-3 bg-light' }) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  // Current User
  const role = currentUser?.role || [];

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setActiveTab('profile');
            }}
          >
            <span className='d-flex align-items-center'>
              <FaUser size={12} />
              <span className='ml-1'>
                Profile
              </span>
            </span>
          </button>
        </li>

        {orgType === 'workforce' && (
          <li className="nav-item ml-2">
            <button
              className={`nav-link ${activeTab === 'supervisors' ? 'active' : ''}`}
              type="button"
              onClick={() => {
                setActiveTab('supervisors');
              }}
            >
              <span className='d-flex align-items-center'>
                <FaUsers />
                <span className='ml-1'>
                  Supervisors
                </span>
              </span>
            </button>
          </li>
        )}

        {enableWorkforceSuite && isStudent(role) && (
          <li className="nav-item ml-2">
            <button
              className={`nav-link ${activeTab === 'private' ? 'active' : ''}`}
              type="button"
              onClick={() => {
                setActiveTab('private');
              }}
            >
              <span className='d-flex align-items-center'>
                <FaEyeSlash />
                <span className='ml-1'>
                  Private
                </span>
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
