import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../actions';
import {
  MODAL_KEY_CHANGE_EMAIL,
  MODAL_KEY_CHANGE_PASSWORD
} from '../../constants/modal';
import {
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import { fetchFlags } from '../../services/flagsmith';

const Account = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);

  const handleClickOnChangeButton = (modalKey) => {
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.users_readonly?.enabled && fetchedFlags?.users_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        }));
        return;
      }
      dispatch(setCurrentModal({ key: modalKey }));
    });
  };

  return (
    <div className="my-5">
      <h4>Account</h4>
      <div className="card overflow-hidden">
        <div className="card-body">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>
                <span className="mr-1 font-weight-bold d-none d-sm-inline-block">
                  Email:
                </span>
                <span>{currentUser?.email}</span>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  aria-label="Change Email"
                  onClick={() =>
                    handleClickOnChangeButton(MODAL_KEY_CHANGE_EMAIL)}
                >
                  Change
                </button>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>
                <span className="mr-1 font-weight-bold d-none d-sm-inline-block">
                  Password:
                </span>
                <span>*********</span>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  aria-label="Change Password"
                  onClick={() =>
                    handleClickOnChangeButton(MODAL_KEY_CHANGE_PASSWORD)}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
