import { findObjectByKey } from '@apprentage/utils';
import { SET_TOASTS } from './types';
import findObjectIndexByKey from '../utils/findObjectIndexByKey';

export const setToasts = (toasts) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOASTS,
      toasts
    });
  };
};

export const addToast = (toast) => {
  return (dispatch, getState) => {
    const { toasts } = getState();

    let toastsArr = toasts;

    if (!findObjectByKey(toasts, 'id', toast.id)) {
      toastsArr = [
        ...toasts,
        toast
      ];
    }

    dispatch({
      type: SET_TOASTS,
      toasts: toastsArr
    });
  };
};

export const removeToast = (id) => {
  return (dispatch, getState) => {
    const { toasts } = getState();

    if (toasts && !toasts.length) return null;

    const toastsCopy = toasts.slice();
    const index = findObjectIndexByKey(toasts, 'id', id);

    if (index !== -1) {
      toastsCopy.splice(index, 1);
    }

    dispatch({
      type: SET_TOASTS,
      toasts: toastsCopy
    });
  };
};
