import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut
} from '@firebase/auth';
import { getAnalytics } from '@firebase/analytics';
import { getMessaging } from '@firebase/messaging';
import { fetchUserByUid } from '../../services/user';
import { app } from '../../firebaseConfig';

class Firebase {
  constructor() {
    this.analytics = getAnalytics(app);
    this.auth = getAuth(app);
    this.messaging = getMessaging(app);

    /**
     * GOOGLE
     *
     * https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const googleAuthProvider = new GoogleAuthProvider();
    // googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    this.googleProvider = googleAuthProvider;

    /**
     * FACEBOOK
     *
     * https://firebase.google.com/docs/auth/web/facebook-login#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const facebookAuthProvider = new FacebookAuthProvider();
    // facebookAuthProvider.addScope('user_birthday');
    this.facebookProvider = facebookAuthProvider;
  }

  // https://firebase.google.com/docs/auth/web/password-auth#create_a_password-based_account
  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  // https://firebase.google.com/docs/auth/web/password-auth#sign_in_a_user_with_an_email_address_and_password
  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  // https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
  doSignInWithGoogle = () =>
    signInWithPopup(this.auth, this.googleProvider);

  // https://firebase.google.com/docs/auth/web/facebook-login#before_you_begin
  doSignInWithFacebook = () =>
    signInWithPopup(this.auth, this.facebookProvider);

  // https://firebase.google.com/docs/auth/web/password-auth#next_steps
  doSignOut = () => signOut(this.auth);

  // https://firebase.google.com/docs/auth/web/manage-users#send_a_password_reset_email
  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email);

  // https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email
  doSendEmailVerification = () =>
    sendEmailVerification(this.auth.currentUser, {
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  // https://firebase.google.com/docs/auth/web/manage-users#set_a_users_password
  doPasswordUpdate = (password) =>
    updatePassword(this.auth.currentUser, password);

  // https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
  onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(this.auth, (authUser) => {
      if (authUser) {
        let count = 0;
        let userExistsContentful = setInterval(() => {
          console.log('onAuthStateChanged', count);

          fetchUserByUid(authUser.uid).then((response) => {
            if (response?.id) {
              // Login
              userExistsContentful = clearInterval(userExistsContentful);
              next({ authUser, currentUser: response });
            } else {
              // New Member Registration (User not in Contentful yet)
              count += 1;

              if (count >= 10) {
                userExistsContentful = clearInterval(userExistsContentful);
                // TODO stop loading
                console.error('CM:Account creation error');
                fallback();
              }
            }
          });
        }, 1000);
      } else {
        fallback();
      }
    });

  // *** User API ***

  // user = uid => this.db.ref(`users/${uid}`);
  //
  // users = () => this.db.ref('users');

  // *** Message API ***

  // message = uid => this.db.ref(`messages/${uid}`);
  //
  // messages = () => this.db.ref('messages');
}

export default Firebase;
