import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FaUserEdit } from 'react-icons/fa';
import { getDirectoryUserProfile, resetDirectoryUserProfile } from '../../../actions/UserProfiles';
import { canManageDirectoryUsers } from '../../../services/currentUser';
import { SLASH } from '../../../constants/routes';
import Loading from '../../Loading';
import Tabs from './Tabs';
import ProfileForm from './ProfileForm';
import PrivateInfoForm from './PrivateInfoForm';
import ManageSupervisors from './ManageSupervisors';
import './style.css'; // TODO use styled components

const EditForms = () => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  const directoryUserProfile = useSelector((state) => state.directoryUserProfile);
  const directoryUserEditing = useSelector((state) => state.directoryUserEditing);
  // Misc
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const title = 'My Information';
  // Local State
  const [activeTab, setActiveTab] = useState(directoryUserEditing?.activeTab);

  useEffect(() => {
    if (directoryUser?.userProfileId && !directoryUserProfile?.id) {
      dispatch(getDirectoryUserProfile(directoryUser.userProfileId));
    }
  }, [directoryUser.userProfileId, directoryUserProfile?.id, activeTab, dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetDirectoryUserProfile());
    };
  }, [dispatch]);

  if (!canManageDirectoryUsers(role) && directoryUser?.id !== userId) {
    return (
      <Redirect to={SLASH} />
    );
  }

  if (!directoryUser?.id) {
    return;
  }

  return (
    <div className="card">
      <div className="card-header bg-light">
        <h6 className="m-0 font-weight-bold">
          <span className='d-flex align-items-center'>
            <FaUserEdit />
            <span className='ml-1'>
              {title}
            </span>
          </span>
        </h6>
      </div>

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === 'profile' && (
        <div
          className="card-content"
          id="userProfileCollapse"
        >
          <ProfileForm />
        </div>
      )}

      {activeTab === 'supervisors' && (
        <div
          className="card-content"
          id="userSupervisorsCollapse"
        >
          <ManageSupervisors />
        </div>
      )}

      {activeTab === 'private' && (
        <div
          className="card-content"
          id="userApprenticeInfoCollapse"
        >
          {directoryUser?.userProfileId && !directoryUserProfile?.id ? (
            <Loading
              text="Decrypting..."
              className='bg-white text-muted text-md'
            />
          ) : (
            <PrivateInfoForm />
          )}
        </div>
      )}
    </div>
  );
};

export default EditForms;
