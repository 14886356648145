import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWidgets } from '../../actions/Widgets';
import ListView from './ListView';

const Widgets = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const widgets = useSelector((state) => state.widgets);
  const widgetsFetched = useSelector((state) => state.widgetsFetched);
  // Organization
  const orgId = organization?.id || null;
  const dashboardWidgetIds = organization?.dashboardWidgetIds || null;

  useEffect(() => {
    if (orgId) {
      dispatch(getWidgets({
        orgId, sortByIds: dashboardWidgetIds, type: 'link', isFeatured: true
      }));
    }
  }, [dashboardWidgetIds, dispatch, orgId]);

  if (!widgets && widgetsFetched) {
    return null;
  }

  return (
    <ListView />
  );
};

export default Widgets;
