import { toast } from 'react-toastify';
import { TURBINE_ADMIN_EMAIL } from '../constants/globals';
import { TURBINE_MARKETING_SITE } from '../constants/urls';
import { subjectIcon } from './assessments';
import { GMAIL_SEND } from '../constants/api';
import * as twApi from './tw-api';

/**
 * Email an update about a submission to a challenge
 * @param {string} status // accepted, rejected, requestChanges
 * @returns {Promise<string>} emails, link
 */
export const emailManagers = ({
  to,
  replyTo,
  subject,
  message,
  bcc,
  // Organization
  orgEmail,
  orgName,
  // User
  userName,
  userPhone,
  userEmail
}) => {
  return new Promise((resolve, reject) => {
    const emailParts = {
      to: to || orgEmail || TURBINE_ADMIN_EMAIL, // peer || org || turbine,
      from: {
        name: orgName
      },
      subject: `${subjectIcon('inReview')} ${subject}`,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi ${orgName} Manager,
        </p>
        <p>
          ${message}
        </p>
        <p>
          Name: ${userName}
          <br />
          Email: <a href="mailto:${userEmail}">${userEmail}</a>
          <br />
          Phone: ${userPhone ? `<a href="tel:${userPhone}">${userPhone}</a>` : 'n/a'}
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${TURBINE_MARKETING_SITE}?track=billingNotifyManager" target="_blank" title="Turbine Workforce">Turbine Workforce</a>
        </p>
      `
    };

    if (bcc) {
      emailParts.bcc = bcc;
    }

    if (replyTo) {
      emailParts.replyTo = replyTo;
    }

    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        // TODO set flag on org notifyUsersByEmail
        // TODO implement firebase messaging and browser notifications to push messages to user
        resolve();
      } else {
        console.error(response.status);

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};

export const emailUser = ({
  subject,
  message,
  replyTo,
  to,
  toName,
  fromName,
  orgName
}) => {
  return new Promise((resolve, reject) => {
    const emailParts = {
      to,
      from: {
        name: orgName
      },
      replyTo,
      subject,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi ${toName},
        </p>
        <p>
          ${message}
        </p>
        <p>
          Best,
          <br />
          ${fromName}
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${TURBINE_MARKETING_SITE}?track=sendEmail" target="_blank" title="Turbine Workforce">Turbine Workforce</a>
        </p>
      `
    };

    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        toast.success('Message sent!');
        resolve();
      } else {
        toast.error(response.message); // ex: 'Something went wrong, try again'
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};
